import i18n from 'i18n/config'
import store from 'store'
import { PluginPostFeed } from 'types/Plugins'
import { auth } from 'utils/firebase'

const endpoint = process.env.REACT_APP_API_ENDPOINT || ''

export const getPlugins = async (
  page: number,
  take: number,
  categoryId: number | undefined,
  orderBy: string,
  headers: HeadersInit = {},
): Promise<{ items: PluginPostFeed[] }> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = `v3/chats_plugins`

  const params = new URLSearchParams({
    offset: ((page - 1) * take).toString(),
    limit: take.toString(),
    order_by: orderBy,
    order_type: orderBy === 'likes' ? 'DESC' : 'ASC',
  }).toString()

  const category = categoryId === 0 ? '' : `&category=${categoryId}`

  const url = `${endpoint}/${domain}?${params}${category}`

  const result = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const getPluginsById = async (
  id: number,
  headers: HeadersInit = {},
): Promise<PluginPostFeed> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = `v3/chats_plugins/get`

  const url = `${endpoint}/${domain}/${id}`

  const result = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const getPluginsCategories = async (headers: HeadersInit = {}) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = `v3/chats_plugins`
  const path = 'categories'

  const url = `${endpoint}/${domain}/${path}`

  const result = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const likePlugin = async (
  pluginId: number,
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v3/chats_plugins'
  const path = 'like'

  const url = `${endpoint}/${domain}/${path}/${pluginId}`

  const result = await fetch(url, {
    method: 'POST',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}
