import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'

import Container from 'components/common/Container'
import ConceptImageInput from 'components/ConceptImageInput'
import ConfirmModal from 'components/modals/ConfirmModal'
import DeleteButton from 'components/DeleteButton'
import PhotosCard from 'components/PhotosCard'
import ConceptFixedButton from 'components/ConceptFixedButton'
import { postConcept } from 'api'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'

import c from './CreateAlbum.module.scss'

function CreateAlbum() {
  const [checkedAssets, setCheckedAssets] = useState<{
    [key: number]: boolean
  }>({})
  const [selectedToDelete, setSelectedToDelete] = useState<number[]>([])
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false)
  const [albumName, setAlbumName] = useState('')
  const [imageFiles, setImageFiles] = useState<(File | undefined)[]>([])

  const { t } = useTranslation()
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const queryClient = useQueryClient()
  const { nickname } = useParams()
  const navigate = useNavigate()

  const { isLoading } = useFetchRequestMyProfile()

  const mutationPostAlbum = useMutation<void, Error>(() => {
    const validImageFiles = imageFiles.filter(
      (file): file is File => file !== undefined,
    )

    return postConcept(validImageFiles, albumName)
  })

  const imageUrls = useMemo(() => {
    return imageFiles.map((file) => {
      if (file) return URL.createObjectURL(file)
      return undefined
    })
  }, [imageFiles])

  const onImageChange = useCallback((e: any) => {
    const { files } = e.target
    if (files.length > 0) {
      setImageFiles((prevFiles) => {
        return [...prevFiles, ...files]
      })
    }
  }, [])

  const handleChangeAlbumName = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setAlbumName(event.target.value)

    const textarea = textareaRef.current
    if (!textarea) return

    textarea.style.height = 'auto'
    const scHeight = event.target.scrollHeight
    textarea.style.height = `${scHeight}px`
  }

  const selectToDelete = useCallback(
    (postId: number) => {
      if (selectedToDelete.includes(postId)) {
        setSelectedToDelete(selectedToDelete.filter((elem) => elem !== postId))
      } else {
        setSelectedToDelete([...selectedToDelete, postId])
      }
    },
    [selectedToDelete],
  )

  const handleMassRemove = () => {
    setImageFiles((prevFiles) => {
      const keysArray = Object.keys(checkedAssets).map(Number)
      const newFiles = prevFiles.filter(
        (_, index) => !keysArray.includes(index),
      )
      return newFiles.length > 0 ? newFiles : []
    })
  }

  const handleCheckboxChange = (id: number) => {
    selectToDelete(id)
    setCheckedAssets((prev) => ({
      ...prev,
      [id]: !prev[id],
    }))
  }

  const handleDeleteItems = useCallback(() => {
    setIsConfirmModalOpen(true)
    setIsDeleting(true)
  }, [])

  const handleDeleteToggle = () => {
    setCheckedAssets({})
    setIsDeleting((prev) => !prev)
    setSelectedToDelete([])
  }

  const closeModal = useCallback(() => {
    setIsConfirmModalOpen(false)
  }, [])

  const handleMassDelete = () => {
    if (isDeleting) {
      if (selectedToDelete.length === 0) {
        setIsDeleting(false)
        return
      }
      handleMassRemove()
      setIsDeleting(false)
      setCheckedAssets({})
      setSelectedToDelete([])
      setIsConfirmModalOpen(false)
    }
  }

  const handlePostAlbum = async () => {
    const result = window.confirm('Are you sure want to create album?')
    if (result) {
      try {
        mutationPostAlbum.mutateAsync().then(() => {
          queryClient.invalidateQueries([`albums`])
          queryClient.refetchQueries([`infinite-concepts`])
        })
        setImageFiles([])
        navigate(`/profile/${nickname}/albums`)
        toast.success('Everything went well')
      } catch (error) {
        toast.error('Something went wrong')
      }
    }
  }

  if (isLoading) return null

  return (
    <div className={c.gridWrapper}>
      <ConfirmModal
        heading={t('modals.general.delete')}
        confirmFunc={handleMassDelete}
        cancelFunc={closeModal}
        isModalOpen={isConfirmModalOpen}
      />
      <DeleteButton
        checkedAssets={checkedAssets}
        selectedToDelete={selectedToDelete}
        isDeleting={isDeleting}
        handleDeleteToggle={handleDeleteToggle}
        handleMassDelete={handleDeleteItems}
      />
      <div className={c.albumActions}>
        <div className={c.action}>
          <textarea
            placeholder={t('profile.setAlbumName')}
            className={c.textarea}
            value={albumName}
            rows={1}
            onChange={handleChangeAlbumName}
            ref={textareaRef}
          />
          <ConceptFixedButton
            name={albumName}
            handlePostConcept={handlePostAlbum}
            imageFiles={imageFiles}
            text={t('profile.createConcept')}
            className={c.button}
          />
        </div>
      </div>
      <Container flex>
        <div className={c.grid}>
          <ConceptImageInput
            onChange={(e) => {
              onImageChange(e)
            }}
          />
          {imageUrls.map((url, index) => {
            if (!url) return null
            const data = { id: index, key: '', link: url }
            return (
              <PhotosCard
                key={data.id}
                data={data}
                isChecked={checkedAssets[index] || false}
                handleCheckboxChange={handleCheckboxChange}
                setImageFiles={setImageFiles}
              />
            )
          })}
        </div>
      </Container>
    </div>
  )
}

export default CreateAlbum
