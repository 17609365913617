import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import Button from 'components/common/Button'

import c from './GenerateHelpModal.module.scss'

interface GenerateHelpModalProps {
  title: string
  description: string
  className: string
  src?: string
  handleClick: () => void
}

function GenerateHelpModal({
  title,
  description,
  src = undefined,
  className = '',
  handleClick,
}: GenerateHelpModalProps) {
  const { t } = useTranslation()

  return (
    <div
      className={classNames(
        { [c.container]: true },
        { [className]: className !== '' },
      )}
    >
      <h1 className={c.title}>{title}</h1>
      {src && (
        <video src={src} autoPlay muted loop className={c.video}>
          Your browser doesn&apos;t support HTML5 video tag.
        </video>
      )}
      <p className={c.text}>{description}</p>
      <Button className={c.button} onClick={handleClick}>
        {t('modals.generateHelper.buttonText')}
      </Button>
    </div>
  )
}

export default GenerateHelpModal
