import { useQuery } from '@tanstack/react-query'
import { getNotifications } from 'api'
import { NotificationsResponse } from 'types/Notifications'
import { auth } from 'utils/firebase'

const useFetchNotifications = () => {
  return useQuery<NotificationsResponse, Error>(['notifications'], async () => {
    const token = await auth.currentUser?.getIdToken()
    if (token) {
      return getNotifications(0, 0)
    }
    return Promise.reject(new Error('Invalid token'))
  })
}

export default useFetchNotifications
