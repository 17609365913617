import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import io, { Socket } from 'socket.io-client'
import { auth } from '../utils/firebase'
import { useAppSelector } from '../hooks/store'
import { MessageItem } from '../types/Chats'

const URL = process.env.REACT_APP_API_ENDPOINT || 'https://core.retomagic.com'

export interface IEventMessageChunk {
  text: string
  id: number
  isLast: boolean
}

interface IWebSocketContext {
  socket?: Socket | undefined
  eventMessage?: MessageItem | undefined
  eventMessageChunk?: IEventMessageChunk | undefined
}

interface WebSocketProps {
  children: React.ReactNode
}

export const WebSocketContext = createContext<IWebSocketContext>({})
export const useWebSocketContext = () => useContext(WebSocketContext)

export function WebSocketProvider({ children }: WebSocketProps) {
  const [eventMessage, setEventMessage] = useState<MessageItem | undefined>(
    undefined,
  )
  const [eventMessageChunk, setEventMessageChunk] = useState<
    IEventMessageChunk | undefined
  >(undefined)
  const [socketIO, setSocketIO] = useState<Socket | undefined>(undefined)
  const { isAuthInitialized } = useAppSelector((state) => state.auth)

  const value = useMemo(
    () => ({
      socket: socketIO,
      eventMessage,
      eventMessageChunk,
    }),
    [socketIO, eventMessageChunk, eventMessage],
  )

  const connect = async () => {
    const token = await auth.currentUser?.getIdToken()
    const socket = io(URL, {
      auth: {
        token,
      },
    })

    socket.on('chatMessage', (message: MessageItem) => {
      setEventMessage(message)
    })
    // socket.on('chatMessageChunk', (message: IEventMessageChunk) => {
    //   setEventMessageChunk(message)
    // })
    setSocketIO(socket)
  }

  useEffect(() => {
    if (isAuthInitialized) {
      if (!socketIO) {
        connect().catch()
      }
    }
  }, [isAuthInitialized])

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  )
}
