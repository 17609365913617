import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import VerifiedIcon from 'components/VerifiedIcon'
import ConfirmModal from 'components/modals/ConfirmModal'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'

import c from './SettingSubscribe.module.scss'

function SettingSubscribe() {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const { t } = useTranslation()

  const { data: myProfileData } = useFetchRequestMyProfile()

  const calcTime = (date: string) => {
    const [newDate] = date.split('T')
    const [year, month, day] = newDate.split('-')

    return `${day}/${month}/${year}`
  }

  const handleConfirmLogOut = useCallback(() => {
    setIsConfirmModalOpen(false)
  }, [])

  const openModal = useCallback(() => {
    setIsConfirmModalOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setIsConfirmModalOpen(false)
  }, [])

  if (!myProfileData?.is_paid_subscription) return null

  return (
    <div className={c.subscribe}>
      <ConfirmModal
        heading={t('settings.subscribe.modalHeading')}
        confirmFunc={handleConfirmLogOut}
        cancelFunc={closeModal}
        isModalOpen={isConfirmModalOpen}
      />
      <div className={c.subscribeLeft}>
        <VerifiedIcon className={c.subscribeLeftIcon} width={24} height={24} />
        <p className={c.subscribeLeftTitle}>{t('settings.subscribe.title')}</p>
      </div>
      <div className={c.subscribeRight}>
        <div className={c.subscribeRightDate}>
          {t('settings.subscribe.expiresAt')}{' '}
          {calcTime(myProfileData.adapty.expires_at)}
        </div>
        {/* <button
          type="button"
          className={c.subscribeRightButton}
          onClick={openModal}
        >
          {t('settings.subscribe.buttonText')}
        </button> */}
      </div>
    </div>
  )
}

export default SettingSubscribe
