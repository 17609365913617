import { useInfiniteQuery } from '@tanstack/react-query'
import { getFeed } from 'api'
import { useAppSelector } from 'hooks/store'
import { PostFeed } from 'types/Post'

interface Params {
  type: 'subscriptions' | 'trending'
  exceptions?: number[]
  enabled?: boolean
  take?: number
  conclusion?: 'video_only' | 'images_only' | 'all'
}

const useInfiniteFetchPosts = ({
  take = 3,
  type,
  exceptions = [],
  enabled = true,
  conclusion = 'images_only',
}: Params) => {
  const deviceId = useAppSelector((state) => state.settings.deviceId)
  const userId = useAppSelector((state) => state.auth.user?.uid)

  const deps = [deviceId, userId, type, exceptions.length, conclusion]

  return useInfiniteQuery<PostFeed[], Error>(
    [`infinite-requests-${type}`, deps],
    ({ pageParam: page = 1 }) => {
      if (type === 'subscriptions' && !userId) return Promise.resolve([])
      return getFeed(type, conclusion, page, take, exceptions)
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (!lastPage || lastPage.length < take) return undefined
        return allPages.length + 1
      },
      enabled,
    },
  )
}

export default useInfiniteFetchPosts
