import React from 'react'

import SearchIcon from 'components/icons/SearchIcon.svg'
import c from './Search.module.scss'

function Search() {
  return (
    <div className={c.searchContainer}>
      <img src={SearchIcon} alt="" className={c.search__icon} />
    </div>
  )
}

export default Search
