import React from 'react'

// eslint-disable-next-line react/require-default-props
function VariationIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
            <path
                d="M2.8834 5.94169C2.03887 5.09717 2.03887 3.72792 2.8834 2.8834C3.72792 2.03887 5.09717 2.03887 5.94169 2.8834L15.1166 12.0583C15.9611 12.9028 15.9611 14.2721 15.1166 15.1166C14.2721 15.9611 12.9028 15.9611 12.0583 15.1166L2.8834 5.94169Z"
                stroke="white"/>
            <path d="M4.5 7.5L7.5 4.5" stroke="white" strokeLinecap="round"/>
            <path
                d="M12.075 1.73039C12.1957 1.4232 12.6289 1.4232 12.7495 1.73039L13.0723 2.55185C13.1092 2.64564 13.1832 2.71988 13.2766 2.75687L14.095 3.08085C14.4011 3.20201 14.4011 3.63675 14.095 3.75791L13.2766 4.08189C13.1832 4.11888 13.1092 4.19312 13.0723 4.2869L12.7496 5.10836C12.6289 5.41556 12.1957 5.41556 12.075 5.10837L11.7523 4.2869C11.7154 4.19312 11.6414 4.11888 11.548 4.08189L10.7296 3.75791C10.4235 3.63675 10.4235 3.20201 10.7296 3.08085L11.548 2.75687C11.6414 2.71988 11.7154 2.64564 11.7523 2.55185L12.075 1.73039Z"
                stroke="white" strokeWidth="0.6"/>
            <path
                d="M14.9754 6.84758C15.0961 6.54039 15.5293 6.54039 15.6499 6.84758L15.7676 7.14709C15.8045 7.24088 15.8785 7.31512 15.9719 7.3521L16.2703 7.47023C16.5763 7.59139 16.5763 8.02616 16.2703 8.14729L15.9719 8.26541C15.8785 8.30239 15.8045 8.37664 15.7676 8.47039L15.6499 8.76994C15.5293 9.07714 15.0961 9.07714 14.9754 8.76994L14.8577 8.47039C14.8208 8.37664 14.7469 8.30239 14.6534 8.26541L14.355 8.14729C14.049 8.02616 14.049 7.59139 14.355 7.47023L14.6534 7.3521C14.7469 7.31512 14.8208 7.24088 14.8577 7.14709L14.9754 6.84758Z"
                stroke="white" strokeWidth="0.6"/>
            <path
                d="M3.8499 11.4804C3.97061 11.1732 4.40375 11.1732 4.52446 11.4804L4.64215 11.7799C4.679 11.8737 4.75297 11.9479 4.84641 11.9849L5.14481 12.103C5.45087 12.2242 5.45087 12.6589 5.14481 12.7801L4.84641 12.8982C4.75297 12.9352 4.679 13.0094 4.64215 13.1033L4.52446 13.4027C4.40375 13.7099 3.97061 13.7099 3.8499 13.4027L3.73221 13.1033C3.69536 13.0094 3.62139 12.9352 3.52795 12.8982L3.22955 12.7801C2.92349 12.6589 2.92349 12.2242 3.22955 12.103L3.52795 11.9849C3.62139 11.9479 3.69536 11.8737 3.73221 11.7799L3.8499 11.4804Z"
                stroke="white" strokeWidth="0.6"/>
        </svg>
    )
}

export default VariationIcon
