import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { forIn } from 'lodash'
import ScrollContainer from 'react-indiana-drag-scroll'

import { ReactComponent as CloseIcon } from '@material-design-icons/svg/round/close.svg'
import DownloadIcon from '../../icons/DownloadIcon'
import c from './GenerationSelectImages.module.scss'
import Button from '../../common/Button'

interface GenerationSelectImagesProps {
  onChange: (items: IItemFile[]) => void
}

export interface IItemFile {
  index: number
  image?: string
  file: File
}

function GenerationSelectImages({ onChange }: GenerationSelectImagesProps) {
  const { t } = useTranslation()
  const [files, setFiles] = useState<IItemFile[]>([])
  const form = useRef<HTMLFormElement>(null)

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const filesArr: IItemFile[] = []

      forIn(e.target.files, (value, key) => {
        if (value instanceof File) {
          if (
            value.type !== 'image/png' &&
            value.type !== 'image/jpeg' &&
            value.type !== 'image/heic'
          ) {
            return
          }

          if (filesArr.length < 5) {
            filesArr.push({
              index: parseInt(key, 10),
              file: value,
              image: URL.createObjectURL(value),
            })
          }
        }
      })

      const filesNew = [...filesArr, ...files]
        .splice(0, 5)
        .map((item, i) => ({ ...item, index: i }))

      setFiles(filesNew)
      onChange(filesNew)

      if (form.current) form.current.reset()
    }
  }

  const onClickRemove = (index: number) => {
    if (form.current) form.current.reset()
    const oldFiles = files.filter((item) => item.index !== index)

    setFiles(oldFiles)
    onChange(oldFiles)
  }

  return (
    <form className={c.fileUpload} ref={form}>
      <ScrollContainer className={c.fileUploadScroll}>
        {files.length <= 4 ? (
          <label className={c.fileUploadItem}>
            <input
              type="file"
              multiple
              onChange={onChangeInput}
              accept="image/png, image/jpeg, image/heic"
            />

            <span className={c.fileUploadItemWrapper}>
              <DownloadIcon />
              <span className={c.itemText}>
                {t('stages.enhancement.idle.dragDrop')}
              </span>
              <span>{t('stages.generation.idle.or')}</span>
              <Button variant="outlined" className={c.uploadButton}>
                {t('stages.enhancement.idle.buttonText')}
              </Button>
            </span>
          </label>
        ) : undefined}

        {files.map((item) => {
          return (
            <div className={c.item} key={item.index}>
              <img className={c.itemImage} src={item.image} alt="" />
              <button
                type="button"
                onClick={() => onClickRemove(item.index)}
                className={c.itemRemove}
              >
                <CloseIcon />
              </button>
            </div>
          )
        })}
      </ScrollContainer>
    </form>
  )
}

export default GenerationSelectImages
