import React, { useCallback, useState } from 'react'

import Suggestions from 'components/Suggestions'
import NegativePromptInput from 'components/NegativePromptInput'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'

import { useTranslation } from 'react-i18next'
import useFetchSuggestionsForNegativePrompt from '../../hooks/query/useFetchSuggestionsForNegativePrompt'
import ShortArrow from '../icons/ShortArrow'
import c from './PromptsContainer.module.scss'

interface PromptsContainerProps {
  negativePrompt: string
  setNegativePrompt: (newPrompt: string) => void
}

function PromptsContainer({
  negativePrompt,
  setNegativePrompt,
}: PromptsContainerProps) {
  const [isPromptsOpen, setIsPromptsOpen] = useState<boolean>(false)

  const { data: suggestionsForNegativePrompt } =
    useFetchSuggestionsForNegativePrompt()

  const { t } = useTranslation()

  const togglePopup = () => {
    setIsPromptsOpen(!isPromptsOpen)
  }

  const onExampleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setNegativePrompt(event.currentTarget?.value)
    },
    [],
  )

  return (
    <div
      className={classNames({
        [c.promptsContainer]: true,
        [c.promptsContainerUnActive]: !isPromptsOpen,
      })}
    >
      <button
        type="button"
        onClick={togglePopup}
        className={c.wrapperAdvancedSettings}
      >
        <div className={c.leftPart}>
          <div className={c.stylesTitle}>
            {t('stages.generation.idle.negativePrompt')}
          </div>
        </div>
        <div className={c.rightPart}>
          <ShortArrow
            className={classNames(c.arrowIcon, {
              [c.arrowIconOpen]: !isPromptsOpen,
            })}
          />
        </div>
      </button>
      <CSSTransition
        in={isPromptsOpen}
        timeout={100}
        classNames={{
          enter: c.settingsEnter,
          enterActive: c.settingsEnterActive,
          exit: c.settingsExit,
          exitActive: c.settingsExitActive,
        }}
        mountOnEnter
        unmountOnExit
      >
        <div className={c.content}>
          <NegativePromptInput
            negativePrompt={negativePrompt}
            setNegativePrompt={setNegativePrompt}
          />
          <Suggestions
            suggestions={suggestionsForNegativePrompt}
            onExampleClick={onExampleClick}
          />
        </div>
      </CSSTransition>
    </div>
  )
}

export default PromptsContainer