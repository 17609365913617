/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useParams, useNavigate } from 'react-router-dom'
import { BaseResponse } from 'types/Response'
import { ReactComponent as CopyIcon } from '@material-design-icons/svg/outlined/content_copy.svg'
import { ReactComponent as CheckIcon } from '@material-design-icons/svg/round/check.svg'
import { ReactComponent as ReplayIcon } from '@material-design-icons/svg/round/replay.svg'
import { deleteRequest, postPost } from 'api'
import IconButton from 'components/common/IconButton'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
import { ReactComponent as DeleteIcon } from '@material-design-icons/svg/outlined/close.svg'
import { ReactComponent as PublishIcon } from '@material-design-icons/svg/outlined/ios_share.svg'
import { ReactComponent as RightIcon } from '@material-design-icons/svg/outlined/arrow_forward_ios.svg'
import { ReactComponent as LeftIcon } from '@material-design-icons/svg/outlined/arrow_back_ios.svg'
import useCopy from 'hooks/useCopy'

import SliderAssets from 'components/SliderAssets'
import SwitchToPrivate from 'components/SwitchToPrivate'

import c from './ArchivePost.module.scss'

interface ArchivePostProps {
  post: BaseResponse
  imgClassName?: string
  prevHandler?: () => void
  nextHandler?: () => void
  currentSlide: number
  setCurrentSlide: (n: number) => void
  onGenerateMore: () => void
  postsData?: any
  currentPostId?: number
}

function ArchivePost({
  post,
  imgClassName = '',
  prevHandler = undefined,
  nextHandler = undefined,
  currentSlide,
  setCurrentSlide,
  onGenerateMore,
  postsData = undefined,
  currentPostId = undefined,
}: ArchivePostProps) {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const queryClient = useQueryClient()

  const { data: you } = useFetchRequestMyProfile()

  const { id } = useParams()

  const [forSubs, setForSubs] = useState<boolean>(false)

  const handleForSubs = () => {
    setForSubs(!forSubs)
  }

  const mutationDelete = useMutation<void, Error>(() => {
    return deleteRequest(post.id)
  })

  const mutationPublish = useMutation<void, Error, number>((assetId) => {
    return postPost({ assets: [assetId!], subscribers_only: forSubs })
  })

  const { isCopied, handleCopy } = useCopy(post.assets?.[0]?.source)

  const deleteYourPost = () => {
    const result = window.confirm(
      'Are you sure want to delete this archive post?',
    )
    if (result) {
      mutationDelete.mutateAsync().then(() => {
        queryClient.invalidateQueries([`archiveData`, id])
        queryClient.refetchQueries([`infinite-requests`]).then(() => {
          toast.success('Everything went well')
          navigate(`/profile/${you?.nickname}`)
        })
      })
    }
  }

  const sharePost = () => {
    const result = window.confirm(
      'Are you sure want to share this archive post?',
    )
    if (result) {
      mutationPublish.mutateAsync(post.assets[currentSlide].id).then(() => {
        queryClient.invalidateQueries([`request`, id])
        queryClient
          .refetchQueries([`infinite-profilePosts-${you?.id}`])
          .then(() => {
            toast.success('Everything went well')
            navigate(`/profile/${you?.nickname}`)
          })
      })
    }
  }

  const copyIcon = useMemo(() => {
    return isCopied ? (
      <CheckIcon className={c.iconForCopy} style={{ fill: 'currentColor' }} />
    ) : (
      <CopyIcon className={c.iconForCopy} style={{ fill: 'currentColor' }} />
    )
  }, [isCopied])

  return (
    <div className={c.post}>
      <div
        className={`${
          post.assets?.[currentSlide].source_type === 'video'
            ? c.postImgWrapperTrending
            : c.postImgWrapper
        } ${imgClassName}`}
      >
        {post.assets?.[currentSlide].source_type === 'video' &&
          post.assets?.[0].url && (
            <video
              src={post.assets?.[currentSlide].url}
              className={c.postImg}
              controls
              loop
            />
          )}
        {post.assets?.[currentSlide].source_type !== 'video' &&
          post.assets?.[currentSlide].url && (
            <>
              <img
                className={c.postImg}
                src={post.assets?.[currentSlide].url}
                alt=""
              />
              <NavLink to={`/archive/${post.id}`} end className={c.link} />
            </>
          )}
      </div>
      {post.assets.length > 1 && (
        <SliderAssets
          assets={post.assets}
          currentSlide={currentSlide}
          nextHandler={nextHandler}
          prevHandler={prevHandler}
          setCurrentSlide={setCurrentSlide}
        />
      )}
      <div className={c.postBottom}>
        <div className={c.arrows}>
          <div className={c.leftArrow}>
            <LeftIcon className={c.icon} />
            {postsData?.[currentPostId! - 1]?.id && (
              <NavLink
                to={`/archive/${postsData?.[currentPostId! - 1]?.id}`}
                end
                className={c.link}
                state={{
                  postsData,
                  index: currentPostId! - 1,
                }}
              />
            )}
          </div>
          <div className={c.rightArrow}>
            <RightIcon className={c.icon} />
            {postsData?.[currentPostId! + 1]?.id && (
              <NavLink
                to={`/archive/${postsData?.[currentPostId! + 1]?.id}`}
                end
                className={c.link}
                state={{
                  postsData,
                  index: currentPostId! + 1,
                }}
              />
            )}
          </div>
        </div>
        <div className={c.textAndCopy}>
          <div className={c.text}>
            {post.assets?.[0]?.source_type === 'image' ? (
              <>
                {post.handler === 'George_Washington' && (
                  <span className={c.textStyle}>
                    {t('navigation.enhancement')}
                  </span>
                )}
                {post.handler === 'Thomas_Jefferson' && (
                  <span className={c.textStyle}>
                    {t('navigation.restoration')}
                  </span>
                )}
                {post.handler === 'James_Monroe' && (
                  <span className={c.textStyle}>{t('navigation.remover')}</span>
                )}
              </>
            ) : (
              <>
                {post.assets?.[0]?.source}
                {post.assets?.[0]?.style &&
                  post.assets?.[0]?.style !== 'undefined' && (
                    <span className={c.textStyle}>
                      {post.assets?.[0]?.style}
                    </span>
                  )}
              </>
            )}
          </div>
          <IconButton
            className={c.postCopy}
            disabled={isCopied}
            onClick={handleCopy}
          >
            {copyIcon}
          </IconButton>
        </div>
        <div className={c.settings}>
          <button type="button" className={c.delete} onClick={deleteYourPost}>
            <DeleteIcon className={c.icon} />
            {t('profile.delete')}
          </button>
          <div className={c.settings__section}>
            <button
              type="button"
              className={c.publish}
              onClick={onGenerateMore}
            >
              <ReplayIcon className={c.icon} />
              {t('profile.repeat')}
            </button>
            <SwitchToPrivate forSubs={forSubs} handleForSubs={handleForSubs} />
            <button type="button" className={c.publish} onClick={sharePost}>
              <PublishIcon className={c.icon} />
              {t('profile.publish')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArchivePost
