import { postRequestEnhancement } from 'api'
import { toast } from 'react-toastify'
import { OutputFormat } from 'types/OutputFormat'
import { BaseResponse, LimitError } from 'types/Response'
import Handlers from 'types/Handlers'
import Strategy from './Strategy'

export default class EnhanceStrategy extends Strategy {
  private readonly images: File[]

  private handler: `${Handlers}` = Handlers.THOMAS_JEFFERSON

  private outputFormat: OutputFormat = 'jpeg'

  private responseSeconds?: number

  constructor(images: File[]) {
    super()

    this.images = images
  }

  public setHandler(handler: `${Handlers}`) {
    this.handler = handler
    return this
  }

  public getSeconds() {
    return this.responseSeconds
  }

  public setOutputFormat(outputFormat: OutputFormat) {
    this.outputFormat = outputFormat
    return this
  }

  public stop(): void {
    this.collector?.stop()
  }

  public async start(): Promise<void> {
    const { id, assets, meta } = await this.makeRequest()
    this.responseSeconds = meta.response_seconds
    this.collectAssets(id, assets.length).then()
  }

  public async makeRequest(): Promise<BaseResponse> {
    try {
      const formData = new FormData()
      formData.append('outputFormat', this.outputFormat)
      this.images.forEach((image) => {
        formData.append('assets', image)
      })
      return await postRequestEnhancement(formData, this.handler)
    } catch (error) {
      this.collector?.stop()

      const errorData = error as LimitError
      if (errorData.key === 'LIMIT_GENERATION') {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw errorData
      }

      toast.error('Something went wrong, try again!')
      throw error
    }
  }
}
