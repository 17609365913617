import React from 'react'
import { Img } from 'react-image'
import { createSlot } from 'react-slotify'
import { useTranslation } from 'react-i18next'

import { ResultCardAsset } from 'types/Response'
import Loader from '../common/Loader'
import FullScreenIcon from '../icons/FullScreenIcon'

import c from './ResultCard.module.scss'

interface ResultCardProps {
  children: React.ReactNode
  asset: ResultCardAsset
  isImageLoaded: { [key: number]: boolean }
  handleImageLoaded: (id: number) => void
  components?: {
    compare: boolean
    fullscreen: boolean
  }
  openModal: () => void
}

export const ResultCardFooterSlot = createSlot()

export default function ResultCard({
  children,
  asset,
  isImageLoaded,
  handleImageLoaded,
  components = {
    compare: false,
    fullscreen: false,
  },
  openModal,
}: ResultCardProps) {
  const { t } = useTranslation()

  return (
    <div className={c.assetContainer}>
      <div className={c.assetImageWrapper}>
        {asset.status === 'success' && (
          <Img
            src={asset.finish_url || 'defaultImageUrl'}
            className={c.assetImage}
            loader={<Loader />}
            onLoad={() => {
              handleImageLoaded(asset.id)
            }}
          />
        )}

        {asset.status !== 'success' && <Loader />}

        {components?.fullscreen && (
          <button type="button" onClick={openModal} className={c.wrapper}>
            {isImageLoaded[asset.id] && (
              <div className={c.fullScreenContainer}>
                <div className={c.iconSetting}>
                  <FullScreenIcon className={c.iconSetting} />
                </div>
              </div>
            )}
          </button>
        )}
      </div>

      <div className={c.assetActions}>
        <ResultCardFooterSlot.Renderer childs={children} />
      </div>
    </div>
  )
}
