/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import GenerationModal from 'components/GenerationModal'
import { useTranslation } from 'react-i18next'
import { changeStage } from 'store/reducers/stageReducer'
import Processor, { VariationsStrategy } from 'api/Retomagic'
import useFetchSuggestions from 'hooks/query/useFetchSuggestions'
import useFetchStyles from 'hooks/query/useFetchStyles'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import PowerSelection from 'components/PowerSelection'
import Suggestions from 'components/Suggestions'
import AdvancedSettings from 'components/AdvancedSettings'
import GenerationStyles from 'components/GenerationStyles'
import GenerationInput from 'components/GenerationInput'
import ProcessingStage from 'components/stages/variations/ProcessingStage'
import {
  ProcessedAssetsChangedPayload,
  ProcessorEvents,
} from 'types/ProcessorEvents'
import AddImage from 'components/AddImage'
import useFetchRequestPost from 'hooks/query/useFetchRequestPost'
import SliderAssets from 'components/SliderAssets'
import ImageComparison from 'components/ImageComparison'
import Button from 'components/common/Button'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createComment } from 'api'
import { toast } from 'react-toastify'
import { PostComment } from 'types/Comments'
import { BaseAsset } from 'types/Response'

import s from './CommentModal.module.scss'

interface CommentModalProps {
  open: boolean
  onClose: () => void
  parentComment: PostComment | undefined
  setParentComment: (comm: PostComment | undefined) => void
}

function CommentModal({
  open,
  onClose,
  parentComment,
  setParentComment,
}: CommentModalProps) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { stage } = useAppSelector((state) => state.stage)

  const queryClient = useQueryClient()

  const { id } = useParams()
  const [seconds, setSeconds] = useState<number | undefined>(0)

  const { data: postData } = useFetchRequestPost({ id: id! })

  const { data: stylesData } = useFetchStyles()

  const { data: suggestionsData } = useFetchSuggestions()

  const asset = useMemo(() => {
    return parentComment !== undefined
      ? parentComment.asset
      : postData?.assets?.[0]
  }, [id, parentComment, postData])

  const [isAdvancedSettingsOpen, setIsAdvancedSettingsOpen] = useState(false)
  const [text, setText] = useState<string>(asset?.source || '')

  const [guidanceScale, setGuidanceScale] = useState<number>(
    asset?.settings?.guidance_scale || 7.5,
  )
  const [chosenStyle, setChosenStyle] = useState<string | undefined>(
    asset?.source || undefined,
  )
  const [seed, setSeed] = useState<string>(asset?.settings?.seed || '')
  const [steps, setSteps] = useState<number>(asset?.settings?.num_steps || 50)
  const [negativePrompt, setNegativePrompt] = useState<string>(
    asset?.settings?.negative_prompt || '',
  )
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  const [finish, setFinish] = useState<boolean | undefined>(undefined)
  const [processedAssets, setProcessedAssets] = useState<
    BaseAsset[] | undefined
  >(undefined)

  const mutationComment = useMutation<void, Error>(() => {
    return createComment(
      Number(id!),
      processedAssets?.[currentSlide]?.id!,
      parentComment?.id,
    )
  })

  const handleCreateComment = () => {
    const result = window.confirm('Are you sure want to leave a comment?')
    if (result) {
      mutationComment.mutateAsync().then(() => {
        queryClient.invalidateQueries([`post`, id])
        queryClient.refetchQueries([`infinite-comments`]).then(() => {
          toast.success('Everything went well')
        })
      })
      onClose()
      setParentComment(undefined)
      setFinish(undefined)
    }
  }

  const [strength, setStrength] = useState<number>(50)

  const [generationProcessor, setGenerationProcessor] =
    useState<Processor | null>(null)

  const imageUrl = useMemo(() => {
    return asset?.url
  }, [postData, id])

  const setInitialSettings = () => {
    setText(asset?.source || '')
    setChosenStyle(asset?.style || '')
    setGuidanceScale(asset?.settings?.guidance_scale || 7.5)
    setSteps(asset?.settings?.num_steps || 50)
    setNegativePrompt(asset?.settings?.negative_prompt || '')
    setSeed(asset?.settings?.seed || '')
  }

  useEffect(() => {
    setInitialSettings()
  }, [asset])

  const handleProcessedAssetsChanged = useCallback(
    (payload: ProcessedAssetsChangedPayload) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { assets } = payload

      setProcessedAssets(assets)
      dispatch(changeStage('idle'))
      setFinish(true)
    },
    [],
  )

  const handleProcessingStart = useCallback(async () => {
    dispatch(changeStage('processing'))

    const thisStyle = stylesData.find(
      (elem) => elem.title === chosenStyle || elem.id === chosenStyle,
    )

    const strategy = new VariationsStrategy({
      image:
        parentComment !== undefined
          ? String(parentComment.asset?.id)
          : String(asset?.id),
      request: text,
      styleTitle: thisStyle?.title!,
      styleText: thisStyle?.text!,
      strength: (strength / 100).toFixed(2),
      guidanceScale,
      negativePrompt,
      numSteps: steps,
      requestId: undefined,
      seed,
    })
    const processor = new Processor(strategy)
    processor.on(
      ProcessorEvents.PROCESSED_ASSETS_CHANGED,
      handleProcessedAssetsChanged,
    )

    await processor.start()
    setSeconds(strategy.getSeconds())

    if (!processor) {
      setGenerationProcessor(processor)
    }
  }, [
    id,
    text,
    handleProcessedAssetsChanged,
    chosenStyle,
    stylesData,
    imageUrl,
    strength,
    isAdvancedSettingsOpen,
    guidanceScale,
    seed,
    steps,
    negativePrompt,
    postData,
    parentComment,
    asset,
  ])

  const onExampleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setText(event.currentTarget?.value)
    },
    [],
  )

  const onStyleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (chosenStyle === event.currentTarget.value) {
        setChosenStyle(undefined)
      } else {
        setChosenStyle(event.currentTarget.value)
      }
    },
    [chosenStyle],
  )

  useEffect(() => {
    return () => {
      if (stage === 'processing' && open) {
        generationProcessor?.stop()
        setGenerationProcessor(null)
        dispatch(changeStage('idle'))
        setFinish(undefined)
        setParentComment(undefined)
      }
    }
  }, [generationProcessor])

  useEffect(() => {
    if (!open) {
      generationProcessor?.stop()
      setParentComment(undefined)
      setGenerationProcessor(null)
      dispatch(changeStage('idle'))
      setFinish(undefined)
    }
  }, [open])

  const prevHandler = () => {
    if (currentSlide > 0) setCurrentSlide((prev) => prev - 1)
  }
  const nextHandler = () => {
    if (currentSlide + 1 < processedAssets!.length)
      setCurrentSlide((prev) => prev + 1)
  }

  const repeat = () => {
    setFinish(undefined)
    setProcessedAssets([])
    dispatch(changeStage('idle'))
  }

  return (
    <GenerationModal open={open} onClose={onClose}>
      {stage === 'idle' && !finish && (
        <div className={s.generation}>
          <div className={s.imageToComment}>
            <AddImage
              imageUrl={
                parentComment !== undefined ? parentComment.asset.url : imageUrl
              }
            />
          </div>

          <Button
            variant="contained"
            className={s.setInitial}
            onClick={setInitialSettings}
          >
            {t('modals.comment.setInitialSettings')}
          </Button>
          <PowerSelection setStrength={setStrength} strength={strength} />
          <GenerationInput
            onProcessingStart={handleProcessingStart}
            onTextChange={setText}
            text={text}
          />
          <Suggestions
            suggestions={suggestionsData}
            onExampleClick={onExampleClick}
          />
          <AdvancedSettings
            isAdvancedSettingsOpen={isAdvancedSettingsOpen}
            setIsAdvancedSettingsOpen={setIsAdvancedSettingsOpen}
            guidanceScale={guidanceScale}
            setGuidanceScale={setGuidanceScale}
            seed={seed}
            setSeed={setSeed}
            steps={steps}
            setSteps={setSteps}
          />
          <GenerationStyles
            chosenStyle={chosenStyle}
            styles={stylesData}
            onStyleClick={onStyleClick}
          />
          <div className={s.fixed}>
            <Button
              className={s.button}
              disabled={text.length <= 0}
              onClick={handleProcessingStart}
            >
              {t('stages.generation.idle.buttonText')}
            </Button>
          </div>
        </div>
      )}
      {stage === 'processing' && (
        <div className={s.processing}>
          <ProcessingStage seconds={seconds!} />
        </div>
      )}
      {stage === 'idle' && finish && (
        <div className={s.finishStage}>
          <div className={s.finish}>
            {/* <ImageComparison
              imageUrl={
                parentComment !== undefined
                  ? parentComment.asset.url!
                  : imageUrl!
              }
              processedImageUrl={processedAssets?.[currentSlide]?.url!}
            /> */}
            <div className={s.controlPanel}>
              {processedAssets!.length > 1 && (
                <SliderAssets
                  assets={processedAssets!}
                  currentSlide={currentSlide}
                  nextHandler={nextHandler}
                  prevHandler={prevHandler}
                  setCurrentSlide={setCurrentSlide}
                  onlyImage
                />
              )}

              <div className={s.settings}>
                <Button
                  className={s.publish}
                  variant="outlined"
                  onClick={handleCreateComment}
                >
                  {t('modals.comment.submit')}
                </Button>
                <Button
                  className={s.repeat}
                  variant="contained"
                  onClick={handleProcessingStart}
                >
                  {t('modals.comment.repeat')}
                </Button>
              </div>
              <div className={`${s.settings} ${s.extra}`}>
                <Button
                  className={s.repeat}
                  variant="contained"
                  onClick={repeat}
                >
                  {t('modals.comment.repeatWithNewText')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </GenerationModal>
  )
}

export default CommentModal
