import React, { useCallback } from 'react'
import TextInput from 'components/common/TextInput'
import { useTranslation } from 'react-i18next'
import int64validator from 'utils/int64validator'

import c from './SeedInput.module.scss'

interface SeedInputProps {
  seed: string | undefined
  setSeed: (newSeed: string) => void
}

function SeedInput({ seed, setSeed }: SeedInputProps) {
  const { t } = useTranslation()

  const handleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (int64validator(parseInt(e.target.value, 10))) {
        setSeed(e.target.value)
      }
    },
    [setSeed],
  )

  return (
    <div>
      <p className={c.heading}>{t('stages.generation.idle.seedInputLabel')}</p>
      <TextInput
        className={c.input}
        value={seed}
        onChange={handleTextChange}
        placeholder={t('stages.generation.idle.seedInputExample')}
      />
    </div>
  )
}

export default SeedInput
