import React from 'react'

// eslint-disable-next-line react/require-default-props
function VerifiedIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M22.953 11.6463L20.916 9.31834C20.82 9.20734 20.772 9.06334 20.784 8.91634L21.069 5.83834C21.093 5.57134 20.916 5.32534 20.652 5.26534L17.64 4.57834C17.496 4.54534 17.373 4.45534 17.295 4.32634L15.717 1.65634C15.579 1.42234 15.291 1.32934 15.042 1.43734L12.207 2.65534C12.072 2.71234 11.919 2.71234 11.784 2.65534L8.94901 1.43734C8.70001 1.32934 8.412 1.42534 8.274 1.65634L6.69901 4.31734C6.62401 4.44334 6.49801 4.53634 6.35401 4.56634L3.34501 5.24134C3.081 5.30134 2.904 5.54734 2.928 5.81434L3.213 8.90434C3.225 9.05134 3.18001 9.19534 3.08401 9.30634L1.044 11.6463C0.867005 11.8503 0.867005 12.1503 1.044 12.3543L3.081 14.6853C3.177 14.7963 3.225 14.9403 3.213 15.0873L2.928 18.1773C2.904 18.4443 3.081 18.6903 3.34501 18.7503L6.36 19.4313C6.504 19.4643 6.62701 19.5543 6.702 19.6803L8.27701 22.3413C8.41501 22.5753 8.703 22.6683 8.95201 22.5603L11.787 21.3363C11.922 21.2763 12.075 21.2763 12.213 21.3363L15.048 22.5543C15.297 22.6623 15.585 22.5663 15.723 22.3353L17.298 19.6743C17.373 19.5483 17.496 19.4583 17.64 19.4253L20.652 18.7413C20.916 18.6813 21.093 18.4353 21.069 18.1683L20.784 15.0903C20.769 14.9433 20.817 14.7993 20.916 14.6883L22.953 12.3603C23.13 12.1503 23.13 11.8503 22.953 11.6463Z"
        fill="url(#paint0_linear_5611_20289)"
      />
      <path
        d="M9.54906 16.3347L6.64506 13.4247C6.37206 13.1517 6.37206 12.7107 6.64506 12.4377L7.17306 11.9097C7.44606 11.6367 7.89006 11.6367 8.16306 11.9097L9.54906 13.3017C9.82206 13.5747 10.2661 13.5747 10.5391 13.3017L15.5401 8.28873C15.8131 8.01573 16.2571 8.01573 16.5301 8.28873L17.0581 8.81673C17.3311 9.08973 17.3311 9.53073 17.0581 9.80373L10.5391 16.3347C10.2661 16.6107 9.82206 16.6107 9.54906 16.3347Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5611_20289"
          x1="-3.16727"
          y1="11.9988"
          x2="25.1526"
          y2="26.3946"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9E5992" />
          <stop offset="0.505208" stopColor="#6A19AB" />
          <stop offset="1" stopColor="#3054BB" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default VerifiedIcon
