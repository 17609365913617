import { useInfiniteQuery } from '@tanstack/react-query'
import { getChatMessages, getChats } from '../../api/chats'
import { MessagesResponse } from '../../types/Chats'
import { auth } from '../../utils/firebase'

const LIMIT = 30

const getOffset = (page: number): number => {
  return (page - 1) * LIMIT
}

interface Params {
  recipientId: string | undefined
  theme?: number
}

const useInfiniteFetchMessages = ({ recipientId, theme }: Params) => {
  return useInfiniteQuery<MessagesResponse, Error>(
    ['infinite-chat-messages', recipientId],
    async ({ pageParam = 1 }): Promise<MessagesResponse> => {
      const offset = getOffset(pageParam)
      const token = await auth.currentUser?.getIdToken()
      if (token && recipientId) {
        return getChatMessages(recipientId, LIMIT, offset, theme)
      }
      throw new Error('Invalid token')
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.items.length === 0) return undefined
        return allPages.length + 1
      },
    },
  )
}

export default useInfiniteFetchMessages
