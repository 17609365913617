import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'

import { createSlot } from 'react-slotify'
import { useLocation } from 'react-router-dom'
import c from './GenerationContainer.module.scss'
import Loader from '../../common/Loader'
import useFetchRequestMyProfile from '../../../hooks/query/useFetchRequestMyProfile'
import GenerationSwitcher from '../../GenerationSwitcher'

interface GenerationContainerProps {
  children: React.ReactNode
  stage: string
  isLoading?: boolean
}

export const MainSlot = createSlot()
export const AsideSlot = createSlot()

export default function GenerationContainer({
  children,
  stage,
  isLoading = false,
}: GenerationContainerProps) {
  const [isMobile, setIsMobile] = useState<boolean>(false)

  const { data: user } = useFetchRequestMyProfile()

  const location = useLocation()

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth <= 768)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  const isDallePage = location.pathname === '/dall-e-3'

  return (
    <div className={c.container}>
      {!(stage !== 'idle' || location.pathname.includes('finish')) && (
        <GenerationSwitcher />
      )}

      <div className={c.content}>
        {!isMobile ? (
          <div className={c.main} id="generationContainerMain">
            <div
              className={classNames({
                [c.wrapper]: true,
                [c.loaderWrapper]: stage === 'processing',
                [c.dalleWrapper]: isDallePage,
              })}
            >
              {isLoading ? (
                <div className={c.loader}>
                  <Loader />
                </div>
              ) : (
                <MainSlot.Renderer childs={children} />
              )}
            </div>
          </div>
        ) : (
          <div
            className={classNames({
              [c.wrapper]: true,
              [c.load]: stage === 'processing',
            })}
            id="generationContainerWrapper"
          >
            <MainSlot.Renderer childs={children} />
          </div>
        )}
        <div
          className={classNames({
            [c.aside]: true,
            [c.visible]:
              (isMobile && stage === 'processing') ||
              (isMobile && location.pathname.includes('finish')),
          })}
        >
          {!isDallePage && <AsideSlot.Renderer childs={children} />}

          {((isDallePage && !isMobile) ||
            (user?.is_paid_subscription && isMobile && isDallePage)) && (
            <AsideSlot.Renderer childs={children} />
          )}
        </div>
      </div>
    </div>
  )
}
