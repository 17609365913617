import React from 'react'

// eslint-disable-next-line react/require-default-props
function AiChatIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Icon">
        <g id="Group">
          <path
            id="Vector"
            d="M4.01195 12.4856C4.01194 12.8169 4.14634 13.1169 4.36355 13.3341C4.58077 13.5513 4.8808 13.6857 5.21206 13.6857L10.8 13.6856L10.7999 19.2736C10.7999 19.9361 11.3372 20.4733 12 20.4737C12.6625 20.4737 13.1998 19.9364 13.2002 19.2735L13.2003 13.6856L18.7882 13.6854C19.4508 13.6854 19.988 13.1481 19.9884 12.4853C19.9884 11.8228 19.4511 11.2855 18.7883 11.2852L13.2003 11.2853L13.2001 5.697C13.2001 5.03448 12.6628 4.49721 12 4.49689C11.3375 4.4969 10.8002 5.03419 10.7998 5.69705L10.7997 11.285L5.21176 11.2851C4.54925 11.2851 4.01196 11.8224 4.01161 12.4853L4.01195 12.4856Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  )
}

export default AiChatIcon
