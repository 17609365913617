import { useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const normalizePathName = (pathname: string): string => {
  // Удаление trailing slash из pathname
  return pathname.replace(/\/+$/, '')
}

const checkNesting = (normalizedPathname: string): boolean => {
  // Проверка на вложенность пути > 1. Пример: "settings/language"
  const slashCount = normalizedPathname.split('/').length - 1
  return slashCount > 1
}

const useLocationInfo = () => {
  const { pathname } = useLocation()
  const { requestId } = useParams()
  const { t } = useTranslation()

  const normalizedPathname = useMemo(
    () => normalizePathName(pathname),
    [pathname],
  )

  const isNested = useMemo(
    () => checkNesting(normalizedPathname),
    [normalizedPathname],
  )

  const heading = useMemo(() => {
    switch (normalizedPathname) {
      case '/settings': {
        return t('settings.heading')
      }
      case '/settings/language': {
        return t('settings.language.heading')
      }
      case '/terms-of-use': {
        return t('settings.documents.items.terms')
      }
      case '/privacy-policy': {
        return t('settings.documents.items.policy')
      }
      case `/history/${requestId}`: {
        return t('stages.enhancement.finish.heading')
      }
      default: {
        return ''
      }
    }
  }, [t, normalizedPathname, requestId])

  const backTo = useMemo(() => {
    switch (normalizedPathname) {
      case '/settings': {
        return '/'
      }
      case '/settings/language': {
        return '/settings'
      }
      case '/terms-of-use': {
        return '/settings'
      }
      case '/privacy-policy': {
        return '/settings'
      }
      default: {
        return '/'
      }
    }
  }, [normalizedPathname])

  return { pathname: normalizedPathname, isNested, heading, backTo }
}

export default useLocationInfo
