import { configureStore } from '@reduxjs/toolkit'
import authReducer from './reducers/authReducer'
import settingsReducer from './reducers/settingsReducer'
import viewedReducer from './reducers/viewedReducer'
import stageReducer from './reducers/stageReducer'
import modalReducer from './reducers/modalReducer'

const store = configureStore({
  reducer: {
    auth: authReducer,
    settings: settingsReducer,
    viewed: viewedReducer,
    stage: stageReducer,
    modal: modalReducer,
  },
})

export type AppDispatch = typeof store.dispatch

export type RootState = ReturnType<typeof store.getState>

export default store
