import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import ChatMessages from 'components/ChatMessages'
import ChatPanelContent from 'components/ChatPanelContent'
import FeatureDisabled from 'components/FeatureDisabled'
import MobileHeader from '../../components/MobileHeader'
import useFetchRequestMyProfile from '../../hooks/query/useFetchRequestMyProfile'
import useFetchRequestProfile from '../../hooks/query/useFetchRequestProfile'
import useInfiniteFetchChats from '../../hooks/query/useInfiniteFetchChats'
import useDidMountEffect from '../../hooks/useDidMountEffect'
import {
  IEventMessageChunk,
  useWebSocketContext,
} from '../../providers/WebSocket'
import { MessageItem } from '../../types/Chats'

import c from './AIChatPage.module.scss'

function AIChatPage() {
  const [activeChat, setActiveChat] = useState<MessageItem | undefined>(
    undefined,
  )
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [chats, setChats] = useState<MessageItem[]>([])

  const { id } = useParams()
  const { data: user } = useFetchRequestMyProfile()

  const { eventMessage, socket, eventMessageChunk } = useWebSocketContext()

  const navigate = useNavigate()

  const { t } = useTranslation()

  const { data: userBot } = useFetchRequestProfile({ user: 23332 })

  const { data: chatsData } = useInfiniteFetchChats()

  useEffect(() => {
    if (activeChat && !id) {
      setActiveChat(undefined)
    }
  }, [id])

  const handleClickChat = useCallback(
    (recipientId: string) => {
      const filteredChat = chats.find((chat) => {
        if (
          (chat.recipient.id.toString() === recipientId &&
            chat.sender.id === user?.id) ||
          (chat.recipient.id === user?.id &&
            chat.sender.id.toString() === recipientId)
        ) {
          return chat
        }
        return undefined
      })

      if (!filteredChat) return
      setActiveChat(filteredChat)
      if (recipientId) navigate(`/chats/${recipientId}`)
    },
    [chats, user, id],
  )

  const resetActiveChat = useCallback(() => {
    setActiveChat(undefined)
    navigate('/chats')
  }, [])

  useEffect(() => {
    if (id) {
      handleClickChat(id)
    }
  }, [id, handleClickChat, user])

  useEffect(() => {
    if (chatsData) {
      const findBotChat = chatsData.find(
        (chat) =>
          String(chat.recipient.id) === '23332' ||
          String(chat.sender.id) === '23332',
      )
      if (!findBotChat && userBot && user) {
        const newChat: MessageItem = {
          id: 0,
          text: 'Hi! I am a bot. I can help you with anything you want.',
          recipient: userBot,
          sender: user,
          created_at: new Date().toISOString(),
        }
        return setChats([newChat, ...chatsData])
      }
      return setChats(chatsData)
    }
    return setChats(chatsData)
  }, [chatsData, userBot])

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  const onNewMessage = (message: MessageItem) => {
    setChats((prev) => {
      return prev.map((chat) => {
        if (
          (chat.recipient.id === message.recipient.id &&
            chat.sender.id === message.sender.id) ||
          (chat.recipient.id === message.sender.id &&
            chat.sender.id === message.recipient.id)
        ) {
          return {
            ...chat,
            id: message.id,
            text: message.text,
            created_at: message.created_at,
          }
        }
        return chat
      })
    })
  }

  const onNewMessageChunk = (message: IEventMessageChunk) => {
    setChats((prev) => {
      return prev.map((chat) => {
        if (chat.id === message.id && chat.id === message.id) {
          return {
            ...chat,
            text: chat.text + message.text,
          }
        }
        return chat
      })
    })
  }

  const renderChatContent = () => {
    if (isMobile) {
      if (activeChat || id) {
        return (
          <div className={c.chatMessagesContainer}>
            <MobileHeader heading={t('aiChat.chats')} />
            <ChatMessages
              onNewMessageChunk={onNewMessageChunk}
              onNewMessage={onNewMessage}
              user={user}
              activeChat={activeChat}
              isMobile={isMobile}
              onGoBack={resetActiveChat}
            />
          </div>
        )
      }
      return (
        <div className={c.chatsContainer}>
          <MobileHeader heading={t('aiChat.chats')} />
          <ChatPanelContent
            chatsData={chats}
            activeChatId={id || ''}
            handleClickChat={handleClickChat}
          />
        </div>
      )
    }
    return (
      <>
        <div className={c.chatsContainer}>
          <ChatPanelContent
            chatsData={chats}
            activeChatId={id || ''}
            handleClickChat={handleClickChat}
          />
        </div>
        {id && (
          <div className={c.chatMessagesContainer}>
            <ChatMessages
              onNewMessageChunk={onNewMessageChunk}
              onNewMessage={onNewMessage}
              user={user}
              activeChat={activeChat}
              isMobile={isMobile}
              onGoBack={resetActiveChat}
            />
          </div>
        )}
      </>
    )
  }

  useDidMountEffect(() => {
    if (socket) {
      socket.on('chatMessageChunk', (message: IEventMessageChunk) => {
        onNewMessageChunk(message)
      })
    }
  }, [socket])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    setIsMobile(windowWidth <= 950)
  }, [windowWidth])

  return (
    <div className={c.aiChatPage}>
      <div className={c.content}>
        {user?.role !== 'admin' ? <FeatureDisabled /> : renderChatContent()}
      </div>
    </div>
  )
}

export default AIChatPage
