import { useQuery } from '@tanstack/react-query'
import { getSuggestions } from 'api'
import { Suggestion } from 'types/Generation'

const useFetchSuggestions = () => {
  return useQuery<Suggestion[]>(
    ['suggestions'],
    () => {
      return getSuggestions()
    },
    { initialData: [] },
  )
}

export default useFetchSuggestions
