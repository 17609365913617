import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Img } from 'react-image'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import Loader from 'components/common/Loader'
import DeleteIcon from 'components/icons/DeleteIcon'
import noImage from 'images/noImage.jpg'
import { deleteConcept } from 'api'
import { ConceptsItem } from 'types/Concepts'

import c from './AlbumCard.module.scss'

interface AlbumCardProps {
  data: ConceptsItem
  index: number
  isChecked: boolean
  handleCheckboxChange: (id: number) => void
  nickname: string
}

function AlbumCard({
  data,
  index,
  isChecked,
  handleCheckboxChange,
  nickname,
}: AlbumCardProps) {
  const firstFile = data.file_ids?.[0]

  const mutationDelete = useMutation<void, Error>(() => {
    return deleteConcept(data.id)
  })

  const queryClient = useQueryClient()

  const deleteYourPost = useCallback(() => {
    const result = window.confirm('Are you sure want to delete this album?')
    if (result) {
      mutationDelete.mutateAsync().then(() => {
        queryClient.invalidateQueries([`conceptData`, data.id])
        queryClient.refetchQueries([`infinite-concepts`]).then(() => {
          toast.success('Everything went well')
        })
      })
    }
  }, [])

  return (
    <div
      className={c.wrapper}
      data-favorite={data?.name === 'Favorites' || data?.name === 'Избранное'}
    >
      <Link
        to={`/profile/${nickname}/albums/${data.id}`}
        className={c.imgWrapper}
      >
        <Img
          className={firstFile ? c.image : c.noImage}
          src={firstFile?.link || noImage}
          loader={
            <div className={c.loaderWrapper}>
              <Loader />
            </div>
          }
        />
      </Link>
      <div className={c.conceptFooter}>
        <input
          type="checkbox"
          className={c.checkbox}
          onChange={() => handleCheckboxChange(data.id)}
          checked={isChecked}
        />
        <p className={c.conceptTitle}>
          {data?.name ? data?.name : `Untitled #${index + 1}`}
        </p>
        <button type="button" onClick={deleteYourPost} className={c.actions}>
          <DeleteIcon />
        </button>
      </div>
    </div>
  )
}

export default AlbumCard
