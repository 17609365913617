import { useMutation } from '@tanstack/react-query'
import { createUserWithEmailAndPassword, UserCredential } from 'firebase/auth'
import { auth } from 'utils/firebase'

interface Params {
  onSuccess?: () => void
}

const DEFAULT_PARAMS: Params = {
  onSuccess: undefined,
}

interface Payload {
  email: string
  password: string
}

const useSignUp = (params?: Params) => {
  const { onSuccess } = params ?? DEFAULT_PARAMS

  return useMutation<UserCredential, Error, Payload>(
    ({ email, password}) => {
      return createUserWithEmailAndPassword(auth, email, password)
    },
    {
      onSuccess,
    },
  )
}

export default useSignUp
