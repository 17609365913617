import React, { useEffect, useState } from 'react'

import LikeIcon from 'components/icons/LikeIcon'
import motivationList from 'constants/motivation'

import c from './MotivationsPost.module.scss'

function MotivationsPost() {
  const [randomMotivation, setRandomMotivation] = useState<number>(0)
  const list = motivationList()

  useEffect(() => {
    if (!randomMotivation) {
      const random = Math.floor(Math.random() * 19)
      setRandomMotivation(random)
    }
  }, [])

  return (
    <div className={c.wrapper}>
      <div className={c.content}>
        <LikeIcon width={36} height={35} />
        <span className={c.title}>{list[randomMotivation].title}</span>
        <p className={c.description}>{list[randomMotivation].description}</p>
      </div>
    </div>
  )
}

export default MotivationsPost
