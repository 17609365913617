import { useMutation } from '@tanstack/react-query'
import { signOut } from 'firebase/auth'
import { auth } from 'utils/firebase'

const useLogOut = () => {
  return useMutation<void, Error, void>(() => {
    return signOut(auth)
  })
}

export default useLogOut
