import React from 'react'
import { useTranslation } from 'react-i18next'
import InputRange from 'react-input-range'

import c from './GuidanceScaleSelection.module.scss'

interface GuidanceScaleSelectionProps {
  strength: number | undefined
  setStrength: (value: any) => void
}

function GuidanceScaleSelection({
  strength,
  setStrength,
}: GuidanceScaleSelectionProps) {
  const { t } = useTranslation()

  return (
    <div className={c.powerSelection}>
      <p className={c.heading}>Guidance Scale</p>
      <div className={c.rangeWrapper}>
        <div className={c.line} style={{ left: '0%' }} />
        <div className={c.line} style={{ left: '25%' }} />
        <div className={c.line} style={{ left: '50%' }} />
        <div className={c.line} style={{ left: '75%' }} />
        <div className={c.line} style={{ right: '0%' }} />
        <InputRange
          formatLabel={(value: number) =>
            value === 15 || value === 0.5 ? '' : `${strength}`
          }
          maxValue={15}
          minValue={0.5}
          step={0.5}
          value={strength || 0.5}
          onChange={(value) => setStrength(value)}
        />
        <div className={c.minLabel}>
          {t('stages.generation.idle.artifacts')}
        </div>
        <div className={c.maxLabel}>
          {t('stages.generation.idle.promptCompliance')}
        </div>
      </div>
    </div>
  )
}

export default GuidanceScaleSelection
