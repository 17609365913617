import { useQuery } from '@tanstack/react-query'
import { getConcept } from 'api'
import { useAppSelector } from 'hooks/store'
import { ConceptsItem } from 'types/Concepts'
import { auth } from 'utils/firebase'

interface Params {
  conceptId: number
}

const useFetchConcept = ({ conceptId }: Params) => {
  const deviceId = useAppSelector((state) => state.settings.deviceId)
  const userId = useAppSelector((state) => state.auth.user?.uid)

  const deps = [deviceId, userId, conceptId]

  return useQuery<ConceptsItem, Error>(
    [`concept`, conceptId, deps],
    async () => {
      const token = await auth.currentUser?.getIdToken()
      if (conceptId && token) {
        return getConcept(conceptId)
      }
      return Promise.reject(new Error('Invalid request ID'))
    },
    {
      enabled: !!conceptId && !!auth.currentUser,
    },
  )
}

export default useFetchConcept
