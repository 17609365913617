import React from 'react'

interface  BackgroundRemoverProps {
  isActive: boolean
}
function BackgroundRemover({isActive}: BackgroundRemoverProps) {
  return (
      isActive ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M22.56 6.41398C22.347 4.82698 21.885 3.75598 21.066 2.93398C20.247 2.11498 19.173 1.65298 17.586 1.43998C16.176 1.25098 14.424 1.25098 12 1.25098C9.57598 1.25098 7.82398 1.25098 6.41398 1.43998C4.82698 1.65298 3.75598 2.11498 2.93398 2.93398C2.11498 3.75298 1.65298 4.82698 1.43998 6.41398C1.25098 7.82398 1.25098 9.57598 1.25098 12C1.25098 14.424 1.25098 16.176 1.43998 17.586C1.65298 19.173 2.11498 20.244 2.93398 21.066C3.75298 21.885 4.82698 22.347 6.41398 22.56C7.82398 22.749 9.57598 22.749 12 22.749C14.424 22.749 16.176 22.749 17.586 22.56C19.173 22.347 20.244 21.885 21.066 21.066C21.885 20.247 22.347 19.173 22.56 17.586C22.749 16.176 22.749 14.424 22.749 12C22.749 9.57598 22.749 7.82398 22.56 6.41398ZM20.004 20.004C18.759 21.249 16.5 21.249 12 21.249C7.49998 21.249 5.24098 21.249 3.99598 20.004C2.75098 18.759 2.75098 16.5 2.75098 12C2.75098 7.49998 2.75098 5.24098 3.99598 3.99598C5.24098 2.75098 7.49998 2.75098 12 2.75098C16.5 2.75098 18.759 2.75098 20.004 3.99598C21.249 5.24098 21.249 7.49998 21.249 12C21.249 16.5 21.249 18.759 20.004 20.004Z"
                fill="url(#paint0_linear_4890_27526)"/>
            <path
                d="M7.80322 6C7.6851 6 7.56754 6.06749 7.52284 6.20405C7.31724 6.83353 6.82728 7.32765 6.20311 7.53501C5.9323 7.62463 5.9323 8.01142 6.20311 8.10157C6.82728 8.30892 7.31776 8.80305 7.52284 9.43253C7.61171 9.70564 7.99473 9.70564 8.0836 9.43253C8.2892 8.80305 8.77916 8.30839 9.40333 8.10157C9.67414 8.01195 9.67414 7.62463 9.40333 7.53501C8.77916 7.32765 8.28867 6.83353 8.0836 6.20405C8.03916 6.06749 7.92134 6 7.80322 6ZM14.4635 6.82861C14.3234 6.82861 14.1839 6.91001 14.1308 7.07202L13.6367 8.58734C13.3933 9.33402 12.8119 9.92032 12.0716 10.1659L10.569 10.6641C10.2477 10.7706 10.2477 11.2297 10.569 11.3363L12.0716 11.8345C12.8119 12.08 13.3933 12.6653 13.6367 13.4119L14.1308 14.9283C14.2364 15.2523 14.6916 15.2523 14.7973 14.9283L15.2913 13.4119C15.5348 12.6653 16.1151 12.08 16.8555 11.8345L18.359 11.3363C18.6803 11.2297 18.6803 10.7706 18.359 10.6641L16.8555 10.1659C16.1151 9.92032 15.5348 9.33402 15.2913 8.58734L14.7973 7.07202C14.7444 6.91001 14.6036 6.82861 14.4635 6.82861ZM9.20511 13.1924C9.06504 13.1924 8.92547 13.2738 8.87236 13.4358L8.81176 13.6212C8.5683 14.3678 7.98798 14.9541 7.2476 15.1997L7.06273 15.2598C6.74144 15.3663 6.74144 15.8254 7.06273 15.932L7.2476 15.9931C7.98798 16.2386 8.5683 16.8239 8.81176 17.5705L8.87236 17.757C8.97805 18.081 9.43321 18.081 9.5389 17.757L9.59847 17.5705C9.84193 16.8239 10.4233 16.2386 11.1637 15.9931L11.3475 15.932C11.6688 15.8254 11.6688 15.3663 11.3475 15.2598L11.1637 15.1997C10.4233 14.9541 9.84193 14.3678 9.59847 13.6212L9.5389 13.4358C9.48605 13.2738 9.34519 13.1924 9.20511 13.1924Z"
                fill="url(#paint1_linear_4890_27526)"/>
            <defs>
              <linearGradient id="paint0_linear_4890_27526" x1="-2.70312" y1="12" x2="25.2398" y2="25.5879"
                              gradientUnits="userSpaceOnUse">
                <stop stopColor="#9E5992"/>
                <stop offset="0.505208" stopColor="#6A19AB"/>
                <stop offset="1" stopColor="#3054BB"/>
              </linearGradient>
              <linearGradient id="paint1_linear_4890_27526" x1="3.6825" y1="12" x2="19.745" y2="20.2013"
                              gradientUnits="userSpaceOnUse">
                <stop stopColor="#9E5992"/>
                <stop offset="0.505208" stopColor="#6A19AB"/>
                <stop offset="1" stopColor="#3054BB"/>
              </linearGradient>
            </defs>
          </svg> :
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path
                d="M22.56 6.83292C22.347 5.24592 21.885 4.17492 21.066 3.35292C20.247 2.53392 19.173 2.07192 17.586 1.85892C16.176 1.66992 14.424 1.66992 12 1.66992C9.57598 1.66992 7.82398 1.66992 6.41398 1.85892C4.82698 2.07192 3.75598 2.53392 2.93398 3.35292C2.11498 4.17192 1.65298 5.24592 1.43998 6.83292C1.25098 8.24292 1.25098 9.99492 1.25098 12.4189C1.25098 14.8429 1.25098 16.5949 1.43998 18.0049C1.65298 19.5919 2.11498 20.6629 2.93398 21.4849C3.75298 22.3039 4.82698 22.7659 6.41398 22.9789C7.82398 23.1679 9.57598 23.1679 12 23.1679C14.424 23.1679 16.176 23.1679 17.586 22.9789C19.173 22.7659 20.244 22.3039 21.066 21.4849C21.885 20.6659 22.347 19.5919 22.56 18.0049C22.749 16.5949 22.749 14.8429 22.749 12.4189C22.749 9.99492 22.749 8.24292 22.56 6.83292ZM20.004 20.4229C18.759 21.6679 16.5 21.6679 12 21.6679C7.49998 21.6679 5.24098 21.6679 3.99598 20.4229C2.75098 19.1779 2.75098 16.9189 2.75098 12.4189C2.75098 7.91892 2.75098 5.65992 3.99598 4.41492C5.24098 3.16992 7.49998 3.16992 12 3.16992C16.5 3.16992 18.759 3.16992 20.004 4.41492C21.249 5.65992 21.249 7.91892 21.249 12.4189C21.249 16.9189 21.249 19.1779 20.004 20.4229Z"
                fill="#948AA3"/>
            <path
                d="M7.80322 6.41797C7.6851 6.41797 7.56754 6.48546 7.52284 6.62201C7.31724 7.2515 6.82728 7.74562 6.20311 7.95297C5.9323 8.0426 5.9323 8.42939 6.20311 8.51954C6.82728 8.72689 7.31776 9.22102 7.52284 9.8505C7.61171 10.1236 7.99473 10.1236 8.0836 9.8505C8.2892 9.22102 8.77916 8.72636 9.40333 8.51954C9.67414 8.42992 9.67414 8.0426 9.40333 7.95297C8.77916 7.74562 8.28867 7.2515 8.0836 6.62201C8.03916 6.48546 7.92134 6.41797 7.80322 6.41797ZM14.4635 7.24658C14.3234 7.24658 14.1839 7.32798 14.1308 7.48999L13.6367 9.00531C13.3933 9.75199 12.8119 10.3383 12.0716 10.5838L10.569 11.082C10.2477 11.1886 10.2477 11.6476 10.569 11.7542L12.0716 12.2524C12.8119 12.498 13.3933 13.0832 13.6367 13.8299L14.1308 15.3463C14.2364 15.6703 14.6916 15.6703 14.7973 15.3463L15.2913 13.8299C15.5348 13.0832 16.1151 12.498 16.8555 12.2524L18.359 11.7542C18.6803 11.6476 18.6803 11.1886 18.359 11.082L16.8555 10.5838C16.1151 10.3383 15.5348 9.75199 15.2913 9.00531L14.7973 7.48999C14.7444 7.32798 14.6036 7.24658 14.4635 7.24658ZM9.20511 13.6103C9.06504 13.6103 8.92547 13.6917 8.87236 13.8537L8.81176 14.0391C8.5683 14.7858 7.98798 15.3721 7.2476 15.6176L7.06273 15.6777C6.74144 15.7843 6.74144 16.2433 7.06273 16.3499L7.2476 16.411C7.98798 16.6566 8.5683 17.2418 8.81176 17.9885L8.87236 18.175C8.97805 18.499 9.43321 18.499 9.5389 18.175L9.59847 17.9885C9.84193 17.2418 10.4233 16.6566 11.1637 16.411L11.3475 16.3499C11.6688 16.2433 11.6688 15.7843 11.3475 15.6777L11.1637 15.6176C10.4233 15.3721 9.84193 14.7858 9.59847 14.0391L9.5389 13.8537C9.48605 13.6917 9.34519 13.6103 9.20511 13.6103Z"
                fill="#948AA3"/>
          </svg>
  )
}

export default BackgroundRemover
