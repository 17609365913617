import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import c from './SearchSort.module.scss'

interface SearchSortProps {
  orderBy: string
  setOrderBy: Dispatch<SetStateAction<string>>
}

function SearchSort({ orderBy, setOrderBy }: SearchSortProps) {
  const { t } = useTranslation()

  return (
    <div className={c.sortContainer}>
      <p className={c.sortTitle}>{t('general.search.searchFor')}</p>
      <div className={c.selectContainer}>
        <label className={c.sortItem}>
          <input
            type="radio"
            name="sortPlugin"
            value="posts"
            checked={orderBy === 'posts'}
            onChange={(e) => setOrderBy(e.target.value)}
          />
          <span>{t('general.search.posts')}</span>
        </label>
        <label className={c.sortItem}>
          <input
            type="radio"
            name="sortPlugin"
            value="users"
            onChange={(e) => setOrderBy(e.target.value)}
            checked={orderBy === 'users'}
          />
          <span>{t('general.search.users')}</span>
        </label>
      </div>
    </div>
  )
}

export default SearchSort
