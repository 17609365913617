import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import useFetchRequest from '../../hooks/query/useFetchRequest'
import { useAppSelector } from '../../hooks/store'
import { Suggestion } from '../../types/Generation'
import Handlers from '../../types/Handlers'
import { EnhancementResponse } from '../../types/Response'
import EnhancementHistory from '../EnhancementHistory'
import GenerationFixedButton from '../GenerationFixedButton'
import GenerationTextArea from '../GenerationTextArea'
import MixerInputs from '../MixerInputs'
import PhotoSelection from '../PhotoSelection'
import PromptsContainer from '../PromptsContainer'
import SuggestionsContainer from '../SuggestionsContainer'

import c from './RequestGenerationContainer.module.scss'

interface RequestGenerationContainerProps {
  text?: string
  handler?: `${Handlers}`
  suggestions?: Suggestion[]
  negativePrompt?: string
  handleInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  negativePromptVisible?: boolean
  enhancementInputsVisible?: boolean
  generationTextAreaVisible?: boolean
  isDeleting?: boolean
  selectToDelete?: (postId: number) => void
  selectedToDelete?: number[]
  promptTipsVisible?: boolean
  mixerInputsVisible?: boolean
  setNegativePrompt?: (newPrompt: string) => void
  onTextChange?: (newText: string) => void
  onProcessingStart: () => void
  photoSelectionVisible?: boolean
  onExampleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  generationHistoryData?: EnhancementResponse[]
  chosenHistory?: number
  imageFile?: File | null
  handleHistoryClick?: (id: number | undefined) => void
  onImageChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  imageUrl?: string | null | undefined
  getImageUrl?: (file: File | null) => string | null
  onDeleteImage?: () => void
  onImageChangeIndex?: (e: React.ChangeEvent<HTMLInputElement>) => void
  inputRef?: React.RefObject<HTMLTextAreaElement>
}

export default function RequestGenerationContainer({
  text = '',
  isDeleting = false,
  selectToDelete = (postId: number) => postId,
  selectedToDelete = [],
  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => e,
  generationTextAreaVisible = false,
  handler = 'Thomas_Jefferson',
  enhancementInputsVisible = false,
  getImageUrl = (file: File | null): string | null => {
    return file ? URL.createObjectURL(file) : null
  },
  imageFile = null,
  mixerInputsVisible = false,
  negativePromptVisible = false,
  promptTipsVisible = false,
  suggestions = [],
  onTextChange = (newText: string) => newText,
  onProcessingStart,
  handleHistoryClick = () => {},
  onImageChange = (event: React.ChangeEvent<HTMLInputElement>) => event,
  generationHistoryData = [],
  chosenHistory = undefined,
  imageUrl = null,
  photoSelectionVisible = false,
  onExampleClick = (event: React.MouseEvent<HTMLButtonElement>) => event,
  setNegativePrompt = (value: string) => value,
  onImageChangeIndex = (e) => {},
  negativePrompt = '',
  onDeleteImage = () => {},
  inputRef = undefined,
}: RequestGenerationContainerProps) {
  const { t } = useTranslation()
  const { stage } = useAppSelector((state) => state.stage)

  const { requestId } = useParams()

  const { data } = useFetchRequest({
    requestId,
  })

  useEffect(() => {
    if (data?.assets) {
      const negativePrompts = data?.assets?.[0]?.settings?.negative_prompt
      if (typeof negativePrompts === 'string') {
        setNegativePrompt(negativePrompts)
      }
    }
  }, [data?.assets, setNegativePrompt])

  return (
    <div className={c.content}>
      {enhancementInputsVisible && (
        <EnhancementHistory
          onChange={handleInputChange}
          handler={handler}
          isDeleting={isDeleting}
          selectToDelete={selectToDelete}
          selectedToDelete={selectedToDelete}
        />
      )}
      {mixerInputsVisible && (
        <MixerInputs
          imageFile={imageFile}
          onImageChange={onImageChangeIndex}
          getImageUrl={getImageUrl}
          onDeleteImage={onDeleteImage}
        />
      )}
      {photoSelectionVisible && (
        <PhotoSelection
          generationHistoryData={generationHistoryData}
          chosenHistory={chosenHistory}
          imageUrl={imageUrl}
          handleHistoryClick={handleHistoryClick}
          onImageChange={onImageChange}
          onDeleteImage={onDeleteImage}
        />
      )}
      {generationTextAreaVisible && (
        <GenerationTextArea
          text={text}
          onTextChange={onTextChange}
          onProcessingStart={onProcessingStart}
          inputRef={inputRef}
        />
      )}
      {promptTipsVisible && (
        <SuggestionsContainer
          onExampleClick={onExampleClick}
          suggestions={suggestions}
        />
      )}
      {negativePromptVisible && (
        <PromptsContainer
          negativePrompt={negativePrompt}
          setNegativePrompt={setNegativePrompt}
        />
      )}
      <GenerationFixedButton
        text={text}
        headingMissing={t('stages.generation.idle.inputLabel')}
        headingEntered={t('stages.generation.idle.buttonText')}
        headingLoader={t('stages.generation.processing.bannerText')}
        isDisabled={stage === 'processing'}
        onProcessingStart={onProcessingStart}
        isProcessing={stage === 'processing'}
      />
    </div>
  )
}
