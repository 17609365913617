import { getChats } from 'api/chats'
import { auth } from 'utils/firebase'
import { useQuery } from '@tanstack/react-query'
import { MessageItem } from '../../types/Chats'

const useInfiniteFetchChats = () => {
  return useQuery<MessageItem[]>(
    ['chats'],
    async () => {
      const token = await auth.currentUser?.getIdToken()
      if (token) {
        return getChats()
      }
      throw new Error('Invalid token')
    },
    { initialData: [] },
  )
}

export default useInfiniteFetchChats 