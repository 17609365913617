import React, { useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { OutputFormat } from '../../types/OutputFormat'
import c from './FormatImageSelection.module.scss'

interface FormatImageSelectionProps {
  imageUrls?: string[]
  outputFormat?: OutputFormat
  onOutputFormatChange?: (newOutputFormat: OutputFormat) => void
  onImagesChange?: (newFiles: File[]) => void
  onImageDelete?: (index: number) => void
}

export default function FormatImageSelection({
  imageUrls = [],
  outputFormat = 'jpeg',
  onOutputFormatChange = (newOutputFormat: OutputFormat) => newOutputFormat,
  onImagesChange = undefined,
  onImageDelete = undefined,
}: FormatImageSelectionProps) {
  const { t } = useTranslation()

  const handleOutputFormatChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onOutputFormatChange(e.target.value as OutputFormat)
    },
    [onOutputFormatChange],
  )
  return (
    <div className={c.container}>
      <div className={c.heading}>
        {t('stages.enhancement.preview.controlPanelTitle')}
      </div>
      <div className={c.switch}>
        <label className={c.switchButton}>
          <input
            className={`sr-only ${c.switchInput}`}
            type="radio"
            name="outputFormat"
            value="jpeg"
            checked={outputFormat === 'jpeg'}
            onChange={handleOutputFormatChange}
          />
          <span className={c.switchBody}>
            <span className={c.switchHeading}>JPEG</span>
            <span className={c.switchText}>
              {t('stages.enhancement.preview.jpegText')}
            </span>
            <span className={c.switchFiller} />
          </span>
        </label>
        <label className={c.switchButton}>
          <input
            className={`sr-only ${c.switchInput}`}
            type="radio"
            name="outputFormat"
            value="png"
            checked={outputFormat === 'png'}
            onChange={handleOutputFormatChange}
          />
          <span className={c.switchBody}>
            <span className={c.switchHeading}>PNG</span>
            <span className={c.switchText}>
              {t('stages.enhancement.preview.pngText')}
            </span>
            <span className={c.switchFiller} />
          </span>
        </label>
      </div>
    </div>
  )
}