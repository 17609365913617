import React from 'react'

// eslint-disable-next-line react/require-default-props
function ArrowLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1541 20.0694C13.8396 20.339 13.3661 20.3026 13.0966 19.9881L7.09651 12.9881C6.85581 12.7072 6.85581 12.2928 7.09651 12.0119L13.0966 5.0119C13.3661 4.6974 13.8396 4.661 14.1541 4.9305C14.4686 5.2001 14.505 5.6736 14.2354 5.9881L8.65381 12.5L14.2354 19.0119C14.505 19.3264 14.4686 19.7999 14.1541 20.0694Z"
        fill="white"
      />
    </svg>
  )
}

export default ArrowLeft
