import { postRepeat, postRequestNeuroArt } from 'api'
import { toast } from 'react-toastify'
import { BaseResponse, LimitError } from 'types/Response'
import Handlers from 'types/Handlers'
import { RequestGeneration } from 'types/FormDataObject'
import Strategy from './Strategy'

export default class GenerateStrategy extends Strategy {
  private readonly text: string

  private styleTitle?: string

  private styleText?: string

  private negativePrompt?: string

  private numSteps?: number

  private seed?: string

  private guidanceScale?: number

  private width?: number

  private height?: number

  private handler: `${Handlers}` = Handlers.JOHN_ADAMS

  private requestId?: string

  private responseSeconds?: number

  constructor(
    request: string,
    styleTitle: string | undefined,
    styleText: string | undefined,
    requestId?: string,
    negativePrompt?: string | undefined,
    numSteps?: number | undefined,
    seed?: string | undefined,
    guidanceScale?: number | undefined,
    width?: number | undefined,
    height?: number | undefined,
  ) {
    super()
    this.text = request
    this.requestId = requestId
    this.styleTitle = styleTitle
    this.styleText = styleText
    this.negativePrompt = negativePrompt
    this.width = width
    this.height = height
    this.guidanceScale = guidanceScale
    this.seed = seed
    this.numSteps = numSteps
  }

  public setHandler(handler: `${Handlers}`) {
    this.handler = handler
    return this
  }

  public getSeconds() {
    return this.responseSeconds
  }

  public async start(): Promise<void> {
    const { id, assets, meta } = await this.makeRequest()
    this.responseSeconds = meta.response_seconds
    this.collectAssets(id, assets.length).then()
  }

  public stop(): void {
    this.collector?.stop()
  }

  public async repeat(): Promise<void> {
    const { id, assets, meta } = await this.makeRequestRepeat()
    this.responseSeconds = meta.response_seconds
    this.collectAssets(id, assets.length).then()
  }

  public async makeRequest(): Promise<BaseResponse> {
    try {
      const requestData: RequestGeneration = {
        text: this.text,
        isManyAssets: true,
      }

      if (this.styleTitle) requestData.styleTitle = this.styleTitle
      if (this.styleText) requestData.styleText = this.styleText
      if (this.negativePrompt) requestData.negativePrompt = this.negativePrompt
      if (this.numSteps) requestData.numSteps = this.numSteps
      if (this.seed) requestData.seed = this.seed
      if (this.guidanceScale) requestData.guidanceScale = this.guidanceScale
      if (this.width) requestData.width = this.width
      if (this.height) requestData.height = this.height

      return await postRequestNeuroArt(requestData)
    } catch (error: LimitError | unknown) {
      this.collector?.stop()

      const errorData = error as LimitError
      if (errorData.key === 'LIMIT_GENERATION') {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw errorData
      }

      toast.error('Something went wrong, try again!')
      throw error
    }
  }

  public async makeRequestRepeat(): Promise<BaseResponse> {
    try {
      return await postRepeat(this.requestId!, this.text)
    } catch (error) {
      toast.error('Something went wrong, try again!')
      this.collector?.stop()
      throw error
    }
  }
}
