import React, { Dispatch, SetStateAction } from 'react'

import { CategoryProps } from 'types/Plugins'

import c from './PluginsCategories.module.scss'

interface PluginsCategoriesProps {
  categories: CategoryProps[]
  activeCategory: CategoryProps
  setActiveCategory: Dispatch<SetStateAction<CategoryProps>>
}

function PluginsCategories({
  categories,
  activeCategory,
  setActiveCategory,
}: PluginsCategoriesProps) {
  const handleActiveCategory = (value: CategoryProps) => {
    setActiveCategory(value)
  }

  const checkActiveCategory = (value: string) => {
    return activeCategory.title === value ? c.activeCategory : ''
  }

  return (
    <>
      <button
        type="button"
        onClick={() => {
          handleActiveCategory({ id: 0, title: 'all' })
        }}
        className={`${c.categoryContainer} ${checkActiveCategory('all')}`}
      >
        <p className={c.categoryText}>All</p>
      </button>
      {categories?.map((category) => {
        return (
          <button
            key={category?.id}
            type="button"
            onClick={() => {
              handleActiveCategory(category)
            }}
            className={`${c.categoryContainer} ${checkActiveCategory(
              category?.title,
            )}`}
          >
            <p className={c.categoryText}>{category?.title}</p>
          </button>
        )
      })}
    </>
  )
}

export default PluginsCategories
