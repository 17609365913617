import { useTranslation } from 'react-i18next'

export default function motivationList(): {
  title: string
  description: string
}[] {
  const { t } = useTranslation()

  const list = [
    {
      title: t('motivations.first.title'),
      description: t('motivations.first.description'),
    },
    {
      title: t('motivations.second.title'),
      description: t('motivations.second.description'),
    },
    {
      title: t('motivations.third.title'),
      description: t('motivations.third.description'),
    },
    {
      title: t('motivations.fourth.title'),
      description: t('motivations.fourth.description'),
    },
    {
      title: t('motivations.fifth.title'),
      description: t('motivations.fifth.description'),
    },
    {
      title: t('motivations.sixth.title'),
      description: t('motivations.sixth.description'),
    },
    {
      title: t('motivations.seventh.title'),
      description: t('motivations.seventh.description'),
    },
    {
      title: t('motivations.eighth.title'),
      description: t('motivations.eighth.description'),
    },
    {
      title: t('motivations.ninth.title'),
      description: t('motivations.ninth.description'),
    },
    {
      title: t('motivations.tenth.title'),
      description: t('motivations.tenth.description'),
    },
    {
      title: t('motivations.eleventh.title'),
      description: t('motivations.eleventh.description'),
    },
    {
      title: t('motivations.twelfth.title'),
      description: t('motivations.twelfth.description'),
    },
    {
      title: t('motivations.thirteenth.title'),
      description: t('motivations.thirteenth.description'),
    },
    {
      title: t('motivations.fourteenth.title'),
      description: t('motivations.fourteenth.description'),
    },
    {
      title: t('motivations.fifteenth.title'),
      description: t('motivations.fifteenth.description'),
    },
    {
      title: t('motivations.sixteenth.title'),
      description: t('motivations.sixteenth.description'),
    },
    {
      title: t('motivations.seventeenth.title'),
      description: t('motivations.seventeenth.description'),
    },
    {
      title: t('motivations.eighteenth.title'),
      description: t('motivations.eighteenth.description'),
    },
  ]

  return list
}
