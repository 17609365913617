import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import getLanguage from 'utils/getLanguage'
import getDeviceId from 'utils/getDeviceId'

interface SettingsState {
  language: string
  deviceId: string
}

const initialState: SettingsState = {
  language: getLanguage(),
  deviceId: getDeviceId(),
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload
    },
    setDeviceId: (state, action: PayloadAction<string>) => {
      state.deviceId = action.payload
    },
  },
})

export const { setLanguage, setDeviceId } = settingsSlice.actions

export default settingsSlice.reducer
