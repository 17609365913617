import { useQuery } from '@tanstack/react-query'
import { getProfile } from 'api'
import { useAppSelector } from 'hooks/store'
import { UserResponse } from 'types/User'

interface Params {
  user: string | number | undefined
}

const useFetchRequestProfile = ({ user }: Params) => {
  const deviceId = useAppSelector((state) => state.settings.deviceId)
  const userId = useAppSelector((state) => state.auth.user?.uid)

  const deps = [deviceId, userId, user]

  return useQuery<UserResponse, Error>(
    ['profile', deps],
    async () => {
      if (user) {
        return getProfile(user)
      }
      return Promise.reject(new Error('Invalid request ID'))
    },
    { enabled: !!user },
  )
}

export default useFetchRequestProfile
