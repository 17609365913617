import { useInfiniteQuery } from '@tanstack/react-query'
import { getNotifications } from 'api'
import { NotificationsResponse } from 'types/Notifications'
import { auth } from 'utils/firebase'

const LIMIT = 11

const getOffset = (page: number): number => {
  return (page - 1) * LIMIT
}

const useInfiniteFetchNotifications = () => {
  return useInfiniteQuery<NotificationsResponse, Error>(
    ['infinite-notifications'],
    async ({ pageParam: page = 1 }) => {
      const offset = getOffset(page)
      const token = await auth.currentUser?.getIdToken()
      if (token) {
        return getNotifications(LIMIT, offset)
      }
      return Promise.reject(new Error('Unauthorized'))
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.items.length === 0) return undefined
        return allPages.length + 1
      },
    },
  )
}

export default useInfiniteFetchNotifications
