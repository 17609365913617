const LANGUAGELISTS = [
  {
    id: 1,
    title: 'Русский',
    code: 'ru',
  },
  {
    id: 2,
    title: 'English',
    code: 'en',
  },
  {
    id: 3,
    title: 'Français',
    code: 'fr',
  },
  {
    id: 4,
    title: 'Deutsch',
    code: 'de',
  },
  {
    id: 5,
    title: 'Español',
    code: 'es',
  },
  {
    id: 6,
    title: 'Italiano',
    code: 'it',
  },
  {
    id: 7,
    title: 'Português',
    code: 'pt',
  },
  {
    id: 8,
    title: 'हिन्दी',
    code: 'hi',
  },
  {
    id: 9,
    title: 'Indonesia',
    code: 'id',
  },
]

export default LANGUAGELISTS
