import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
} from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'
import DeleteIcon from '../icons/DeleteIcon'
import CopyIcon from '../icons/CopyIcon'
import { MessageItem, SettingsMessageData } from '../../types/Chats'
import { clearChat, deleteMessages } from '../../api/chats'
import useFetchRequestMyProfile from '../../hooks/query/useFetchRequestMyProfile'
import c from './SettingsMessage.module.scss'
import isInbox from '../../utils/isInbox'

interface SettingsMessageProps {
  settingsData: SettingsMessageData
  selectedUserId?: string
  selectedMessageId?: number | null
  selectMessage?: (messageId: number) => void
  selectedMessageIds?: number[]
  setSettingsMessageVisible?: Dispatch<SetStateAction<boolean>>
  setPreventDefaultNextTime?: Dispatch<SetStateAction<boolean>>
  variant: 'Chat' | 'Message'
  messageData?: MessageItem | null
  messagesData?: MessageItem[]
}

function SettingsMessage({
  settingsData,
  messagesData = [],
  selectedUserId = '',
  selectedMessageId = 0,
  selectedMessageIds = [],
  setPreventDefaultNextTime = () => {},
  selectMessage = () => {},
  variant,
  setSettingsMessageVisible = () => {},
  messageData = null,
}: SettingsMessageProps) {
  const { t } = useTranslation()
  const [rightClickCount, setRightClickCount] = useState(0)
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null)
  const [isVisible, setIsVisible] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const settingsMessageRef = useRef<HTMLDivElement>(null)

  const queryClient = useQueryClient()

  const { data: user } = useFetchRequestMyProfile()

  const { id } = useParams()

  const isSelected = selectedMessageIds.includes(messageData?.id || 0)

  const mutationDelete = useMutation<void, Error>(() => {
    let messagesToDelete: number[] = []

    if (selectedMessageIds && selectedMessageIds.length > 0) {
      messagesToDelete = selectedMessageIds
    } else if (selectedMessageId != null && selectedMessageId >= 0) {
      messagesToDelete = [selectedMessageId]
    }

    return deleteMessages(messagesToDelete)
  })

  const mutationClear = useMutation<void, Error>(() => {
    return clearChat(selectedUserId)
  })

  const handleDeleteMessages = () => {
    mutationDelete.mutate()
    setSettingsMessageVisible(false)
    setPreventDefaultNextTime(true)
  }

  const handleClearChat = () => {
    mutationClear.mutate()
    setSettingsMessageVisible(false)
    setPreventDefaultNextTime(true)
  }

  const copyTextToClipboard = async () => {
    const selectedMessages = messagesData.filter((message) => {
      if (selectedMessageIds.length > 0) {
        return selectedMessageIds.includes(message.id)
      }
      return false
    })

    if (selectedMessages.length <= 0 && messageData) {
      selectedMessages.push(messageData)
    }

    const formattedMessages = selectedMessages.map((message) => {
      const formattedTimestamp = `[${message.created_at}]`
      const formattedAuthor = `${
        isInbox(user, message)
          ? message.recipient.nickname
          : message.sender.nickname
      }, ${formattedTimestamp}`
      return `${formattedAuthor}\n${message.text}`
    })

    const concatenatedText = formattedMessages.join('\n\n')

    if (concatenatedText) {
      try {
        await navigator.clipboard.writeText(concatenatedText)
      } catch (err) {
        console.error('Failed to copy text: ', err)
      }
    }

    setSettingsMessageVisible(false)
    setPreventDefaultNextTime(true)
  }

  const handleRightClick = useCallback(() => {
    if (isVisible) {
      setIsVisible(false)
      return
    }
    const newCount = rightClickCount + 1
    setRightClickCount(newCount)

    if (timerId) {
      clearTimeout(timerId)
    }

    const newTimerId = setTimeout(() => setRightClickCount(0), 100)
    setTimerId(newTimerId)

    if (newCount === 1) {
      setIsVisible(true)
      if (settingsMessageRef.current) {
        setPosition({
          x:
            settingsData.position.x +
            settingsMessageRef.current.getBoundingClientRect().width,
          y: settingsData.position.y,
        })
      }
    } else if (newCount === 2) {
      setIsVisible(false)
    }
  }, [isVisible, settingsData, settingsMessageRef.current])

  useEffect(() => {
    handleRightClick()
  }, [settingsData])

  const handleMouseLeave = useCallback(() => {
    setIsVisible(false)
  }, [])

  const renderContent = () => {
    if (!isVisible) return null

    return (
      <div className={c.settingsWrapper}>
        <div className={c.settingsWindow}>
          <div className={c.actions}>
            {variant === 'Message' ? (
              <>
                <button
                  type="button"
                  onClick={handleDeleteMessages}
                  className={c.deleteAction}
                >
                  <DeleteIcon />
                  {`${t('aiChat.clearChat')}`}
                </button>
                <button
                  type="button"
                  className={c.copyAction}
                  onClick={copyTextToClipboard}
                >
                  <CopyIcon style={{ width: '16px', height: '16px' }} />
                  {`${t('aiChat.copyText')}`}
                </button>
                <button
                  type="button"
                  className={c.copyAction}
                  onClick={() => selectMessage(messageData?.id || 0)}
                >
                  <CopyIcon style={{ width: '16px', height: '16px' }} />
                  {isSelected
                    ? ` ${t('aiChat.unSelect')} `
                    : ` ${t('aiChat.select')}`}
                </button>
              </>
            ) : (
              <button
                type="button"
                onClick={handleClearChat}
                className={c.deleteAction}
              >
                <DeleteIcon />
                {`${t('aiChat.clearChat')}`}
              </button>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className={classNames({
        [c.container]: true,
        [c.visible]: isVisible,
      })}
      style={{
        left: position.x,
        top: position.y,
      }}
      ref={settingsMessageRef}
      onMouseLeave={handleMouseLeave}
    >
      {renderContent()}
    </div>
  )
}

export default SettingsMessage
