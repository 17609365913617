import React, { useEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useTranslation } from 'react-i18next'
import { Link, Outlet, useLocation, useParams } from 'react-router-dom'

import Loader from 'components/common/Loader'
import AlbumContent from 'components/AlbumContent'
import useInfiniteFetchConcepts from 'hooks/query/useInfiniteFetchConcepts'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'

import c from './ProfileAlbumsPage.module.scss'

function ProfileAlbumsPage() {
  const { t } = useTranslation()
  const { ref, inView } = useInView()

  const { nickname } = useParams()
  const { pathname } = useLocation()

  const { data: myProfileData } = useFetchRequestMyProfile()

  const isMyProfile = useMemo(
    () => nickname === myProfileData?.nickname,
    [nickname, myProfileData?.nickname],
  )

  const {
    data: infiniteAlbumsData,
    isLoading: isLoadingAlbums,
    isFetchingNextPage: isFetchingNextPageAlbums,
    fetchNextPage: fetchNextPageAlbums,
    hasNextPage: hasNextPageAlbums,
  } = useInfiniteFetchConcepts()

  const albumsData = useMemo(() => {
    if (!infiniteAlbumsData) return { items: [], meta: {} }
    const combinedItems = infiniteAlbumsData.pages.flatMap((p) => p.items)
    const lastMeta =
      infiniteAlbumsData.pages[infiniteAlbumsData.pages.length - 1].meta
    return {
      items: combinedItems,
      meta: lastMeta,
    }
  }, [infiniteAlbumsData])

  useEffect(() => {
    if (isLoadingAlbums) return
    fetchNextPageAlbums()
  }, [inView])

  const activePage = (page: string) => {
    const array = pathname.split('/')
    const name = array[array.length - 1]

    return page === name
  }

  return (
    <div className={c.gridWrapper}>
      {!isLoadingAlbums && activePage('albums') && (
        <div className={c.albumActions}>
          <div className={c.action}>
            <Link
              to={`/profile/${nickname}/albums/create`}
              className={c.createText}
            >
              {t('profile.newConcepts')}
            </Link>
          </div>
        </div>
      )}
      {activePage('albums') && isMyProfile && (
        <div className={c.gridWrapper}>
          <AlbumContent
            albumsData={albumsData.items}
            nickname={nickname || ''}
          />
          {!isLoadingAlbums &&
            !isFetchingNextPageAlbums &&
            hasNextPageAlbums && <div ref={ref} />}
          {isFetchingNextPageAlbums && (
            <div className={c.loaderWrapper}>
              <div className={c.loader}>
                <Loader />
              </div>
            </div>
          )}
        </div>
      )}
      <Outlet />
    </div>
  )
}

export default ProfileAlbumsPage
