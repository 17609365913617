import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import useFetchRequestMyProfile from '../../hooks/query/useFetchRequestMyProfile'
import UnPaidSubscriptionContent from '../UnPaidSubscriptionContent'

import c from './StartChat.module.scss'

function StartChat() {
  const { t } = useTranslation()
  const { data: user } = useFetchRequestMyProfile()

  const { id } = useParams()

  if (!user) {
    return null
  }

  const hasAssistantMessage = id === '23332' && !user?.is_paid_subscription

  return (
    <div className={c.container}>
      <div className={c.content}>
        {hasAssistantMessage ? (
          <UnPaidSubscriptionContent />
        ) : (
          <div className={c.content__PaidSubscription}>
            <div className={c.content__Heading}>{`${t(
              'aiChat.chatStartHeading',
            )}`}</div>
            <div className={c.content__Note}>{`${t(
              'aiChat.chatStartNote',
            )}`}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default StartChat
