import React from 'react'
import { BaseAsset } from 'types/Response'
import Loader from 'components/common/Loader'
import { ReactComponent as ArrowNextIcon } from '@material-design-icons/svg/filled/arrow_forward_ios.svg'
import { ReactComponent as ArrowPrevIcon } from '@material-design-icons/svg/filled/arrow_back_ios.svg'

import s from './SliderAssets.module.scss'

interface SliderAssetsProps {
  assets: BaseAsset[]
  prevHandler?: () => void
  nextHandler?: () => void
  currentSlide: number
  setCurrentSlide: (index: number) => void
  onlyImage?: boolean
}

function SliderAssets({
  assets,
  currentSlide,
  nextHandler = undefined,
  prevHandler = undefined,
  setCurrentSlide,
  onlyImage = false,
}: SliderAssetsProps) {
  return (
    <div className={s.slider}>
      <div className={s.control}>
        <button type="button" className={s.arrow} onClick={prevHandler}>
          <ArrowPrevIcon />
        </button>
        <div className={s.number}>
          {currentSlide + 1}/{assets!.length}
        </div>
        <button type="button" className={s.arrow} onClick={nextHandler}>
          <ArrowNextIcon />
        </button>
      </div>
      <div className={s.sliders}>
        {assets.length > 0 &&
          assets.map((asset, index) => {
            return (
              <button
                className={s.slideWrapper}
                type="button"
                key={asset.url}
                onClick={() => setCurrentSlide(index)}
              >
                {onlyImage ? (
                  <>
                    <img src={asset.url} alt="" className={s.image} />
                    {!asset.url && (
                      <div className={s.loaderWrapper}>
                        <Loader />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {asset.source_type === 'video' && asset.url && (
                      // eslint-disable-next-line jsx-a11y/media-has-caption
                      <video src={asset.url} className={s.image} loop muted />
                    )}
                    {asset.source_type !== 'video' && asset.url && (
                      <img src={asset.url} alt="" className={s.image} />
                    )}
                    {!asset?.url && (
                      <div className={s.loaderWrapper}>
                        <Loader />
                      </div>
                    )}
                  </>
                )}
              </button>
            )
          })}
      </div>
    </div>
  )
}

export default SliderAssets
