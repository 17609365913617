import React from 'react'

// eslint-disable-next-line react/require-default-props
function LinkIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M6 8H9.99999"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path
        d="M5.9987 12H5.33203C3.12289 12 1.33203 10.2091 1.33203 8C1.33203 5.79086 3.12289 4 5.33203 4H5.9987"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path
        d="M10 4H10.6667C12.8758 4 14.6667 5.79086 14.6667 8C14.6667 10.2091 12.8758 12 10.6667 12H10"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default LinkIcon

