import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import ScrollChatBack from 'components/ScrollChatBack'
import MessageUser from 'components/MessageUser'
import useFetchRequestMyProfile from '../../hooks/query/useFetchRequestMyProfile'
import { MessageItem, SettingsMessageData } from '../../types/Chats'
import c from './ChatTextBox.module.scss'
import SettingsMessage from '../SettingsMessage'
import isInbox from '../../utils/isInbox'

interface ChatTextBoxProps {
  messagesData: MessageItem[]
  isLoading: boolean
}

function ChatTextBox({ isLoading, messagesData }: ChatTextBoxProps) {
  const { data: user } = useFetchRequestMyProfile()
  const [settingsMessageData, setSettingsMessageData] =
    useState<SettingsMessageData>({ id: 0, position: { x: 0, y: 0 } })

  const [settingsMessageVisible, setSettingsMessageVisible] =
    useState<boolean>(false)

  const [selectedMessageIds, setSelectedMessageIds] = useState<number[]>([])
  const [selectedMessageId, setSelectedMessageId] = useState<number | null>(
    null,
  )

  const [preventDefaultNextTime, setPreventDefaultNextTime] =
    useState<boolean>(true)

  const [messageData, setMessageData] = useState<MessageItem>()

  const contentRef = useRef<HTMLDivElement>(null)
  const [isScrolledUp, setIsScrolledUp] = useState(false)

  const smoothScrollToBottom = useCallback(() => {
    if (contentRef.current) {
      const { scrollHeight, clientHeight } = contentRef.current
      contentRef.current.scrollTo({
        top: scrollHeight - clientHeight,
      })
    }
  }, [])

  const checkScrollPosition = useCallback(() => {
    if (contentRef.current) {
      const { scrollHeight, clientHeight, scrollTop } = contentRef.current
      setIsScrolledUp(scrollTop + clientHeight < scrollHeight)
    }
  }, [contentRef.current])

  const handleRightClick = (
    event: React.MouseEvent<HTMLDivElement>,
    data: MessageItem,
  ) => {
    if (preventDefaultNextTime) {
      event.preventDefault()
    }

    setMessageData(data)

    setPreventDefaultNextTime(!preventDefaultNextTime)

    setSettingsMessageVisible((prev) => !prev)
    setSettingsMessageData({
      id: 0,
      position: {
        x: event.clientX + 100,
        y: event.clientY - 50,
      },
    })

    setSelectedMessageId(data.id)
  }

  const selectMessage = useCallback((messageId: number) => {
    setSelectedMessageIds((prevSelectedMessageIds) => {
      if (prevSelectedMessageIds.includes(messageId)) {
        return prevSelectedMessageIds.filter((id) => id !== messageId)
      }
      return [...(prevSelectedMessageIds || []), messageId]
    })
    setSettingsMessageVisible(false)
    setPreventDefaultNextTime(true)
  }, [])

  useEffect(() => {
    const content = contentRef.current
    if (content) {
      content.addEventListener('scroll', checkScrollPosition)
    }

    return () => {
      if (content) {
        content.removeEventListener('scroll', checkScrollPosition)
      }
    }
  }, [])

  useLayoutEffect(() => {
    smoothScrollToBottom()
  }, [messagesData])


  return (
    <div className={c.container}>
      <div className={c.content} ref={contentRef}>
        {[...messagesData].map((item, index: number) => (
          <MessageUser
            selectMessage={selectMessage}
            selectedMessageId={selectedMessageId}
            settingsMessageVisible={settingsMessageVisible}
            user={user}
            selectedMessageIds={selectedMessageIds}
            isLoading={index === messagesData.length - 1 && isLoading}
            handleRightClick={handleRightClick}
            key={`${new Date().getMilliseconds()}-${item.id}`}
            data={item}
            isAnother={isInbox(user, item)}
          />
        ))}
        <div className={c.scrollContainer}>
          {isScrolledUp && (
            <ScrollChatBack goBackScroll={smoothScrollToBottom} />
          )}
        </div>
      </div>
      {settingsMessageVisible && (
        <SettingsMessage
          messagesData={messagesData}
          selectMessage={selectMessage}
          selectedMessageId={selectedMessageId}
          selectedMessageIds={selectedMessageIds}
          setPreventDefaultNextTime={setPreventDefaultNextTime}
          setSettingsMessageVisible={setSettingsMessageVisible}
          messageData={messageData}
          variant="Message"
          settingsData={settingsMessageData}
        />
      )}
    </div>
  )
}

export default ChatTextBox
