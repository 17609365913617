import React, { useEffect } from 'react'

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { NavLink, useParams } from 'react-router-dom'
import VerifiedIcon from '../VerifiedIcon'
import SettingsChat from '../SettingsChat/SettingsChat'
import c from './HeaderChatMessages.module.scss'
import { ThemesResponse } from '../../types/Chats'
import UnknownUserAvatar from '../icons/UnknownUserAvatar.svg'
import ArrowUp from '../icons/ArrowUp'
import useFetchRequestMyProfile from '../../hooks/query/useFetchRequestMyProfile'
import useFetchRequestProfile from '../../hooks/query/useFetchRequestProfile'
import { createThemes } from '../../api/chats'
import ChatThemesSelect from '../ChatThemesSelect'

interface HeaderChatMessagesProps {
  isMobile: boolean
  themesData: ThemesResponse[]
  theme?: ThemesResponse | undefined
  setTheme?: (theme: ThemesResponse | undefined) => void
  onGoBack: () => void
}

function HeaderChatMessages({
  isMobile,
  onGoBack,
  theme = undefined,
  themesData,
  setTheme = () => {},
}: HeaderChatMessagesProps) {
  const { id } = useParams()

  const { data: userChat, failureCount } = useFetchRequestProfile({ user: id })

  const { data: user } = useFetchRequestMyProfile()

  const queryClient = useQueryClient()

  const mutationPost = useMutation<ThemesResponse, Error>(() => {
    return createThemes(id!)
  })

  const handleCreateTheme = () => {
    mutationPost.mutateAsync().then((result) => {
      if (result) {
        setTheme(result)
        queryClient.invalidateQueries([`chats`])
        queryClient.refetchQueries([`infinite-chat-messages`, id])
      }
    })
  }

  useEffect(() => {
    if (failureCount === 1 && !userChat) {
      onGoBack()
    }
  }, [userChat, failureCount])

  return userChat ? (
    <div className={c.header}>
      <div className={c.chatHeaderUser}>
        {isMobile && (
          <button type="button" onClick={onGoBack} className={c.buttonGoBack}>
            <ArrowUp className={c.arrowIconSetting} />
          </button>
        )}
        <div className={c.avatarWrapper}>
          <img
            className={c.avatar}
            src={userChat.avatar || UnknownUserAvatar}
            alt="imgUser"
            onError={(e) =>
              e.currentTarget.setAttribute('src', UnknownUserAvatar)
            }
          />
        </div>
        <div className={c.nicknameWrapper}>
          <p className={c.nickname}>
            {userChat.nickname}
            {userChat.is_paid_subscription && <VerifiedIcon />}
          </p>
        </div>
        {user?.is_paid_subscription && id === '23332' && (
          <div className={c.chatThemesSelect}>
            <ChatThemesSelect
              themesData={themesData}
              theme={theme}
              setTheme={setTheme}
              handleCreateTheme={handleCreateTheme}
            />
          </div>
        )}
      </div>

      <div className={c.settingsPost}>
        <SettingsChat theme={theme} />
      </div>
    </div>
  ) : null
}

export default HeaderChatMessages
