import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

import ChatIcon from 'components/icons/ChatIcon'
import { UserResponse } from 'types/User'
import VerifiedIcon from 'components/VerifiedIcon'
import c from './UserList.module.scss'

interface UserListProps {
  user: UserResponse
}

function UserList({ user }: UserListProps) {
  const [isFollow, setIsFollow] = useState<boolean>(false)
  const navigate = useNavigate()

  const handleFollow = () => {
    setIsFollow(true)
  }

  const handleClick = () => {
    navigate(`/profile/${user.nickname}`)
  }

  const handleOpenChat = useCallback(() => {
    navigate(`/chats/${user.id}`)
  }, [user])

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  return (
    <div
      role="button"
      tabIndex={0}
      className={c.userContainer}
      onClick={handleClick}
    >
      <div className={c.column}>
        <img className={c.avatar} src={user?.avatar} alt="avatar" />
        <p className={c.nickname}>{user?.nickname}</p>
        {user.is_paid_subscription && <VerifiedIcon />}
      </div>
      <div
        role="button"
        tabIndex={0}
        className={c.column}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          type="button"
          className={classNames(
            { [c.button]: true },
            { [c.isFollow]: isFollow },
          )}
          onClick={handleFollow}
        >
          {isFollow ? 'Followed' : 'Follow'}
        </button>
        {/* <button type="button" onClick={handleOpenChat} className={c.chatButton}>
          <ChatIcon />
        </button> */}
      </div>
    </div>
  )
}

export default UserList
