import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserResponse } from 'types/User'
import { NavLink } from 'react-router-dom'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
import Button from 'components/common/Button'
import { useMutation } from '@tanstack/react-query'
import { followRequest, unFollowRequest } from 'api'
import { toast } from 'react-toastify'
import VerifiedIcon from 'components/VerifiedIcon'

import s from './Suber.module.scss'

interface SuberProps {
  sub: UserResponse
  type: 'subscribers' | 'subscriptions'
}

function Suber({ sub, type }: SuberProps) {
  const { t } = useTranslation()

  const { data: myProfileData } = useFetchRequestMyProfile()

  const isMyProfile = useMemo(
    () => sub.id?.toString() === myProfileData?.id?.toString(),
    [sub.id, myProfileData?.id],
  )

  const [isSubscribed, setIsSubscribed] = useState(sub.is_subscribed)
  const mutationFollow = useMutation<void, Error, string>((id) => {
    return followRequest(id!)
  })
  const mutationUnFollow = useMutation<void, Error, string>((id) => {
    return unFollowRequest(id!)
  })
  const handleFollow = () => {
    mutationFollow.mutate(sub.id)
    setIsSubscribed(!isSubscribed)
    toast.success('Everything went well')
  }

  const handleUnfollow = () => {
    mutationUnFollow.mutate(sub.id)
    setIsSubscribed(!isSubscribed)
    toast.success('Everything went well')
  }

  return (
    <li className={s.subs__item}>
      <div className={s.sub__avatar}>
        <img src={sub.avatar} alt="" />
        <NavLink className={s.link} end to={`/profile/${sub.nickname}`} />
      </div>
      <div style={{ position: 'relative' }}>
        <div className={s.name}>
          <div className={s.sub__username}>{sub.nickname}</div>
          {sub.is_paid_subscription && <VerifiedIcon />}
        </div>
        <NavLink className={s.link} end to={`/profile/${sub.nickname}`} />
      </div>
      <div className={s.followOrUnfollow}>
        {(isMyProfile && (
          <Button variant="outlined">{t('profile.itsYou')}</Button>
        )) ||
          ((!isSubscribed && type === 'subscriptions') ||
          (isSubscribed && type === 'subscribers') ? (
            <Button onClick={handleUnfollow} variant="outlined">
              {t('profile.unFollow')}
            </Button>
          ) : (
            <Button onClick={handleFollow}>{t('profile.follow')}</Button>
          ))}
      </div>
    </li>
  )
}

export default Suber
