import React from 'react'
import ImageComparisonSpace from './ImageComparisonSpace'
import c from './ImageComparison.module.scss'

interface ImageComparisonProps {
  imageUrl: string
  sourceUrl: string
  imageSize?: { width: number; height: number } | null
}

function ImageComparison({
  imageUrl,
  sourceUrl,
  imageSize = null,
}: ImageComparisonProps) {
  return (
    <div className={c.container}>
      <ImageComparisonSpace
        imageSize={imageSize}
        imageUrl={imageUrl}
        sourceUrl={sourceUrl}
      />
    </div>
  )
}

export default ImageComparison
