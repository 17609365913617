import { useQuery } from '@tanstack/react-query'
import { getGeneration } from 'api'
import { useAppSelector } from 'hooks/store'
import { EnhancementResponse } from 'types/Response'

const useFetchGeneration = () => {
  const deviceId = useAppSelector((state) => state.settings.deviceId)
  const userId = useAppSelector((state) => state.auth.user?.uid)

  const deps = [deviceId, userId]

  return useQuery<EnhancementResponse[], Error>(
    ['magicVariations', deps],
    () => {
      return getGeneration()
    },
  )
}

export default useFetchGeneration
