import React, { useState } from 'react'
import classNames from 'classnames'
import { ThemesResponse } from '../../types/Chats'
import c from './ChatThemesSelect.module.scss'
import AddIcon from '../icons/AddIcon'
import Arrow from '../icons/Arrow'

interface ChatThemesSelectProps {
  themesData: ThemesResponse[]
  theme: ThemesResponse | undefined
  setTheme: (theme: ThemesResponse | undefined) => void
  handleCreateTheme: () => void
}

export default function ChatThemesSelect({
  theme,
  handleCreateTheme,
  themesData,
  setTheme,
}: ChatThemesSelectProps) {
  const [isListOpen, setIsListOpen] = useState<boolean>()

  const handleListOpen = () => {
    if (themesData.length > 0) {
      setIsListOpen(!isListOpen)
    }
  }

  const handleSetNewTheme = (themeData: ThemesResponse | undefined) => {
    setTheme(themeData)
  }

  return (
    <button
      type="button"
      onClick={themesData.length > 0 ? handleListOpen : handleCreateTheme}
      className={classNames({
        [c.chatThemesSelect]: true,
        [c.chatThemesSelectActive]: isListOpen,
        [c.chatThemesSelectDisabled]: !isListOpen,
      })}
    >
      <div className={c.content}>
        <div className={c.themeName}>{theme ? theme.title : 'Chats'}</div>
        <div className={c.icon}>
          {themesData.length > 0 ? (
            <Arrow
              className={classNames({
                [c.iconSetting]: true,
                [c.active]: isListOpen,
              })}
            />
          ) : (
            <AddIcon style={{ width: '14px', height: '14px' }} />
          )}
        </div>
      </div>
      {isListOpen && (
        <div className={c.themes}>
          <button
            type="button"
            className={classNames({
              [c.theme]: true,
            })}
            onClick={() => handleSetNewTheme(undefined)}
          >
            Default
          </button>
          {themesData.map((themeData) => {
            return (
              <button
                type="button"
                key={themeData.id}
                className={classNames({
                  [c.theme]: true,
                  [c.active]: themeData.id === theme?.id,
                })}
                onClick={() => handleSetNewTheme(themeData)}
              >
                {themeData.title}
              </button>
            )
          })}
          <button
            type="button"
            className={classNames({
              [c.theme]: true,
            })}
            onClick={handleCreateTheme}
          >
            New Chat
          </button>
        </div>
      )}
    </button>
  )
}