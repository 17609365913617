import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InView } from 'react-intersection-observer'
import classNames from 'classnames'

import { ILightbox } from 'pages/TrendingPage/TrendingPage'
import Loader from 'components/common/Loader'
import TrendingPost from 'components/TrendingPost'
import UserList from 'components/UserList'
import useInfiniteFetchSearchResult from 'hooks/query/useInfiniteFetchSearchResult'

import c from './SearchContent.module.scss'

interface SearchContentProps {
  searchValue: string
  orderBy: string
}

function SearchContent({ searchValue, orderBy }: SearchContentProps) {
  const [isLightboxOpen, setIsLightboxOpen] = useState<ILightbox>({
    id: null,
    state: false,
  })

  const { t } = useTranslation()

  const isMobile = window.matchMedia('(max-width: 700px)').matches

  const {
    data: infiniteSearchData,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteFetchSearchResult({
    userText: searchValue,
    category: orderBy,
    limit: isMobile ? 10 : 20,
  })

  const searchDataUsers = useMemo(() => {
    if (infiniteSearchData === undefined) return []
    return infiniteSearchData.pages.flatMap((p) => p.users)
  }, [infiniteSearchData])
  const searchDataPosts = useMemo(() => {
    if (infiniteSearchData === undefined) return []
    return infiniteSearchData.pages.flatMap((p) => p.posts)
  }, [infiniteSearchData])

  const onScrollToEnd = () => {
    if (isLoading) return
    fetchNextPage()
  }

  console.log(hasNextPage)

  if (isLoading) {
    return (
      <div className={c.loaderWrapper}>
        <div className={c.loader}>
          <Loader />
        </div>
        <span className={c.loaderText}>
          {t('general.search.searchLoading')}
        </span>
      </div>
    )
  }

  return (
    <div className={c.searchContent}>
      <div className={c.container}>
        {orderBy === 'users' &&
          (searchDataUsers.length > 0 ? (
            searchDataUsers.map((user) => (
              <UserList key={user.id} user={user} />
            ))
          ) : (
            <div className={c.nothingText}>{t('general.search.notFound')}</div>
          ))}
        {orderBy === 'posts' &&
          (searchDataPosts.length > 0 ? (
            searchDataPosts.map((post) => (
              <div
                className={c.wrapper}
                key={post.id}
                style={{
                  width: '100%',
                  zIndex:
                    post.id === isLightboxOpen.id && isLightboxOpen.state
                      ? 1000
                      : 'auto',
                }}
              >
                <TrendingPost
                  post={post}
                  trending
                  isLightboxOpen={isLightboxOpen}
                  setIsLightboxOpen={setIsLightboxOpen}
                />
              </div>
            ))
          ) : (
            <div className={c.nothingText}>{t('general.search.notFound')}</div>
          ))}
        {!isLoading && !isFetchingNextPage && hasNextPage && (
          <InView as="div" onChange={(inView) => inView && onScrollToEnd()} />
        )}
        {!isLoading && isFetchingNextPage && (
          <div
            className={classNames(
              { [c.loaderWrapper]: true },
              {
                [c.loaderWrapperInfinity]: true,
              },
            )}
          >
            <div className={c.loader}>
              <Loader />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SearchContent
