interface HandleChangeOriginYProps {
  imageWrapperRef: React.RefObject<HTMLDivElement>
  originY: number
  handleChangeOrigin: (offsetValue: { y: number } | { x: number }) => void
}

export const HandleImagePositionEffectY = ({
  imageWrapperRef,
  originY,
  handleChangeOrigin,
}: HandleChangeOriginYProps) => {
  if (imageWrapperRef.current) {
    const { height } = imageWrapperRef.current.getBoundingClientRect()
    const isOutOfWrapper = height > window.innerHeight

    if (originY + height > window.innerHeight || originY <= 0) {
      if (isOutOfWrapper) {
        if (originY * -1 >= height - window.innerHeight && originY < 0) {
          handleChangeOrigin({
            y: (height - window.innerHeight) * -1,
          })
        } else if (
          originY > 0 &&
          !(originY * -1 >= height - window.innerHeight)
        ) {
          handleChangeOrigin({
            y: 0,
          })
        }
      } else if (originY <= 0) {
        handleChangeOrigin({
          y: 0,
        })
      } else if (originY + height > window.innerHeight) {
        handleChangeOrigin({
          y: window.innerHeight - height,
        })
      }
    }
  }
}

interface HandleChangeOriginXProps {
  imageWrapperRef: React.RefObject<HTMLDivElement>
  originX: number
  handleChangeOrigin: (offsetValue: { y: number } | { x: number }) => void
}

export const HandleImagePositionEffectX = ({
  imageWrapperRef,
  originX,
  handleChangeOrigin,
}: HandleChangeOriginXProps) => {
  if (imageWrapperRef.current) {
    const { width } = imageWrapperRef.current.getBoundingClientRect()
    const isOutOfWrapper = width > window.innerWidth

    if (originX + width > window.innerWidth || originX <= 0) {
      if (isOutOfWrapper) {
        if (originX * -1 >= width - window.innerWidth && originX < 0) {
          handleChangeOrigin({ x: (width - window.innerWidth) * -1 })
        } else if (
          originX > 0 &&
          !(originX * -1 >= width - window.innerWidth)
        ) {
          handleChangeOrigin({ x: 0 })
        }
      } else if (originX <= 0) {
        handleChangeOrigin({ x: 0 })
      } else if (originX + width > window.innerWidth) {
        handleChangeOrigin({ x: window.innerWidth - width })
      }
    }
  }
}
