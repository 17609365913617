import React from 'react'

// eslint-disable-next-line react/require-default-props
function PlayIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M82.1477 29.3064C80.1227 24.5027 77.2089 20.1939 73.5077 16.4927C69.8064 12.7914 65.4977 9.88891 60.6939 7.85266C55.7214 5.74891 50.4452 4.69141 45.0002 4.69141C39.5552 4.69141 34.2789 5.76016 29.3064 7.86391C24.5027 9.88891 20.1939 12.8027 16.4927 16.5039C12.7914 20.2052 9.88891 24.5139 7.85266 29.3177C5.74891 34.2789 4.69141 39.5552 4.69141 45.0002C4.69141 50.4452 5.76016 55.7214 7.86391 60.6939C9.88891 65.4977 12.8027 69.8064 16.5039 73.5077C20.2052 77.2089 24.5139 80.1114 29.3177 82.1477C34.2902 84.2514 39.5664 85.3202 45.0114 85.3202C50.4564 85.3202 55.7327 84.2514 60.7052 82.1477C65.5089 80.1227 69.8177 77.2089 73.5189 73.5077C77.2202 69.8064 80.1227 65.4977 82.1589 60.6939C84.2627 55.7214 85.3314 50.4452 85.3314 45.0002C85.3089 39.5552 84.2514 34.2789 82.1477 29.3064ZM45.0002 79.6839C25.8752 79.6839 10.3164 64.1251 10.3164 45.0002C10.3164 25.8752 25.8752 10.3164 45.0002 10.3164C64.1252 10.3164 79.6839 25.8752 79.6839 45.0002C79.6839 64.1251 64.1252 79.6839 45.0002 79.6839Z"
        fill="white"
      />
      <path
        d="M59.2312 38.6098L41.535 28.1586C39.375 26.8873 36.7875 26.8648 34.6162 28.1023C32.355 29.3961 30.9487 31.8711 30.9487 34.5486V55.4511C30.9487 58.1286 32.355 60.6036 34.6162 61.8973C35.685 62.5048 36.855 62.8198 38.025 62.8198C39.2287 62.8198 40.4325 62.4936 41.535 61.8523L59.2312 51.4011C61.47 50.0736 62.8087 47.6886 62.8087 45.0111C62.8087 42.3223 61.47 39.9261 59.2312 38.6098ZM56.3737 46.5523L38.6775 57.0036C38.1487 57.3186 37.6987 57.1836 37.4062 57.0148C36.8775 56.7111 36.5737 56.1373 36.5737 55.4511V34.5486C36.5737 33.8736 36.8887 33.2886 37.4062 32.9848C37.5637 32.8948 37.7887 32.8048 38.0362 32.8048C38.2275 32.8048 38.4525 32.8611 38.6775 32.9961L56.3737 43.4473C57.1275 43.8973 57.195 44.7411 57.195 44.9998C57.1837 45.2473 57.1275 46.1023 56.3737 46.5523Z"
        fill="white"
      />
    </svg>
  )
}

export default PlayIcon
