import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Modal from 'components/common/Modal'
import AuthForm from 'components/AuthForm'
import useSignUp from 'hooks/query/auth/useSignUp'
import c from './SignUpModal.module.scss'

interface SignUpModalProps {
  open: boolean
  onClose: () => void
}

function SignUpModal({ open, onClose }: SignUpModalProps) {
  const { t } = useTranslation()

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const onSuccess = () => {
    onClose()
    setEmail('')
    setPassword('')
  }

  const {
    mutate: signUp,
    isLoading: isSigningUp,
    error,
  } = useSignUp({ onSuccess })

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      signUp({ email, password })
    },
    [signUp, email, password],
  )

  useEffect(() => {
    if (error?.message) toast.error(error.message)
  }, [error?.message])

  return (
    <Modal open={open} onClose={onClose} heading={t('modals.signUp.heading')}>
      <AuthForm
        onClose={onClose}
        className={c.form}
        email={email}
        password={password}
        submitText={t('modals.signUp.submit')}
        isSubmitting={isSigningUp}
        onEmailChange={setEmail}
        onPasswordChange={setPassword}
        onSubmit={handleSubmit}
        formType="signUp"
      />
    </Modal>
  )
}

export default SignUpModal
