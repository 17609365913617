import { useQuery } from '@tanstack/react-query'
import { getMyProfile } from 'api'

import { setUserInfo } from 'store/reducers/authReducer'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import { UserResponse } from 'types/User'
import { auth } from 'utils/firebase'

const useFetchRequestMyProfile = () => {
  const deviceId = useAppSelector((state) => state.settings.deviceId)
  const userId = useAppSelector((state) => state.auth.user?.uid)
  const { isAuthInitialized } = useAppSelector((state) => state.auth)

  const dispatch = useAppDispatch()

  const deps = [deviceId, userId]

  return useQuery<UserResponse | null, Error>(
    ['myProfile', deps],
    async () => {
      const token = await auth.currentUser?.getIdToken()
      if (userId && token) {
        const profile = await getMyProfile()
        dispatch(
          setUserInfo({
            is_paid_subscription: profile.is_paid_subscription,
          }),
        )
        return profile
      }
      return Promise.reject(new Error('Invalid token'))
    },
    { enabled: isAuthInitialized },
  )
}

export default useFetchRequestMyProfile
