/* eslint-disable import/prefer-default-export */
import store from 'store'
import i18n from 'i18n/config'
import { auth } from 'utils/firebase'

const endpoint = process.env.REACT_APP_API_ENDPOINT || ''
export const adminDeleteEverywhere = async (
  postId: number,
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/admin/post'

  const result = await fetch(`${endpoint}/${domain}/${postId}`, {
    method: 'DELETE',
    headers: {
      'x-language': language,
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  // return result.json()
}

export const adminHidePost = async (
  postId: number,
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/admin/post'

  const result = await fetch(`${endpoint}/${domain}/${postId}/lock`, {
    method: 'POST',
    headers: {
      'x-language': language,
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  // return result.json()
}

export const adminUnHidePost = async (
  postId: number,
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/admin/post'

  const result = await fetch(`${endpoint}/${domain}/${postId}/unlock`, {
    method: 'POST',
    headers: {
      'x-language': language,
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  // return result.json()
}

export const adminBlockUser = async (
  userId: string,
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/admin/user'

  const result = await fetch(`${endpoint}/${domain}/${userId}/block`, {
    method: 'POST',
    headers: {
      'x-language': language,
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  // return result.json()
}

export const adminUnBlockUser = async (
  userId: string,
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/admin/user'

  const result = await fetch(`${endpoint}/${domain}/${userId}/unblock`, {
    method: 'POST',
    headers: {
      'x-language': language,
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  // return result.json()
}

export const adminHideTrends = async (
  userId: string,
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/admin/user'
  const path = 'trending'

  const result = await fetch(`${endpoint}/${domain}/${userId}/${path}/block`, {
    method: 'POST',
    headers: {
      'x-language': language,
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  // return result.json()
}
export const adminUnHideTrends = async (
  userId: string,
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/admin/user'
  const path = 'trending'

  const result = await fetch(
    `${endpoint}/${domain}/${userId}/${path}/unblock`,
    {
      method: 'POST',
      headers: {
        'x-language': language,
        'X-Device-Id': deviceId,
        ...(token && {
          Authorization: `Bearer ${token}`,
        }),
        ...headers,
      },
    },
  )

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  // return result.json()
}
