import { useQuery } from '@tanstack/react-query'

import { getPluginsCategories } from 'api/plugins'
import { CategoryProps } from 'types/Plugins'

const useFetchPluginsCategories = () => {
  return useQuery<{ items: CategoryProps[] }, Error>(
    ['pluginsCategories'],
    async () => {
      return getPluginsCategories()
    },
  )
}

export default useFetchPluginsCategories
