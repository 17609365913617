import { postRepeat, postRequestMixer } from 'api'
import { toast } from 'react-toastify'
import { BaseResponse, LimitError } from 'types/Response'
import Strategy from './Strategy'

interface Constructor {
  text: string
  image: File | undefined
  image2?: File | undefined
  image3?: File | undefined
  styleTitle?: string | undefined
  styleText?: string | undefined
  requestId?: string | undefined
}

export default class MixerStrategy extends Strategy {
  private readonly text: string

  private readonly image: File | undefined

  private readonly image2?: File | undefined

  private readonly image3?: File | undefined

  private readonly styleTitle?: string | undefined

  private readonly styleText?: string | undefined

  private readonly requestId?: string | undefined

  private responseSeconds?: number

  constructor({
    text,
    image,
    styleTitle,
    styleText,
    image2,
    image3,
    requestId,
  }: Constructor) {
    super()
    this.text = text
    this.image = image
    this.styleTitle = styleTitle
    this.styleText = styleText
    this.image2 = image2
    this.image3 = image3
    this.requestId = requestId
  }

  public async start(): Promise<void> {
    const { id, assets, meta } = await this.makeRequest()
    this.responseSeconds = meta.response_seconds
    this.collectAssets(id, assets.length).then()
  }

  public async repeat(): Promise<void> {
    const { id, assets, meta } = await this.makeRequestRepeat()
    this.responseSeconds = meta.response_seconds
    this.collectAssets(id, assets.length).then()
  }

  public getSeconds() {
    return this.responseSeconds
  }

  public stop(): void {
    this.collector?.stop()
  }

  public async makeRequest(): Promise<BaseResponse> {
    try {
      const formData = new FormData()
      formData.append('text', this.text)
      formData.append('styleText', this.styleText!)
      formData.append('styleTitle', this.styleTitle!)
      formData.append('isManyAssets', 'true')
      formData.append('assets', this.image!)
      formData.append('assets', this.image2!)
      formData.append('assets', this.image3!)

      return await postRequestMixer(formData)
    } catch (error) {
      this.collector?.stop()

      const errorData = error as LimitError
      if (errorData.key === 'LIMIT_GENERATION') {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw errorData
      }

      toast.error('Something went wrong, try again!')
      throw error
    }
  }

  public async makeRequestRepeat(): Promise<BaseResponse> {
    try {
      return await postRepeat(this.requestId!, this.text)
    } catch (error) {
      toast.error('Something went wrong, try again!')
      this.collector?.stop()
      throw error
    }
  }
}
