import React from 'react'

// eslint-disable-next-line react/require-default-props
function SearchIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Interface / Search_Magnifying_Glass">
        <path
          id="Vector"
          d="M22.7999 24C22.4929 24 22.1858 23.883 21.9514 23.6486L15.4876 17.1849C13.8111 18.4917 11.7598 19.2001 9.60014 19.2001C7.03586 19.2001 4.62493 18.2015 2.81197 16.3886C0.998643 14.5749 0 12.1643 0 9.60007C0 7.03581 0.998642 4.6249 2.81161 2.81159C4.62493 0.998635 7.03586 0 9.60014 0C12.1644 0 14.5754 0.998635 16.3883 2.81159C18.2016 4.6249 19.1999 7.03544 19.1999 9.59971C19.1999 11.7597 18.4914 13.8106 17.1846 15.4871L23.6485 21.9509C24.1172 22.4196 24.1172 23.1792 23.6485 23.6479C23.4141 23.8823 23.107 23.9993 22.7999 23.9993V24ZM9.60014 2.40011C5.63005 2.40011 2.40013 5.63001 2.40013 9.60007C2.40013 13.5701 5.63005 16.8 9.60014 16.8C13.5702 16.8 16.8002 13.5701 16.8002 9.60007C16.8002 5.63001 13.5702 2.40011 9.60014 2.40011Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export default SearchIcon
