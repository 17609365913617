import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import IdleStage from 'components/stages/variations/IdleStage'
import ProcessingStage from 'components/stages/variations/ProcessingStage'
import {
  changeCurrentAssetIndex,
  changeProcessedAssets,
  changeStage,
} from 'store/reducers/stageReducer'
import Processor, { VariationsStrategy } from 'api/Retomagic'
import useFetchAsset from 'hooks/query/useFetchAsset'
import useFetchGeneration from 'hooks/query/useFetchGeneration'
import useFetchRequest from 'hooks/query/useFetchRequest'
import useFetchStyles from 'hooks/query/useFetchStyles'
import useFetchSuggestions from 'hooks/query/useFetchSuggestions'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import {
  ProcessedAssetsChangedPayload,
  ProcessorEvents,
} from 'types/ProcessorEvents'
import { LimitError } from 'types/Response'
import useFetchRequestMyProfile from '../../hooks/query/useFetchRequestMyProfile'
import GenerationContainer, {
  AsideSlot,
  MainSlot,
} from '../GenerationControls/GenerationContainer/GenerationContainer'

import c from './MagicVariations.module.scss'

function MagicVariations() {
  const dispatch = useAppDispatch()
  const { stage } = useAppSelector((state) => state.stage)
  const { t } = useTranslation()
  const { assetId, requestId } = useParams()

  const { data: requestData } = useFetchRequest({
    requestId,
  })

  const navigate = useNavigate()

  const { data: generatedElement } = useFetchAsset({ assetId })

  const { data: generationHistoryData } = useFetchGeneration()

  const { data: stylesData } = useFetchStyles()

  const { data: suggestionsData } = useFetchSuggestions()
  const [seconds, setSeconds] = useState<number | undefined>(0)
  const [chosenStyle, setChosenStyle] = useState<string | undefined>('')

  const [isAdvancedSettingsOpen, setIsAdvancedSettingsOpen] = useState(false)
  const [text, setText] = useState<string>('')
  const [guidanceScale, setGuidanceScale] = useState<number>(8)
  const [seed, setSeed] = useState<string | undefined>(undefined)
  const [steps, setSteps] = useState<number>(20)
  const [negativePrompt, setNegativePrompt] = useState<string>('')

  const [imageFile, setImageFile] = useState<File | null>(null)
  const [strength, setStrength] = useState<number>(55)

  const [imageUrlParamAsset, setImageUrlParamAsset] = useState<
    string | undefined
  >('')

  const [generationProcessor, setGenerationProcessor] =
    useState<Processor | null>(null)

  const [chosenHistory, setChosenHistory] = useState<number | undefined>(
    undefined,
  )

  const { data: user } = useFetchRequestMyProfile()

  const imageUrl = useMemo(() => {
    // if (imageFromConcept) return imageFromConcept
    if (imageFile) {
      return URL.createObjectURL(imageFile)
    }
    return null
    // , imageFromConcept
  }, [imageFile])

  useEffect(() => {
    if (!assetId) return
    const isInHistory = generationHistoryData?.find(
      (elem) => elem.assets[0].id === Number(assetId),
    )

    if (isInHistory) {
      setChosenHistory(Number(assetId))
    } else if (generatedElement && !isInHistory) {
      setImageUrlParamAsset(generatedElement?.url)
      setChosenHistory(Number(assetId))
    }
  }, [generatedElement, assetId])

  const handleHistoryClick = useCallback((id: number | undefined) => {
    setChosenHistory(id)
    setImageUrlParamAsset(undefined)
  }, [])

  const onImageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0]
      if (file) {
        setImageFile(file)
        setChosenHistory(undefined)
      }
    },
    [],
  )
  const onDeleteImage = useCallback(() => {
    setImageFile(null)
    setChosenHistory(undefined)
    // setImageFromConcept(null)
  }, [])

  const handleProcessedAssetsChanged = useCallback(
    (payload: ProcessedAssetsChangedPayload) => {
      const { assets, isFirstSucceededResult, id } = payload

      dispatch(changeProcessedAssets(assets))
      dispatch(changeStage('idle'))

      if (isFirstSucceededResult) {
        navigate(`/magic-variations/finish/${id}`)
      }
    },
    [],
  )

  const handleProcessingStart = useCallback(async () => {
    if (!imageFile && !chosenHistory) {
      toast.error(t('stages.generation.idle.uploadPhoto'))
      return
    }
    try {
      dispatch(changeStage('processing'))

      const thisStyle = stylesData.find((elem) => elem.id === chosenStyle)

      const strategy = new VariationsStrategy({
        image: chosenHistory?.toString() || imageFile!,
        request: text,
        styleTitle: thisStyle?.title || '',
        styleText: thisStyle?.text || '',
        strength: (strength / 100).toFixed(2),
        guidanceScale,
        negativePrompt,
        numSteps: steps,
        requestId: undefined,
        seed,
      })
      const processor = new Processor(strategy)
      processor.on(
        ProcessorEvents.PROCESSED_ASSETS_CHANGED,
        handleProcessedAssetsChanged,
      )

      await processor.start()
      setSeconds(strategy.getSeconds())
      setGenerationProcessor(processor)
    } catch (error) {
      const errorData = error as LimitError
      if (errorData.key === 'LIMIT_GENERATION') {
        setText('')
        setChosenStyle('')
        setChosenHistory(undefined)
        dispatch(changeStage('idle'))
      }
      console.log(error)
    }
  }, [
    text,
    handleProcessedAssetsChanged,
    chosenStyle,
    stylesData,
    imageFile,
    strength,
    isAdvancedSettingsOpen,
    guidanceScale,
    seed,
    steps,
    negativePrompt,
    imageUrl,
  ])

  useEffect(() => {
    if (stage !== 'idle') return
    dispatch(changeProcessedAssets([]))
    dispatch(changeCurrentAssetIndex(0))
  }, [stage])

  const onExampleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setText(event.currentTarget?.value)
    },
    [],
  )

  const onStyleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (chosenStyle === event.currentTarget.value) {
        setChosenStyle(undefined)
      } else {
        setChosenStyle(event.currentTarget.value)
      }
    },
    [chosenStyle],
  )

  useEffect(() => {
    return () => {
      if (stage !== 'processing') {
        dispatch(changeProcessedAssets([]))
        dispatch(changeCurrentAssetIndex(0))
        generationProcessor?.stop()
        setGenerationProcessor(null)
      }
    }
  }, [generationProcessor, stage])

  useEffect(() => {
    if (requestData) {
      setText(requestData.assets[0].source)
      setChosenStyle(requestData.assets[0].settings?.style_title)
      setNegativePrompt(requestData.assets[0].settings?.negative_prompt || '')
      setStrength(Number(requestData.assets[0].settings?.strength) * 100 || 55)
    }
  }, [requestData])

  return (
    <GenerationContainer stage={stage}>
      <MainSlot>
        {stage === 'processing' && seconds && (
          <div className={c.processing}>
            <ProcessingStage seconds={seconds} />
          </div>
        )}
        <Outlet />
      </MainSlot>
      <AsideSlot>
        <IdleStage
          text={text}
          onTextChange={setText}
          onProcessingStart={handleProcessingStart}
          suggestions={suggestionsData}
          onExampleClick={onExampleClick}
          onStyleClick={onStyleClick}
          handleHistoryClick={handleHistoryClick}
          onImageChange={onImageChange}
          setStrength={setStrength}
          strength={strength}
          chosenStyle={chosenStyle}
          chosenHistory={chosenHistory}
          styles={stylesData}
          generationHistoryData={generationHistoryData}
          imageUrl={imageUrl || imageUrlParamAsset}
          guidanceScale={guidanceScale}
          setGuidanceScale={setGuidanceScale}
          seed={seed}
          setSeed={setSeed}
          steps={steps}
          setSteps={setSteps}
          isAdvancedSettingsOpen={isAdvancedSettingsOpen}
          setIsAdvancedSettingsOpen={setIsAdvancedSettingsOpen}
          negativePrompt={negativePrompt}
          setNegativePrompt={setNegativePrompt}
          onDeleteImage={onDeleteImage}
        />
      </AsideSlot>
    </GenerationContainer>
  )
}

export default MagicVariations
