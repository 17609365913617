import React from 'react';
import ArrowBottomIcon from "../icons/ArrowBottomIcon";
import c from './ScrollChatBack.module.scss';

interface ScrollChatBackProps {
    goBackScroll: () => void;
}

function ScrollChatBack({ goBackScroll }: ScrollChatBackProps) {
    return (
        <div className={c.container}>
            <div className={c.content}>
                <button type='button' className={c.contentButton} onClick={goBackScroll}>
                    <ArrowBottomIcon />
                </button>
            </div>
        </div>
    );
}


export default ScrollChatBack;
