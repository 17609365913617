import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BaseAsset } from 'types/Response'
import { Stage } from 'types/Stage'

interface InitialState {
  stage: Stage
  processedAssets: BaseAsset[]
  currentAssetIndex: number
}

const initialState: InitialState = {
  stage: 'idle',
  processedAssets: [],
  currentAssetIndex: 0,
}

const stageSlice = createSlice({
  name: 'stage',
  initialState,
  reducers: {
    changeStage: (state, action: PayloadAction<Stage>) => {
      state.stage = action.payload
    },
    changeProcessedAssets: (state, action: PayloadAction<BaseAsset[]>) => {
      state.processedAssets = action.payload
    },
    changeCurrentAssetIndex: (state, action: PayloadAction<number>) => {
      state.currentAssetIndex = action.payload
    },
  },
})

export default stageSlice.reducer

export const { changeCurrentAssetIndex, changeProcessedAssets, changeStage } =
  stageSlice.actions
