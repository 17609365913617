import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useInView } from 'react-intersection-observer'
import Loader from 'components/common/Loader'
import ConfirmModal from 'components/modals/ConfirmModal'
import DeleteButton from 'components/DeleteButton'
import ArchiveContent from 'components/ArchiveContent'
import { useTranslation } from 'react-i18next'
import useInfiniteFetchRequests from 'hooks/query/useInfiniteFetchRequests'
import { toast } from 'react-toastify'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteRequests } from 'api'
import c from './ProfileArchivePage.module.scss'

export interface CheckedItems {
  [key: number]: boolean
}

function ProfileArchivePage() {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { ref, inView } = useInView()
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [selectedToDelete, setSelectedToDelete] = useState<number[]>([])
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false)
  const [checkedAssets, setCheckedAssets] = useState<{
    [key: number]: boolean
  }>({})
  const [checkedItems, setCheckedItems] = useState<CheckedItems>({})

  const mutationMassDeletion = useMutation<void, Error>(() => {
    return deleteRequests(selectedToDelete)
  })

  const massDeleteRequest = () => {
    mutationMassDeletion.mutateAsync().then(() => {
      queryClient.invalidateQueries([`userData`])
      queryClient.refetchQueries([`infinite-requests`])
    })
    toast.success('Everything went well')
  }

  const {
    data: infiniteArchiveData,
    isLoading: isLoadingArchive,
    isFetchingNextPage: isFetchingNextPageArchive,
    fetchNextPage: fetchNextPageArchive,
    hasNextPage: hasNextPageArchive,
  } = useInfiniteFetchRequests({})

  const archiveData = useMemo(() => {
    if (!infiniteArchiveData) return []
    return infiniteArchiveData.pages.flatMap((p) => p)
  }, [infiniteArchiveData])

  const handleMassDelete = () => {
    if (isDeleting) {
      if (selectedToDelete.length === 0) {
        setIsDeleting(false)
        return
      }
      massDeleteRequest()
      setIsDeleting(false)
      setSelectedToDelete([])
      setIsConfirmModalOpen(false)
    }
  }
  const handleDeleteToggle = () => {
    setCheckedAssets({})
    setIsDeleting(!isDeleting)
    setSelectedToDelete([])
    setCheckedItems({})
  }

  const closeModal = useCallback(() => {
    setIsConfirmModalOpen(false)
  }, [])

  const handleDeleteItems = useCallback(() => {
    setIsConfirmModalOpen(true)
  }, [])

  const selectToDelete = useCallback(
    (postId: number) => {
      if (selectedToDelete.includes(postId)) {
        setSelectedToDelete(selectedToDelete.filter((elem) => elem !== postId))
      } else {
        setSelectedToDelete([...selectedToDelete, postId])
      }
    },
    [selectedToDelete],
  )
  useEffect(() => {
    if (isLoadingArchive) return
    fetchNextPageArchive()
  }, [inView])
  return (
    <div className={c.gridWrapper}>
      <ConfirmModal
        heading={t('modals.general.delete')}
        confirmFunc={handleMassDelete}
        cancelFunc={closeModal}
        isModalOpen={isConfirmModalOpen}
      />
      <DeleteButton
        checkedAssets={checkedAssets}
        selectedToDelete={selectedToDelete}
        isDeleting={isDeleting}
        handleDeleteToggle={handleDeleteToggle}
        handleMassDelete={handleDeleteItems}
      />
      <ArchiveContent
        checkedAssets={checkedAssets}
        setCheckedAssets={setCheckedAssets}
        setIsDeleting={setIsDeleting}
        archiveData={archiveData}
        isDeleting={isDeleting}
        selectToDelete={selectToDelete}
        selectedToDelete={selectedToDelete}
        checkedItems={checkedItems}
        setCheckedItems={setCheckedItems}
      />
      {!isLoadingArchive &&
        !isFetchingNextPageArchive &&
        hasNextPageArchive && <div ref={ref} />}
      {isFetchingNextPageArchive && (
        <div className={c.loaderWrapper}>
          <div className={c.loader}>
            <Loader />
          </div>
        </div>
      )}
    </div>
  )
}

export default ProfileArchivePage
