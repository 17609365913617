import React from 'react'

import Container from 'components/common/Container'
import GenerationStyles from 'components/GenerationStyles'
import { Style } from 'types/Generation'
import { fixedResolutionsDalle3 } from 'constants/advancedSettings'
import AdvancedSettings from '../../../AdvancedSettings'
import RequestGenerationContainer from '../../../RequestGenerationContainer'

import c from './IdleStage.module.scss'

interface IdleStageProps {
  text: string
  styles?: Style[]
  isAdvancedSettingsOpen: boolean
  chosenStyle: string | undefined
  onTextChange: (newText: string) => void
  onProcessingStart: () => void
  setIsAdvancedSettingsOpen: (prev: boolean) => void
  onStyleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  resolution: number
  onExampleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  setResolution: (newIndex: any) => void
}

function IdleStage({
  text,
  resolution,
  onExampleClick,
  isAdvancedSettingsOpen,
  setIsAdvancedSettingsOpen,
  styles = [],
  chosenStyle,
  onTextChange,
  onProcessingStart,
  onStyleClick,
  setResolution,
}: IdleStageProps) {
  return (
    <Container flex>
      <div className={c.content}>
        <RequestGenerationContainer
          generationTextAreaVisible
          text={text}
          onTextChange={onTextChange}
          onProcessingStart={onProcessingStart}
          onExampleClick={onExampleClick}
        />

        <GenerationStyles
          chosenStyle={chosenStyle}
          styles={styles}
          onStyleClick={onStyleClick}
        />

        <AdvancedSettings
          isAdvancedSettingsOpen={isAdvancedSettingsOpen}
          setIsAdvancedSettingsOpen={setIsAdvancedSettingsOpen}
          resolution={resolution}
          setResolution={setResolution}
          fixedResolutions={fixedResolutionsDalle3}
          fixedResolutionSelectionVisible
        />
      </div>
    </Container>
  )
}

export default IdleStage
