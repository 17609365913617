import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useFetchRequest from 'hooks/query/useFetchRequest'
import LightBox from 'components/LightBox'
import { BaseAsset } from '../../types/Response'
import { useAppSelector } from '../../hooks/store'
import MobileHeader from '../../components/MobileHeader'
import c from './CommentsFinishPage.module.scss'
import ResultCard from '../../components/ResultCard'
import { ResultCardFooterSlot } from '../../components/ResultCard/ResultCard'
import Button from '../../components/common/Button'
import { createComment } from '../../api'

function CommentsFinishPage(): JSX.Element {
  const { requestId, id, commentId } = useParams()

  const { stage } = useAppSelector((state) => state.stage)

  const navigate = useNavigate()

  const { data } = useFetchRequest({
    requestId,
  })

  const [cards, setCards] = useState<Array<Array<BaseAsset>>>([])
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)
  const [lightboxIndex, setLightboxIndex] = useState(0)

  const [heading] = useState<string>('')
  const [finish, setFinish] = useState<boolean | undefined>(undefined)

  const [isImageLoaded, setIsImageLoaded] = useState<{
    [key: number]: boolean
  }>(
    data?.assets.reduce((acc, asset) => ({ ...acc, [asset.id]: false }), {}) ||
      {},
  )

  const assets = useMemo(() => {
    if (data === undefined) return []
    return data?.assets
  }, [data])

  useEffect(() => {
    const uniquePostIds = new Set()

    const arr: BaseAsset[][] = []

    data?.assets.forEach((elem: BaseAsset, index: number) => {
      if (!uniquePostIds.has(elem.id)) {
        uniquePostIds.add(elem.id)

        if (!arr[index % 2]) arr[index % 2] = []

        arr[index % 2].push(elem)
      }
    })

    setCards(arr)
  }, [data])

  const handleImageLoaded = useCallback((idAsset: number) => {
    setIsImageLoaded((prevState) => ({ ...prevState, [idAsset]: true }))
  }, [])

  const handleCreateComment = (
    e: React.MouseEvent<HTMLButtonElement>,
    assetId: number,
  ) => {
    e.preventDefault()

    const result = window.confirm('Are you sure want to leave a comment?')

    if (result) {
      createComment(
        Number(id!),
        assetId,
        commentId ? Number(commentId) : undefined,
      )
      setFinish(undefined)
    }
  }

  const handleFullScreenOpen = (index: number) => {
    setLightboxIndex(index)
    setIsLightboxOpen(true)
  }

  const handleFullScreenClose = () => {
    setIsLightboxOpen(false)
  }

  // const handleCreateComment = () => {
  //   const result = window.confirm('Are you sure want to leave a comment?')
  //   if (result) {
  //     mutationComment.mutateAsync().then(() => {
  //       queryClient.invalidateQueries([`post`, id])
  //       queryClient.refetchQueries([`infinite-comments`]).then(() => {
  //         toast.success('Everything went well')
  //       })
  //     })
  //     onClose()
  //     setParentComment(undefined)
  //     setFinish(undefined)
  //   }
  // }

  const moveToNext = () => {
    setLightboxIndex((prevIndex) =>
      prevIndex === assets.length - 1 ? 0 : prevIndex + 1,
    )
  }

  const moveToPrev = () => {
    setLightboxIndex((prevIndex) =>
      prevIndex === 0 ? assets.length - 1 : prevIndex - 1,
    )
  }

  useEffect(() => {
    const mainContent = document.getElementById('generationContainerMain')
    if (mainContent) {
      if (isLightboxOpen) {
        mainContent.style.zIndex = '100'
      } else {
        mainContent.style.zIndex = 'auto'
      }
    }
  }, [isLightboxOpen])

  if (!data) return <div />

  return stage !== 'processing' ? (
    <div className={c.cardsContainer}>
      <MobileHeader heading={heading} isOnGoBack />
      <div className={c.grid}>
        {assets.map((asset, assetIndex) => (
          <ResultCard
            key={`${new Date(assetIndex).getTime().toString()}`}
            isImageLoaded={isImageLoaded}
            handleImageLoaded={handleImageLoaded}
            asset={{
              id: asset.id,
              finish_url: asset.url,
              original_url: asset.source_url,
              status: asset.status,
            }}
            components={{
              fullscreen: true,
              compare: true,
            }}
            openModal={() => handleFullScreenOpen(assetIndex)}
          >
            <ResultCardFooterSlot>
              <Button
                variant="contained"
                onClick={(e) => {
                  navigate(`/trending-post/${id}`)
                  handleCreateComment(e, asset.id)
                }}
                disabled={!isImageLoaded[asset.id]}
              >
                Create comment
              </Button>
            </ResultCardFooterSlot>
          </ResultCard>
        ))}
      </div>

      {isLightboxOpen && assets[lightboxIndex].url && (
        <LightBox
          imageUrl={assets[lightboxIndex].url || 'default'}
          onCloseRequest={handleFullScreenClose}
          sourceUrl={assets[lightboxIndex].source_url || ''}
          nextUrl={assets[(lightboxIndex + 1) % assets.length].url}
          prevUrl={
            assets[(lightboxIndex + assets.length - 1) % assets.length].url
          }
          onMovePrevRequest={moveToPrev}
          onMoveNextRequest={moveToNext}
        />
      )}
    </div>
  ) : (
    <div />
  )
}

export default CommentsFinishPage
