import React, { useMemo } from 'react'
import classNames from 'classnames'

import c from './TextInput.module.scss'

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  inputRef?: React.RefObject<HTMLInputElement>
}

function TextInput({
  className,
  startIcon = undefined,
  endIcon = undefined,
  inputRef = undefined,
  ...props
}: TextInputProps) {
  const classNamesProps = useMemo(() => {
    let result = c.inputContainer
    if (startIcon) result += ` ${c.inputContainerLeftPadded}`
    if (endIcon) result += ` ${c.inputContainerRightPadded}`
    if (className) result += ` ${className}`
    return result
  }, [className, startIcon, endIcon])

  return (
    <div className={classNamesProps}>
      {startIcon}
      <input
        className={classNames({
          [c.input]: true,
          [classNamesProps]: true,
        })}
        {...props}
        ref={inputRef}
      />
      {endIcon}
    </div>
  )
}

export default TextInput
