import React from 'react'

import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import PremiumIcon from '../icons/PremiumIcon'
import { UserResponse } from '../../types/User'

import UnknownUserAvatar from '../icons/UnknownUserAvatar.svg'

import c from './SubChatItem.module.scss'

interface SubChatItemProps {
  sub: UserResponse
  userData: UserResponse | null | undefined
}

function SubChatItem({ sub, userData }: SubChatItemProps) {
  if (!sub) return null

  const { t } = useTranslation()

  const navigate = useNavigate()

  const queryClient = useQueryClient()

  const handleOpenChat = () => {
    navigate(`/chats/${sub.id}`)
  }

  return (
    <button
      type="button"
      onClick={handleOpenChat}
      className={classNames({
        [c.container]: true,
      })}
    >
      <div className={c.userInfo}>
        <div className={c.userInfo__Wrapper}>
          <img
            alt="userImg"
            className={c.userInfo__Avatar}
            src={sub.avatar || UnknownUserAvatar}
            onError={(e) =>
              e.currentTarget.setAttribute('src', UnknownUserAvatar)
            }
          />
        </div>
      </div>
      <div className={c.userContainer}>
        <div className={c.userMessageWrapper}>
          <div className={c.userContainer__Nickname}>
            {sub.nickname}
            {sub.is_paid_subscription && (
              <PremiumIcon className={c.iconSetting} />
            )}
          </div>
        </div>
      </div>
    </button>
  )
}

export default SubChatItem