import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Container from 'components/common/Container'
import MobileHeader from '../../components/MobileHeader'
import c from './SettingsPage.module.scss'

function SettingsPage() {
  const location = useLocation()
  const { t } = useTranslation()

  // Function to determine the heading based on the current path
  const getHeading = () => {
    const path = location.pathname

    switch (path) {
      case '/settings':
        return t('navigation.settings')
      case '/settings/language':
        return t('navigation.selectLanguage')
      case '/settings/info':
        return t('navigation.infoCompany')
      default:
        return 'Default Heading'
    }
  }

  return (
    <main className={c.main}>
      <MobileHeader heading={getHeading()} isOnGoBack />
      <Container>
        <div className={c.content}>
          <Outlet />
        </div>
      </Container>
    </main>
  )
}

export default SettingsPage
