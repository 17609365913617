/* eslint-disable consistent-return */
import React from 'react'
import ProcessingLoader from 'components/ProcessingLoader'

import 'react-circular-progressbar/dist/styles.css'

interface ProcessingStageProps {
  seconds: number
}

function ProcessingStage({ seconds }: ProcessingStageProps) {
  return <ProcessingLoader seconds={seconds} />
}

export default ProcessingStage
