import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { useTranslation } from 'react-i18next'
import c from './GenerationSwitcher.module.scss'

export default function GenerationSwitcher() {
  const location = useLocation()
  const { t } = useTranslation()
  const [isMobile, setIsMobile] = useState<boolean>(false)

  const dataGeneration = useMemo(
    () => ({
      'Neuro Art': '/neuro-art',
      'Video Art': '/video-art',
      'Dalle-e-3': '/dall-e-3',
      'Magic Variations': '/magic-variations',
      'AI Avatars': '/ai-avatars',
    }),
    [],
  )

  const dataEnchance = useMemo(
    () => ({
      [t('navigation.enhancement')]: '/enhancement',
      [t('navigation.restoration')]: '/face-restoration',
      [t('navigation.remover')]: '/background-remover',
    }),
    [t],
  )

  const checkPathInData = (data: {}) => {
    return Object.values(data).includes(location.pathname)
  }

  const displayData = useMemo(() => {
    if (checkPathInData(dataGeneration)) {
      return dataGeneration
    }
    if (checkPathInData(dataEnchance)) {
      return dataEnchance
    }
    return {}
  }, [location.pathname, dataGeneration, dataEnchance])

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth <= 1170)
  }, [window])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (!isMobile) return null

  return (
    <div className={c.container}>
      {Object.entries(displayData).map(([key, value]) => (
        <NavLink to={value} key={key}>
          <div
            className={classNames({
              [c.buttonWrapper]: true,
              [c.active]: location.pathname === value,
              [c.unActive]: location.pathname !== value,
            })}
          >
            <span className={c.buttonWrapperText}>{key}</span>
          </div>
        </NavLink>
      ))}
    </div>
  )
}
