import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { ReactComponent as CheckIcon } from '@material-design-icons/svg/round/check.svg'

import c from './CodeBlock.module.scss'
import CopyIcon from '../icons/CopyIcon'

interface CodeBlockProps {
  value: string
}

export default function CodeBlock({ value }: CodeBlockProps) {
  const [isCopied, setIsCopied] = useState<boolean>(false)

  const copyToClipboard = (str: string) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(str)
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 1500)
    }
    return undefined
  }

  return (
    <ReactMarkdown
      className={c.codeBlockWrapper}
      rehypePlugins={[rehypeRaw]}
      remarkPlugins={[remarkGfm]}
      components={{
        // eslint-disable-next-line react/no-unstable-nested-components
        code({ className = 'blogCode', children }) {
          const match = /language-(\w+)/.exec(className || '')
          return match ? (
            <div style={{ position: 'relative' }}>
              <SyntaxHighlighter
                style={vscDarkPlus}
                language={match[1]}
                PreTag="div"
              >
                {String(children).replace(/\n$/, '')}
              </SyntaxHighlighter>
              <button
                type="button"
                className={c.copyButton}
                onClick={() =>
                  copyToClipboard(String(children).replace(/\n$/, ''))
                }
                style={{
                  position: 'absolute',
                  top: '15px',
                  right: '10px',
                }}
              >
                {!isCopied ? (
                  <CopyIcon style={{ width: '18px', height: '18px' }} />
                ) : (
                  <CheckIcon
                    style={{ fill: '#FFF', width: '18px', height: '18px' }}
                  />
                )}
              </button>
            </div>
          ) : (
            <code className={className}>{children}</code>
          )
        },
      }}
    >
      {value}
    </ReactMarkdown>
  )
}
