import React, { useEffect, useMemo, useState } from 'react'

import { DateTime } from 'luxon'
import classNames from 'classnames'
import PinUserIcon from '../icons/PinUserIcon'
import PremiumIcon from '../icons/PremiumIcon'
import BotIcon from '../icons/BotIcon'
import { MessageItem } from '../../types/Chats'
import c from './ChatPanelItem.module.scss'
import useFetchRequestMyProfile from '../../hooks/query/useFetchRequestMyProfile'
import UnknownUserAvatar from '../icons/UnknownUserAvatar.svg'
import isInbox from '../../utils/isInbox'

interface ChatPanelItemProps {
  isPinned?: boolean
  inboxCount?: number
  chatData: MessageItem
  handleClickChat: (id: string) => void
  activeChatId: string
  lastItem: boolean
  handleRightClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
  ) => void
}

function ChatPanelItem({
  isPinned = false,
  lastItem,
  inboxCount = 0,
  chatData,
  handleClickChat,
  activeChatId,
  handleRightClick,
}: ChatPanelItemProps) {
  const { data: user } = useFetchRequestMyProfile()

  const [isActive, setIsActive] = useState<boolean>(false)

  useEffect(() => {
    if (activeChatId === chatData.recipient.id.toString()) {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }, [activeChatId, chatData])

  return (
    <button
      type="button"
      onClick={() =>
        handleClickChat(
          isInbox(user, chatData)
            ? chatData.sender.id.toString()
            : chatData.recipient.id.toString(),
        )
      }
      onContextMenu={(event) => {
        handleRightClick(
          event,
          isInbox(user, chatData) ? chatData.sender.id : chatData.recipient.id,
        )
      }}
      className={classNames({
        [c.container]: true,
        [c.inboxBackground]: inboxCount > 0,
        [c.activeBackground]: isActive,
        [c.lastContainerItem]: lastItem,
      })}
    >
      <div className={c.userInfo}>
        <div className={c.userInfo__Wrapper}>
          <img
            src={
              isInbox(user, chatData)
                ? chatData.sender?.avatar || UnknownUserAvatar
                : chatData.recipient?.avatar || UnknownUserAvatar
            }
            onError={(e) =>
              e.currentTarget.setAttribute('src', UnknownUserAvatar)
            }
            alt="userImg"
            className={c.userInfo__Avatar}
          />
        </div>
      </div>
      <div className={c.userTextContainer}>
        <div className={c.userMessageWrapper}>
          <div className={c.userTextContainer__Nickname}>
            {isInbox(user, chatData)
              ? chatData.sender?.nickname || 'Unknown'
              : chatData.recipient?.nickname || 'Unknown'}
            {isInbox(user, chatData)
              ? chatData.sender?.is_paid_subscription && (
                  <PremiumIcon className={c.iconSetting} />
                )
              : chatData.recipient?.is_paid_subscription && (
                  <PremiumIcon className={c.iconSetting} />
                )}
            {isInbox(user, chatData) && chatData.recipient?.role === 'bot' && (
              <BotIcon className={c.iconSetting} />
            )}
          </div>

          <div className={c.chatInfo}>
            <div className={c.chatInfo__Time}>
              {chatData?.created_at && (
                <p className={c.date}>
                  {DateTime.fromISO(chatData.created_at).toFormat('HH:mm')}
                </p>
              )}
            </div>
            {isPinned && (
              <div className={c.chatInfo__Pinned}>
                <PinUserIcon />
              </div>
            )}
            {inboxCount > 0 && (
              <div className={c.chatInfo__Inbox}>{inboxCount}</div>
            )}
          </div>
        </div>
        <div className={c.userTextContainer__LastMessage}>{chatData?.text}</div>
      </div>
    </button>
  )
}

export default ChatPanelItem
