import React from 'react';
import Lottie from "lottie-react";
import successAnimation from 'constants/lottie-success.json'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import c from './PaymentSuccessPage.module.scss'

function PaymentSuccessPage() {

    const { t } = useTranslation()

    const style = {
        height: 260,
        width: 260
    }
    
    return (
        <div className={c.wrapper}>
            <Lottie animationData={successAnimation} style={style} loop={false}/>
            <span className={c.title}>{t('successPayment.title')}</span>
            <span className={c.subtitle}>{t('successPayment.subtitle')}</span>
            <Link to='/' className={c.link}>{t('successPayment.link')}</Link>
        </div>
    );
}

export default PaymentSuccessPage;