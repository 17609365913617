import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'

import ShortArrow from 'components/icons/ShortArrow'
import NavigationModal from 'components/modals/NavigationModal'
import navigationModalItems from 'constants/navigationModalItems'
import AiChatIcon from '../icons/AiChatIcon.svg'
import EnhancePhotoIcon from '../icons/EnhancePhotoIcon.svg'
import GPTPlugin from '../icons/GPTPluginIcon.svg'
import HomeIcon from '../icons/HomeIcon.svg'
import LeftArrowIcon from '../icons/LeftArrowIcon.svg'
import NeuroArtIcon from '../icons/NeuroArtIcon.svg'
import videoIcon from '../icons/VideoArtIcon.svg'

import c from './TrendingNavItems.module.scss'

function TrendingNavItems() {
  const { t } = useTranslation()
  const { id, nickname, albumId } = useParams()
  const [modal, setModal] = useState({
    isArt: false,
    isPhoto: false,
  })

  const { ArtGenerationItems, PhotoProcessingItems } = navigationModalItems()

  const location = useLocation()

  const isActive = useCallback(
    (path: string) => location.pathname === path,
    [location.pathname],
  )

  const navigate = useNavigate()

  const goBack = useCallback(() => {
    navigate(-1)
  }, [])

  const handleModalArtOpen = () => setModal({ isArt: true, isPhoto: false })

  const handleModalPhotoOpen = () => setModal({ isArt: false, isPhoto: true })
  const handleModalClose = () => setModal({ isArt: false, isPhoto: false })

  return (
    <nav className={c.navbar}>
      <div className={c.content}>
        <div className={c.links}>
          <div className={c.photoArtContainer}>
            <ul className={c.navbarCategoryItems}>
              {(isActive(`/trending-post/${id}`) ||
                isActive(`/profile/${nickname}`) ||
                isActive(`/profile/${nickname}/liked`) ||
                isActive(`/profile/${nickname}/archive`) ||
                isActive(`/profile/${nickname}/albums`) ||
                isActive(`/profile/${nickname}/albums/create`) ||
                isActive(`/profile/${nickname}/albums/${albumId}`) ||
                isActive(`/notifications`) ||
                isActive('/settings') ||
                isActive('/settings/info') ||
                isActive('/settings/delete') ||
                isActive('/settings/language') ||
                isActive('/terms-of-use') ||
                isActive('/privacy-policy') ||
                isActive(`/search`)) && (
                <button type="button" onClick={goBack}>
                  <li className={c.backNavbarItem}>
                    <div className={c.navbarItem__icon}>
                      <img src={LeftArrowIcon} alt="neuroPhotoIcon" />
                    </div>
                    <div className={c.navbarItem__title}>
                      {t('navigation.rearward')}
                    </div>
                  </li>
                </button>
              )}

              <li
                className={`${c.navbarItem} ${
                  isActive('/') ||
                  isActive('/trending') ||
                  isActive('/trending-video-art') ||
                  isActive('/feed')
                    ? c.active
                    : ''
                }`}
              >
                <div className={c.navbarItem__icon}>
                  <img src={HomeIcon} alt="neuroPhotoIcon" />
                </div>
                <div className={c.navbarItem__title}>
                  {t('navigation.home')}
                </div>
                <NavLink to="/" end className={c.link} />
              </li>
              <li
                className={`${c.navbarItem} ${c.navbarItemModal}`}
                onMouseEnter={handleModalArtOpen}
                onMouseLeave={handleModalClose}
              >
                <div className={c.navbarItem__button}>
                  <div className={c.navbarItem__icon}>
                    <img src={NeuroArtIcon} alt="neuroPhotoIcon" />
                  </div>
                  <div className={c.navbarItem__title}>
                    {t('navigation.artGeneration')}
                  </div>
                  <div className={c.navbarItem__arrowIcon}>
                    <ShortArrow
                      className={`${c.arrowIcon} ${
                        modal.isArt ? c.rotateIcon : ''
                      }`}
                    />
                  </div>
                  <NavLink
                    to={ArtGenerationItems[0].link}
                    end
                    className={c.link}
                  />
                </div>
                <div
                  className={classNames({
                    [c.navbarItem__modal]: true,
                    [c.modalActive]: modal.isArt,
                  })}
                >
                  <NavigationModal
                    variant="artGeneration"
                    setModal={setModal}
                  />
                </div>
              </li>
              <li
                className={`${c.navbarItem} ${c.navbarItemModal}`}
                onMouseEnter={handleModalPhotoOpen}
                onMouseLeave={handleModalClose}
              >
                <button className={c.navbarItem__button} type="button">
                  <div className={c.navbarItem__icon}>
                    <img src={EnhancePhotoIcon} alt="neuroPhotoIcon" />
                  </div>
                  <div className={c.navbarItem__title}>
                    {t('navigation.photoProcessing')}
                  </div>
                  <div className={c.navbarItem__arrowIcon}>
                    <ShortArrow
                      className={`${c.arrowIcon} ${
                        modal.isPhoto ? c.rotateIcon : ''
                      }`}
                    />
                  </div>
                  <NavLink
                    to={PhotoProcessingItems[0].link}
                    end
                    className={c.link}
                  />
                </button>
                <div
                  className={classNames({
                    [c.navbarItem__modal]: true,
                    [c.modalActive]: modal.isPhoto,
                  })}
                >
                  <NavigationModal
                    variant="photoProcessing"
                    setModal={setModal}
                  />
                </div>
              </li>
              <li
                className={`${c.navbarItem} ${
                  isActive('/video-art') ? c.active : ''
                }`}
              >
                <div className={c.navbarItem__icon}>
                  <img src={videoIcon} alt="videoPhotoIcon" />
                </div>
                <div className={c.navbarItem__title}>
                  {t('navigation.videoArt')}
                </div>
                <NavLink to="/video-art" end className={c.link} />
              </li>
              <li
                className={`${c.navbarItem} ${
                  isActive('/chats') || isActive(`/chats/${id}`) ? c.active : ''
                }`}
              >
                <div className={c.navbarItem__icon}>
                  <img src={AiChatIcon} alt="aiChatIcon" />
                </div>
                <div className={c.navbarItem__title}>
                  {t('navigation.aiChat')}
                </div>
                <NavLink to="/chats/23332" end className={c.link} />
              </li>
              <li
                className={`${c.navbarItem} ${
                  isActive('/plugins') || isActive(`/plugins/${id}`)
                    ? c.active
                    : ''
                }`}
              >
                <div className={c.navbarItem__icon}>
                  <img src={GPTPlugin} alt="GPTPlugin" />
                </div>
                <div className={c.navbarItem__title}>
                  {t('navigation.plugins')}
                </div>
                <NavLink to="/plugins" end className={c.link} />
              </li>
            </ul>

            <ul className={c.bottomPart}>
              {/*
                  <li className={`${c.navbarItemForTexts} ${c.unimportant}`}>
                <div className={c.navbarItem}>
                  <div className={c.footerTitle}>
                    {t('navigation.termsOfUse')}
                  </div>
                  <NavLink to="/terms-of-use" end className={c.link} />
                </div>
                <div className={c.navbarItem}>
                  <div className={c.footerTitle}>
                    {t('navigation.privacyPolicy')}
                  </div>
                  <NavLink to="/privacy-policy" end className={c.link} />
                </div>
              </li>
              */}
              <li className={`${c.navbarItemForTextss} ${c.unimportant}`}>
                <div className={c.footerTitle}>
                  © 2022-{new Date().getFullYear()} Retomagic Inc.
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default TrendingNavItems
