import { useInfiniteQuery } from '@tanstack/react-query'
import { getMyPosts } from 'api'
import { useAppSelector } from 'hooks/store'
import { PostFeed } from 'types/Post'

interface Params {
  id: string | number | undefined
  take?: number
}

const useInfiniteFetchProfilePosts = ({ id, take = 4 }: Params) => {
  const deviceId = useAppSelector((state) => state.settings.deviceId)
  const userId = useAppSelector((state) => state.auth.user?.uid)

  const deps = [deviceId, userId, id]

  return useInfiniteQuery<PostFeed[], Error>(
    [`infinite-profilePosts-${id}`, deps],
    async ({ pageParam: page = 1 }) => {
      if (id) {
        return getMyPosts(id!, page, take)
      }
      return Promise.reject(new Error('Invalid token'))
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < take) return undefined
        return allPages.length + 1
      },
    },
  )
}

export default useInfiniteFetchProfilePosts
