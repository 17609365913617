import React from 'react'
import { NavLink } from 'react-router-dom'

import { useAppSelector } from 'hooks/store'

import LangIcon from 'components/icons/LangIcon.svg'

import c from './LanguageChanger.module.scss'

function LanguageChanger() {
  const language = useAppSelector((state) => state.settings.language)

  return (
    <NavLink to="/settings/language" className={c.container}>
      <img src={LangIcon} alt="Language" className={c.icon} />
      <p className={c.text}>{language}</p>
    </NavLink>
  )
}

export default LanguageChanger
