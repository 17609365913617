import React from 'react'

// eslint-disable-next-line react/require-default-props
function DownloadFileIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        d="M2.25 11.25C2.25 13.3713 2.25 14.4319 2.90901 15.091C3.56802 15.75 4.62868 15.75 6.75 15.75H11.25C13.3713 15.75 14.4319 15.75 15.091 15.091C15.75 14.4319 15.75 13.3713 15.75 11.25"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 2.25V12M9 12L12 8.71875M9 12L6 8.71875"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DownloadFileIcon
