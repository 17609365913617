import React from 'react'

// eslint-disable-next-line react/require-default-props
function ArrowDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M11.9995 20.751C11.5855 20.751 11.2495 20.415 11.2495 20.001V5.81095L6.53055 10.53C6.23655 10.824 5.76255 10.824 5.46855 10.53C5.17455 10.236 5.17455 9.76195 5.46855 9.46795L11.4685 3.46795L11.4715 3.46495L11.4745 3.46195C11.4745 3.46195 11.4745 3.46195 11.4775 3.45895C11.4775 3.45895 11.4775 3.45895 11.4805 3.45895C11.5495 3.39295 11.6305 3.34195 11.7145 3.30595C11.7925 3.27295 11.8795 3.25495 11.9695 3.25195H11.9725C11.9875 3.25195 12.0055 3.25195 12.0205 3.25195H12.0235C12.1135 3.25495 12.1975 3.27295 12.2785 3.30595C12.3625 3.34195 12.4435 3.39295 12.5125 3.45895C12.5125 3.45895 12.5125 3.45895 12.5155 3.46195L12.5185 3.46495L12.5215 3.46795L18.5215 9.46795C18.8155 9.76195 18.8155 10.236 18.5215 10.53C18.2275 10.824 17.7535 10.824 17.4595 10.53L12.7495 5.81095V20.001C12.7495 20.415 12.4135 20.751 11.9995 20.751Z"
        fill="white"
      />
    </svg>
  )
}

export default ArrowDown
