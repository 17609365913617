import React from 'react'

// eslint-disable-next-line react/require-default-props
function GooglePlayIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.90478 2.80345C3.67978 3.04495 3.55078 3.41695 3.55078 3.90045V21.0925C3.55078 21.576 3.67978 21.948 3.91328 22.181L3.97428 22.2325L13.6073 12.5995V12.383L3.96578 2.75195L3.90478 2.80345Z"
        fill="url(#paint0_linear_5879_16573)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8116 15.8229L13.5986 12.6089V12.3839L16.8126 9.16992L16.8821 9.21292L20.6836 11.3734C21.7721 11.9869 21.7721 12.9979 20.6836 13.6199L16.8821 15.7804C16.8811 15.7799 16.8116 15.8229 16.8116 15.8229Z"
        fill="url(#paint1_linear_5879_16573)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8808 15.7796L13.5983 12.4961L3.9043 22.1901C4.2583 22.5706 4.8543 22.6136 5.5203 22.2416L16.8808 15.7796Z"
        fill="url(#paint2_linear_5879_16573)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8808 9.21443L5.5203 2.76093C4.8553 2.38043 4.2583 2.43193 3.9043 2.81243L13.5973 12.4969L16.8808 9.21443Z"
        fill="url(#paint3_linear_5879_16573)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5879_16573"
          x1="19.3463"
          y1="-2.89737"
          x2="-1.44208"
          y2="2.64562"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#047ED6" />
          <stop offset="1" stopColor="#50E6FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5879_16573"
          x1="22.1014"
          y1="12.4957"
          x2="11.324"
          y2="12.4957"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDA1C" />
          <stop offset="1" stopColor="#FEB705" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5879_16573"
          x1="15.0931"
          y1="14.2804"
          x2="2.70744"
          y2="35.1298"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9414F" />
          <stop offset="1" stopColor="#8C193F" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5879_16573"
          x1="1.46243"
          y1="-2.91676"
          x2="6.98772"
          y2="6.39583"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#33C481" />
          <stop offset="1" stopColor="#61E3A7" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default GooglePlayIcon
