import React, { useEffect, useMemo } from 'react'

import useInfiniteFetchLikedPosts from 'hooks/query/useInfiniteFetchLikedPosts'
import PostsContent from 'components/PostsContent'
import { useInView } from 'react-intersection-observer'
import Loader from 'components/common/Loader'
import c from './ProfileLikedPage.module.scss'

function ProfileLikedPage() {
  const { ref, inView } = useInView()
  const {
    data: infiniteLikedPostsData,
    isLoading: isLoadingLikedPosts,
    isFetchingNextPage: isFetchingNextPageLikedPosts,
    fetchNextPage: fetchNextPageLikedPosts,
    hasNextPage: hasNextPageLikedPosts,
  } = useInfiniteFetchLikedPosts()

  const likedPostsData = useMemo(() => {
    if (!infiniteLikedPostsData) return { items: [], meta: {} }

    const combinedItems = infiniteLikedPostsData.pages.flatMap((p) => p.items)
    const lastMeta =
      infiniteLikedPostsData.pages[infiniteLikedPostsData.pages.length - 1].meta
    return {
      items: combinedItems,
      meta: lastMeta,
    }
  }, [infiniteLikedPostsData])

  useEffect(() => {
    if (isLoadingLikedPosts) return
    fetchNextPageLikedPosts()
  }, [inView])

  return (
    <div>
      <div className={c.columnsContainer}>
        {likedPostsData?.items.map((postData) => {
          return <PostsContent key={postData.id} post={postData} />
        })}
        {!isLoadingLikedPosts &&
          !isFetchingNextPageLikedPosts &&
          hasNextPageLikedPosts && <div ref={ref} />}
      </div>
      {isFetchingNextPageLikedPosts && (
        <div className={c.loaderWrapper}>
          <div className={c.loader}>
            <Loader />
          </div>
        </div>
      )}
    </div>
  )
}

export default ProfileLikedPage
