import React from 'react'
import { useTranslation } from 'react-i18next'
import InputRange from 'react-input-range'

import c from './PowerSelection.module.scss'

interface PowerSelectionProps {
  strength: number
  setStrength: (value: any) => void
}

function PowerSelection({ strength, setStrength }: PowerSelectionProps) {
  const { t } = useTranslation()
  return (
    <div className={c.powerSelection}>
      <p className={c.heading}>{t('stages.variations.idle.chosePower')}</p>
      <div className={c.rangeWrapper}>
        <div className={c.line} style={{ left: '0%' }} />
        <div className={c.line} style={{ left: '25%' }} />
        <div className={c.line} style={{ left: '50%' }} />
        <div className={c.line} style={{ left: '75%' }} />
        <div className={c.line} style={{ right: '0%' }} />
        <InputRange
          maxValue={100}
          minValue={10}
          value={strength || 10}
          onChange={(value) => setStrength(value)}
        />
      </div>
    </div>
  )
}

export default PowerSelection
