import { useQuery } from '@tanstack/react-query'
import { getPost } from 'api'
import { useAppSelector } from 'hooks/store'
import { PostFeed } from 'types/Post'

interface Params {
  id: string | undefined
}

const useFetchRequestPost = ({ id }: Params) => {
  const deviceId = useAppSelector((state) => state.settings.deviceId)
  const userId = useAppSelector((state) => state.auth.user?.uid)

  const deps = [deviceId, userId, id]

  return useQuery<PostFeed | null, Error>(['post', deps], () => {
    if (id) {
      return getPost(id)
    }
    return Promise.resolve(null)
  })
}

export default useFetchRequestPost
