import React from 'react'

// eslint-disable-next-line react/require-default-props
function AboutIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_6942_24460)">
        <path
          d="M9 13.3125C9.31065 13.3125 9.5625 13.0607 9.5625 12.75V8.25C9.5625 7.93935 9.31065 7.6875 9 7.6875C8.68935 7.6875 8.4375 7.93935 8.4375 8.25V12.75C8.4375 13.0607 8.68935 13.3125 9 13.3125Z"
          fill="white"
        />
        <path
          d="M9 5.25C9.41422 5.25 9.75 5.58579 9.75 6C9.75 6.41421 9.41422 6.75 9 6.75C8.58578 6.75 8.25 6.41421 8.25 6C8.25 5.58579 8.58578 5.25 9 5.25Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.9375 9C0.9375 4.5472 4.5472 0.9375 9 0.9375C13.4528 0.9375 17.0625 4.5472 17.0625 9C17.0625 13.4528 13.4528 17.0625 9 17.0625C4.5472 17.0625 0.9375 13.4528 0.9375 9ZM9 2.0625C5.16853 2.0625 2.0625 5.16853 2.0625 9C2.0625 12.8314 5.16853 15.9375 9 15.9375C12.8314 15.9375 15.9375 12.8314 15.9375 9C15.9375 5.16853 12.8314 2.0625 9 2.0625Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6942_24460">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AboutIcon
