import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ThumbUpIcon } from '@material-design-icons/svg/outlined/thumb_up.svg'
import { ReactComponent as ThumbDownIcon } from '@material-design-icons/svg/outlined/thumb_down.svg'
import { ReactComponent as SyncIcon } from '@material-design-icons/svg/round/sync.svg'
import { ReactComponent as CloseIcon } from '@material-design-icons/svg/round/close.svg'
import { ReactComponent as RestartIcon } from '@material-design-icons/svg/round/replay.svg'
import IconButton from 'components/common/IconButton'
import ImageComparison from 'components/ImageComparison'
import BottomControls from 'components/BottomControls'
import SliderPhotos from 'components/SliderPhotos'
import ShareButton from 'components/ShareButton'
import SaveButton from 'components/SaveButton'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
import { ReactComponent as DeleteIcon } from '@material-design-icons/svg/outlined/close.svg'
import ImageInput from 'components/ImageInput'
import { Rating } from 'types/Rating'
import { toast } from 'react-toastify'
import { deleteRequest } from 'api'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import c from './FinishStage.module.scss'
import EnhancementCard from '../../../EnhancementCard'
import Loader from '../../../common/Loader'

interface FinishStageProps {
  imageUrls: string[]
  processedImageUrls: (string | undefined)[]
  rating: Rating | null
  onRatingChange: (newRating: Rating | null) => void
  onEnhanceMore?: (newFile: File) => void
  onGoBack?: () => void
  assetId: string | undefined
  currentIndex?: number
  setCurrentIndex?: (newIndex: number) => void
}

function FinishStage({
  imageUrls,
  processedImageUrls,
  rating,
  onRatingChange,
  onEnhanceMore = undefined,
  onGoBack = () => {},
  currentIndex = 0,
  setCurrentIndex = undefined,
  assetId,
}: FinishStageProps) {
  const { t } = useTranslation()

  const { data: profile } = useFetchRequestMyProfile()

  const navigate = useNavigate()

  const { id } = useParams()

  const queryClient = useQueryClient()

  const mutationDelete = useMutation<void, Error>(() => {
    return deleteRequest(Number(assetId))
  })

  const deleteYourPost = () => {
    const result = window.confirm('Are you sure want to delete this image?')
    if (result) {
      mutationDelete.mutateAsync().then(() => {
        queryClient.invalidateQueries([`request`, id])
        queryClient.refetchQueries([`infinite-requests`]).then(() => {
          toast.success('Everything went well')
        })
      })
    }
  }

  const ratingText = useMemo<string>(() => {
    return rating !== null
      ? t('stages.enhancement.finish.ratingTextSuccess')
      : t('stages.enhancement.finish.ratingText')
  }, [t, rating])

  const handleLike = useCallback(() => {
    onRatingChange('like')
  }, [onRatingChange])

  const handleDislike = useCallback(() => {
    onRatingChange('dislike')
  }, [onRatingChange])

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onEnhanceMore == null) return

      const file = e.target.files?.[0]
      if (file) onEnhanceMore(file)
    },
    [onEnhanceMore],
  )

  return (
    <div className={c.finishStage}>
      <div className={c.content}>
        {/* <ImageComparison
          imageUrl={imageUrls[currentIndex]}
          processedImageUrl={processedImageUrls[currentIndex]!}
        /> */}
        <div className={c.settingsPart}>
          {imageUrls.length > 1 && (
            <SliderPhotos
              photos={imageUrls!}
              currentSlide={currentIndex}
              setCurrentSlide={setCurrentIndex!}
              isPaidSubscription={profile?.is_paid_subscription}
              finish
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default FinishStage
