import { getChatThemes } from 'api/chats'
import { auth } from 'utils/firebase'
import { useQuery } from '@tanstack/react-query'
import { ThemesResponse } from '../../types/Chats'

interface Props {
  recipientId: number | undefined
}

const useFetchChatThemes = ({ recipientId }: Props) => {
  return useQuery<ThemesResponse[]>(
    ['themes'],
    async () => {
      const token = await auth.currentUser?.getIdToken()
      if (token && recipientId) {
        return getChatThemes(recipientId)
      }
      throw new Error('Invalid token')
    },
    { initialData: [] },
  )
}

export default useFetchChatThemes