import React, { useState } from 'react'
import { Switch } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import c from './SwitchToPrivate.module.scss'

interface SwitchToPrivateProps {
  forSubs: boolean
  handleForSubs: () => void
}

function SwitchToPrivate({ forSubs, handleForSubs }: SwitchToPrivateProps) {
  const { t } = useTranslation()
  const [showMore, setShowMore] = useState<boolean>(false)
  return (
    <div className={c.switch}>
      <div className={c.privateSubsContent}>
        <Switch
          checked={forSubs}
          onChange={handleForSubs}
          name="checkedA"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
        <div>{t('stages.generation.finish.onlyFriends')}</div>
      </div>
      {!showMore && (
        <button
          type="button"
          onClick={() => setShowMore(!showMore)}
          className={c.privatePostsInfo}
        >
          {t('stages.generation.finish.privatePostsInfoWhatIsIt')}
        </button>
      )}
      {showMore && (
        <button
          type="button"
          onClick={() => setShowMore(!showMore)}
          className={c.privatePostsInfo}
        >
          {t('stages.generation.finish.privatePostsInfo')}
        </button>
      )}
    </div>
  )
}

export default SwitchToPrivate
