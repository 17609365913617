import React from 'react'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import ReadedMessageIcon from '../icons/ReadedMessageIcon'
import { MessageItem } from '../../types/Chats'
import c from './MessageUser.module.scss'
import Loader from '../common/Loader'
import { UserResponse } from '../../types/User'
import UnknownUserAvatar from '../icons/UnknownUserAvatar.svg'
import CodeBlock from '../CodeBlock'

interface MessageUserProps {
  data: MessageItem
  isAnother: boolean
  selectedMessageId: number | null
  user: UserResponse | null | undefined
  selectMessage: (messageId: number) => void
  handleRightClick: (
    event: React.MouseEvent<HTMLDivElement>,
    data: MessageItem,
  ) => void
  isLoading: boolean
  settingsMessageVisible: boolean
  selectedMessageIds?: number[]
}

function MessageUser({
  data,
  isAnother,
  selectMessage,
  selectedMessageId,
  user,
  handleRightClick,
  isLoading,
  settingsMessageVisible,
  selectedMessageIds = [],
}: MessageUserProps) {
  const { t } = useTranslation()

  const onRightClick = (event: React.MouseEvent<HTMLDivElement>) => {
    handleRightClick(event, data)
  }

  const getDateTimeCreateAt = () => {
    const nowDate = DateTime.now()
    const dateTimeCreateAt = DateTime.fromISO(data.created_at)
    const dateTimeFormatted = nowDate.diff(dateTimeCreateAt, [
      'months',
      'days',
      'hours',
      'minutes',
      'seconds',
    ])

    const date = dateTimeFormatted.toObject()

    if (date.months! > 0) {
      return dateTimeCreateAt.toFormat('dd.LL.yyyy HH:mm')
    }

    if (date.hours! <= 0) {
      if (date.minutes! < 1) {
        return t('aiChat.recentlyMessage')
      }
      return `${dateTimeFormatted.minutes} minutes ago`
    }

    return dateTimeCreateAt.toFormat('HH:mm')
  }

  const onContainerClick = () => {
    if (selectedMessageIds.length > 0) {
      selectMessage(data?.id || 0)
    }
  }

  return (
    <div
      role="button"
      tabIndex={0}
      className={classNames({
        [c.container]: true,
        [c.container__Another]: isAnother,
        [c.selectedMessage]: selectedMessageIds?.includes(data.id),
      })}
      onContextMenu={onRightClick}
      onClick={onContainerClick}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onContainerClick()
        }
      }}
    >
      <div
        className={classNames({
          [c.content]: true,
        })}
      >
        <div
          className={classNames({
            [c.content__MessageContainer]: true,
            [c.selectedMessageAlone]:
              selectedMessageId === data.id && settingsMessageVisible,
          })}
        >
          <div className={c.content__MessageContainer__UserMessage}>
            {isLoading ? (
              <div className={c.loaderContainer}>
                <Loader /> {t('stages.generation.idle.loading')}
              </div>
            ) : (
              <CodeBlock value={data.text} />
            )}
          </div>
          <div className={c.content__MessageContainer__InfoMessage}>
            <ReadedMessageIcon />
            {getDateTimeCreateAt()}
          </div>
        </div>
        <div className={c.content__User}>
          <img
            src={data.sender.avatar || UnknownUserAvatar}
            alt="userAvatar"
            className={c.content__User__Avatar}
          />
        </div>
      </div>
    </div>
  )
}

export default MessageUser;
