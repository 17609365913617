const getLanguage = (): string => {
  const storedLanguage = window.localStorage.getItem('lng')
  if (storedLanguage) return storedLanguage

  window.localStorage.setItem('languageModal', 'true')
  if (navigator.language === 'ru') return 'ru'
  if (navigator.language === 'de') return 'de'
  if (navigator.language === 'fr') return 'fr'
  if (navigator.language === 'es') return 'es'
  if (navigator.language === 'it') return 'it'
  if (navigator.language === 'hi') return 'hi'
  if (navigator.language === 'pt') return 'pt'
  if (navigator.language === 'id') return 'id'

  return 'en'
}

export default getLanguage
