import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as SettingsIcon } from '@material-design-icons/svg/round/more_horiz.svg'
import classNames from 'classnames'

import { PostFeed } from 'types/Post'
import { UserResponse } from 'types/User'

import c from './SettingsPost.module.scss'

export interface SettingConfirmModalProps {
  isOpen: boolean
  isDelete: boolean
  isReport: boolean
}

interface SettingsPostProps {
  postData: PostFeed
  userData: UserResponse | null | undefined
  setIsConfirmModal: Dispatch<SetStateAction<SettingConfirmModalProps>>
}

function SettingsPost({
  userData,
  postData,
  setIsConfirmModal,
}: SettingsPostProps) {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)

  const settingsRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()

  const toggleSettings = () => {
    setIsSettingsOpen(!isSettingsOpen)
  }

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      settingsRef.current &&
      !settingsRef.current.contains(event.target as Node)
    ) {
      setIsSettingsOpen(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  const downloadImage = () => {
    const link = document.createElement('a')
    link.href = postData.assets?.[0].url
    link.target = '_blank'
    link.download = 'image'
    document.body.appendChild(link) //
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div
      className={c.container}
      role="button"
      onClick={toggleSettings}
      onKeyPress={(event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          toggleSettings()
        }
      }}
      style={
        isSettingsOpen
          ? { backgroundColor: '#282631', borderRadius: '200px' }
          : {}
      }
      ref={settingsRef}
      tabIndex={0}
    >
      <div className={c.content}>
        <div className={c.iconWrapper}>
          <SettingsIcon fill="#D1D1D6" className={c.settingsIcon} />
        </div>
        {isSettingsOpen && (
          <div className={c.settingsWrapper}>
            <div className={c.settingsWindow}>
              <div className={c.actions}>
                <div>
                  <button
                    type="button"
                    className={c.action}
                    onClick={downloadImage}
                  >
                    {t('general.post.download')}
                  </button>
                </div>
                {postData.user.id === userData?.id && (
                  <div>
                    <button
                      type="button"
                      className={classNames({
                        [c.action]: true,
                        [c.deleteAction]: true,
                      })}
                      onClick={() =>
                        setIsConfirmModal({
                          isDelete: true,
                          isOpen: true,
                          isReport: false,
                        })
                      }
                    >
                      {t('profile.delete')}
                    </button>
                  </div>
                )}
                {postData.user.id !== userData?.id && (
                  <div>
                    {postData.is_strike ? (
                      <button
                        type="button"
                        className={classNames({
                          [c.action]: true,
                          [c.strikeAction]: true,
                        })}
                        onClick={() =>
                          setIsConfirmModal({
                            isDelete: false,
                            isOpen: true,
                            isReport: true,
                          })
                        }
                      >
                        {t('general.buttons.unReport')}
                      </button>
                    ) : (
                      <button
                        type="button"
                        className={classNames({
                          [c.action]: true,
                          [c.strikeAction]: true,
                        })}
                        onClick={() =>
                          setIsConfirmModal({
                            isDelete: false,
                            isOpen: true,
                            isReport: true,
                          })
                        }
                      >
                        {t('general.buttons.report')}
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SettingsPost
