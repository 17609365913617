import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useFetchRequestPost from 'hooks/query/useFetchRequestPost'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import Container from 'components/common/Container'
import { EffectCoverflow, Mousewheel, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useMutation } from '@tanstack/react-query'
import { viewedRecommendations } from 'api'
import useFetchRecommendations from 'hooks/query/useFetchRecommendations'
import useInfiniteFetchComments from 'hooks/query/useInfiniteFetchComments'
import { useTranslation } from 'react-i18next'
import Loader from 'components/common/Loader'
import { useInView } from 'react-intersection-observer'
import Comment from 'components/Comment'
import { PostComment } from 'types/Comments'
import TrendingPostItem from 'components/TrendingPostItem'
import { Img } from 'react-image'

import Lightbox from 'react-image-lightbox'
import FullScreenIcon from '../../components/icons/FullScreenIcon'
import CommentIcon from '../../components/icons/CommentIcon'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'react-image-lightbox/style.css'
import 'swiper/css/pagination'
import c from './TrendingPostPage.module.scss'
import MobileHeader from '../../components/MobileHeader'

function TrendingPostPage() {
  const { id } = useParams()
  const { t } = useTranslation()
  const {
    data: commentsData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteFetchComments({ postId: Number(id!) })

  const { data: recommendations } = useFetchRecommendations({
    postId: Number(id)!,
  })

  const [parentComment, setParentComment] = useState<PostComment | undefined>(
    undefined,
  )
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false)
  const [isLightboxOpen, setIsLightboxOpen] = useState<boolean>(false)
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0)
  const [activeImageUrl, setActiveImageUrl] = useState<string>('')

  const { ref, inView } = useInView()

  const comments = useMemo(() => {
    if (commentsData === undefined) return { items: [], meta: {} }

    const combinedItems = commentsData.pages.flatMap((p) => p.items)
    const lastMeta = commentsData.pages[commentsData.pages.length - 1].meta

    return {
      items: combinedItems,
      meta: lastMeta,
    }
  }, [commentsData])

  useEffect(() => {
    if (isLoading) return
    fetchNextPage()
  }, [inView])

  const navigate = useNavigate()

  const { data: postData } = useFetchRequestPost({ id })

  const mutationView = useMutation<void, Error, number>(
    (selected_post: number) => {
      const recommendationIds = recommendations?.map((r) => r.id)
      return viewedRecommendations(
        Number(id),
        recommendationIds!,
        selected_post,
      )
    },
  )
  const handleViewRecommendation = useCallback((selected_post: number) => {
    mutationView.mutate(selected_post)
  }, [])

  const handleImageLoaded = useCallback(() => {
    setIsImageLoaded(true)
  }, [])

  const handleFullScreenOpen = useCallback((imageUrl: string) => {
    setActiveImageUrl(imageUrl)
    setIsLightboxOpen(true)
  }, [])

  const handleFullScreenClose = useCallback(() => {
    setIsLightboxOpen(false)
  }, [])

  return (
    <div className={c.postPage}>
      <MobileHeader
        heading={postData?.user.nickname}
        isUserPremium={postData?.user.is_paid_subscription}
        isOnGoBack
      />
      <Container flex>
        {postData && (
          <div className={c.content}>
            <div className={c.postWrapper}>
              <div className={c.postItem}>
                <TrendingPostItem post={postData} imgClassName={c.postImage} />
              </div>
              <Swiper
                effect="coverflow"
                grabCursor
                onSlideChange={(swiper) =>
                  setActiveSlideIndex(swiper.activeIndex)
                }
                centeredSlides
                slidesPerView="auto"
                initialSlide={1}
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true,
                }}
                speed={200}
                modules={[EffectCoverflow, Pagination, Mousewheel]}
                mousewheel
                className={`mySwiper ${c.recommendations__swiper}`}
                style={{ borderRadius: '36px' }}
              >
                {recommendations &&
                  recommendations?.length > 0 &&
                  recommendations.map((r, index: number) => (
                    <SwiperSlide
                      className={c.recommendations__swiperItem}
                      onClick={() => handleViewRecommendation(r.id)}
                      key={r.id}
                    >
                      <div className={c.postImgContainer}>
                        <Img
                          src={r.assets[0].url}
                          className={c.postImg}
                          loader={<Loader />}
                          onLoad={handleImageLoaded}
                        />

                        {index === activeSlideIndex && (
                          <button
                            type="button"
                            onClick={() =>
                              handleFullScreenOpen(r.assets[0].url)
                            }
                            className={c.wrapper}
                          >
                            {isImageLoaded && (
                              <div className={c.fullScreenContainer}>
                                <div className={c.iconSetting}>
                                  <FullScreenIcon />
                                </div>
                              </div>
                            )}
                          </button>
                        )}
                      </div>
                      {isLightboxOpen && activeImageUrl === r.assets[0].url && (
                        <Lightbox
                          mainSrc={activeImageUrl}
                          onCloseRequest={handleFullScreenClose}
                          closeLabel=""
                        />
                      )}
                      <NavLink
                        className={c.link}
                        to={`/trending-post/${r.id}`}
                        end
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
              <div className={c.comment}>
                <div className={c.comment__Heading}>
                  {t('modals.comment.comments')}
                </div>
                <div className={c.comment__ButtonWrapper}>
                  <NavLink
                    to={`/trending-post/${id}/comments`}
                    className={c.comment__Button}
                    end
                  >
                    <CommentIcon className={c.iconSetting} />
                    <span className={c.comment__Text}>
                      {t('modals.comment.create')}
                    </span>
                  </NavLink>
                </div>
              </div>
              <div className={c.comments}>
                {comments.items.map(
                  (com) =>
                    com.is_deleted || (
                      <Comment
                        postData={postData}
                        com={com}
                        key={com.id}
                        setParentComment={setParentComment}
                        parentComment={parentComment}
                        isFirst
                      />
                    ),
                )}
                {!isLoading && !isFetchingNextPage && hasNextPage && (
                  <div ref={ref} />
                )}
                {isFetchingNextPage && (
                  <div className={c.loaderWrapper}>
                    <div className={c.loader}>
                      <Loader />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Container>
    </div>
  )
}

export default TrendingPostPage
