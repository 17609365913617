import React, { useMemo, useEffect, useState, useRef } from 'react'
import { InView } from 'react-intersection-observer'
import { useMutation } from '@tanstack/react-query'
import { sendViewedArray } from 'api'
import TrendingPost from 'components/TrendingPost'
import Loader from 'components/common/Loader'
import useInfiniteFetchPosts from 'hooks/query/useInfiniteFetchPosts'
import SwitcherPages from 'components/SwitcherPages'
import { ILightbox } from 'pages/TrendingPage/TrendingPage'

import { useTranslation } from 'react-i18next'
import c from './FeedPage.module.scss'
import MobileHeader from '../../components/MobileHeader'

function FeedPage() {
  const [viewedPosts, setViewedPosts] = useState<number[]>([])

  const { t } = useTranslation()

  const [localSViewedPosts, setLocalSViewedPosts] = useState<number[]>(
    JSON.parse(localStorage.getItem('viewedPosts') || '[]') || [],
  )
  const [isLightboxOpen, setIsLightboxOpen] = useState<ILightbox>({
    id: null,
    state: false,
  })

  const mutationView = useMutation<void, Error>(() => {
    return sendViewedArray(viewedPosts)
  })

  const {
    data: infinitePostsData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteFetchPosts({
    take: 4,
    type: 'subscriptions',
    conclusion: 'all',
  })

  const postsData = useMemo(() => {
    if (infinitePostsData === undefined) return []
    return infinitePostsData.pages.flatMap((p) => p)
  }, [infinitePostsData])

  const onScrollToEnd = () => {
    if (isLoading) return
    fetchNextPage()
  }

  const viewHandle = (viewed: boolean, postId: number) => {
    if (
      viewedPosts.includes(postId) ||
      !viewed ||
      localSViewedPosts.includes(postId)
    )
      return

    setViewedPosts((prev) => [...prev, postId])
  }

  useEffect(() => {
    if (viewedPosts.length === 3) {
      mutationView.mutate()
      const newViewedArray = [...localSViewedPosts, ...viewedPosts]
      localStorage.setItem('viewedPosts', JSON.stringify(newViewedArray))
      setViewedPosts([])
      setLocalSViewedPosts(newViewedArray)
    }
  }, [viewedPosts])

  return (
    <div className={c.feedPage}>
      <MobileHeader heading={t('navigation.feed')} isOnGoBack />
      <div className={c.content}>
        <div className={c.switch}>
          <SwitcherPages />
        </div>
        <div className={c.feed}>
          <div className={c.columnsContainer}>
            {postsData.map((post) => (
              <div
                className={c.wrapper}
                key={post.id}
                style={{
                  zIndex:
                    post.id === isLightboxOpen.id && isLightboxOpen.state
                      ? 1000
                      : 'auto',
                }}
              >
                <TrendingPost
                  post={post}
                  isLightboxOpen={isLightboxOpen}
                  setIsLightboxOpen={setIsLightboxOpen}
                  trending
                />
                <InView
                  as="div"
                  onChange={(inView) => viewHandle(inView, post.id)}
                  triggerOnce
                />
              </div>
            ))}
          </div>
          {!isLoading && !isFetchingNextPage && hasNextPage && (
            <InView as="div" onChange={(inView) => inView && onScrollToEnd()} />
          )}
          {isFetchingNextPage && (
            <div className={c.loaderWrapper}>
              <div className={c.loader}>
                <Loader />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default FeedPage
