import React from 'react'

// eslint-disable-next-line react/require-default-props
function LockIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M22.632 12.6089C22.494 11.5889 22.191 10.8899 21.651 10.3499C21.108 9.80691 20.412 9.50391 19.392 9.36891C19.191 9.34191 18.975 9.32091 18.75 9.30591V8.00391C18.75 6.20091 18.048 4.50591 16.773 3.23091C15.498 1.95591 13.803 1.25391 12 1.25391C10.197 1.25391 8.50198 1.95591 7.22698 3.23091C5.95198 4.50591 5.24998 6.20091 5.24998 8.00391V9.30591C5.02498 9.32091 4.81198 9.34191 4.60798 9.36891C3.58798 9.50691 2.88898 9.80991 2.34898 10.3499C1.80598 10.8929 1.50298 11.5889 1.36798 12.6089C1.25098 13.4759 1.25098 14.5349 1.25098 16.0019C1.25098 17.4689 1.25098 18.5279 1.36798 19.3949C1.50598 20.4149 1.80898 21.1139 2.34898 21.6539C2.89198 22.1969 3.58798 22.4999 4.60798 22.6349C5.47498 22.7519 6.53398 22.7519 8.00098 22.7519H16.002C17.469 22.7519 18.528 22.7519 19.395 22.6349C20.415 22.4969 21.114 22.1939 21.654 21.6539C22.194 21.1139 22.5 20.4149 22.635 19.3949C22.752 18.5279 22.752 17.4689 22.752 16.0019C22.752 14.5349 22.749 13.4759 22.632 12.6089ZM6.74998 8.00091C6.74998 5.10591 9.10498 2.75091 12 2.75091C14.895 2.75091 17.25 5.10591 17.25 8.00091V9.25491C16.869 9.25191 16.455 9.25191 15.999 9.25191H8.00098C7.54798 9.25191 7.13398 9.25191 6.74998 9.25491V8.00091ZM20.592 20.5919C19.932 21.2519 18.618 21.2519 16.002 21.2519H8.00098C5.38198 21.2519 4.06798 21.2519 3.41098 20.5919C2.75098 19.9319 2.75098 18.6179 2.75098 16.0019C2.75098 13.3859 2.75098 12.0689 3.41098 11.4119C4.07098 10.7519 5.38498 10.7519 8.00098 10.7519H16.002C18.621 10.7519 19.935 10.7519 20.592 11.4119C21.252 12.0719 21.252 13.3859 21.252 16.0019C21.252 18.6179 21.249 19.9319 20.592 20.5919Z"
        fill="white"
      />
      <path
        d="M12 13.25C11.586 13.25 11.25 13.586 11.25 14V17.999C11.25 18.413 11.586 18.749 12 18.749C12.414 18.749 12.75 18.413 12.75 17.999V14C12.75 13.586 12.414 13.25 12 13.25Z"
        fill="white"
      />
    </svg>
  )
}

export default LockIcon
