import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate, useParams } from 'react-router-dom'

import ActivityFeed from 'components/icons/ActivityFeed'
import BackgroundRemover from 'components/icons/BackgroundRemover'
import GalleryIcon from 'components/icons/GalleryFilled'
import User from 'components/icons/User'
import { setLogInModalOpen } from 'store/reducers/modalReducer'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
import { useAppDispatch } from 'hooks/store'
import useLocationInfo from 'hooks/useLocationInfo'
import Chat from '../../../components/icons/Chat'

import c from './LayoutBottom.module.scss'

function LayoutBottom() {
  const { t } = useTranslation()
  const { pathname } = useLocationInfo()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { id } = useParams()

  const { data: user, isLoading } = useFetchRequestMyProfile()

  const isEnhancement = useMemo(
    () =>
      pathname === '/enhancement' ||
      pathname === '/face-restoration' ||
      pathname === '/background-remover',
    [pathname],
  )
  const isGeneration = useMemo(
    () => pathname === '/neuro-art' || pathname === '/magic-variations',
    [pathname],
  )

  const isFeed = useMemo(
    () => pathname === '/trending' || pathname === '/feed',
    [pathname],
  )
  const isChat = useMemo(
    () => pathname === '/chats' || pathname === `/chats/${id}`,
    [pathname],
  )

  return (
    <div className={c.mobileContent} id="mobileContent">
      <nav className={c.nav}>
        <ul className={c.nav__list}>
          <li className={`${c.nav__elem} ${isFeed ? c.active : c.noActive}`}>
            <ActivityFeed isActive={isFeed} />
            {t('navigation.feed')}
            <NavLink to={user ? '/feed' : '/trending'} className={c.link} end>
              {t('navigation.feed')}
            </NavLink>
          </li>
          <li
            className={`${c.nav__elem} ${
              isEnhancement ? c.active : c.noActive
            }`}
          >
            <GalleryIcon isActive={isEnhancement} />
            {t('navigation.AIphoto')}
            <NavLink to="/enhancement" className={c.link} end>
              {t('navigation.AIphoto')}
            </NavLink>
          </li>
          <li
            className={`${c.nav__elem} ${isGeneration ? c.active : c.noActive}`}
          >
            <BackgroundRemover isActive={isGeneration} />
            {t('navigation.AIart')}
            <NavLink to="/neuro-art" className={c.link} end>
              {t('navigation.AIart')}
            </NavLink>
          </li>
          <li className={`${c.nav__elem} ${isChat ? c.active : c.noActive}`}>
            <Chat isActive={isChat} />
            {t('navigation.chats')}
            <NavLink to="/chats" className={c.link} end>
              {t('navigation.chats')}
            </NavLink>
          </li>
          <li
            className={`${c.nav__elem} ${
              pathname === `/profile/${user?.nickname}` ? c.active : c.noActive
            }`}
          >
            <User isActive={pathname === `/profile/${user?.nickname}`} />
            {t('navigation.profile')}
            <button
              type="button"
              className={c.link}
              onClick={() => {
                if (!isLoading && user) {
                  navigate(`/profile/${user.nickname}`)
                  return
                }
                if (!isLoading) {
                  dispatch(setLogInModalOpen(true))
                }
              }}
            >
              {t('navigation.profile')}
            </button>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default LayoutBottom
