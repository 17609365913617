import React, { useMemo } from 'react'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'contained' | 'outlined' | 'text'
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
}

function Button({
  variant = 'contained',
  startIcon = undefined,
  endIcon = undefined,
  children,
  className,
  ...props
}: ButtonProps) {
  const classNames = useMemo(() => {
    let result = 'button'
    if (variant === 'outlined') result += ' buttonOutlined'
    if (variant === 'text') result += ' buttonText'
    if (className) result += ` ${className}`
    return result
  }, [className, variant])

  return (
    <button className={classNames} type="button" {...props}>
      {startIcon}
      <span className="buttonLabel">{children}</span>
      {endIcon}
    </button>
  )
}

export default Button
