import Processor from './Processor/Processor'
import EnhanceStrategy from './Processor/Strategies/EnhanceStrategy'
import GenerateStrategy from './Processor/Strategies/GenerateStrategy'
import MixerStrategy from './Processor/Strategies/MixerStrategy'
import VariationsStrategy from './Processor/Strategies/VariationsStrategy'
import VideoStrategy from './Processor/Strategies/VideoStrategy'
import InpaintStrategy from './Processor/Strategies/InpaintStrategy'

export {
  EnhanceStrategy,
  GenerateStrategy,
  VariationsStrategy,
  VideoStrategy,
  MixerStrategy,
  InpaintStrategy,
}

export default Processor
