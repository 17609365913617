import store from '../store'
import { auth } from '../utils/firebase'
import i18n from '../i18n/config'
import { MessageItem, MessagesResponse, ThemesResponse } from '../types/Chats'

const endpoint = process.env.REACT_APP_API_ENDPOINT || ''
export const getChats = async (
  headers: HeadersInit = {},
): Promise<MessageItem[]> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = `v3/chats`

  const url = `${endpoint}/${domain}`

  const result = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const getChatMessages = async (
  recipientId: string,
  LIMIT: number,
  offset: number,
  theme?: number,
  page: number = 1,
  take: number = 14,
  headers: HeadersInit = {},
): Promise<MessagesResponse> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = `v3/chats`

  const params = new URLSearchParams({
    offset: ((page - 1) * take).toString(),
    limit: LIMIT.toString(),
  }).toString()

  const url = `${endpoint}/${domain}/${recipientId}?${params}${
    theme ? `&theme=${theme.toString()}` : ''
  }`

  const result = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const deleteMessages = async (
  messages: number[],
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v3/chats'

  const result = await fetch(`${endpoint}/${domain}`, {
    method: 'DELETE',
    body: JSON.stringify({ message_ids: messages }),
    headers: {
      'Content-Type': 'application/json',
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }
}

export const clearChat = async (
  recipientId?: string,
  theme?: number,
  headers: HeadersInit = {},
) => {
  if (!recipientId) return
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v3/chats/clear'

  const result = await fetch(`${endpoint}/${domain}/${recipientId}`, {
    method: 'DELETE',
    body: JSON.stringify({ theme }),
    headers: {
      'Content-Type': 'application/json',
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }
}

export const createThemes = async (
  botId: string,
  headers: HeadersInit = {},
): Promise<ThemesResponse> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v3/chats/themes'

  const result = await fetch(`${endpoint}/${domain}`, {
    method: 'POST',
    body: JSON.stringify({ botId }),
    headers: {
      'Content-Type': 'application/json',
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }
  return result.json()
}

export const getChatThemes = async (
  recipientId: number,
  headers: HeadersInit = {},
): Promise<ThemesResponse[]> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = `v3/chats/themes`

  const url = `${endpoint}/${domain}/${recipientId}`

  const result = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}
