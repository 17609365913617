import { EventEmitter } from 'events'
import Strategy from './Strategies/Strategy'

export default class Processor extends EventEmitter {
  private strategy: Strategy

  constructor(strategy: Strategy) {
    super()

    this.strategy = strategy
    this.strategy.emitter.emit = (eventName, data) => this.emit(eventName, data)
  }

  public async start() {
    await this.strategy.start()
  }

  public async repeat() {
    await this.strategy.repeat()
  }

  public stop() {
    this.strategy.stop()
  }
}
