import React from 'react'
import { useAppSelector } from 'hooks/store'
import ScrollContainer from 'react-indiana-drag-scroll'
import { Suggestion } from 'types/Generation'

import c from './Suggestions.module.scss'

interface SuggestionsProps {
  suggestions?: Suggestion[]
  onExampleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

function Suggestions({ suggestions = [], onExampleClick }: SuggestionsProps) {
  const language = useAppSelector((state) => state.settings.language)

  const switchSuggestionLang = (suggestion: Suggestion) => {
    switch (language) {
      case 'en':
        return suggestion.en
      case 'ru':
        return suggestion.ru

      default:
        return suggestion.en
    }
  }

  return (
    <ScrollContainer className={c.suggestionsWrapper}>
      <ul className={c.suggestionsList}>
        {suggestions.length > 0 &&
          suggestions.map((elem) => (
            <button
              type="button"
              className={c.suggestion}
              key={`examples-${elem.id}`}
              data-id={elem.id}
              value={switchSuggestionLang(elem)}
              onClick={onExampleClick}
            >
              <span className={c.text}>{switchSuggestionLang(elem)}</span>
            </button>
          ))}
      </ul>
    </ScrollContainer>
  )
}

export default Suggestions
