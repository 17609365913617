import React from 'react'

// eslint-disable-next-line react/require-default-props
function CloseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        d="M0.527737 17.473C0.879083 17.8243 1.33987 18 1.80065 18C2.26143 18 2.72221 17.8243 3.07356 17.473L9.00036 11.5459L14.9272 17.473C15.6299 18.1757 16.7696 18.1757 17.473 17.473C18.1757 16.7702 18.1757 15.6305 17.473 14.927L11.5462 9L17.473 3.07296C18.1757 2.37024 18.1757 1.23048 17.473 0.52704C16.7703 -0.17568 15.6306 -0.17568 14.9272 0.52704L9.00036 6.45408L3.07284 0.52704C2.37014 -0.17568 1.23043 -0.17568 0.527019 0.52704C-0.175673 1.22976 -0.175673 2.36952 0.527019 3.07296L6.45382 9L0.527019 14.927C-0.175673 15.6298 -0.175673 16.7695 0.527019 17.473H0.527737Z"
        fill="white"
      />
    </svg>
  )
}

export default CloseIcon
