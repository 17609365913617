import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import classNames from 'classnames'

import InstagramIcon from 'components/icons/InstagramIcon'
import SettingSubscribe from 'components/SettingSubscribe'
import { setUserInfo } from 'store/reducers/authReducer'
import {
  setLogInModalOpen,
  setSignUpModalOpen,
  setUpdateUserModalOpen,
} from 'store/reducers/modalReducer'
import useLogOut from 'hooks/query/auth/useLogOut'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import useModal from 'hooks/useModal'
import AboutIcon from '../icons/AboutIcon'
import ArrowLeft from '../icons/ArrowLeft'
import LanguageIcon from '../icons/LanguageIcon'
import LogoutIcon from '../icons/LogoutIcon'
import ShareIcon from '../icons/ShareIcon'
import UserIcon from '../icons/UserIcon'
import ConfirmModal from '../modals/ConfirmModal'

import c from './SettingsList.module.scss'

function SettingsList() {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false)

  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const { logInModalOpen, signUpModalOpen, updateUserModalOpen } =
    useAppSelector((state) => state.modal)

  const { open: openUpdateUserModal } = useModal(
    updateUserModalOpen,
    setUpdateUserModalOpen,
  )
  const { open: openLogInModal } = useModal(logInModalOpen, setLogInModalOpen)
  const { open: openSignUpModal } = useModal(
    signUpModalOpen,
    setSignUpModalOpen,
  )

  const user = useAppSelector((state) => state.auth.user)
  const language = useAppSelector((state) => state.settings.language)

  const { mutate: logOut, error } = useLogOut()

  const handleLogOut = useCallback(() => {
    setIsConfirmModalOpen(true)
  }, [])

  const handleConfirmLogOut = useCallback(() => {
    logOut()
    dispatch(setUserInfo(null))
    setIsConfirmModalOpen(false)
  }, [])

  const closeModal = useCallback(() => {
    setIsConfirmModalOpen(false)
  }, [])

  const handleShare = useCallback(() => {
    const url = window.location.origin
    const data = { url }
    const canShare = navigator.canShare(data)
    if (canShare) {
      navigator.share(data)
      return
    }
    toast.error(t('error.browser'))
  }, [t])

  useEffect(() => {
    if (error?.message) toast.error(error.message)
  }, [error?.message])

  return (
    <div className={c.settings}>
      <ConfirmModal
        heading={t('modals.general.logout')}
        confirmFunc={handleConfirmLogOut}
        cancelFunc={closeModal}
        isModalOpen={isConfirmModalOpen}
      />
      <SettingSubscribe />
      <div className={c.settingsItem}>
        <p className={c.listTitle}>{t('settings.profile.title')}</p>
        <ul className={c.list}>
          {user ? (
            <>
              <li className={c.listItem}>
                <button
                  className={classNames({
                    [c.button]: true,
                    [c.borderButton]: true,
                  })}
                  onClick={openUpdateUserModal}
                  type="button"
                >
                  <UserIcon className={c.buttonStartIcon} />
                  <p className={c.text}>{user.email}</p>
                  <div className={c.buttonEndIcon}>
                    <ArrowLeft />
                    <span className={c.textButton}>{t('settings.edit')}</span>
                  </div>
                </button>
              </li>
              <li className={c.listItem}>
                <Link
                  to="/settings/delete"
                  className={classNames({
                    [c.button]: true,
                    [c.borderButton]: true,
                  })}
                  type="button"
                >
                  <UserIcon className={c.buttonStartIcon} />
                  <p className={c.buttonText}>{t('profile.deleteAccount')}</p>
                  <ArrowLeft className={c.buttonEndIcon} />
                </Link>
              </li>
              <li className={c.listItem}>
                <button
                  className={classNames({
                    [c.button]: true,
                    [c.borderBottom]: true,
                  })}
                  type="button"
                  onClick={handleLogOut}
                >
                  <LogoutIcon className={c.buttonStartIcon} />
                  <span className={c.buttonText}>
                    {t('settings.profile.items.logOut')}
                  </span>
                  <ArrowLeft className={c.buttonEndIcon} />
                </button>
              </li>
            </>
          ) : (
            <>
              <li className={c.listItem}>
                <button
                  className={classNames({
                    [c.button]: true,
                    [c.borderButton]: true,
                  })}
                  type="button"
                  onClick={openLogInModal}
                >
                  <LogoutIcon className={c.buttonStartIcon} />
                  <span className={c.buttonText}>
                    {t('settings.profile.items.logIn')}
                  </span>
                  <ArrowLeft className={c.buttonEndIcon} />
                </button>
              </li>
              <li className={c.listItem}>
                <button
                  className={classNames({
                    [c.button]: true,
                    [c.borderBottom]: true,
                  })}
                  type="button"
                  onClick={openSignUpModal}
                >
                  <LogoutIcon className={c.buttonStartIcon} />
                  <span className={c.buttonText}>
                    {t('settings.profile.items.signUp')}
                  </span>
                  <ArrowLeft className={c.buttonEndIcon} />
                </button>
              </li>
            </>
          )}
        </ul>
      </div>

      <div className={c.settingsItem}>
        <p className={c.listTitle}>{t('settings.general.title')}</p>
        <ul className={c.list}>
          <li className={c.listItem}>
            <Link
              className={classNames({
                [c.button]: true,
                [c.borderButton]: true,
              })}
              to="/settings/language"
            >
              <LanguageIcon className={c.buttonStartIcon} />
              <span className={c.buttonText}>
                {t('settings.general.items.language')}
              </span>
              <div className={c.buttonEndIcon}>
                <ArrowLeft />
                <span className={c.textButton}>
                  {t('settings.language.title')}
                </span>
              </div>
            </Link>
          </li>
          <li className={c.listItem}>
            <Link
              className={classNames({
                [c.button]: true,
                [c.borderBottom]: true,
              })}
              to="/settings/info"
            >
              <AboutIcon className={c.buttonStartIcon} />
              <span className={c.buttonText}>
                {t('settings.general.items.about')}
              </span>
              <ArrowLeft className={c.buttonEndIcon} />
            </Link>
          </li>
        </ul>
      </div>

      <div className={c.settingsItem}>
        <p className={c.listTitle}>{t('settings.social.title')}</p>
        <ul className={c.list}>
          <li className={c.listItem}>
            <button
              className={classNames({
                [c.button]: true,
                [c.borderButton]: true,
              })}
              type="button"
              onClick={handleShare}
            >
              <ShareIcon className={c.buttonStartIcon} />
              <span className={c.buttonText}>
                {t('settings.social.items.share')}
              </span>
              <ArrowLeft className={c.buttonEndIcon} />
            </button>
          </li>
          <li className={c.listItem}>
            <a
              className={classNames({
                [c.button]: true,
                [c.borderBottom]: true,
              })}
              href="https://www.instagram.com/ai_magicly?igsh=azBjNjdxcGN3NnFv&utm_source=qr"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon className={c.buttonStartIcon} />
              <span className={c.buttonText}>
                {t('settings.social.items.instagram')}
              </span>
              <ArrowLeft className={c.buttonEndIcon} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default SettingsList
