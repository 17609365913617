import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SearchIcon from '../icons/SearchIcon'
import Loader from '../common/Loader'
import c from './ChatPanelSearch.module.scss'

interface ChatPanelSearchProps {
  setFormData: (text: string) => void
  formData: string | undefined
  isLoading: boolean
}

function ChatPanelSearch({
  setFormData,
  formData,
  isLoading,
}: ChatPanelSearchProps) {
  const { t } = useTranslation()
  const [userInput, setUserInput] = useState('')

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setUserInput(e.target.value)
    },
    [],
  )

  const handleFormSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      setFormData(userInput)
    },
    [userInput],
  )

  return (
    <div className={c.container}>
      <div className={c.content}>
        <form className={c.content__Form} onSubmit={handleFormSubmit}>
          <input
            id="searchInput"
            type="text"
            className={c.content__Input}
            placeholder={t('aiChat.chatSearch')}
            onChange={handleInputChange}
            value={userInput}
          />
          {isLoading && formData !== undefined ? (
            <Loader />
          ) : (
            <button type="submit" className={c.content__SubmitForm}>
              <SearchIcon style={{ width: '16px', height: '16px' }} />
            </button>
          )}
        </form>
      </div>
    </div>
  )
}

export default ChatPanelSearch
