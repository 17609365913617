import React from 'react'

// eslint-disable-next-line react/require-default-props
function ArrowUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      {...props}
    >
      <path
        d="M0.249477 11.002C0.249477 10.81 0.321476 10.618 0.468477 10.471C0.762476 10.177 1.23648 10.177 1.53048 10.471L6.24948 15.19L6.24948 1C6.24948 0.586 6.58548 0.25 6.99948 0.25C7.41348 0.25 7.74948 0.586 7.74948 1L7.74948 15.19L12.4685 10.471C12.7625 10.177 13.2365 10.177 13.5305 10.471C13.8245 10.765 13.8245 11.239 13.5305 11.533L7.53348 17.53C7.52448 17.539 7.51248 17.551 7.50348 17.56C7.44048 17.617 7.37148 17.662 7.29648 17.692C7.20948 17.728 7.11348 17.749 7.01448 17.749L7.01148 17.749L7.00848 17.749L7.00548 17.749L7.00248 17.749C6.90348 17.749 6.80748 17.728 6.72048 17.692C6.72048 17.692 6.72048 17.692 6.71748 17.692C6.63648 17.659 6.55848 17.608 6.49248 17.545C6.49248 17.545 6.49248 17.545 6.48948 17.542L6.48648 17.539L6.48348 17.536L6.48048 17.533L0.480477 11.533C0.321477 11.386 0.249477 11.194 0.249477 11.002Z"
        fill="white"
      />
    </svg>
  )
}

export default ArrowUp
