import React from 'react'

// eslint-disable-next-line react/require-default-props
function ReplyIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="reply">
        <path
          id="Vector"
          d="M14.499 6.2495C14.691 6.2495 14.883 6.3215 15.03 6.4685L20.028 11.4665C20.04 11.4785 20.052 11.4905 20.064 11.5055C20.064 11.5055 20.064 11.5085 20.067 11.5085C20.118 11.5685 20.16 11.6345 20.19 11.7035C20.19 11.7035 20.19 11.7035 20.19 11.7065C20.229 11.7935 20.25 11.8895 20.25 11.9915L20.25 11.9945L20.25 11.9975L20.25 12.0005L20.25 12.0035C20.25 12.1055 20.229 12.2015 20.19 12.2885C20.19 12.2885 20.19 12.2885 20.19 12.2915C20.16 12.3605 20.118 12.4265 20.067 12.4865C20.067 12.4865 20.067 12.4895 20.064 12.4895C20.052 12.5015 20.04 12.5165 20.028 12.5285L15.03 17.5295C14.736 17.8235 14.262 17.8235 13.968 17.5295C13.674 17.2355 13.674 16.7615 13.968 16.4675L17.688 12.7475L9.498 12.7475C8.775 12.7475 7.689 12.9725 6.81 13.6055C5.772 14.3525 5.247 15.4925 5.247 16.9955C5.247 17.4095 4.911 17.7455 4.497 17.7455C4.083 17.7455 3.747 17.4095 3.747 16.9955C3.747 14.4875 4.935 13.1045 5.934 12.3875C7.404 11.3285 9.033 11.2475 9.498 11.2475L17.688 11.2475L13.968 7.5275C13.674 7.2335 13.674 6.7595 13.968 6.4655C14.115 6.3245 14.307 6.2495 14.499 6.2495Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export default ReplyIcon
