import { useMutation } from '@tanstack/react-query'
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  UserCredential,
} from 'firebase/auth'
import { auth } from 'utils/firebase'

interface Params {
  onSuccess?: () => void
}

const DEFAULT_PARAMS: Params = {
  onSuccess: undefined,
}

interface EmailAndPasswordPayload {
  provider: 'email-and-password'
  email: string
  password: string
}

interface ApplePayload {
  provider: 'apple'
}

interface GooglePayload {
  provider: 'google'
}

type Payload = EmailAndPasswordPayload | ApplePayload | GooglePayload

const useLogIn = (params?: Params) => {
  const { onSuccess } = params ?? DEFAULT_PARAMS

  return useMutation<UserCredential, Error, Payload>(
    (payload) => {
      switch (payload.provider) {
        case 'email-and-password': {
          return signInWithEmailAndPassword(
            auth,
            payload.email,
            payload.password,
          )
        }
        case 'apple': {
          const provider = new OAuthProvider('apple.com')
          return signInWithPopup(auth, provider)
        }
        case 'google': {
          const provider = new GoogleAuthProvider()
          return signInWithPopup(auth, provider)
        }
        default: {
          return Promise.resolve({} as UserCredential)
        }
      }
    },
    {
      onSuccess,
    },
  )
}

export default useLogIn
