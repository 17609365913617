import React, { useEffect, useMemo, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { InView } from 'react-intersection-observer'

import Container from 'components/common/Container'
import PluginsCategories from 'components/PluginsCategories'
import PluginPost from 'components/PluginPost'
import Loader from 'components/common/Loader'
import SortLikesIcon from 'components/icons/SortLikesIcon'
import SortNameIcon from 'components/icons/SortNameIcon'

import useResize from 'hooks/useResize'
import useFetchPluginsCategories from 'hooks/query/useFetchPluginsCategory'
import useInfiniteFetchPlugins from 'hooks/query/useInfiniteFetchPlugins'

import { useTranslation } from 'react-i18next'
import { CategoryProps } from 'types/Plugins'

import MobileHeader from '../../components/MobileHeader'
import c from './PluginsPage.module.scss'

function Plugins() {
  const [take, setTake] = useState<number>(16)
  const [orderBy, setOrderBy] = useState<string>('likes')

  const width = useResize()

  const [activeCategory, setActiveCategory] = useState<CategoryProps>({
    id: 0,
    title: 'all',
  })

  const { data: categoriesData, isLoading: categoriesLoading } =
    useFetchPluginsCategories()

  const {
    data: infinitePluginsData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteFetchPlugins({
    take,
    categoryId: activeCategory.id,
    orderBy,
  })

  const pluginsData = useMemo(() => {
    if (infinitePluginsData === undefined) return []
    return infinitePluginsData.pages.flatMap((p) => p.items)
  }, [infinitePluginsData])

  const onScrollToEnd = () => {
    if (isLoading) return
    fetchNextPage()
  }

  useEffect(() => {
    if (width < 500) {
      setTake(4)
    } else if (width < 1620) {
      setTake(8)
    } else if (width > 1620) {
      setTake(12)
    }
  }, [width])

  return (
    <div className={c.pluginsPage}>
      <MobileHeader heading="GPT Plugins" isOnGoBack />
      <Container noPaddingOnMobile>
        <div className={c.content}>
          {!categoriesLoading ? (
            <div className={c.plugins}>
              <div className={c.tagsContainer}>
                {!categoriesLoading && categoriesData && (
                  <PluginsCategories
                    categories={categoriesData?.items}
                    activeCategory={activeCategory}
                    setActiveCategory={setActiveCategory}
                  />
                )}
              </div>
              <div className={c.sortContainer}>
                <p className={c.sortTitle}>Sort by:</p>
                <label className={c.sortItem}>
                  <input
                    type="radio"
                    name="sortPlugin"
                    value="likes"
                    onChange={(e) => setOrderBy(e.target.value)}
                    checked={orderBy === 'likes'}
                  />
                  <span>
                    <SortLikesIcon />
                    Likes
                  </span>
                </label>
                <label className={c.sortItem}>
                  <input
                    type="radio"
                    name="sortPlugin"
                    value="chat_plugins.title"
                    checked={orderBy === 'chat_plugins.title'}
                    onChange={(e) => setOrderBy(e.target.value)}
                  />
                  <span>
                    <SortNameIcon />
                    Name
                  </span>
                </label>
              </div>
              <div className={c.postsContainer}>
                {!categoriesLoading &&
                  !isLoading &&
                  pluginsData.length > 0 &&
                  pluginsData?.map((plugin: any) => (
                    <PluginPost post={plugin} key={plugin.id} />
                  ))}
                {!isLoading && !isFetchingNextPage && hasNextPage && (
                  <InView
                    as="div"
                    onChange={(inView) => inView && onScrollToEnd()}
                  />
                )}
              </div>
              {!isLoading && isFetchingNextPage && (
                <div className={c.loaderWrapper}>
                  <div className={c.loader}>
                    <Loader />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className={c.loaderWrapper}>
              <div className={c.loader}>
                <Loader />
              </div>
            </div>
          )}
        </div>
      </Container>
      <Outlet />
    </div>
  )
}

export default Plugins
