import React, { useCallback, useMemo, useState } from 'react'
import ChatPanelItem from '../ChatPanelItem'
import useFetchRequestMyProfile from '../../hooks/query/useFetchRequestMyProfile'
import { MessageItem, SettingsMessageData } from '../../types/Chats'
import ChatPanelHeader from '../ChatPanelHeader'
import ChatPanelSearch from '../ChatPanelSearch'
import SubChatItem from '../SubChatItem'

import c from './ChatPanelContent.module.scss'
import useInfiniteFetchSearchResult from '../../hooks/query/useInfiniteFetchSearchResult'
import SettingsMessage from '../SettingsMessage'
import useFetchSubscribers from '../../hooks/query/useFetchSubscribers'

interface ChatPanelContentProps {
  chatsData: MessageItem[]
  handleClickChat: (id: string) => void
  activeChatId: string
}

function ChatPanelContent({
  chatsData,
  handleClickChat,
  activeChatId,
}: ChatPanelContentProps) {
  const [subscriptionsVisible, setSubscriptionsVisible] =
    useState<boolean>(false)

  const [settingsChatData, setSettingsChatData] = useState<SettingsMessageData>(
    { id: 0, position: { x: 0, y: 0 } },
  )

  const [preventDefaultNextTime, setPreventDefaultNextTime] =
    useState<boolean>(true)

  const [settingsMessageVisible, setSettingsMessageVisible] =
    useState<boolean>(false)

  const [selectedUserId, setSelectedUserId] = useState<string>('')

  const [formData, setFormData] = useState<string | undefined>(undefined)

  const { data: userData } = useFetchRequestMyProfile()

  const { data: infiniteSearchResult, isLoading } =
    useInfiniteFetchSearchResult({ userText: formData, limit: 20 })

  const { data: subs } = useFetchSubscribers({
    userId: userData?.id!,
  })

  const searchData = useMemo(() => {
    if (!infiniteSearchResult) return []
    return infiniteSearchResult.pages.flatMap((p) => p.users)
  }, [infiniteSearchResult])

  const toggleSubscriptionVisible = useCallback(() => {
    setSubscriptionsVisible(!subscriptionsVisible)
  }, [subscriptionsVisible])

  const handleRightClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
  ) => {
    if (preventDefaultNextTime) {
      event.preventDefault()
    }
    setSelectedUserId(id)
    setPreventDefaultNextTime(!preventDefaultNextTime)
    setSettingsMessageVisible((prev) => !prev)
    setSettingsChatData({
      id: 0,
      position: {
        x: event.clientX + 100,
        y: event.clientY - 35,
      },
    })
  }

  return (
    <div className={c.container}>
      <div className={c.content}>
        <ChatPanelHeader
          subscriptionsVisible={subscriptionsVisible}
          toggleSubscriptionVisible={toggleSubscriptionVisible}
        />
        {subscriptionsVisible && (
          <ChatPanelSearch
            setFormData={setFormData}
            formData={formData}
            isLoading={isLoading}
          />
        )}
        {subscriptionsVisible &&
          searchData &&
          searchData.map((sub) => (
            <SubChatItem key={sub.id} sub={sub} userData={userData} />
          ))}

        {subscriptionsVisible &&
          subs &&
          subs.map((sub) => (
            <SubChatItem key={sub.id} sub={sub} userData={userData} />
          ))}

        {!subscriptionsVisible &&
          chatsData.map((chatItem, index: number) => (
            <ChatPanelItem
              key={chatItem.id}
              handleRightClick={handleRightClick}
              activeChatId={activeChatId}
              handleClickChat={handleClickChat}
              chatData={chatItem}
              lastItem={index === chatsData.length - 1}
            />
          ))}
      </div>
      {settingsMessageVisible && (
        <SettingsMessage
          variant="Chat"
          setSettingsMessageVisible={setSettingsMessageVisible}
          selectedUserId={selectedUserId}
          settingsData={settingsChatData}
        />
      )}
    </div>
  )
}

export default ChatPanelContent
