import { useQuery } from '@tanstack/react-query'
import { getComment } from 'api'
import { useAppSelector } from 'hooks/store'
import { PostComment } from 'types/Comments'

interface Params {
  commentId: string | undefined
}

const useFetchComment = ({ commentId }: Params) => {
  const deviceId = useAppSelector((state) => state.settings.deviceId)
  const userId = useAppSelector((state) => state.auth.user?.uid)

  const deps = [deviceId, userId, commentId]

  return useQuery<PostComment, Error>(['comment', deps], () => {
    if (commentId) {
      return getComment(Number(commentId))
    }
    return Promise.reject(new Error('Invalid comment ID'))
  })
}

export default useFetchComment
