import React, { useEffect, useState } from 'react'
import { Img } from 'react-image'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'
import { TelegramShareButton, VKShareButton } from 'react-share'
import LightBox from 'components/LightBox'
import Loader from '../common/Loader'
import FullScreenIcon from '../icons/FullScreenIcon'
import { BaseAsset } from '../../types/Response'
import { deleteAsset } from '../../api'
import SaveButton from '../SaveButton'
import SocialShareIcon from '../icons/SocialShareIcon'
import VkIcon from '../icons/VkIcon'
import TelegramIcon from '../icons/TelegramIcon'
import LinkIcon from '../icons/LinkIcon'

import c from './EnhancementCard.module.scss'

interface EnhancementCardProps {
  asset: BaseAsset
  index: number
  isImageLoaded: { [key: number]: boolean }
  handleImageLoaded: (id: number) => void
  shareHandler: (assetId: number) => void
  isShared: { [key: number]: boolean }
  isVideo?: boolean
  isImg?: boolean
  imageUrls: string[] | null
}

export default function EnhancementCard({
  isImg = false,
  isVideo = false,
  asset,
  index,
  imageUrls,
  isImageLoaded,
  handleImageLoaded,
  shareHandler,
  isShared,
}: EnhancementCardProps) {
  const { t } = useTranslation()

  const [isSharedPhoto, setIsSharedPhoto] = useState<boolean>(false)

  const mutationDelete = useMutation<void, Error>(() => {
    return deleteAsset(asset.id)
  })

  const [isLightboxOpen, setIsLightboxOpen] = useState(false)

  const handleFullScreenOpen = () => {
    setIsLightboxOpen(true)
  }

  const handleFullScreenClose = () => {
    setIsLightboxOpen(false)
  }

  const shareSocialHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setIsSharedPhoto(!isSharedPhoto)
  }

  const deleteYourPost = () => {
    const result = window.confirm('Are you sure want to delete this image?')
    if (result) {
      mutationDelete.mutate()
    }
  }

  const shareUrl = `${asset.url}` || 'defaultImageUrl' // Use your actual share URL here

  useEffect(() => {
    const mainContent = document.getElementById('generationContainerMain')
    if (mainContent) {
      if (isLightboxOpen) {
        mainContent.style.zIndex = '100'
      } else {
        mainContent.style.zIndex = 'auto'
      }
    }
  }, [isLightboxOpen])

  return (
    <div className={c.assetContainer}>
      <div className={c.assetImageWrapper}>
        {asset.status === 'success' && (
          <Img
            src={asset.thumbnail_url || asset.url || 'defaultImageUrl'}
            className={c.assetImage}
            loader={<Loader />}
            onLoad={() => {
              handleImageLoaded(asset.id)
            }}
          />
        )}
        {asset.status !== 'success' && <Loader />}
        <CSSTransition
          in={isSharedPhoto}
          timeout={100}
          classNames={{
            enter: c.settingsEnter,
            enterActive: c.settingsEnterActive,
            exit: c.settingsExit,
            exitActive: c.settingsExitActive,
          }}
          mountOnEnter
          unmountOnExit
        >
          <div className={c.socialNetworksContainer}>
            <div className={c.socials}>
              <TelegramShareButton
                title={t('socials.shareTitle')}
                url={shareUrl}
              >
                <TelegramIcon className={c.iconSocial} />
              </TelegramShareButton>
              <VKShareButton url={shareUrl}>
                <VkIcon className={c.iconSocial} />
              </VKShareButton>
            </div>
            <LinkIcon className={c.iconSocial} />
          </div>
        </CSSTransition>
        <button
          type="button"
          onClick={handleFullScreenOpen}
          className={c.wrapper}
        >
          {isImageLoaded[asset.id] && (
            <div className={c.fullScreenContainer}>
              <div className={c.iconSetting}>
                <FullScreenIcon className={c.iconSetting} />
              </div>
            </div>
          )}
        </button>
      </div>
      {isLightboxOpen && (
        <LightBox
          imageUrl={asset.url || 'default'}
          onCloseRequest={handleFullScreenClose}
          sourceUrl={asset.source || ''}
        />
      )}
      <div className={c.assetActions}>
        <button
          type="button"
          disabled={asset.status !== 'success'}
          onClick={shareSocialHandler}
          className={c.shareAction}
        >
          <SocialShareIcon />
          {isShared[asset.id]
            ? t('general.buttons.shared')
            : t('general.buttons.share')}
        </button>
        <div className={c.cardAct}>
          <SaveButton className={c.actions} imageUrl={asset.url} />
        </div>
      </div>
    </div>
  )
}
