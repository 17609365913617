import React from 'react'
import { Notification as NotificationType } from 'types/Notifications'
import ReactTimeAgo from 'react-time-ago'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'hooks/store'

import useFetchRequestMyProfile from '../../hooks/query/useFetchRequestMyProfile'
import s from './NotificationMiddle.module.scss'
import PremiumIcon from '../icons/PremiumIcon'

interface NotificationMiddleProps {
  notification: NotificationType
}

function NotificationMiddle({ notification }: NotificationMiddleProps) {
  const { t } = useTranslation()

  const { data: user } = useFetchRequestMyProfile()

  const language = useAppSelector((state) => state.settings.language)
  return (
    <div className={s.event}>
      <div className={s.userInfo}>
        <div className={s.nickname}>
          {notification.from_user.nickname}
          {user?.is_paid_subscription && (
            <PremiumIcon style={{ width: '16px', height: '16px' }} />
          )}
        </div>
        <div className={s.date}>
          <ReactTimeAgo
            date={new Date(notification.created_at)}
            locale={language === 'en' ? 'en-US' : 'ru'}
          />
        </div>
      </div>
      <div className={s.whatHeDid}>
        {notification.type === 'subscription' && (
          <>{t('notifications.subscription')}</>
        )}
        {notification.type === 'like' && <>{t('notifications.liked')}</>}
        {notification.type === 'like_comment' && (
          <>{t('notifications.liked_comment')}</>
        )}
        {notification.type === 'reply' && <>{t('notifications.reply')}</>}
      </div>
    </div>
  )
}

export default NotificationMiddle
