import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { postStripeSubscribe } from 'api'
import CloseIcon from 'components/icons/CloseIcon'
import Button from 'components/common/Button'
import getPaymentModalList from 'constants/getPaymentModalList'
import YoKassaIcon from 'components/icons/YoKassaIcon'
import StripeIcon from 'components/icons/StripeIcon'

import c from './PaymentModal.module.scss'

interface PaymentModalProps {
  open: boolean
  closeModal: () => void
}

function PaymentModal({ open, closeModal }: PaymentModalProps) {
  const [activeId, setActiveId] = useState<string>('')
  const { paymentDesc, typesOfSubscription } = getPaymentModalList()
  const [isScroll, setIsScroll] = useState(false)

  const contentRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()

  const className = classNames({
    [c.wrapper]: true,
    [c.wrapperOpen]: open,
    [c.isScroll]: isScroll,
  })

  useEffect(() => {
    if (open) {
      const originalStyle = window.getComputedStyle(document.body).overflow
      const originalOverflowX = window.getComputedStyle(document.body).overflowX

      document.body.style.overflow = 'hidden'
      document.body.style.overflowX = 'hidden'

      return () => {
        document.body.style.overflow = originalStyle
        document.body.style.overflowX = originalOverflowX
      }
    }

    return () => null
  }, [open])

  const handleChangeActiveRates = (id: string) => {
    typesOfSubscription.some((elem) => {
      if (elem.id === id) {
        setActiveId(id)
        return true
      }
      return false
    })
  }

  const handleClick = async () => {
    try {
      const result = await postStripeSubscribe(activeId)

      if (result) {
        const link = document.createElement('a')
        link.href = result.url
        link.click()
      }
    } catch (error) {
      toast.error('Something went wrong')
    }
  }

  useEffect(() => {
    if (contentRef.current) {
      setIsScroll(
        contentRef.current?.getBoundingClientRect().height > window.innerHeight,
      )
    }
  }, [contentRef.current, open])

  return (
    <div
      className={className}
      onClick={() => {
        setActiveId('')
        closeModal()
      }}
      onKeyDown={() => {}}
      role="button"
      tabIndex={0}
    >
      <div
        className={c.container}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
        ref={contentRef}
      >
        <div className={c.header}>
          <span className={c.title}>{t('modals.payment.title')}</span>
          <button
            type="button"
            className={c.closeButton}
            onClick={() => {
              setActiveId('')
              closeModal()
            }}
          >
            <CloseIcon />
          </button>
        </div>
        <div className={c.content}>
          <ul className={c.contentLists}>
            {paymentDesc.map((item) => (
              <li key={item.id} className={c.list}>
                <img src={item.icon} alt={item.desc} />
                <span>{item.desc}</span>
              </li>
            ))}
          </ul>
          <div className={c.contentPrice}>
            <span className={c.contentPriceTitle}>
              {t('modals.payment.removeSubscribe')}
            </span>
            <ul className={c.contentPriceLists}>
              {typesOfSubscription.map((item) => (
                <li key={item.id}>
                  <button
                    type="button"
                    className={classNames({
                      [c.list]: true,
                      [c.active]: item.id === activeId,
                    })}
                    onClick={() => handleChangeActiveRates(item.id)}
                  >
                    <p>{item.period}</p>
                    <div className={c.priceWrapper}>
                      <span>{item.price}</span>
                      {item.tips && (
                        <span className={c.priceTips}>{item.tips}</span>
                      )}
                    </div>
                    {item.isPopular && (
                      <span className={c.popularTips}>Most Popular</span>
                    )}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className={c.contentLinks}>
            <Button
              variant="contained"
              className={c.button}
              onClick={handleClick}
              disabled={activeId === ''}
            >
              {t('modals.payment.buttonText')}
            </Button>
            {/* <button type="button" className={c.button}>
              <YoKassaIcon />
            </button>
            <button type="button" className={c.button}>
              <StripeIcon />
            </button> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentModal
