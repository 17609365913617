import React, { useCallback } from 'react'
import { ReactComponent as CheckIcon } from '@material-design-icons/svg/round/check.svg'
import { useTranslation } from 'react-i18next'
import { setLanguage } from 'store/reducers/settingsReducer'

import { useAppDispatch, useAppSelector } from 'hooks/store'
import LANGUAGELISTS from 'constants/languageLists'
import c from './LanguageChange.module.scss'

function LanguageChange() {
  const dispatch = useAppDispatch()

  const language = useAppSelector((state) => state.settings.language)

  const handleLanguageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setLanguage(e.target.value))
    },
    [dispatch],
  )

  const { t } = useTranslation()

  return (
    <div className={c.language}>
      <p className={c.listTitle}>{t('settings.general.items.language')}</p>
      <ul className={c.list}>
        {LANGUAGELISTS.map((item) => (
          <li key={item.id} className={c.listItem}>
            <label className={c.label} htmlFor={item.code}>
              <input
                className="sr-only"
                type="radio"
                name="language"
                value={item.code}
                id={item.code}
                checked={language === item.code}
                onChange={handleLanguageChange}
              />
              {item.title}
              {language === item.code && <CheckIcon className={c.checkIcon} />}
            </label>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default LanguageChange
