import React, { useMemo } from 'react'
import c from './TextArea.module.scss'

interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  textareaRef?: React.RefObject<HTMLTextAreaElement>
}

function TextArea({
  className,
  startIcon = undefined,
  endIcon = undefined,
  textareaRef = undefined,
  ...props
}: TextAreaProps) {
  const classNames = useMemo(() => {
    let result = c.inputContainer
    if (className) result += ` ${className}`
    return result
  }, [className, startIcon, endIcon])

  // @ts-ignore
  return (
    <div className={classNames}>
      {/* Disable ESLint for the next line */}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <textarea className={c.input} {...props} ref={textareaRef} />
    </div>
  )
}

export default TextArea
