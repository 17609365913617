import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Exception } from 'types/Post'

interface InitialState {
  exceptions: Exception[]
  freezedExceptions: Exception[]
}

const initialState: InitialState = {
  exceptions: JSON.parse(localStorage.getItem('exceptions') || '[]'),
  freezedExceptions: JSON.parse(localStorage.getItem('exceptions') || '[]'),
}

const viewedSlice = createSlice({
  name: 'viewed',
  initialState,
  reducers: {
    setViewedFromStorage(state) {
      state.exceptions = JSON.parse(localStorage.getItem('exceptions') || '[]')
    },
    synchronizeArrays(state) {
      state.freezedExceptions = state.exceptions
    },
    pushViewedPosts(state, action: PayloadAction<number[]>) {
      const postsWithTime = action.payload?.map((elem) => ({
        id: elem,
        expired: Date.now() + 345600000,
      }))
      state.exceptions = [...state.exceptions, ...postsWithTime]
      localStorage.setItem('exceptions', JSON.stringify(state.exceptions))
    },
    clearExpired(state) {
      const clearArray = state.exceptions.filter(
        (elem) => elem.expired > Date.now(),
      )
      state.exceptions = clearArray
      state.freezedExceptions = clearArray
      localStorage.setItem('exceptions', JSON.stringify(state.exceptions))
    },
  },
})

export const {
  clearExpired,
  pushViewedPosts,
  setViewedFromStorage,
  synchronizeArrays,
} = viewedSlice.actions

export default viewedSlice.reducer
