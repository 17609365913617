import { useQuery } from '@tanstack/react-query'
import { getSuggestionsForNegativePrompt } from 'api'
import { Suggestion } from 'types/Generation'

const useFetchSuggestionsForNegativePrompt = () => {
  return useQuery<Suggestion[]>(
    ['suggestionsForNegativePrompt'],
    () => {
      return getSuggestionsForNegativePrompt()
    },
    { initialData: [] },
  )
}

export default useFetchSuggestionsForNegativePrompt
