import React, { useEffect } from 'react'
import { onAuthStateChanged } from 'firebase/auth'

import { setUser, initialize } from 'store/reducers/authReducer'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import { auth } from 'utils/firebase'
import convertUserToUserInfo from 'utils/convertUserToUserInfo'
import { setSignUpModalOpen } from 'store/reducers/modalReducer'
import useModal from 'hooks/useModal'

function Authorizer() {
  const { isAuthInitialized } = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()

  const { signUpModalOpen } = useAppSelector((state) => state.modal)

  const { open: openSignUpModal, close: closeSignUpModal } = useModal(
    signUpModalOpen,
    setSignUpModalOpen,
  )

  useEffect(() => {
    return onAuthStateChanged(auth, (currentUser) => {
      const userInfo = convertUserToUserInfo(currentUser)
      dispatch(setUser(userInfo))

      if (isAuthInitialized) {
        return
      }

      if (!userInfo) {
        openSignUpModal()
      }

      dispatch(initialize())
    })
  }, [])

  return null
}

export default Authorizer
