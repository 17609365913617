/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
import logoG from 'images/logoG.png'
import close from 'components/icons/close.svg'

import c from './ProcessingLoader.module.scss'
import 'react-circular-progressbar/dist/styles.css'

interface ProcessingLoaderProps {
  video?: boolean
  seconds: number
}

function ProcessingLoader({ video = false, seconds }: ProcessingLoaderProps) {
  const { t } = useTranslation()

  const { data: profile } = useFetchRequestMyProfile()

  const [percentage, setPercentage] = useState<number>(0)
  const [closeButton, setClose] = useState<boolean>(false)

  useEffect(() => {
    if (seconds > 0) {
      let elapsedSeconds = 0
      const interval = setInterval(() => {
        elapsedSeconds += 1
        const newPercentage = (elapsedSeconds / seconds) * 100
        setPercentage(newPercentage)

        if (elapsedSeconds >= seconds) {
          clearInterval(interval)
        }
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [seconds])

  return (
    <div className={c.processing}>
      <div
        className={c.longLoading}
        style={{ display: closeButton || percentage < 100 ? 'none' : 'flex' }}
      >
        <div className={c.longLoadingWrapper}>
          <div className={c.longLoadingText}>
            {!profile
              ? t('stages.generation.processing.loaderText')
              : t('stages.generation.processing.loaderTextForNoProfile')}
          </div>
          <button
            type="button"
            className={c.closeIcon}
            onClick={() => setClose(true)}
          >
            <img src={close} alt="" />
          </button>
        </div>
      </div>
      <div className={c.processingStage}>
        <div className={c.loaderWrapper}>
          <CircularProgressbar
            value={Math.round(percentage)}
            strokeWidth={6}
            styles={buildStyles({
              textSize: '8px',
              pathColor: '#7B23BE66',
              textColor: '#7B23BE66',
              trailColor: '#1E1C2B80',
              backgroundColor: 'rgba(30, 28, 43, 0.50)',
            })}
          />
          <div className={c.percentageText}>{Math.round(percentage)}%</div>
          <div className={c.logo}>
            <img className={c.logoImage} alt="Logo" src={logoG} />
          </div>
        </div>
        <p className={c.bannerText}>
          {t('stages.enhancement.processing.loaderText')}
        </p>
        <p className={c.text}>
          {' '}
          {t('stages.enhancement.processing.bannerText')}
        </p>
      </div>
    </div>
  )
}

export default ProcessingLoader
