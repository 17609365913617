import { useEffect, useRef } from 'react'

export default function useDidMountEffect(func: () => void, deps: any[]) {
  const didMount = useRef<boolean>(false)

  useEffect(() => {
    if (didMount.current) func()
    else didMount.current = true
  }, deps)
}
