import React from 'react'
import { useTranslation } from 'react-i18next'
import ScrollContainer from 'react-indiana-drag-scroll'

import AddImage from 'components/AddImage'

import s from './MixerInputs.module.scss'

interface MixerInputsProps {
  imageFile: File | null
  onImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  getImageUrl: (file: File | null) => string | null
  onDeleteImage: () => void
}

function MixerInputs({
  imageFile,
  getImageUrl,
  onImageChange,
  onDeleteImage,
}: MixerInputsProps) {
  const { t } = useTranslation()

  return (
    <div className={s.photoSelection}>
      <p className={s.heading}>{t('stages.mixer.idle.heading')}</p>
      <ul className={s.photoSelection__list}>
        <li
          key={imageFile ? `image-${imageFile.name}` : ''}
          className={s.photoSelection__item}
        >
          <AddImage
            imageUrl={imageFile ? getImageUrl(imageFile) : null}
            onChange={onImageChange}
            onDeleteImage={onDeleteImage}
          />
        </li>
      </ul>
    </div>
  )
}

export default MixerInputs
