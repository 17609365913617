import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface InitialState {
  signUpModalOpen: boolean
  logInModalOpen: boolean
  promoModalOpen: boolean
  userModalOpen: boolean
  commentModalOpen: boolean
  conceptsModalOpen: boolean
  updateUserModalOpen: boolean
  notificationModalOpen: boolean
  paymentModalOpen: boolean
  techWorkModalOpen: boolean
  switchLanguageModalOpen: boolean
}

const initialState: InitialState = {
  signUpModalOpen: false,
  logInModalOpen: false,
  promoModalOpen: false,
  userModalOpen: false,
  commentModalOpen: false,
  conceptsModalOpen: false,
  updateUserModalOpen: false,
  notificationModalOpen: false,
  paymentModalOpen: false,
  techWorkModalOpen: false,
  switchLanguageModalOpen: false,
}

const turnEveryModalClose = (state: InitialState) => {
  state.signUpModalOpen = false
  state.logInModalOpen = false
  state.promoModalOpen = false
  state.userModalOpen = false
  state.commentModalOpen = false
  state.conceptsModalOpen = false

  state.updateUserModalOpen = false
  state.notificationModalOpen = false
  state.paymentModalOpen = false
  state.techWorkModalOpen = false
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setLogInModalOpen: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        turnEveryModalClose(state)
        state.logInModalOpen = true
      } else {
        state.logInModalOpen = false
      }
    },
    setSignUpModalOpen: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        turnEveryModalClose(state)
        state.signUpModalOpen = true
      } else {
        state.signUpModalOpen = false
      }
    },
    setPromoModalOpen: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        turnEveryModalClose(state)
        state.promoModalOpen = true
      } else {
        state.promoModalOpen = false
      }
    },
    setUserModalOpen: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        turnEveryModalClose(state)
        state.userModalOpen = true
      } else {
        state.userModalOpen = false
      }
    },
    setUpdateUserModalOpen: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        turnEveryModalClose(state)
        state.updateUserModalOpen = true
      } else {
        state.updateUserModalOpen = false
      }
    },
    setCommentModalOpen: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        turnEveryModalClose(state)
        state.commentModalOpen = true
      } else {
        state.commentModalOpen = false
      }
    },
    setConceptsModalOpen: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        turnEveryModalClose(state)
        state.conceptsModalOpen = true
      } else {
        state.conceptsModalOpen = false
      }
    },
    setNotificationModalOpen: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        turnEveryModalClose(state)
        state.notificationModalOpen = true
      } else {
        state.notificationModalOpen = false
      }
    },
    setPaymentModalOpen: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        turnEveryModalClose(state)
        state.paymentModalOpen = true
      } else {
        state.paymentModalOpen = false
      }
    },
    setTechWorkModalOpen: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        turnEveryModalClose(state)
        state.techWorkModalOpen = true
      } else {
        state.techWorkModalOpen = false
      }
    },
    setSwitchLanguageModalOpen: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        turnEveryModalClose(state)
        state.switchLanguageModalOpen = true
      } else {
        state.switchLanguageModalOpen = false
      }
    },
  },
})

export default modalSlice.reducer

export const {
  setUpdateUserModalOpen,
  setLogInModalOpen,
  setSignUpModalOpen,
  setPromoModalOpen,
  setUserModalOpen,
  setCommentModalOpen,
  setConceptsModalOpen,
  setNotificationModalOpen,
  setPaymentModalOpen,
  setTechWorkModalOpen,
  setSwitchLanguageModalOpen,
} = modalSlice.actions
