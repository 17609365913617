import React, { useMemo } from 'react'

interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

function IconButton({ children, className, ...props }: IconButtonProps) {
  const classNames = useMemo(() => {
    let result = 'iconButton'
    if (className) result += ` ${className}`
    return result
  }, [className])

  return (
    <button className={classNames} type="button" {...props}>
      {children}
    </button>
  )
}

export default IconButton
