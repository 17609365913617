import { useInfiniteQuery } from '@tanstack/react-query'
import { getComments } from 'api'
import { CommentsResponse } from 'types/Comments'

const LIMIT = 3

const getOffset = (page: number): number => {
  return (page - 1) * LIMIT
}

interface Params {
  postId: number
}

const useInfiniteFetchComments = ({ postId }: Params) => {
  return useInfiniteQuery<CommentsResponse, Error>(
    ['infinite-comments', postId],
    ({ pageParam: page = 1 }) => {
      const offset = getOffset(page)
      return getComments(postId, LIMIT, offset)
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.items.length === 0) return undefined
        return allPages.length + 1
      },
    },
  )
}

export default useInfiniteFetchComments
