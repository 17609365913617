/* eslint-disable import/prefer-default-export */

export const fixedResolutions = [
  {
    id: 1,
    width: 1024,
    height: 1536,
  },
  {
    id: 2,
    width: 1536,
    height: 1536,
  },
  {
    id: 3,
    width: 1536,
    height: 1024,
  },
]

export const fixedResolutionsDalle3 = [
  {
    id: 1,
    width: 1024,
    height: 1792,
  },
  {
    id: 2,
    width: 1024,
    height: 1024,
  },
  {
    id: 3,
    width: 1792,
    height: 1024,
  },
]
