import React from 'react'

// eslint-disable-next-line react/require-default-props
function HelpIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C5.38324 0 0 5.38324 0 12C0 18.6168 5.38324 24 12 24C18.6168 24 24 18.6168 24 12C24 5.38324 18.6168 0 12 0ZM12 1.8C17.644 1.8 22.2 6.35604 22.2 12C22.2 17.644 17.644 22.2 12 22.2C6.35603 22.2 1.8 17.644 1.8 12C1.8 6.35604 6.35603 1.8 12 1.8ZM12 5.4C10.0225 5.4 8.4 7.02248 8.4 9V9.3C8.39831 9.41926 8.42035 9.53767 8.46482 9.64834C8.50929 9.75901 8.57532 9.85974 8.65906 9.94467C8.7428 10.0296 8.84258 10.097 8.95261 10.1431C9.06264 10.1891 9.18073 10.2128 9.3 10.2128C9.41927 10.2128 9.53736 10.1891 9.64739 10.1431C9.75742 10.097 9.8572 10.0296 9.94094 9.94467C10.0247 9.85974 10.0907 9.75901 10.1352 9.64834C10.1797 9.53767 10.2017 9.41926 10.2 9.3V9C10.2 7.99552 10.9955 7.2 12 7.2C13.0045 7.2 13.8 7.99552 13.8 9C13.8 10.4045 13.4348 10.5805 12.7945 11.0836C12.4744 11.3351 12.0551 11.6251 11.6965 12.1137C11.3379 12.6022 11.1 13.2832 11.1 14.1C11.0983 14.2193 11.1203 14.3377 11.1648 14.4483C11.2093 14.559 11.2753 14.6597 11.3591 14.7447C11.4428 14.8296 11.5426 14.897 11.6526 14.9431C11.7626 14.9891 11.8807 15.0128 12 15.0128C12.1193 15.0128 12.2374 14.9891 12.3474 14.9431C12.4574 14.897 12.5572 14.8296 12.6409 14.7447C12.7247 14.6597 12.7907 14.559 12.8352 14.4483C12.8797 14.3377 12.9017 14.2193 12.9 14.1C12.9 13.5881 12.9996 13.3801 13.1473 13.1789C13.2949 12.9777 13.5506 12.778 13.9055 12.4992C14.6152 11.9416 15.6 10.8955 15.6 9C15.6 7.02248 13.9775 5.4 12 5.4ZM12 16.8C11.6817 16.8 11.3765 16.9264 11.1515 17.1515C10.9264 17.3765 10.8 17.6817 10.8 18C10.8 18.3183 10.9264 18.6235 11.1515 18.8485C11.3765 19.0736 11.6817 19.2 12 19.2C12.3183 19.2 12.6235 19.0736 12.8485 18.8485C13.0736 18.6235 13.2 18.3183 13.2 18C13.2 17.6817 13.0736 17.3765 12.8485 17.1515C12.6235 16.9264 12.3183 16.8 12 16.8Z"
        fill="white"
      />
    </svg>
  )
}

export default HelpIcon
