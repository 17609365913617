import React, { useRef } from 'react'

import DownloadIcon from 'components/icons/DownloadIcon'
import Button from 'components/common/Button'
import { useTranslation } from 'react-i18next'

import c from './ConceptImageInput.module.scss'

interface AddImageProps extends React.HTMLAttributes<HTMLInputElement> {
  text?: string
  bigger?: boolean
  imageUrl?: string | null | undefined
  index?: number
}

function ConceptImageInput({
  imageUrl = null,
  className,
  index = 0,
  ...props
}: AddImageProps) {
  const { t } = useTranslation()
  const fileInput = useRef<HTMLInputElement>(null)

  return (
    <div className={`${c.fileUpload} ${className}`}>
      <label className={c.fileUploadItem}>
        <input
          type="file"
          accept="image/*"
          className="sr-only"
          multiple
          {...props}
          ref={fileInput}
        />
        <span className={c.fileUploadItemWrapper}>
          <DownloadIcon />
          <span className={c.itemText}>
            {t('stages.enhancement.idle.dragDrop')}
          </span>
          <span>{t('stages.generation.idle.or')}</span>
          <Button variant="outlined" className={c.uploadButton}>
            {t('stages.enhancement.idle.buttonText')}
          </Button>
        </span>
      </label>
    </div>
  )
}

export default ConceptImageInput
