import { useQuery } from '@tanstack/react-query'
import { getAssetById } from 'api'
import { useAppSelector } from 'hooks/store'
import { BaseAsset } from 'types/Response'

interface Params {
  assetId: string | undefined
}

const useFetchAsset = ({ assetId }: Params) => {
  const deviceId = useAppSelector((state) => state.settings.deviceId)
  const userId = useAppSelector((state) => state.auth.user?.uid)

  const deps = [deviceId, userId, assetId]

  return useQuery<BaseAsset, Error>(['asset', deps], () => {
    if (assetId) {
      const numberAssetId = parseInt(assetId, 10)
      return getAssetById(numberAssetId)
    }
    return Promise.reject(new Error('Invalid asset ID'))
  })
}

export default useFetchAsset
