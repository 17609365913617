import React, { useCallback } from 'react'
import TextInput from 'components/common/TextInput'
import { useTranslation } from 'react-i18next'

import c from './NegativePromptInput.module.scss'

interface NegativePromptInputProps {
  negativePrompt: string | undefined
  setNegativePrompt: (prompt: string) => void
}

function NegativePromptInput({
  negativePrompt,
  setNegativePrompt,
}: NegativePromptInputProps) {
  const { t } = useTranslation()

  const handleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNegativePrompt(e.target.value)
    },
    [setNegativePrompt],
  )

  return (
    <div>
      <TextInput
        className={c.input}
        value={negativePrompt}
        onChange={handleTextChange}
        placeholder={t('stages.generation.idle.negativePromptInputExample')}
      />
    </div>
  )
}

export default NegativePromptInput
