import React from 'react'
import ProcessingLoader from 'components/ProcessingLoader'

interface ProcessingStageProps {
  seconds: number
}

function ProcessingStage({seconds}:ProcessingStageProps) {
  return <ProcessingLoader video seconds={seconds} />
}

export default ProcessingStage
