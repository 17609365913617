import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ImageInput from 'components/ImageInput'
import { ReactComponent as ArrowNextIcon } from '@material-design-icons/svg/filled/arrow_forward_ios.svg'
import { ReactComponent as ArrowPrevIcon } from '@material-design-icons/svg/filled/arrow_back_ios.svg'
import { ReactComponent as PlusIcon } from '@material-design-icons/svg/outlined/add.svg'

import s from './SliderPhotos.module.scss'

interface SliderPhotosProps {
  photos: string[]
  prevHandler?: () => void
  nextHandler?: () => void
  currentSlide: number
  setCurrentSlide: (index: number) => void
  onImageChange?: (newFiles: File[]) => void
  isPaidSubscription?: boolean
  finish?: boolean
}

function SliderPhotos({
  photos,
  currentSlide,
  nextHandler = undefined,
  prevHandler = undefined,
  setCurrentSlide,
  isPaidSubscription = false,
  finish = false,
  onImageChange = undefined,
}: SliderPhotosProps) {
  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target
      if (files && onImageChange)
        onImageChange(Array.from(files).slice(0, 5 - photos.length))
    },
    [onImageChange, photos],
  )

  return (
    <div className={s.slider}>
      <div className={s.control}>
        <button type="button" className={s.arrow} onClick={prevHandler}>
          <ArrowPrevIcon />
        </button>
        <div className={s.number}>
          {currentSlide + 1}/{photos!.length}
        </div>
        <button type="button" className={s.arrow} onClick={nextHandler}>
          <ArrowNextIcon />
        </button>
      </div>
      <div className={s.sliders}>
        {photos.length > 0 &&
          photos.map((photo, index) => {
            return (
              <button
                className={s.slideWrapper}
                type="button"
                key={photo}
                onClick={() => setCurrentSlide(index)}
              >
                <img src={photo} alt="" className={s.image} />
              </button>
            )
          })}
        {photos.length >= 5 || !isPaidSubscription || finish || (
          <ImageInput
            className={s.imageInput}
            //   label={t('stages.enhancement.idle.buttonText')}
            startIcon={<PlusIcon style={{ fill: 'currentColor' }} />}
            onChange={handleInputChange}
            multiple={isPaidSubscription}
          />
        )}
      </div>
    </div>
  )
}

export default SliderPhotos
