import React, { useCallback, useState } from 'react'
import { t } from 'i18next'

import { ConceptsItem } from 'types/Concepts'
import AlbumCard from 'components/AlbumCard/AlbumCard'
import ConfirmModal from 'components/modals/ConfirmModal'
import DeleteButton from 'components/DeleteButton'

import c from './AlbumContent.module.scss'

interface AlbumContentProps {
  albumsData: ConceptsItem[]
  nickname: string
}

function AlbumContent({ albumsData, nickname }: AlbumContentProps) {
  const [checkedAssets, setCheckedAssets] = useState<{
    [key: number]: boolean
  }>({})
  const [selectedToDelete, setSelectedToDelete] = useState<number[]>([])
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false)

  const handleMassDelete = () => {
    if (isDeleting) {
      if (selectedToDelete.length === 0) {
        setIsDeleting(false)
        return
      }
      // Добавить при наличии массового удаления альбомов
      // massDeleteRequest()
      setIsDeleting(false)
      setSelectedToDelete([])
      setIsConfirmModalOpen(false)
    }
  }

  const closeModal = useCallback(() => {
    setIsConfirmModalOpen(false)
  }, [])

  const handleDeleteToggle = () => {
    setCheckedAssets({})
    setIsDeleting(!isDeleting)
    setSelectedToDelete([])
  }

  const handleDeleteItems = useCallback(() => {
    setIsConfirmModalOpen(true)
  }, [])

  const selectToDelete = useCallback(
    (postId: number) => {
      if (selectedToDelete.includes(postId)) {
        setSelectedToDelete(selectedToDelete.filter((elem) => elem !== postId))
      } else {
        setSelectedToDelete([...selectedToDelete, postId])
      }
    },
    [selectedToDelete],
  )

  const handleCheckboxChange = (id: number, idData: number) => {
    selectToDelete(idData)
    setCheckedAssets((prev) => ({
      ...prev,
      [id]: !prev[id],
    }))
  }

  return (
    <>
      <ConfirmModal
        heading={t('modals.general.delete')}
        confirmFunc={handleMassDelete}
        cancelFunc={closeModal}
        isModalOpen={isConfirmModalOpen}
      />
      <DeleteButton
        checkedAssets={checkedAssets}
        selectedToDelete={selectedToDelete}
        isDeleting={isDeleting}
        handleDeleteToggle={handleDeleteToggle}
        handleMassDelete={handleDeleteItems}
      />
      <div className={c.grid}>
        {albumsData.length > 0 &&
          albumsData.map((album, index) => {
            return (
              <AlbumCard
                key={album.id}
                data={album}
                index={index}
                isChecked={checkedAssets[album.id] || false}
                handleCheckboxChange={() => {
                  handleCheckboxChange(album.id, album.id)
                }}
                nickname={nickname}
              />
            )
          })}
      </div>
    </>
  )
}

export default AlbumContent
