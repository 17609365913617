import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import Modal from 'components/common/Modal'
import AuthForm from 'components/AuthForm'
import useLogOut from 'hooks/query/auth/useLogOut'
import { putProfile } from 'api/'
import { UserRequest } from 'types/User'
import i18n from 'i18n/config'

interface UserModalProps {
  open: boolean
  onClose: () => void
}

function UserModal({ open, onClose }: UserModalProps) {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const [nickname, setNickname] = useState<string>('')
  const [userName, setUserName] = useState<string>('')
  const [description, setDescription] = useState<string>('')

  const onSuccess = () => {
    queryClient.refetchQueries(['myProfile'])
    onClose()
    setNickname('')
    setUserName('')
    setDescription('')
  }

  const { mutate: logOut } = useLogOut()

  const handleLogOut = useCallback(() => {
    logOut()
    onClose()
  }, [logOut])

  const mutation = useMutation<void, Error, UserRequest>(
    (userFields) => {
      return putProfile(userFields!)
    },
    {
      onSuccess,
    },
  )

  const validateNickname = (nick: string) => {
    const regex = /^[a-zA-Z0-9_]*$/

    if (!regex.test(nick)) {
      return i18n.t('formError.nickname.forbiddenSymbols')
    }
    const regexSymbols = /^[A-Za-z]/

    if (!regexSymbols.test(nick[0])) {
      return i18n.t('formError.nickname.intNickname')
    }

    if (nick.length >= 18) {
      return i18n.t('formError.nickname.maxSymbols')
    }

    if (nick.length <= 3) {
      return i18n.t('formError.nickname.minSymbols')
    }
    return null
  }

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const validateError = validateNickname(nickname)
      if (!validateError) {
        mutation.mutate({ nickname, name: userName, description })
      } else {
        toast.error(validateError)
      }
    },
    [nickname, userName, description],
  )

  useEffect(() => {
    if (mutation.error?.message) toast.error(mutation.error.message)
  }, [mutation.error?.message])

  return (
    <Modal
      open={open}
      screen="userModal"
      heading={t('modals.userInfo.heading')}
    >
      <AuthForm
        screen="userModal"
        nickname={nickname}
        userName={userName}
        description={description}
        submitText={t('modals.userInfo.submit')}
        isSubmitting={mutation.isLoading}
        onNicknameChange={setNickname}
        onUserNameChange={setUserName}
        onDescriptionChange={setDescription}
        onSubmit={handleSubmit}
        handleLogOut={handleLogOut}
      />
    </Modal>
  )
}

export default UserModal
