import React from 'react'

// eslint-disable-next-line react/require-default-props
function VkIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_6888_25785)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#2787F5"
        />
        <path
          d="M14.1489 5H9.8511C5.92877 5 5 5.92877 5 9.8511V14.1489C5 18.0712 5.92877 19 9.8511 19H14.1489C18.0712 19 19 18.0712 19 14.1489V9.8511C19 5.92877 18.0613 5 14.1489 5ZM16.3027 14.9886H15.284C14.8987 14.9886 14.7802 14.6824 14.0886 13.981C13.4859 13.3988 13.2193 13.3191 13.0699 13.3191C12.8624 13.3191 12.803 13.3773 12.803 13.6649V14.5837C12.803 14.8309 12.7239 14.979 12.0719 14.979C10.995 14.979 9.79958 14.327 8.95958 13.1116C7.69493 11.3332 7.34915 9.99925 7.34915 9.72274C7.34915 9.57459 7.40736 9.43634 7.69493 9.43634H8.71364C8.97064 9.43634 9.06931 9.5548 9.16798 9.8316C9.67181 11.284 10.5118 12.5585 10.8561 12.5585C10.9845 12.5585 11.0439 12.5003 11.0439 12.1732V10.6713C11.0043 9.97975 10.6387 9.92037 10.6387 9.67356C10.6387 9.55509 10.7374 9.43634 10.8957 9.43634H12.4965C12.714 9.43634 12.7928 9.5548 12.7928 9.81181V11.837C12.7928 12.0544 12.8915 12.1333 12.9509 12.1333C13.0793 12.1333 13.1881 12.0541 13.425 11.8172C14.1562 10.9973 14.6798 9.73264 14.6798 9.73264C14.7491 9.58449 14.8675 9.44624 15.1245 9.44624H16.1432C16.4494 9.44624 16.5187 9.60428 16.4494 9.8217C16.3211 10.4146 15.0762 12.1732 15.0762 12.1732C14.9677 12.351 14.9281 12.4302 15.0762 12.6275C15.1848 12.7757 15.5419 13.0819 15.7777 13.3587C16.2125 13.8535 16.5484 14.2677 16.6372 14.5541C16.7376 14.8405 16.5891 14.9886 16.3027 14.9886Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6888_25785">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default VkIcon
