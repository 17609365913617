import React from 'react'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.min.css'
import './Toastify.scss'

function Toastify() {
  return (
    <ToastContainer
      position="bottom-right"
      icon={false}
      theme="colored"
      toastStyle={{
        borderRadius: '8px',
        border: '2px solid #161420',
        background: '#191724',
      }}
      autoClose={3000}
    />
  )
}

export default Toastify
