import React from 'react'
import { useTranslation } from 'react-i18next'

import Container from 'components/common/Container'
import GenerationStyles from 'components/GenerationStyles'
import { Style, Suggestion } from 'types/Generation'
import RequestGenerationContainer from '../../../RequestGenerationContainer'

import s from './IdleStage.module.scss'

interface IdleStageProps {
  text: string
  onTextChange: (newText: string) => void
  styles?: Style[]
  suggestions?: Suggestion[]
  onExampleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  chosenStyle: string | undefined
  onProcessingStart: () => void
  onDeleteImage: () => void
  onStyleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  imageFile: File | null
  onImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  getImageUrl: (file: File | null) => string | null
  inputRef: React.RefObject<HTMLTextAreaElement>
}

function IdleStage({
  text,
  chosenStyle,
  onProcessingStart,
  onStyleClick,
  onTextChange,
  styles = [],
  onDeleteImage,
  imageFile,
  getImageUrl,
  onImageChange,
  suggestions = [],
  onExampleClick,
  inputRef,
}: IdleStageProps) {
  const { t } = useTranslation()

  return (
    <Container flex>
      <div className={s.content}>
        <RequestGenerationContainer
          generationTextAreaVisible
          mixerInputsVisible
          promptTipsVisible
          imageFile={imageFile}
          onImageChangeIndex={onImageChange}
          getImageUrl={getImageUrl}
          onDeleteImage={onDeleteImage}
          suggestions={suggestions}
          text={text}
          onTextChange={onTextChange}
          onProcessingStart={onProcessingStart}
          onExampleClick={onExampleClick}
          inputRef={inputRef}
        />
        <GenerationStyles
          chosenStyle={chosenStyle}
          styles={styles}
          onStyleClick={onStyleClick}
        />
      </div>
    </Container>
  )
}

export default IdleStage
