import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import { setLogInModalOpen } from 'store/reducers/modalReducer'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
import { useAppDispatch } from 'hooks/store'
import FeedIcon from '../icons/FeedIcon.svg'
import TrendingIcon from '../icons/TrendingIcon.svg'

import c from './SwitcherPages.module.scss'

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
function SwitcherPages() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const location = useLocation()
  const navigate = useNavigate()
  const { data, isLoading } = useFetchRequestMyProfile()

  const isActive = useCallback((path: string) => location.pathname === path, [])

  return (
    <div className={c.container}>
      <div className={c.content}>
        <ul className={c.listSwitchers}>
          <li
            className={`${c.itemSwitcher} ${
              isActive('/trending') || isActive('/') ? c.active : ''
            }`}
          >
            <div className={c.itemSwitcher__icon}>
              <img
                src={TrendingIcon}
                alt="trendingIcon"
                className={c.iconSetting}
              />
            </div>
            <div className={c.itemSwitcher__title}>
              {t('navigation.bestArt')}
            </div>
            <NavLink to="/trending" end className={c.link} />
          </li>
          <li
            className={`${c.itemSwitcher} ${
              isActive('/trending-video-art') ? c.active : ''
            }`}
          >
            <div className={c.itemSwitcher__icon}>
              <img
                src={TrendingIcon}
                alt="trendingIcon"
                className={c.iconSetting}
              />
            </div>
            <div className={c.itemSwitcher__title}>
              {t('navigation.bestVideo')}
            </div>
            <NavLink to="/trending-video-art" end className={c.link} />
          </li>
          <li
            className={`${c.itemSwitcher} ${isActive('/feed') ? c.active : ''}`}
            onClick={() => {
              if (!isLoading && data) {
                navigate('/feed')
                return
              }
              if (!isLoading) {
                dispatch(setLogInModalOpen(true))
              }
            }}
          >
            <div className={c.itemSwitcher__icon}>
              <img
                src={FeedIcon}
                alt="trendingIcon"
                className={c.iconSetting}
              />
            </div>
            <div className={c.itemSwitcher__title}>{t('navigation.feed')}</div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default SwitcherPages
