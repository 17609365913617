import React from 'react'
import classNames from 'classnames'
import { Img } from 'react-image'

import ReplyIcon from 'components/icons/ReplyIcon'
import Loader from '../common/Loader'

import c from './CommentParentCard.module.scss'

export interface ITagCommentParentCard {
  text: string
  type: 'default' | 'filled'
}

interface CommentParentCardProps {
  url: string
  text: string
  tags: ITagCommentParentCard[]
  reply?: {
    url: string
    text: string
  }
}

export default function CommentParentCard({
  url,
  text,
  tags = [],
  reply = undefined,
}: CommentParentCardProps) {
  return (
    <div className={c.container}>
      {reply && (
        <div className={c.reply}>
          <div className={c.replyIcon}>
            <ReplyIcon />
          </div>
          <Img
            src={reply.url || 'defaultImageUrl'}
            className={c.replyImage}
            loader={<Loader />}
            onLoad={() => {}}
          />
          <p className={c.replyText}>{reply.text}</p>
        </div>
      )}
      <div className={c.content}>
        <Img
          src={url || 'defaultImageUrl'}
          className={c.contentImage}
          loader={<Loader />}
          onLoad={() => {}}
        />

        <div className={c.contentFooter}>
          <p className={c.contentText}>{text}</p>

          {tags.length > 0 && (
            <div className={c.contentTags}>
              {tags.map((tag) => {
                if (!tag.text) return null
                return (
                  <span
                    className={classNames({
                      [c.contentTag]: true,
                      [c.contentTagFilled]: tag.type === 'filled',
                    })}
                    key={tag.text}
                  >
                    {tag.text}
                  </span>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
