import React from 'react'

// eslint-disable-next-line react/require-default-props
function CompareIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M12 21H13C16.7712 21 18.6569 21 19.8284 19.8284C21 18.6569 21 16.7712 21 13V11C21 7.22876 21 5.34315 19.8284 4.17157C18.6569 3 16.7712 3 13 3H12"
        stroke="white"
        strokeWidth="1.4"
      />
      <path
        d="M13 21H9C6.17157 21 4.75736 21 3.87868 20.1213C3 19.2426 3 17.8284 3 15V9C3 6.17157 3 4.75736 3.87868 3.87868C4.75736 3 6.17157 3 9 3H13"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeDasharray="83.33 100"
      />
      <path
        d="M12 22V2"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default CompareIcon
