import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Modal from 'components/common/Modal'
import AuthForm from 'components/AuthForm'
import SocialAuth from 'components/SocialAuth'
import useLogIn from 'hooks/query/auth/useLogIn'
import useForgotPassword from 'hooks/query/auth/useForgotPassword'
import c from './LogInModal.module.scss'

type Screen = 'log-in' | 'forgot-password' | 'forgot-password-success'

interface LogInModalProps {
  open: boolean
  onClose: () => void
}

function LogInModal({ open, onClose }: LogInModalProps) {
  const { t } = useTranslation()

  const [screen, setScreen] = useState<Screen>('log-in')

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const resetForm = useCallback(() => {
    setScreen('log-in')
    setEmail('')
    setPassword('')
  }, [])

  const handleClose = useCallback(() => {
    resetForm()
    onClose()
  }, [resetForm, onClose])

  const {
    mutate: logIn,
    isLoading: isLoggingIn,
    error: logInError,
  } = useLogIn({ onSuccess: handleClose })

  const {
    mutate: sendResetPasswordLink,
    isLoading: isSendingResetPasswordLink,
    error: sendResetPasswordLinkError,
  } = useForgotPassword({
    onSuccess: () => setScreen('forgot-password-success'),
  })

  const heading = useMemo(() => {
    if (screen === 'log-in') return t('modals.logIn.heading')
    if (screen === 'forgot-password') return t('modals.forgotPassword.heading')
    if (screen === 'forgot-password-success')
      return t('modals.forgotPasswordSuccess.heading')
    return ''
  }, [screen])

  const handleLogIn = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      logIn({ provider: 'email-and-password', email, password })
    },
    [logIn, email, password],
  )

  const handleSendResetPasswordLink = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      sendResetPasswordLink({ email })
    },
    [sendResetPasswordLink, email],
  )

  const handleForgotPasswordClick = useCallback(() => {
    setScreen('forgot-password')
  }, [])

  useEffect(() => {
    if (logInError?.message) toast.error(logInError.message)
  }, [logInError?.message])

  useEffect(() => {
    if (sendResetPasswordLinkError?.message)
      toast.error(sendResetPasswordLinkError.message)
  }, [sendResetPasswordLinkError?.message])

  return (
    <Modal
      open={open}
      heading={heading}
      onClose={handleClose}
      screen={
        screen === 'forgot-password' || screen === 'forgot-password-success'
          ? 'userModal'
          : 'none'
      }
    >
      {screen === 'log-in' && (
        <AuthForm
          onClose={onClose}
          className={c.form}
          email={email}
          screen="log-in"
          password={password}
          submitText={t('modals.logIn.submit')}
          isSubmitting={isLoggingIn}
          onEmailChange={setEmail}
          onPasswordChange={setPassword}
          onForgotPassword={handleForgotPasswordClick}
          onSubmit={handleLogIn}
          formType="logIn"
        />
      )}
      {screen === 'forgot-password' && (
        <>
          <p className={c.text}>{t('modals.forgotPassword.text')}</p>
          <AuthForm
            onClose={onClose}
            screen="forgot-password"
            className={c.form}
            email={email}
            submitText={t('modals.forgotPassword.submit')}
            isSubmitting={isSendingResetPasswordLink}
            onEmailChange={setEmail}
            onSubmit={handleSendResetPasswordLink}
          />
        </>
      )}
      {screen === 'forgot-password-success' && (
        <p className={c.text}>{t('modals.forgotPasswordSuccess.text')}</p>
      )}
    </Modal>
  )
}

export default LogInModal
