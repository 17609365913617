import { useQuery } from '@tanstack/react-query'
import { getSubscriptions } from 'api'
import { UserResponse } from 'types/User'
import { auth } from 'utils/firebase'

interface Params {
  userId: string
}

const useFetchSubscriptions = ({ userId }: Params) => {
  return useQuery<UserResponse[], Error>(['subscriptions'], async () => {
    const token = await auth.currentUser?.getIdToken()
    if (userId && token) {
      return getSubscriptions(userId)
    }
    return Promise.reject(new Error('Invalid token'))
  })
}

export default useFetchSubscriptions
