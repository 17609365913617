import React, { useEffect, useState } from 'react'
import { ReactComponent as NotifsIcon } from '@material-design-icons/svg/filled/notifications.svg'
import { NavLink } from 'react-router-dom'
import bell from 'components/icons/bell.svg'
import useFetchNotifications from 'hooks/query/useFetchNotifications'

import c from './NotificationBell.module.scss'

function NotificationBell() {
  const { data: notifications } = useFetchNotifications()
  const [unreadNotifsCount, setUnreadNotifsCount] = useState<number>(0)

  useEffect(() => {
    if (notifications) {
      setUnreadNotifsCount(notifications.meta.counts_all_unread)
    }
  }, [notifications])
  return (
    <div className={c.notifications}>
      <img src={bell} alt="" className={c.notif__icon} />

      {notifications &&
        (unreadNotifsCount === 0 || (
          <div className={c.hints}>
            <div
              className={`${c.notif__count} ${
                unreadNotifsCount > 99 && c.three
              } ${unreadNotifsCount <= 99 && unreadNotifsCount >= 10 && c.two}`}
            >
              {unreadNotifsCount}
            </div>
          </div>
        ))}
    </div>
  )
}

export default NotificationBell
