import { useMutation } from '@tanstack/react-query'
import { sendPasswordResetEmail } from 'firebase/auth'
import { auth } from 'utils/firebase'

interface Params {
  onSuccess?: () => void
}

const DEFAULT_PARAMS: Params = {
  onSuccess: undefined,
}

interface Payload {
  email: string
}

const useForgotPassword = (params: Params) => {
  const { onSuccess } = params ?? DEFAULT_PARAMS

  return useMutation<void, Error, Payload>(
    ({ email }) => {
      return sendPasswordResetEmail(auth, email)
    },
    { onSuccess },
  )
}

export default useForgotPassword
