import React from 'react'

import { useNavigate } from 'react-router-dom'
import useFetchSubscribers from 'hooks/query/useFetchSubscribers'
import Container from 'components/common/Container'
import IconButton from 'components/common/IconButton'
import { ReactComponent as ArrowBackIcon } from '@material-design-icons/svg/round/arrow_back_ios.svg'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
import Suber from 'components/Suber'

import s from './SubscribersPage.module.scss'

function SubscribersPage() {
  const { data: myProfileData } = useFetchRequestMyProfile()

  const navigate = useNavigate()

  const { data: subs } = useFetchSubscribers({
    userId: myProfileData?.id!,
  })

  const onGoBack = () => {
    navigate(-1)
  }

  return (
    <Container flex>
      <div className={s.navigationPanel}>
        <IconButton onClick={onGoBack} className={s.goBackButton}>
          <ArrowBackIcon style={{ fill: 'currentColor' }} />
        </IconButton>
      </div>
      <div className={s.subs}>
        <ul className={s.subs__list}>
          {subs &&
            subs.length > 0 &&
            subs.map((sub) => (
              <Suber sub={sub} key={sub.id} type="subscribers" />
            ))}
        </ul>
      </div>
    </Container>
  )
}

export default SubscribersPage
