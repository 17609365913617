import { useInfiniteQuery } from '@tanstack/react-query'
import { getCommentsOnComment } from 'api'
import { CommentsResponse } from 'types/Comments'

const LIMIT = 3

const getOffset = (page: number): number => {
  return (page - 1) * LIMIT
}

interface Params {
  postId: number
  commentId: number
}

const useInfiniteFetchCommentsOnComment = ({ postId, commentId }: Params) => {
  return useInfiniteQuery<CommentsResponse, Error>(
    ['infinite-commentsOnComment', postId, commentId],
   ({ pageParam: page = 1 }) => {
      const offset = getOffset(page)
      return getCommentsOnComment(postId, commentId, LIMIT, offset)
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.items.length === 0) return undefined
        return allPages.length + 1
      },
    },
  )
}

export default useInfiniteFetchCommentsOnComment
