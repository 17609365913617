import { useTranslation } from 'react-i18next'
import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

const useCopy = (text: string) => {
  const { t } = useTranslation()

  const [isCopied, setIsCopied] = useState(false)

  const handleCopy = useCallback(() => {
    try {
      navigator.clipboard.writeText(text)
      setIsCopied(true)
      setTimeout(() => setIsCopied(false), 3000)
    } catch (e) {
      toast.error(t('error.browser'))
    }
  }, [t, text])

  return { isCopied, handleCopy }
}

export default useCopy
