import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CheckIcon } from '@material-design-icons/svg/round/check.svg'
import classNames from 'classnames'

import Button from 'components/common/Button'
import LANGUAGELISTS from 'constants/languageLists'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import { setLanguage } from 'store/reducers/settingsReducer'
import { setSwitchLanguageModalOpen } from 'store/reducers/modalReducer'

import c from './SwitchLanguageModal.module.scss'

interface SwitchLanguageModalProps {
  isOpen: boolean
}

function SwitchLanguageModal({ isOpen }: SwitchLanguageModalProps) {
  const [isScroll, setIsScroll] = useState(false)

  const contentRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const language = useAppSelector((state) => state.settings.language)

  const handleLanguageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setLanguage(e.target.value))
    },
    [dispatch],
  )

  useEffect(() => {
    if (isOpen) {
      const originalStyle = window.getComputedStyle(document.body).overflow
      const originalOverflowX = window.getComputedStyle(document.body).overflowX

      document.body.style.overflow = 'hidden'
      document.body.style.overflowX = 'hidden'

      return () => {
        document.body.style.overflow = originalStyle
        document.body.style.overflowX = originalOverflowX
      }
    }

    return () => null
  }, [isOpen])

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      dispatch(setSwitchLanguageModalOpen(false))
      window.localStorage.removeItem('languageModal')
    },
    [setSwitchLanguageModalOpen],
  )

  useEffect(() => {
    if (contentRef.current) {
      setIsScroll(
        contentRef.current?.getBoundingClientRect().height > window.innerHeight,
      )
    }
  }, [contentRef.current, isOpen])

  return (
    <div className={classNames({ [c.wrapper]: true, [c.isScroll]: isScroll })}>
      <div className={c.container} ref={contentRef}>
        <span className={c.title}>{t('modals.language.title')}</span>
        <ul className={c.list}>
          {LANGUAGELISTS.map((item) => (
            <li key={item.id} className={c.listItem}>
              <label className={c.label} htmlFor={item.code}>
                <input
                  className="sr-only"
                  type="radio"
                  name="language"
                  value={item.code}
                  id={item.code}
                  checked={language === item.code}
                  onChange={handleLanguageChange}
                />
                {item.title}
                {language === item.code && (
                  <CheckIcon width={16} height={16} className={c.checkIcon} />
                )}
              </label>
            </li>
          ))}
        </ul>
        <Button className={c.button} onClick={handleClick}>
          {t('modals.language.buttonText')}
        </Button>
      </div>
    </div>
  )
}

export default SwitchLanguageModal
