import React, { useCallback, useEffect } from 'react'
import debounce from 'lodash.debounce'

function HeightSetter() {
  const setHeight = useCallback(
    debounce(() => {
      const height = window.innerHeight
      document.documentElement.style.setProperty('--height', `${height}px`)
    }, 100),
    [],
  )

  useEffect(() => {
    setHeight()
    window.addEventListener('resize', setHeight)

    return () => {
      window.removeEventListener('resize', setHeight)
    }
  }, [])

  return null
}

export default HeightSetter
