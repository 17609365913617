import React, { useCallback, useState } from 'react'
import Handlers, { HandlerOption } from 'types/Handlers'
import { useTranslation } from 'react-i18next'

import Container from 'components/common/Container'
import AdvancedSettings from '../../../AdvancedSettings'
import c from './IdleStage.module.scss'
import { OutputFormat } from '../../../../types/OutputFormat'
import EnhancementHistory from '../../../EnhancementHistory'
import GenerationSection from '../../../GenerationControls'
import GenerationFixedButton from '../../../GenerationFixedButton'
import { useAppSelector } from '../../../../hooks/store'
import GenerationSelectImages, {
  IItemFile,
} from '../../../GenerationControls/GenerationSelectImages/GenerationSelectImages'

interface IdleStageProps {
  handler: `${Handlers}`
  handlerOptions: HandlerOption[]
  imageUrls: string[]
  outputFormat: OutputFormat
  onOutputFormatChange: (newOutputFormat: OutputFormat) => void
  onProcessingStart: () => void
  setImageFiles: (newFiles: File[]) => void
  setIsAdvancedSettingsOpen: (prev: boolean) => void
  isAdvancedSettingsOpen: boolean
}

function IdleStage({
  imageUrls,
  outputFormat,
  onOutputFormatChange,
  onProcessingStart,
  handler,
  handlerOptions,
  setImageFiles,
  setIsAdvancedSettingsOpen,
  isAdvancedSettingsOpen,
}: IdleStageProps) {
  const { stage } = useAppSelector((state) => state.stage)
  const [isCompleteInputs, setIsCompleteInputs] = useState(false)

  const { t } = useTranslation()

  const handleInputChange = useCallback(
    (items: IItemFile[]) => {
      if (items.length > 0) {
        setImageFiles(items.map((value) => value.file))
        setIsCompleteInputs(true)
      } else {
        setImageFiles([])
        setIsCompleteInputs(false)
      }
    },
    [setImageFiles],
  )

  return (
    <Container flex>
      <div className={c.content}>
        <GenerationSection>
          <GenerationSelectImages onChange={handleInputChange} />

          <GenerationFixedButton
            headingMissing={t('stages.enhancement.idle.buttonText')}
            headingEntered={t(
              'stages.enhancement.preview.buttonTextProcessing',
            )}
            headingLoader={t('stages.enhancement.processing.bannerText')}
            text={imageUrls.length > 0 ? 'Start processing' : ''}
            onProcessingStart={onProcessingStart}
            isDisabled={!isCompleteInputs || stage === 'processing'}
            isProcessing={stage === 'processing'}
          />
        </GenerationSection>

        <AdvancedSettings
          formatImageVisible
          imageUrls={imageUrls}
          outputFormat={outputFormat}
          onOutputFormatChange={onOutputFormatChange}
          isAdvancedSettingsOpen={isAdvancedSettingsOpen}
          setIsAdvancedSettingsOpen={setIsAdvancedSettingsOpen}
        />
      </div>
    </Container>
  )
}

export default IdleStage
