import React, { useMemo } from 'react'
import classNames from 'classnames'
import useSave from 'hooks/useSave'
import { ReactComponent as CheckIcon } from '@material-design-icons/svg/round/check.svg'

import CompareIcon from 'components/icons/CompareIcon'
import CloseIcon from 'components/icons/CloseIcon'
import ZoomPlus from 'components/icons/ZoomPlus'
import ZoomMinus from 'components/icons/ZoomMinus'
import DownloadFileIcon from 'components/icons/DownloadFileIcon'

import c from './LightBoxHeader.module.scss'

interface LightBoxHeaderProps {
  imageUrl: string
  setActive: (value: 'default' | 'show' | 'close') => void
  scale: number
  setScale: (value: number) => void
  isCompare: boolean
  compareHandler: () => void
  sourceUrl?: string
  resetControls: () => void
}

function LightBoxHeader({
  imageUrl,
  setActive,
  scale,
  setScale,
  isCompare,
  compareHandler,
  sourceUrl = '',
  resetControls,
}: LightBoxHeaderProps) {
  const { isSaved, handleSave } = useSave(imageUrl)

  const saveIcon = useMemo(() => {
    return isSaved ? (
      <CheckIcon width={24} height={24} style={{ fill: 'currentColor' }} />
    ) : (
      <DownloadFileIcon width={24} height={24} />
    )
  }, [isSaved])
  return (
    <div
      className={c.header}
      onClick={(event) => event.stopPropagation()}
      onKeyDown={() => {}}
      role="button"
      tabIndex={0}
    >
      {sourceUrl && (
        <button
          type="button"
          onClick={() => {
            compareHandler()
            resetControls()
          }}
        >
          <span
            className={classNames({
              [c.iconSetting]: true,
              [c.active]: isCompare,
            })}
          >
            <CompareIcon />
          </span>
        </button>
      )}
      <button type="button" onClick={handleSave}>
        <span className={c.iconSetting}>{saveIcon}</span>
      </button>
      {!isCompare && (
        <>
          <button
            type="button"
            disabled={scale >= 10}
            onClick={() => setScale(scale + 0.2)}
            className={c.iconButton}
          >
            <span className={c.iconSetting}>
              <ZoomPlus />
            </span>
          </button>
          <button
            type="button"
            disabled={scale <= 0.8}
            onClick={() => setScale(scale - 0.2)}
            className={c.iconButton}
          >
            <span className={c.iconSetting}>
              <ZoomMinus />
            </span>
          </button>
        </>
      )}
      <button type="button" onClick={() => setActive('close')}>
        <span className={c.iconSetting}>
          <CloseIcon />
        </span>
      </button>
    </div>
  )
}

export default LightBoxHeader
