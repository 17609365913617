import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import getLanguage from 'utils/getLanguage'
import ruTranslation from './ru/translation.json'
import enTranslation from './en/translation.json'
import deTranslation from './de/translation.json'
import esTranslation from './es/translation.json'
import frTranslation from './fr/translation.json'
import idTranslation from './id/translation.json'
import hiTranslation from './hi/translation.json'
import itTranslation from './it/translation.json'
import ptTranslation from './pt/translation.json'

export const resources = {
  ru: {
    translation: ruTranslation,
  },
  en: {
    translation: enTranslation,
  },
  de: {
    translation: deTranslation,
  },
  es: {
    translation: esTranslation,
  },
  fr: {
    translation: frTranslation,
  },
  id: {
    translation: idTranslation,
  },
  hi: {
    translation: hiTranslation,
  },
  it: {
    translation: itTranslation,
  },
  pt: {
    translation: ptTranslation,
  },
} as const

const lng = getLanguage()

i18n.use(initReactI18next).init({
  lng,
  interpolation: { escapeValue: false },
  resources,
})

export default i18n
