import React from 'react'
import { useTranslation } from 'react-i18next'
import { Img } from 'react-image'

import { BaseAsset } from '../../types/Response'
import Loader from '../common/Loader/Loader'
import FullScreenIcon from '../icons/FullScreenIcon'
import ShareIcon from '../icons/ShareIcom'
import VariationIcon from '../icons/VariationIcon'

import c from './FinishResultCard.module.scss'

interface FinishResultCardProps {
  asset: BaseAsset
  index: number
  isImageLoaded: { [key: number]: boolean }
  handleImageLoaded: (id: number) => void
  handleFullScreenOpen: (index: number) => void
  shareHandler: (assetId: number) => void
  isShared: { [key: number]: boolean }
  isVideo?: boolean
  isImg?: boolean
  variationsHandler: (assetId: number) => void
}

function FinishResultCard({
  isImg = false,
  isVideo = false,
  asset,
  index,
  isImageLoaded,
  handleImageLoaded,
  handleFullScreenOpen,
  shareHandler,
  isShared,
  variationsHandler,
}: FinishResultCardProps) {
  const { t } = useTranslation()
  return (
    <div className={c.assetContainer}>
      <div className={c.assetImageWrapper}>
        {asset.status === 'success' &&
          (isVideo ? (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video src={asset.url} className={c.assetImage} controls loop />
          ) : (
            <Img
              src={asset.url || 'defaultImageUrl'}
              className={c.assetImage}
              loader={<Loader />}
              onLoad={() => handleImageLoaded(asset.id)}
            />
          ))}
        {asset.status !== 'success' && <Loader />}

        <button
          type="button"
          onClick={() => handleFullScreenOpen(index)}
          className={c.wrapper}
        >
          {isImageLoaded[asset.id] && (
            <div className={c.fullScreenContainer}>
              <div className={c.iconSetting}>
                <FullScreenIcon className={c.iconSetting} />
              </div>
            </div>
          )}
        </button>
      </div>
      <div className={c.assetActions}>
        <button
          type="button"
          disabled={asset.status !== 'success'}
          onClick={() => shareHandler(asset.id)}
          className={c.shareAction}
        >
          <ShareIcon className={c.iconSetting} />
          {isShared[asset.id]
            ? t('general.buttons.publish')
            : t('general.buttons.publish')}
        </button>
        <button
          type="button"
          disabled={asset.status !== 'success'}
          onClick={() => variationsHandler(asset.id)}
          className={c.variationsAction}
        >
          <VariationIcon className={c.iconSetting} />
          {t('stages.generation.finish.variations')}
        </button>
      </div>
    </div>
  )
}

export default FinishResultCard
