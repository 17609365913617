import React, { useMemo } from 'react'
import c from './ImageComparisonFrame.module.scss'
import ImageComparisonSlider from '../ImageComparisonSlider'

interface ImageComparisonFrameProps {
  imageUrl: string
  sourceUrl: string
  size?: { width: number; height: number } | null
  sliderX: number
  scale: number
  originX: number
  originY: number
  onLoad?: () => void
  onLoadError?: () => void
  imageRef: React.RefObject<HTMLDivElement>
  handleSliderPointerDown: (e: React.PointerEvent<HTMLDivElement>) => void
  handleSliderTouchStart: (e: React.TouchEvent<HTMLDivElement>) => void
}

function ImageComparisonFrame({
  imageUrl,
  sourceUrl,
  size = null,
  sliderX,
  scale,
  originX,
  originY,
  onLoad = undefined,
  onLoadError = undefined,
  imageRef,
  handleSliderPointerDown,
  handleSliderTouchStart,
}: ImageComparisonFrameProps) {
  const beforeClipStyles = useMemo(() => {
    return {
      clip: `rect(0px ${sliderX}px auto 0px)`,
    }
  }, [sliderX, scale])

  const afterClipStyles = useMemo(() => {
    return {
      clip: `rect(0px auto auto ${sliderX}px)`,
    }
  }, [sliderX, scale])

  const getTransform = useMemo(() => {
    if (size && size.width > 0 && size.height > 0) {
      return {
        transform: `translate(${originX}px, ${originY}px)`,
        width: size.width * scale,
        height: size.height * scale,
      }
    }
    return {}
  }, [originX, originY, scale, size])

  return (
    <div className={c.outerWrapper} ref={imageRef} style={getTransform}>
      <div className={c.hidden} />
      <div className={c.innerWrapper} style={beforeClipStyles}>
        <div className={c.imageContainer}>
          <img
            className={c.image}
            alt=""
            src={sourceUrl}
            onLoad={onLoad}
            onError={onLoadError}
          />
        </div>
      </div>
      <div className={c.innerWrapper} style={afterClipStyles}>
        <div className={c.imageContainer}>
          <img
            className={c.image}
            alt=""
            src={imageUrl}
            onLoad={onLoad}
            onError={onLoadError}
          />
        </div>
      </div>
      <ImageComparisonSlider
        originY={originY}
        sliderX={sliderX}
        scale={scale}
        handlePointerDown={handleSliderPointerDown}
        handleTouchStart={handleSliderTouchStart}
      />
    </div>
  )
}

export default ImageComparisonFrame
