import React, { useCallback, useEffect, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Img } from 'react-image'
import { toast } from 'react-toastify'

import Loader from 'components/common/Loader'
import DeleteIcon from 'components/icons/DeleteIcon'
import { ConceptsItemFile } from 'types/Concepts'
import { deleteConcept, deleteConceptItems } from 'api'
import FullScreenIcon from 'components/icons/FullScreenIcon'
import LightBox from 'components/LightBox'

import c from './PhotosCard.module.scss'

interface PhotosCardProps {
  data: ConceptsItemFile
  isChecked: boolean
  handleCheckboxChange: (id: number) => void
  setImageFiles: React.Dispatch<React.SetStateAction<(File | undefined)[]>>
  isConceptPhoto?: boolean
  conceptId?: number
}

function PhotosCard({
  data,
  isChecked,
  handleCheckboxChange,
  setImageFiles,
  isConceptPhoto = false,
  conceptId = 0,
}: PhotosCardProps) {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)
  const queryClient = useQueryClient()

  const mutationDelete = useMutation<void, Error>(() => {
    return deleteConceptItems(conceptId, [data.id])
  })

  const deletePhoto = useCallback(() => {
    const result = window.confirm('Are you sure want to delete this album?')
    if (result) {
      mutationDelete.mutateAsync().then(() => {
        queryClient.invalidateQueries([`conceptData`, data.id])
        queryClient.refetchQueries([`concept`, conceptId]).then(() => {
          toast.success('Everything went well')
        })
      })
    }
  }, [])

  const deleteYourPhoto = useCallback((id: number) => {
    setImageFiles((prevFiles) => {
      const newFiles = [...prevFiles]
      newFiles.splice(id, 1)
      return newFiles.length > 0 ? newFiles : []
    })
  }, [])

  const handleFullScreenOpen = () => {
    setIsLightboxOpen(true)
  }

  const handleFullScreenClose = () => {
    setIsLightboxOpen(false)
  }

  return (
    <div
      className={c.wrapper}
      style={{ zIndex: isLightboxOpen ? '1000' : 'auto' }}
    >
      <div className={c.imgWrapper}>
        <Img
          className={c.image}
          src={data.link}
          loader={
            <div className={c.loaderWrapper}>
              <Loader />
            </div>
          }
        />
        <button type="button" onClick={handleFullScreenOpen}>
          <div className={c.fullScreenContainer}>
            <div className={c.iconSetting}>
              <FullScreenIcon className={c.iconSetting} />
            </div>
          </div>
        </button>
      </div>
      <div className={c.conceptFooter}>
        <input
          type="checkbox"
          className={c.checkbox}
          onChange={() => handleCheckboxChange(data.id)}
          checked={isChecked}
        />
        <button
          type="button"
          onClick={
            isConceptPhoto ? deletePhoto : () => deleteYourPhoto(data.id)
          }
          className={c.actions}
        >
          <DeleteIcon />
        </button>
      </div>
      {isLightboxOpen && (
        <LightBox
          imageUrl={data.link || 'default'}
          onCloseRequest={handleFullScreenClose}
        />
      )}
    </div>
  )
}

export default PhotosCard
