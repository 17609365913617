import { useInfiniteQuery } from '@tanstack/react-query'

import { useAppSelector } from 'hooks/store'
import { getPlugins } from 'api/plugins'
import { PluginPostFeed } from 'types/Plugins'

interface Params {
  take?: number
  categoryId?: number
  orderBy: string
  enabled?: boolean
}

function useInfiniteFetchPlugins({
  take = 10,
  categoryId,
  orderBy = 'likes',
  enabled = true,
}: Params) {
  const deviceId = useAppSelector((state) => state.settings.deviceId)
  const userId = useAppSelector((state) => state.auth.user?.uid)

  const deps = [deviceId, userId, categoryId, orderBy]

  return useInfiniteQuery<{ items: PluginPostFeed[] }>(
    ['infinite-plugins', deps],
    async ({ pageParam: page = 1 }) => {
      return getPlugins(page, take, categoryId, orderBy)
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (!lastPage || lastPage.items.length < take) return undefined
        return allPages.length + 1
      },
      enabled,
    },
  )
}

export default useInfiniteFetchPlugins
