import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CheckedItems } from 'pages/ProfileArchivePage/ProfileArchivePage'
import ArchiveCard from '../ArchiveCard'
import { BaseResponse } from '../../types/Response'

import c from './ArchiveContent.module.scss'

interface ArchiveContentProps {
  archiveData: BaseResponse[]
  checkedAssets: {
    [key: number]: boolean
  }
  setCheckedAssets: React.Dispatch<
    React.SetStateAction<{
      [key: number]: boolean
    }>
  >
  isDeleting: boolean
  selectedToDelete: number[]
  setIsDeleting: (value: boolean | ((prevValue: boolean) => boolean)) => void
  selectToDelete: (id: number) => void
  checkedItems: CheckedItems
  setCheckedItems: React.Dispatch<React.SetStateAction<CheckedItems>>
}

function ArchiveContent({
  archiveData,
  checkedAssets,
  setCheckedAssets,
  isDeleting,
  setIsDeleting,
  selectToDelete,
  selectedToDelete,
  checkedItems,
  setCheckedItems,
}: ArchiveContentProps) {
  const navigate = useNavigate()

  const onGoBack = useCallback(() => navigate(-1), [navigate])
  const isImageLoadedInitial = useMemo(
    () =>
      archiveData.reduce((acc, asset) => ({ ...acc, [asset.id]: false }), {}),
    [archiveData],
  )

  const [isImageLoaded, setIsImageLoaded] = useState<{
    [key: number]: boolean
  }>(isImageLoadedInitial)

  const handleImageLoaded = useCallback((assetId: number) => {
    setIsImageLoaded((prevState) => ({ ...prevState, [assetId]: true }))
  }, [])

  const handleCheckboxChange = (id: number, idData: number) => {
    selectToDelete(idData)
    setCheckedAssets((prev) => ({
      ...prev,
      [id]: !prev[id],
    }))
    setCheckedItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }))
  }

  useEffect(() => {
    const isAnyItemChecked = Object.values(checkedItems).some((value) => value)
    setIsDeleting(isAnyItemChecked)
  }, [checkedItems])

  return (
    <div className={c.grid}>
      {archiveData.map((response: BaseResponse, index) => (
        <ArchiveCard
          data={response}
          setIsDeleting={setIsDeleting}
          archiveData={archiveData}
          selectToDelete={selectToDelete}
          isDeleting={isDeleting}
          isChecked={checkedAssets[response.id] || false}
          handleCheckboxChange={() =>
            handleCheckboxChange(response.id, response.id)
          }
          selectedToDelete={selectedToDelete}
          key={response.id}
          asset={response}
          index={index}
          isImageLoaded={isImageLoaded}
          handleImageLoaded={handleImageLoaded}
          isSelected={Object.entries(checkedItems).some((item) => {
            if (item[1]) return true
            return false
          })}
        />
      ))}
    </div>
  )
}

export default ArchiveContent
