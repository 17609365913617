import React from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import c from './DeleteButton.module.scss'

interface DeleteButtonProps {
  isDeleting: boolean
  handleMassDelete: () => void
  handleDeleteToggle: () => void
  text?: string
  selectedToDelete?: number[]
  className?: string
  checkedAssets?: {
    [key: number]: boolean
  }
}

function DeleteButton({
  isDeleting,
  checkedAssets = {},
  handleDeleteToggle,
  handleMassDelete,
  selectedToDelete = [],
  text = undefined,
  className = '',
}: DeleteButtonProps) {
  const { t } = useTranslation()

  return selectedToDelete.length > 0 ? (
    <div
      className={classNames({
        [c.deleteButton]: true,
        [className]: true,
      })}
    >
      <div className={c.content}>
        <div className={c.chooseToDelete}>
          <button
            type="button"
            className={c.clear}
            onClick={handleDeleteToggle}
          >
            {t('stages.enhancement.idle.clear')}
          </button>
          <button
            type="button"
            className={c.massDeletion}
            onClick={handleMassDelete}
          >
            {t('profile.delete')} {selectedToDelete.length}{' '}
            {t('stages.enhancement.idle.items')}
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className={c.hidden}>werwe</div>
  )
}

export default DeleteButton
