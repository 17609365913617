import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash.debounce'
import { ReactComponent as FocusIcon } from '@material-design-icons/svg/round/filter_center_focus.svg'
import IconButton from 'components/common/IconButton'
import useGestures from 'hooks/useGestures'
import {
  HandleImagePositionEffectX,
  HandleImagePositionEffectY,
} from 'utils/handleImagePositionEffect'
import ImageComparisonFrame from '../ImageComparisonFrame'
import ImageComparisonSlider from '../ImageComparisonSlider'
import c from './ImageComparisonSpace.module.scss'

interface ImageComparisonSpaceProps {
  imageUrl: string
  sourceUrl: string
  onLoad?: () => void
  onLoadError?: () => void
  imageSize?: { width: number; height: number } | null
}

function ImageComparisonSpace({
  imageUrl,
  sourceUrl,
  onLoad = undefined,
  onLoadError = undefined,
  imageSize = null,
}: ImageComparisonSpaceProps) {
  const [wrapperRect, setWrapperRect] = useState<DOMRect | undefined>(undefined)

  const wrapperRef = useRef<HTMLDivElement>(null)
  const imageWrapperRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()

  const handleWrapperRectChange = useCallback(
    debounce(() => {
      const rect = wrapperRef.current?.getBoundingClientRect()
      setWrapperRect(rect)
    }, 100),
    [wrapperRef.current],
  )

  useEffect(() => {
    handleWrapperRectChange()
    window.addEventListener('resize', handleWrapperRectChange)

    return () => {
      window.removeEventListener('resize', handleWrapperRectChange)
    }
  }, [])

  const {
    sliderX,
    originX,
    originY,
    scale,
    currentGesture,
    isControlsDirty,
    resetControls,
    handleWrapperPointerDown,
    handleWrapperTouchStart,
    handleWrapperWheel,
    handleSliderPointerDown,
    handleSliderTouchStart,
    handleChangeOrigin,
  } = useGestures(wrapperRect, imageSize, imageWrapperRef)

  useEffect(() => {
    HandleImagePositionEffectY({ imageWrapperRef, originY, handleChangeOrigin })
  }, [originY, imageWrapperRef.current, imageSize])

  useEffect(() => {
    HandleImagePositionEffectX({ imageWrapperRef, originX, handleChangeOrigin })
  }, [originX, imageWrapperRef.current, imageSize])

  const isDragging = useMemo(
    () => currentGesture === 'dragging',
    [currentGesture],
  )

  useEffect(() => {
    if (wrapperRef.current && imageSize) {
      const posX = window.innerWidth / 2 - imageSize.width / 2
      const posY = window.innerHeight / 2 - imageSize.height / 2

      handleChangeOrigin({
        x: posX,
        y: posY,
      })
    }
  }, [imageSize])

  return (
    <div
      ref={wrapperRef}
      className={c.wrapper}
      onWheel={handleWrapperWheel}
      onPointerDown={handleWrapperPointerDown}
      onTouchStart={handleWrapperTouchStart}
    >
      <ImageComparisonFrame
        size={imageSize}
        imageUrl={imageUrl}
        sourceUrl={sourceUrl}
        sliderX={sliderX}
        scale={scale}
        originX={originX}
        originY={originY}
        onLoad={onLoad}
        onLoadError={onLoadError}
        imageRef={imageWrapperRef}
        handleSliderPointerDown={handleSliderPointerDown}
        handleSliderTouchStart={handleSliderTouchStart}
      />
      <IconButton
        disabled={!isControlsDirty || isDragging}
        className={c.resetButton}
        onClick={resetControls}
      >
        <FocusIcon style={{ fill: 'currentColor' }} />
      </IconButton>
    </div>
  )
}

export default ImageComparisonSpace
