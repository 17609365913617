import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import navigationModalItems from 'constants/navigationModalItems'

import c from './NavigationModal.module.scss'

interface NavigationModalProps {
  variant: 'artGeneration' | 'photoProcessing'
  setModal: React.Dispatch<
    React.SetStateAction<{ isArt: boolean; isPhoto: boolean }>
  >
}

function NavigationModal({ variant, setModal }: NavigationModalProps) {
  const { ArtGenerationItems, PhotoProcessingItems } = navigationModalItems()
  const [navItems, setNavItems] = useState(
    variant === 'artGeneration' ? ArtGenerationItems : PhotoProcessingItems,
  )
  const language = useTranslation()

  useEffect(() => {
    setNavItems(
      variant === 'artGeneration' ? ArtGenerationItems : PhotoProcessingItems,
    )
  }, [language[1].language])

  return (
    <ul className={c.wrapperList}>
      {navItems &&
        navItems.map((item) => (
          <li className={c.listItem} key={item.id}>
            <div className={c.header}>
              <img className={c.image} src={item.icon} alt={item.title} />
              <p className={c.title}>{item.title}</p>
            </div>
            <p className={c.text}>{item.desc}</p>
            <NavLink
              to={item.link}
              end
              className={c.link}
              onClick={() => setModal({ isArt: false, isPhoto: false })}
            />
          </li>
        ))}
    </ul>
  )
}

export default NavigationModal
