import React, { useEffect, useMemo, useState } from 'react'

import HeaderChatMessages from 'components/HeaderChatMessages'
import { useParams, useRevalidator } from 'react-router-dom'
import ChatInputMessage from 'components/ChatInputMessage'
import ChatTextBox from 'components/ChatTextBox'
import useInfiniteFetchMessages from '../../hooks/query/useInfiniteFetchMessages'
import { MessageItem, ThemesResponse } from '../../types/Chats'
import StartChatText from '../StartChatText'
import c from './ChatMessages.module.scss'
import {
  IEventMessageChunk,
  useWebSocketContext,
} from '../../providers/WebSocket'
import { UserResponse } from '../../types/User'
import useDidMountEffect from '../../hooks/useDidMountEffect'
import useFetchChatThemes from '../../hooks/query/useFetchChatThemes'

interface ChatMessagesProps {
  activeChat: MessageItem | undefined
  isMobile: boolean
  onGoBack: () => void
  onNewMessage: (message: MessageItem) => void
  onNewMessageChunk: (message: IEventMessageChunk) => void
  user: UserResponse | null | undefined
}

function ChatMessages({
  activeChat,
  isMobile,
  onNewMessageChunk,
  onNewMessage,
  onGoBack,
  user,
}: ChatMessagesProps) {
  const { eventMessage, socket } = useWebSocketContext()

  const [messages, setMessages] = useState<MessageItem[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isWriting, setIsWriting] = useState<boolean>(false)
  const [activeTheme, setActiveTheme] = useState<ThemesResponse | undefined>(
    undefined,
  )
  const { id } = useParams()

  const { data: infiniteMessagesData, refetch: refetchMessages } =
    useInfiniteFetchMessages({
      recipientId: id!,
      theme: activeTheme?.id,
    })

  const { data: themesData, refetch: refetchThemes } = useFetchChatThemes({
    recipientId: Number(id),
  })

  useEffect(() => {
    setMessages([])
  }, [id])

  useEffect(() => {
    if (
      (activeChat && activeTheme?.id) ||
      (activeTheme === undefined && activeChat)
    ) {
      setMessages([])
      refetchMessages()
      refetchThemes()
    }
  }, [activeTheme])

  useEffect(() => {
    if (infiniteMessagesData) {
      const combinedItems = infiniteMessagesData.pages.flatMap((p) => p.items)

      setMessages((prevMessages) => {
        const uniqueMessages = new Set(
          prevMessages.map((message) => message.id),
        )
        const filteredItems = combinedItems
          .reverse()
          .filter((item) => !uniqueMessages.has(item.id))

        return [...prevMessages, ...filteredItems]
      })
    }
  }, [infiniteMessagesData])

  useDidMountEffect(() => {
    if (socket && eventMessage) {
      if (
        eventMessage.sender.id.toString() === id ||
        eventMessage.sender.id === user?.id
      ) {
        onNewMessage(eventMessage)
        const newMessage = {
          ...eventMessage,
          created_at: eventMessage.created_at || new Date().toISOString(),
        }
        setMessages((prevMessages) => [...prevMessages, newMessage])
      }
    }
  }, [eventMessage, socket])

  useDidMountEffect(() => {
    if (socket) {
      let index = 0
      socket.on('chatMessageChunk', (message: IEventMessageChunk) => {
        index += 1
        setTimeout(() => {
          if (message.isLast) {
            index = 0
          }
          setIsWriting(!message.isLast)
          setMessages((prevMessages) =>
            prevMessages.map((mess) => {
              if (mess.id === message.id) {
                return { ...mess, text: mess.text + message.text }
              }
              return mess
            }),
          )
        }, 50 * index)
      })
    }
  }, [socket])

  useEffect(() => {
    const isLastMessageEmpty =
      messages.length > 0 && messages[messages.length - 1].text === ''
    setIsLoading(isLastMessageEmpty)
  }, [messages])

  return (
    <div className={c.container}>
      <HeaderChatMessages
        isMobile={isMobile}
        onGoBack={onGoBack}
        themesData={themesData}
        theme={activeTheme}
        setTheme={setActiveTheme}
      />
      <div className={c.chatTextBox}>
        <ChatTextBox messagesData={messages} isLoading={isLoading} />
      </div>
      {messages && messages.length === 0 && id && <StartChatText />}
      {id && (
        <div className={c.chatInput}>
          <ChatInputMessage
            theme={activeTheme}
            isWriting={isWriting || isLoading}
          />
        </div>
      )}
    </div>
  )
}

export default ChatMessages
