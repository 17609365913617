import React, { useEffect, useState } from 'react'
import { Notification as NotificationType } from 'types/Notifications'
import { NavLink } from 'react-router-dom'

import s from './NotificationStart.module.scss'

interface NotificationStartProps {
  notification: NotificationType
}

function NotificationStart({ notification }: NotificationStartProps) {
  const [showUnviewed, setShowUnviewed] = useState(!notification.is_viewed)

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!notification.is_viewed) {
      const timerId = setTimeout(() => {
        setShowUnviewed(false)
      }, 3000)

      return () => clearTimeout(timerId)
    }
  }, [notification.is_viewed])
  return (
    <div className={s.fromUserAvatar}>
      <div className={s.imageWrapper}>
        <img src={notification.from_user.avatar} alt="" />
      </div>
      {showUnviewed && <div className={s.unViewed} />}
      <NavLink
        className={s.link}
        end
        to={`/profile/${notification.from_user.nickname}`}
      />
    </div>
  )
}

export default NotificationStart
