import React from 'react'

// eslint-disable-next-line react/require-default-props
function DownloadIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <path
        d="M6.28571 19.5C3.91878 19.5 2 17.6038 2 15.2647C2 12.9256 3.91878 11.0294 6.28571 11.0294C6.56983 11.0294 6.8475 11.0567 7.11616 11.1089M7.11616 11.1089C6.88706 10.4978 6.7619 9.83687 6.7619 9.14706C6.7619 6.02827 9.32028 3.5 12.4762 3.5C15.4159 3.5 17.8371 5.69371 18.1551 8.51498M7.11616 11.1089C7.68059 11.2184 8.20528 11.4374 8.66667 11.7426M14.381 8.52721C14.9767 8.31911 15.6178 8.20588 16.2857 8.20588C16.9404 8.20588 17.5693 8.31468 18.1551 8.51498M18.1551 8.51498C20.393 9.28024 22 11.3811 22 13.8529C22 16.5599 20.0726 18.8221 17.5 19.3722"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path
        d="M12 16.5V22.5M12 16.5L14 18.5M12 16.5L10 18.5"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DownloadIcon
