import { useInfiniteQuery } from '@tanstack/react-query'
import { getConcepts } from 'api'
import { ConceptsResponse } from 'types/Concepts'
import { auth } from 'utils/firebase'

const LIMIT = 8

const getOffset = (page: number): number => {
  return (page - 1) * LIMIT
}

const useInfiniteFetchConcepts = () => {
  return useInfiniteQuery<ConceptsResponse, Error>(
    [`infinite-concepts`],
    async ({ pageParam: page = 1 }) => {
      const offset = getOffset(page)

      const token = await auth.currentUser?.getIdToken()
      if (token) {
        return getConcepts(LIMIT, offset)
      }
      return Promise.reject(new Error('Invalid token'))
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.items.length === 0) return undefined
        return allPages.length + 1
      },
    },
  )
}

export default useInfiniteFetchConcepts
