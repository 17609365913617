import React, { useMemo } from 'react'
import { ReactComponent as ArrowLeftIcon } from '@material-design-icons/svg/round/arrow_left.svg'
import { ReactComponent as ArrowRightIcon } from '@material-design-icons/svg/round/arrow_right.svg'
import c from './ImageComparisonSlider.module.scss'

interface ImageComparisonSliderProps {
  originY: number
  sliderX: number
  scale: number
  handlePointerDown: (e: React.PointerEvent<HTMLDivElement>) => void
  handleTouchStart: (e: React.TouchEvent<HTMLDivElement>) => void
}

function ImageComparisonSlider({
  originY,
  sliderX,
  scale,
  handlePointerDown,
  handleTouchStart,
}: ImageComparisonSliderProps) {
  const sliderStyles = useMemo(() => {
    return {
      transform: `translateX(${sliderX}px)`,
    }
  }, [sliderX])

  return (
    <div
      className={c.slider}
      style={sliderStyles}
      onPointerDown={handlePointerDown}
      onTouchStart={handleTouchStart}
    >
      <div className={c.sliderRail} />
      <div
        className={c.sliderButton}
        style={
          scale >= 1
            ? {
                top: `${window.innerHeight - originY - 50}px`,
              }
            : { bottom: '20px' }
        }
      >
        <ArrowLeftIcon className={`${c.sliderArrow} ${c.sliderArrowLeft}`} />
        <ArrowRightIcon className={`${c.sliderArrow} ${c.sliderArrowRight}`} />
      </div>
    </div>
  )
}

export default ImageComparisonSlider
