import { EventEmitter } from 'events'
import { FormDataObject } from 'types/FormDataObject'
import { BaseResponse } from 'types/Response'
import AssetsCollector from '../AssetsCollector'

export default abstract class Strategy {
  public emitter: EventEmitter

  public collector: AssetsCollector | undefined

  protected constructor() {
    this.emitter = new EventEmitter()
  }

  public async start(): Promise<void> {
    throw new Error('Void method start() of abstract class Strategy')
  }

  public async repeat(): Promise<void> {
    throw new Error('Void method repeat() of abstract class Strategy')
  }

  public stop(): void {
    throw new Error('Void method stop() of abstract class Strategy')
  }

  public async makeRequest(): Promise<BaseResponse> {
    throw new Error('Void method makeRequest() of abstract class Strategy')
  }

  public async makeRequestRepeat(): Promise<BaseResponse> {
    throw new Error(
      'Void method makeRequestRepeat() of abstract class Strategy',
    )
  }

  protected async collectAssets(
    requestId: number,
    expectedAssetsLength: number,
  ): Promise<void> {
    const collector = new AssetsCollector(requestId, expectedAssetsLength)
    collector.setEmitter(this.emitter)

    this.collector = collector

    await collector.start()
  }

  protected assembleFormData(object: FormDataObject): FormData {
    const formData = new FormData()
    Object.keys(object).forEach((fieldName) => {
      if (object[fieldName]) formData.append(fieldName, object[fieldName])
    })
    return formData
  }
}
