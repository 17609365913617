import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import ScrollContainer from 'react-indiana-drag-scroll'

import { EnhancementResponse } from 'types/Response'
import AddImage from 'components/AddImage'

import c from './PhotoSelection.module.scss'

interface PhotoSelectionProps {
  generationHistoryData?: EnhancementResponse[]
  chosenHistory?: number
  imageUrl: string | null | undefined
  handleHistoryClick?: (id: number | undefined) => void
  onImageChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onDeleteImage?: () => void
}

function PhotoSelection({
  generationHistoryData = [],
  chosenHistory = undefined,
  imageUrl,
  handleHistoryClick = () => {},
  onImageChange = undefined,
  onDeleteImage = undefined,
}: PhotoSelectionProps) {
  const { assetId } = useParams()
  const { t } = useTranslation()

  return (
    <div className={c.photoSelection}>
      <p className={c.heading}>{t('stages.variations.idle.addImage')}</p>
      <ScrollContainer className={c.historyListWrapper}>
        <ul className={c.historyList}>
          {imageUrl ? (
            <AddImage
              imageUrl={imageUrl}
              onChange={onImageChange}
              onDeleteImage={onDeleteImage}
              className={c.wrapper}
              chosenHistory={chosenHistory}
              handleHistoryClick={handleHistoryClick}
            />
          ) : (
            <AddImage onChange={onImageChange} className={c.wrapper} />
          )}
          {generationHistoryData.length > 0 &&
            generationHistoryData.map((elem) => {
              const asset = elem.assets.find(
                (item) => item.id === Number(assetId),
              )
              if (asset && asset.url) {
                return (
                  <button
                    key={asset.id}
                    className={`${c.wrapper} ${
                      chosenHistory === asset.id ? c.active : ''
                    }`}
                    type="button"
                    onClick={() => handleHistoryClick(asset.id)}
                  >
                    <img src={asset.url} alt="" />
                  </button>
                )
              }
              if (!elem.assets[0].url) return null
              return (
                <button
                  key={elem.assets[0]?.id}
                  className={`${c.wrapper} ${
                    chosenHistory === elem.assets[0]?.id ? c.active : ''
                  }`}
                  type="button"
                  onClick={() => handleHistoryClick(elem.assets[0]?.id)}
                >
                  <img src={elem.assets[0].url} alt="" />
                </button>
              )
            })}
        </ul>
      </ScrollContainer>
    </div>
  )
}

export default PhotoSelection
