import { useInfiniteQuery } from '@tanstack/react-query'
import { getLikedPosts } from 'api'
import { useAppSelector } from 'hooks/store'
import { LikedPostResponse } from 'types/Post'

const LIMIT = 8

const getOffset = (page: number): number => {
  return (page - 1) * LIMIT
}

const useInfiniteFetchLikedPosts = () => {
  const deviceId = useAppSelector((state) => state.settings.deviceId)
  const userId = useAppSelector((state) => state.auth.user?.uid)

  const deps = [deviceId, userId]

  return useInfiniteQuery<LikedPostResponse, Error>(
    [`infinite-likedPosts-${userId}`, deps],
    async ({ pageParam: page = 1 }) => {
      const offset = getOffset(page)
      return getLikedPosts(LIMIT, offset)
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.items.length === 0) return undefined
        return allPages.length + 1
      },
    },
  )
}

export default useInfiniteFetchLikedPosts
