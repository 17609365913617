import { UserInfo } from 'firebase/auth'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UserData {
  is_paid_subscription: boolean
}

interface AuthState {
  user: UserInfo | null
  isAuthInitialized: boolean
  userData: UserData | null
}

const initialState: AuthState = {
  user: null,
  isAuthInitialized: false,
  userData: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserInfo | null>) => {
      state.user = action.payload
    },
    initialize: (state) => {
      state.isAuthInitialized = true
    },
    setUserInfo: (state, action: PayloadAction<UserData | null>) => {
      state.userData = action.payload
    },
  },
})

export const { setUser, initialize, setUserInfo } = authSlice.actions

export default authSlice.reducer
