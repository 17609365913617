import React from 'react'

// eslint-disable-next-line react/require-default-props
function SortLikesIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path d="M20 7H3H20Z" fill="white" />
      <path
        d="M20 7H3"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path d="M10 17H3H10Z" fill="white" />
      <path
        d="M10 17H3"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path d="M9 12H3H9Z" fill="white" />
      <path
        d="M9 12H3"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path
        d="M16.5 11.5959L16.0407 12.0467C16.1616 12.1705 16.3271 12.2402 16.5 12.2402C16.6729 12.2402 16.8384 12.1705 16.9593 12.0467L16.5 11.5959ZM16.0157 16.4056C15.4641 15.9981 14.7579 15.4196 14.1958 14.7762C13.6138 14.11 13.2857 13.4862 13.2857 12.9779H12C12 13.9753 12.5991 14.9046 13.2285 15.6252C13.8779 16.3686 14.6673 17.0104 15.2529 17.443L16.0157 16.4056ZM13.2857 12.9779C13.2857 12.0261 13.7096 11.525 14.1457 11.3616C14.589 11.1954 15.2958 11.2841 16.0407 12.0467L16.9593 11.145C15.9686 10.1308 14.7468 9.76043 13.6954 10.1545C12.6369 10.5512 12 11.6295 12 12.9779H13.2857ZM17.7471 17.443C18.3327 17.0104 19.1221 16.3686 19.7715 15.6252C20.4009 14.9046 21 13.9753 21 12.9779H19.7143C19.7143 13.4862 19.3862 14.1101 18.8042 14.7762C18.2421 15.4196 17.5359 15.9981 16.9843 16.4056L17.7471 17.443ZM21 12.9779C21 11.6295 20.3631 10.5512 19.3046 10.1545C18.2532 9.76044 17.0314 10.1308 16.0407 11.145L16.9593 12.0467C17.7042 11.2841 18.411 11.1954 18.8543 11.3616C19.2904 11.525 19.7143 12.0261 19.7143 12.9779H21ZM15.2529 17.443C15.5887 17.6911 15.9645 18 16.5 18V16.7113C16.4873 16.7113 16.4727 16.7133 16.4134 16.6811C16.3293 16.6354 16.224 16.5595 16.0157 16.4056L15.2529 17.443ZM16.9843 16.4056C16.776 16.5595 16.6707 16.6354 16.5866 16.6811C16.5273 16.7133 16.5127 16.7113 16.5 16.7113V18C17.0355 18 17.4113 17.6911 17.7471 17.443L16.9843 16.4056Z"
        fill="white"
      />
    </svg>
  )
}

export default SortLikesIcon
