import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import useFetchRequest from 'hooks/query/useFetchRequest'
import EnhancementCard from '../../components/EnhancementCard'
import { BaseAsset } from '../../types/Response'
import c from './EnhancementFinishPage.module.scss'
import { useAppSelector } from '../../hooks/store'
import MobileHeader from '../../components/MobileHeader'

function EnhancementFinishPage(): JSX.Element {
  const { assetId } = useParams()

  const { stage } = useAppSelector((state) => state.stage)

  const location = useLocation()

  const { data, isSuccess } = useFetchRequest({
    requestId: assetId,
  })

  const [cards, setCards] = useState<Array<Array<BaseAsset>>>([])

  const [heading, setHeading] = useState<string>('')

  const { t } = useTranslation()

  const imageUrls = useMemo(
    () =>
      (isSuccess && data.assets.map((asset) => asset.source).filter(Boolean)) ||
      [],
    [data?.assets, isSuccess],
  )

  useEffect(() => {
    if (location.pathname.includes('face-restoration')) {
      setHeading(t('navigation.restoration'))
    } else if (location.pathname.includes('enhancement')) {
      setHeading(t('navigation.enhancement'))
    } else if (location.pathname.includes('background-remover')) {
      setHeading(t('navigation.remover'))
    } else if (location.pathname.includes('ai-inpaint')) {
      setHeading(t('navigation.aiInpaint'))
    } else {
      setHeading('nothing')
    }
  }, [location.pathname, t])

  const [isImageLoaded, setIsImageLoaded] = useState<{
    [key: number]: boolean
  }>(
    data?.assets.reduce((acc, asset) => ({ ...acc, [asset.id]: false }), {}) ||
      {},
  )
  const [isShared, setIsShared] = useState<{ [key: number]: boolean }>({})

  useEffect(() => {
    const uniquePostIds = new Set()

    const arr: BaseAsset[][] = []

    data?.assets.forEach((elem: BaseAsset, index: number) => {
      if (!uniquePostIds.has(elem.id)) {
        uniquePostIds.add(elem.id)

        if (!arr[index % 2]) arr[index % 2] = []

        arr[index % 2].push(elem)
      }
    })

    setCards(arr)
  }, [data])

  const shareHandler = (idAsset: number) => {
    console.log(idAsset)
  }

  const handleImageLoaded = useCallback((idAsset: number) => {
    setIsImageLoaded((prevState) => ({ ...prevState, [idAsset]: true }))
  }, [])

  if (!data) return <div />

  return stage !== 'processing' ? (
    <div className={c.cardsContainer}>
      <MobileHeader heading={heading} isOnGoBack />
      {cards.map((container, containerIndex) => (
        <div
          className={c.secondColumn}
          key={`${new Date(containerIndex).getTime().toString()}`}
        >
          {container.map((asset, assetIndex) => (
            <EnhancementCard
              key={`${new Date(assetIndex).getTime().toString()}`}
              isImageLoaded={isImageLoaded}
              handleImageLoaded={handleImageLoaded}
              imageUrls={imageUrls}
              asset={asset}
              index={assetIndex}
              isShared={isShared}
              shareHandler={shareHandler}
            />
          ))}
        </div>
      ))}
    </div>
  ) : (
    <div />
  )
}

export default EnhancementFinishPage
