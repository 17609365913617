import React from 'react'
import { useTranslation } from 'react-i18next'
import VerifiedIcon from '../VerifiedIcon'
import AppStoreIcon from '../icons/AppStoreIcon'
import GooglePlayIcon from '../icons/GooglePlayIcon'
import c from './UnPaidSubscriptionContent.module.scss'

interface UnPaidSubscriptionContentProps{
  text?: 'aiChat.chatAccess'
}

export default function UnPaidSubscriptionContent({text='aiChat.chatAccess'}:UnPaidSubscriptionContentProps) {
  const { t } = useTranslation()

  return (
    <div className={c.content}>
      <div className={c.content__UnPaidSubscription}>
        <div className={c.content__TextContainer}>
          <div className={c.content__PremuimContainer}>
            <VerifiedIcon
              className={c.iconSetting}
              style={{ width: '24px', height: '24px' }}
            />
            {t('profile.updateTo')}
            <span className={c.content__textPremium}>Premium</span>
          </div>
          <div className={c.content__Note}>{text && t(text)}</div>
        </div>
        <div className={c.content__Integration}>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://apps.apple.com/us/app/magicly-ai-art-photo/id1574190211"
          >
            <div className={c.content__Integration__Item}>
              <AppStoreIcon className={c.iconSetting} />
              <span className={c.content__Intergration__Text}>App Store</span>
            </div>
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.retomagic.client&pcampaignid=web_share"
          >
            <div className={c.content__Integration__Item}>
              <GooglePlayIcon className={c.iconSetting} />
              <span className={c.content__Intergration__Text}>Google Play</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}