import React from 'react'

// eslint-disable-next-line react/require-default-props
function ReadedMessageIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none" {...props}>
            <g clipPath="url(#clip0_5878_15945)">
                <path d="M11.8054 0.579701C11.5595 0.359701 11.1701 0.369701 10.9446 0.609701L5.66708 6.1297L5.39039 5.8597L9.62267 1.4197C9.84812 1.1797 9.83787 0.799701 9.59193 0.579701C9.34598 0.359701 8.95658 0.369701 8.73113 0.609701L4.51934 5.0097L4.30414 5.2297L3.44334 6.1297L1.04539 3.7797C0.809691 3.5497 0.42028 3.5497 0.184584 3.7797C-0.0511115 4.0097 -0.0511115 4.3897 0.184584 4.6197L3.04368 7.4097C3.1564 7.5197 3.31012 7.5797 3.47408 7.5797H3.48433C3.64829 7.5797 3.81225 7.5097 3.92498 7.3897L4.56033 6.7197L5.25717 7.3997C5.3699 7.5097 5.52361 7.5697 5.68757 7.5697H5.69782C5.86178 7.5697 6.02575 7.4997 6.13847 7.3797L11.8362 1.4197C12.0616 1.1797 12.0514 0.799701 11.8054 0.579701Z" fill="#93919B"/>
            </g>
            <defs>
                <clipPath id="clip0_5878_15945">
                    <rect width="12" height="7.16" fill="white" transform="translate(0 0.419922)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default ReadedMessageIcon
