import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import Container from 'components/common/Container'
import SearchForm from 'components/SearchForm'
import SearchContent from 'components/SearchContent'

import SearchSort from 'components/SearchSort'
import c from './SearchPage.module.scss'
import MobileHeader from '../../components/MobileHeader'

function SearchPage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchValue = searchParams.get('text') || ''
  const [orderBy, setOrderBy] = useState<string>('posts')

  const { t } = useTranslation()

  return (
    <div className={c.search}>
      <Container>
        <MobileHeader heading={t('navigation.search')} isOnGoBack />
        <div className={c.searchContainer}>
          <SearchSort orderBy={orderBy} setOrderBy={setOrderBy} />
          <SearchForm />
          {searchValue && (
            <SearchContent searchValue={searchValue} orderBy={orderBy} />
          )}
        </div>
      </Container>
    </div>
  )
}

export default SearchPage
