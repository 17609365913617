import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useMutation } from '@tanstack/react-query'
import { deleteAccount } from 'api'

import Button from 'components/common/Button'
import ConfirmModal from 'components/modals/ConfirmModal'
import useLogOut from 'hooks/query/auth/useLogOut'

import c from './DeleteAccount.module.scss'

function DeleteAccount() {
  const { t } = useTranslation()
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const navigate = useNavigate()

  const { mutate } = useMutation(['Delete Account'], () => {
    return deleteAccount()
  })
  const { mutate: logOut, error } = useLogOut()

  const handleDeleteAccount = useCallback(() => {
    try {
      logOut()
      mutate()
      toast.success('Ты удален')
      setIsConfirmModalOpen(false)
      navigate('/')
    } catch (err) {
      console.log(err)
      toast.error(t('error.somethingWrong'))
    }
  }, [mutate])

  const closeModal = useCallback(() => {
    setIsConfirmModalOpen(false)
  }, [])

  const openModal = useCallback(() => {
    setIsConfirmModalOpen(true)
  }, [])

  useEffect(() => {
    if (error?.message) toast.error(error.message)
  }, [error?.message])

  return (
    <div className={c.delete}>
      <ConfirmModal
        heading={t('modals.general.delete')}
        confirmFunc={handleDeleteAccount}
        cancelFunc={closeModal}
        isModalOpen={isConfirmModalOpen}
      />
      <p className={c.deleteTitle}>{t('profile.deleteAccount')}</p>
      <div className={c.row}>
        <h3 className={c.title}>{t('profile.deleteAccountInfo.howDelete')}</h3>
        <p>{t('profile.deleteAccountInfo.howDeleteInfo')}</p>
      </div>
      <div className={c.row}>
        <h3 className={c.title}>
          {t('profile.deleteAccountInfo.whatDeleted')}
        </h3>
        <ul className={c.list}>
          <li>
            - <strong>{t('profile.deleteAccountInfo.avatar.strong')}</strong>:{' '}
            {t('profile.deleteAccountInfo.avatar.text')}
          </li>
          <li>
            - <strong>{t('profile.deleteAccountInfo.message.strong')}</strong>:{' '}
            {t('profile.deleteAccountInfo.message.text')}
          </li>
          <li>
            -{' '}
            <strong>{t('profile.deleteAccountInfo.generation.strong')}</strong>:{' '}
            {t('profile.deleteAccountInfo.generation.text')}
          </li>
          <li>
            - <strong>{t('profile.deleteAccountInfo.post.strong')}</strong>:{' '}
            {t('profile.deleteAccountInfo.post.text')}
          </li>
        </ul>
      </div>
      <Button onClick={openModal} className={c.button}>
        {t('profile.delete')}
      </Button>
    </div>
  )
}

export default DeleteAccount
