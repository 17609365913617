import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import classNames from 'classnames'

import GuidanceScaleSelection from 'components/GuidanceScaleSelection'
import SeedInput from 'components/SeedInput'
import StepsSelection from 'components/StepsSelection'
import { ResolutionType } from 'types/Response'
import useFetchRequest from '../../hooks/query/useFetchRequest'
import { OutputFormat } from '../../types/OutputFormat'
import FormatImageSelection from '../FormatImageSelection'
import ShortArrow from '../icons/ShortArrow'
import PowerSelection from '../PowerSelection'
import ResolutionSelectionFixedValues from '../stages/dallE3/ResolutionSelectionFixedValues'

import c from './AdvancedSettings.module.scss'

interface AdvancedSettingsProps {
  changeableResolutionSelectionVisible?: boolean
  powerSelectionVisible?: boolean
  fixedResolutionSelectionVisible?: boolean
  guidanceScaleSelectionVisible?: boolean
  seedSelectionVisible?: boolean
  stepsSelectionVisible?: boolean
  resolution?: number
  setResolution?: (newIndex: number | Range) => void
  guidanceScale?: number
  setGuidanceScale?: (newIndex: number) => void
  seed?: string | undefined
  setSeed?: (newSeed: string) => void
  steps?: number
  imageUrls?: string[]
  outputFormat?: OutputFormat
  onOutputFormatChange?: (newOutputFormat: OutputFormat) => void
  onImagesChange?: (newFiles: File[]) => void
  onImageDelete?: (index: number) => void
  power?: number
  formatImageVisible?: boolean
  setStrength?: (value: number) => void
  setSteps?: (newStep: number) => void
  isAdvancedSettingsOpen: boolean
  setIsAdvancedSettingsOpen: (prev: boolean) => void
  fixedResolutions?: ResolutionType[]
}

function AdvancedSettings({
  setStrength = (value: number) => value,
  powerSelectionVisible = false,
  imageUrls = [],
  outputFormat = 'jpeg',
  onOutputFormatChange = (newOutputFormat: OutputFormat) => newOutputFormat,
  onImagesChange = undefined,
  onImageDelete = undefined,
  formatImageVisible = false,
  changeableResolutionSelectionVisible = false,
  guidanceScaleSelectionVisible = false,
  fixedResolutionSelectionVisible = false,
  seedSelectionVisible = false,
  stepsSelectionVisible = false,
  isAdvancedSettingsOpen,
  setIsAdvancedSettingsOpen,
  guidanceScale = 10,
  setGuidanceScale = (value: number) => value,
  seed = '',
  setSeed = (value: string) => value,
  steps = 55,
  power = 55,
  setSteps = (value: number) => value,
  resolution = undefined,
  setResolution = () => {},
  fixedResolutions = undefined,
}: AdvancedSettingsProps) {
  const { t } = useTranslation()

  const { requestId } = useParams()

  const { data } = useFetchRequest({
    requestId,
  })

  useEffect(() => {
    if (
      data &&
      data.assets &&
      data.assets.length > 0 &&
      data.assets[0].settings
    ) {
      setGuidanceScale(data.assets[0].settings.guidance_scale || 0)
      setSeed(data.assets[0].settings.seed || '')
      setSteps(data.assets[0].settings.num_steps || 0)
    }
  }, [data])

  const togglePopup = () => {
    setIsAdvancedSettingsOpen(!isAdvancedSettingsOpen)
  }

  return (
    <div
      className={classNames({
        [c.advancedSettingsContainer]: true,
        [c.advancedSettingsContainerUnActive]: !isAdvancedSettingsOpen,
      })}
    >
      <button
        type="button"
        onClick={togglePopup}
        className={c.wrapperAdvancedSettings}
      >
        <div className={c.leftPart}>
          <div className={c.stylesTitle}>
            {t('stages.generation.idle.advancedSettings')}
          </div>
        </div>
        <div className={c.rightPart}>
          <ShortArrow
            className={classNames(c.arrowIcon, {
              [c.arrowIconOpen]: !isAdvancedSettingsOpen,
            })}
          />
        </div>
      </button>

      <CSSTransition
        in={isAdvancedSettingsOpen}
        timeout={100}
        classNames={{
          enter: c.settingsEnter,
          enterActive: c.settingsEnterActive,
          exit: c.settingsExit,
          exitActive: c.settingsExitActive,
        }}
        mountOnEnter
        unmountOnExit
      >
        <div className={c.settings}>
          {formatImageVisible && (
            <FormatImageSelection
              imageUrls={imageUrls}
              onImageDelete={onImageDelete}
              onOutputFormatChange={onOutputFormatChange}
              outputFormat={outputFormat}
            />
          )}
          {/* {resolution! >= 0 &&
            setResolution &&
            changeableResolutionSelectionVisible && (
              <ResolutionSelection
                setStrength={setResolution}
                strength={resolution!}
              />
            )} */}
          {fixedResolutionSelectionVisible && (
            <ResolutionSelectionFixedValues
              resolution={resolution}
              setResolution={setResolution}
              fixedResolutions={fixedResolutions}
            />
          )}
          {powerSelectionVisible && (
            <PowerSelection setStrength={setStrength} strength={power} />
          )}
          {guidanceScaleSelectionVisible && (
            <GuidanceScaleSelection
              setStrength={setGuidanceScale}
              strength={guidanceScale}
            />
          )}
          {seedSelectionVisible && <SeedInput seed={seed} setSeed={setSeed} />}
          {stepsSelectionVisible && (
            <StepsSelection setStrength={setSteps} strength={steps} />
          )}
        </div>
      </CSSTransition>
    </div>
  )
}

export default AdvancedSettings
