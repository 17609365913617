import React from 'react'

// eslint-disable-next-line react/require-default-props
function MutedIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="muted">
        <g id="Group">
          <path
            id="Vector"
            d="M15.5008 11.25C15.0868 11.25 14.7508 11.586 14.7508 12C14.7508 12.513 14.7178 13.452 14.6638 14.571C14.5918 16.101 14.5408 17.175 14.4028 17.949C14.2648 18.72 14.0668 19.014 13.8508 19.167C13.7308 19.251 13.5988 19.32 13.4608 19.365C13.2358 19.443 12.9208 19.44 12.3298 19.176C11.7298 18.909 10.9738 18.438 9.91481 17.745C9.56981 17.517 9.10481 17.616 8.87681 17.961C8.64881 18.306 8.74781 18.771 9.09281 18.999C10.1308 19.68 10.9918 20.223 11.7238 20.547C12.4648 20.874 13.2028 21.036 13.9468 20.784C14.2198 20.691 14.4808 20.559 14.7148 20.394C15.4198 19.899 15.7198 19.113 15.8818 18.213C16.0438 17.316 16.0978 16.125 16.1668 14.64C16.2178 13.524 16.2538 12.552 16.2538 12C16.2508 11.586 15.9148 11.25 15.5008 11.25Z"
            fill="white"
          />
          <path
            id="Vector_2"
            d="M20.2649 6.31512C19.8869 6.48612 19.7189 6.93012 19.8899 7.30812C20.2829 8.17812 20.7509 9.69912 20.7509 12.0001C20.7509 14.0041 20.3879 15.3991 20.0819 16.2211C19.7549 17.1031 19.4219 17.5231 19.4189 17.5261L19.4219 17.5201L20.5739 18.4801C20.6429 18.3991 22.2479 16.4221 22.2479 12.0001C22.2479 9.43212 21.7079 7.69512 21.2549 6.69312C21.0869 6.31512 20.6429 6.14712 20.2649 6.31512Z"
            fill="white"
          />
          <path
            id="Vector_3"
            d="M18.1648 9.26671C17.7598 9.35071 17.4988 9.74672 17.5828 10.1547C17.6938 10.6917 17.7508 11.3127 17.7508 12.0027C17.7508 13.8297 17.3428 14.6397 17.3398 14.6487C17.3428 14.6427 17.3458 14.6397 17.3458 14.6397L18.6568 15.3687C18.7168 15.2577 19.2508 14.2347 19.2508 12.0057C19.2508 11.2137 19.1848 10.4907 19.0528 9.85472C18.9658 9.44072 18.5698 9.17971 18.1648 9.26671Z"
            fill="white"
          />
          <path
            id="Vector_4"
            d="M22.53 1.4705C22.236 1.1765 21.762 1.1765 21.468 1.4705L16.02 6.9185C15.975 6.3965 15.915 5.9315 15.828 5.5295C15.657 4.7405 15.351 4.0565 14.712 3.6065C14.478 3.4415 14.214 3.3095 13.944 3.2165C13.125 2.9375 12.312 3.1595 11.487 3.5615C10.662 3.9605 9.66 4.6235 8.409 5.4515L8.19 5.5955C7.743 5.8895 7.587 5.9885 7.428 6.0605C7.257 6.1355 7.077 6.1895 6.894 6.2195C6.723 6.2495 6.54 6.2495 6.003 6.2495C4.698 6.2495 3.813 6.2345 3.027 6.5975C2.331 6.9185 1.662 7.5485 1.299 8.2235C0.891 8.9825 0.858 9.7745 0.786 10.9295C0.765 11.2955 0.75 11.6585 0.75 12.0005C0.75 12.3425 0.765 12.7055 0.786 13.0745C0.855 14.2265 0.891 15.0215 1.299 15.7805C1.662 16.4555 2.331 17.0825 3.027 17.4065C3.639 17.6885 4.311 17.7425 5.193 17.7515L1.47 21.4715C1.176 21.7655 1.176 22.2395 1.47 22.5335C1.617 22.6805 1.809 22.7525 2.001 22.7525C2.193 22.7525 2.385 22.6805 2.532 22.5335L22.53 2.5295C22.824 2.2385 22.824 1.7645 22.53 1.4705ZM2.619 15.0665C2.4 14.6615 2.361 14.2475 2.283 12.9815C2.262 12.6365 2.25 12.3035 2.25 12.0005C2.25 11.6975 2.262 11.3645 2.283 11.0165C2.361 9.7535 2.4 9.3365 2.619 8.9315C2.829 8.5445 3.255 8.1425 3.654 7.9565C4.068 7.7645 4.557 7.7495 6 7.7495C6.489 7.7495 6.816 7.7525 7.14 7.6985C7.446 7.6475 7.746 7.5575 8.031 7.4315C8.331 7.2995 8.604 7.1165 9.012 6.8465L9.231 6.7025C10.521 5.8535 11.427 5.2565 12.141 4.9115C12.852 4.5665 13.209 4.5515 13.458 4.6355C13.596 4.6835 13.731 4.7495 13.848 4.8335C14.043 4.9715 14.226 5.2265 14.361 5.8475C14.49 6.4325 14.553 7.2275 14.61 8.3285L6.681 16.2575C6.483 16.2485 6.264 16.2485 6 16.2485C4.557 16.2485 4.068 16.2335 3.654 16.0415C3.255 15.8585 2.829 15.4565 2.619 15.0665Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  )
}

export default MutedIcon
