import React from 'react'
import c from './GenerationSection.module.scss'

interface GenerationSectionProps {
  children: React.ReactNode
}

function GenerationSection({ children }: GenerationSectionProps) {
  return <div className={c.content}>{children}</div>
}

export default GenerationSection
