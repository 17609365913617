import React from 'react'

// eslint-disable-next-line react/require-default-props
function FullScreenIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.3694 13.0469C20.0229 13.0469 19.7416 13.3281 19.7416 13.6747C19.7416 16.6554 19.7416 18.1496 18.9456 18.9456C18.1496 19.7416 16.6529 19.7416 13.6747 19.7416C13.3281 19.7416 13.0469 20.0229 13.0469 20.3694C13.0469 20.716 13.3281 20.9972 13.6747 20.9972C15.3019 20.9972 16.4796 20.9972 17.4314 20.8691C18.5237 20.7235 19.2645 20.4021 19.8345 19.832C20.4046 19.262 20.7235 18.5212 20.8717 17.4289C20.9997 16.4746 20.9997 15.2994 20.9997 13.6722C20.9972 13.3281 20.716 13.0469 20.3694 13.0469Z"
        fill="white"
      />
      <path
        d="M10.3264 19.7416C7.3457 19.7416 5.85156 19.7416 5.05552 18.9456C4.25949 18.1496 4.25949 16.6529 4.25949 13.6747C4.25949 13.3281 3.97824 13.0469 3.6317 13.0469C3.28516 13.0469 3.00391 13.3281 3.00391 13.6747C3.00391 15.3019 3.00391 16.4796 3.13198 17.4314C3.27762 18.5237 3.59905 19.2645 4.16908 19.8345C4.73912 20.4046 5.47991 20.7235 6.57227 20.8717C7.52651 20.9997 8.70173 20.9997 10.329 20.9997C10.6755 20.9997 10.9568 20.7185 10.9568 20.3719C10.9568 20.0254 10.673 19.7416 10.3264 19.7416Z"
        fill="white"
      />
      <path
        d="M10.3251 3.00391C8.69782 3.00391 7.52009 3.00391 6.56836 3.13198C5.476 3.27762 4.73521 3.59905 4.16518 4.16908C3.59515 4.73912 3.27623 5.47991 3.12807 6.57227C3 7.52651 3 8.70173 3 10.329C3 10.6755 3.28125 10.9568 3.62779 10.9568C3.97433 10.9568 4.25558 10.6755 4.25558 10.329C4.25558 7.35072 4.25558 5.85407 5.05162 5.05804C5.84766 4.262 7.34431 4.262 10.3225 4.262C10.6691 4.262 10.9503 3.98075 10.9503 3.63421C10.9528 3.28516 10.6716 3.00391 10.3251 3.00391Z"
        fill="white"
      />
      <path
        d="M13.6733 4.25809C16.654 4.25809 18.1482 4.25809 18.9442 5.05413C19.7402 5.85017 19.7402 7.34682 19.7402 10.3251C19.7402 10.6716 20.0215 10.9528 20.368 10.9528C20.7146 10.9528 20.9958 10.6716 20.9958 10.3251C20.9958 8.69782 20.9958 7.52009 20.8677 6.56836C20.7221 5.476 20.4007 4.73521 19.8306 4.16518C19.2606 3.59515 18.5198 3.27623 17.4275 3.12807C16.4732 3 15.298 3 13.6708 3C13.3242 3 13.043 3.28125 13.043 3.62779C13.0455 3.97684 13.3267 4.25809 13.6733 4.25809Z"
        fill="white"
      />
    </svg>
  )
}

export default FullScreenIcon
