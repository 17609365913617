import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'
import { useTranslation } from 'react-i18next'

import useInfiniteFetchComments from 'hooks/query/useInfiniteFetchComments'
import Comment from 'components/Comment'
import Loader from 'components/common/Loader'
import useFetchRequestPost from 'hooks/query/useFetchRequestPost'
import { PostComment } from 'types/Comments'
import GenerationContainer, {
  AsideSlot,
  MainSlot,
} from 'components/GenerationControls/GenerationContainer/GenerationContainer'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import AdvancedSettings from 'components/AdvancedSettings'
import GenerationStyles from 'components/GenerationStyles'
import useFetchStyles from 'hooks/query/useFetchStyles'
import GenerationFixedButton from 'components/GenerationFixedButton'
import PromptsContainer from 'components/PromptsContainer'
import SuggestionsContainer from 'components/SuggestionsContainer'
import GenerationTextArea from 'components/GenerationTextArea'
import { changeStage } from 'store/reducers/stageReducer'
import Processor, { VariationsStrategy } from 'api/Retomagic'
import {
  ProcessedAssetsChangedPayload,
  ProcessorEvents,
} from 'types/ProcessorEvents'
import { LimitError } from 'types/Response'
import useFetchSuggestions from 'hooks/query/useFetchSuggestions'
import ProcessingStage from 'components/stages/variations/ProcessingStage'
import useFetchComment from 'hooks/query/useFetchComment'

import c from './CommentsPage.module.scss'
import CommentParentCard from '../../components/CommentParentCard'
import concatArrayStrings from '../../utils/concatArrayStrings'
import { ITagCommentParentCard } from '../../components/CommentParentCard/CommentParentCard'

function CommentsPage() {
  const { id, commentId } = useParams()
  const location = useLocation()
  const { stage } = useAppSelector((state) => state.stage)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [parentComment, setParentComment] = useState<PostComment | undefined>(
    undefined,
  )
  const [isAdvancedSettingsOpen, setIsAdvancedSettingsOpen] = useState(false)
  const [text, setText] = useState<string>('')
  const [guidanceScale, setGuidanceScale] = useState<number>(8)
  const [seed, setSeed] = useState<string | undefined>(undefined)
  const [steps, setSteps] = useState<number>(20)
  const [negativePrompt, setNegativePrompt] = useState<string>('')
  const [chosenStyle, setChosenStyle] = useState<string | undefined>('')

  const [strength, setStrength] = useState<number>(55)
  const [seconds, setSeconds] = useState<number | undefined>(0)

  const { ref, inView } = useInView()

  const {
    data: commentsData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteFetchComments({ postId: Number(id!) })

  const { data: stylesData } = useFetchStyles()

  const comments = useMemo(() => {
    if (commentsData === undefined) return { items: [], meta: {} }

    const combinedItems = commentsData.pages.flatMap((p) => p.items)
    const lastMeta = commentsData.pages[commentsData.pages.length - 1].meta

    return {
      items: combinedItems,
      meta: lastMeta,
    }
  }, [commentsData])

  const { data: commentFetchData } = useFetchComment({ commentId })

  const { data: postData } = useFetchRequestPost({ id })

  const { data: suggestionsData } = useFetchSuggestions()

  useEffect(() => {
    if (isLoading) return
    fetchNextPage()
  }, [inView])

  const asset = useMemo(() => {
    return parentComment !== undefined
      ? parentComment.asset
      : postData?.assets?.[0]
  }, [id, parentComment, postData])

  const onStyleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (chosenStyle === event.currentTarget.value) {
        setChosenStyle(undefined)
      } else {
        setChosenStyle(event.currentTarget.value)
      }
    },
    [chosenStyle],
  )

  const handleProcessedAssetsChanged = useCallback(
    (payload: ProcessedAssetsChangedPayload) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { isFirstSucceededResult, id: requestId } = payload

      dispatch(changeStage('idle'))

      if (isFirstSucceededResult) {
        if (commentId) {
          navigate(
            `/trending-post/${id}/comments/${commentId}/finish/${requestId}`,
          )
        } else {
          navigate(`/trending-post/${id}/comments/finish/${requestId}`)
        }
      }
    },
    [],
  )

  const [generationProcessor, setGenerationProcessor] =
    useState<Processor | null>(null)

  const imageUrl = useMemo(() => {
    return asset?.url
  }, [postData, id])

  const handleProcessingStart = useCallback(async () => {
    try {
      dispatch(changeStage('processing'))

      const thisStyle = stylesData.find(
        (elem) => elem.title === chosenStyle || elem.id === chosenStyle,
      )

      const strategy = new VariationsStrategy({
        image:
          parentComment !== undefined
            ? String(parentComment.asset?.id)
            : String(asset?.id),
        request: text,
        styleTitle: thisStyle?.title!,
        styleText: thisStyle?.text!,
        strength: (strength / 100).toFixed(2),
        guidanceScale,
        negativePrompt,
        numSteps: steps,
        requestId: undefined,
        seed,
      })
      const processor = new Processor(strategy)
      processor.on(
        ProcessorEvents.PROCESSED_ASSETS_CHANGED,
        handleProcessedAssetsChanged,
      )

      await processor.start()
      setSeconds(strategy.getSeconds())

      if (!processor) {
        setGenerationProcessor(processor)
      }
    } catch (error) {
      const errorData = error as LimitError
      if (errorData.key === 'LIMIT_GENERATION') {
        setText('')
        setChosenStyle('')
        dispatch(changeStage('idle'))
      }
      console.log(error)
    }
  }, [
    id,
    text,
    handleProcessedAssetsChanged,
    chosenStyle,
    stylesData,
    imageUrl,
    strength,
    isAdvancedSettingsOpen,
    guidanceScale,
    seed,
    steps,
    negativePrompt,
    postData,
    parentComment,
    asset,
  ])

  const onExampleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setText(event.currentTarget?.value)
    },
    [],
  )

  return (
    <GenerationContainer stage={stage}>
      <MainSlot>
        {stage === 'processing' && seconds && (
          <div className={c.processing}>
            <ProcessingStage seconds={seconds} />
          </div>
        )}
        {stage === 'idle' && !location.pathname.includes('finish') && (
          <div className={c.comments}>
            {postData &&
              comments.items.map(
                (comment) =>
                  comment.is_deleted || (
                    <Comment
                      key={comment.id}
                      postData={postData}
                      com={comment}
                      setParentComment={setParentComment}
                      parentComment={parentComment}
                      isFirst
                    />
                  ),
              )}
            {!isLoading && !isFetchingNextPage && hasNextPage && (
              <div ref={ref} />
            )}
            {isFetchingNextPage && (
              <div className={c.loaderWrapper}>
                <div className={c.loader}>
                  <Loader />
                </div>
              </div>
            )}
          </div>
        )}
        <Outlet />
      </MainSlot>
      <AsideSlot>
        <div className={c.content}>
          {commentFetchData && (
            <CommentParentCard
              reply={{
                url: commentFetchData.parent?.asset.url || '',
                text: commentFetchData.parent?.asset.source || '',
              }}
              url={commentFetchData.asset.url || ''}
              text={commentFetchData.asset.source}
              tags={[]}
            />
          )}
          {postData && !commentFetchData && (
            <CommentParentCard
              url={postData.assets[0].url}
              text={postData.assets[0].source}
              tags={concatArrayStrings<ITagCommentParentCard>([
                postData.handler === 'William_Henry_Harrison'
                  ? { text: 'Dalle3', type: 'filled' }
                  : undefined,
                {
                  text: postData.assets[0].style,
                  type: 'default',
                },
              ])}
            />
          )}

          <GenerationTextArea
            text={text}
            onTextChange={setText}
            onProcessingStart={handleProcessingStart}
          />

          <SuggestionsContainer
            onExampleClick={onExampleClick}
            suggestions={suggestionsData}
          />

          <PromptsContainer
            negativePrompt={negativePrompt}
            setNegativePrompt={setNegativePrompt}
          />
          <GenerationFixedButton
            text={text}
            headingMissing={t('stages.generation.idle.inputLabel')}
            headingEntered={t('stages.generation.idle.buttonText')}
            headingLoader={t('stages.generation.processing.bannerText')}
            isDisabled={stage === 'processing'}
            onProcessingStart={handleProcessingStart}
            isProcessing={stage === 'processing'}
          />
          <AdvancedSettings
            isAdvancedSettingsOpen={isAdvancedSettingsOpen}
            setIsAdvancedSettingsOpen={setIsAdvancedSettingsOpen}
            guidanceScale={guidanceScale}
            setGuidanceScale={setGuidanceScale}
            power={strength}
            setStrength={setStrength}
            guidanceScaleSelectionVisible
            seedSelectionVisible
            stepsSelectionVisible
            powerSelectionVisible
            seed={seed}
            setSeed={setSeed}
            steps={steps}
            setSteps={setSteps}
          />
          <GenerationStyles
            chosenStyle={chosenStyle}
            styles={stylesData}
            onStyleClick={onStyleClick}
          />
        </div>
      </AsideSlot>
    </GenerationContainer>
  )
}

export default CommentsPage
