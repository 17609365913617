const concatArrayStrings = <T>(array: Array<T | undefined>): Array<T> => {
  const newArr: T[] = []

  array.forEach((item) => {
    if (item !== undefined) newArr.push(item)
  })

  return newArr
}

export default concatArrayStrings
