import React from 'react'

// eslint-disable-next-line react/require-default-props
function ShareIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M16.5 10.4984C16.4783 13.0589 16.3361 14.4705 15.4053 15.4014C14.3066 16.5 12.5384 16.5 9.00195 16.5C5.4655 16.5 3.69727 16.5 2.59864 15.4014C1.5 14.3027 1.5 12.5345 1.5 8.99805C1.5 5.46159 1.5 3.69336 2.59864 2.59472C3.52949 1.66386 4.94105 1.52171 7.50157 1.5"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M16.5 5.25H10.5C9.1368 5.25 8.31502 5.91901 8.0103 6.22522C7.91595 6.32005 7.86877 6.36747 7.8681 6.36811C7.8675 6.36876 7.82002 6.41594 7.72522 6.51031C7.41901 6.81503 6.75 7.6368 6.75 9V11.25M16.5 5.25L12.75 1.5M16.5 5.25L12.75 9"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ShareIcon
