import { BaseAsset } from 'types/Response'

export enum ProcessorEvents {
  PROCESSED_ASSETS_CHANGED = 'processedAssetsChanged',
}

export interface ProcessedAssetsChangedPayload {
  id: number
  assets: BaseAsset[]
  finish: boolean
  isFirstSucceededResult: boolean
}
