import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from 'components/common/Loader'
import useResize from 'hooks/useResize'
import useFetchPluginById from 'hooks/query/useFetchPluginById'
import PluginPost from 'components/PluginPost'
import c from './PluginList.module.scss'
import MobileHeader from '../MobileHeader'

function PluginList() {
  const navigate = useNavigate()
  const { id } = useParams()

  const { data, isFetching } = useFetchPluginById(Number(id))
  const [opacity, setOpacity] = useState<number>(0)

  const width = useResize()

  useEffect(() => {
    setTimeout(() => {
      setOpacity(1)
    }, 100)

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  const handleClose = (
    event:
      | React.MouseEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (!(event.target instanceof HTMLElement)) return
    const target = event.target as HTMLElement

    if (target.className === c.postLikes || target.className === c.postLink)
      return
    if (target.className === c.plugin && width > 640) navigate('/plugins')
  }

  const goBack = () => {
    navigate('/plugins')
  }
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow
    const originalOverflowX = window.getComputedStyle(document.body).overflowX

    document.body.style.overflow = 'hidden'
    document.body.style.overflowX = 'hidden'

    return () => {
      document.body.style.overflow = originalStyle
      document.body.style.overflowX = originalOverflowX
    }
  }, [])

  return (
    <div
      style={{
        opacity,
      }}
      className={c.plugin}
      onClick={handleClose}
      onKeyDown={handleClose}
      role="button"
      tabIndex={0}
    >
      <MobileHeader heading={data?.title} onClose={goBack} />
      {!isFetching && data ? (
        <PluginPost post={data} onePost />
      ) : (
        <div className={c.loaderWrapper}>
          <div className={c.loader}>
            <Loader />
          </div>
        </div>
      )}
    </div>
  )
}

export default PluginList;
