import { useQuery } from '@tanstack/react-query'
import { getRecommendations } from 'api'
import { PostFeed } from 'types/Post'

interface Params {
  postId: number
}

const useFetchRecommendations = ({ postId }: Params) => {
  return useQuery<PostFeed[], Error>(['recommendations', postId], () => {
    return getRecommendations(postId)
  })
}

export default useFetchRecommendations
