import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import AIChatPage from 'pages/AIChatPage'
import ArchivePostPage from 'pages/ArchivePostPage'
import CommentsFinishPage from 'pages/CommentsFinishPage'
import DallE3FinishPage from 'pages/DallE3FinishPage'
import DocumentPage from 'pages/DocumentPage'
import EnhancementFinishPage from 'pages/EnhancementFinishPage'
import EnhancementPage from 'pages/EnhancementPage'
import FeedPage from 'pages/FeedPage'
import GenerationFinishPage from 'pages/GenerationFinishPage'
import GenerationPage from 'pages/GenerationPage'
import HistoryPage from 'pages/HistoryPage'
import MixerFinishPage from 'pages/MixerFinishPage'
import NoMatchPage from 'pages/NoMatchPage'
import PaymentSuccessPage from 'pages/PaymentSuccessPage'
import PluginsPage from 'pages/PluginsPage'
import PostPage from 'pages/PostPage'
import ProfileAlbumsPage from 'pages/ProfileAlbumsPage'
import ProfileArchivePage from 'pages/ProfileArchivePage'
// import ImageFilterPage from 'pages/ImageFilterPage'
import ProfileLikedPage from 'pages/ProfileLikedPage'
import ProfilePage from 'pages/ProfilePage'
import SearchPage from 'pages/SearchPage'
import SettingsPage from 'pages/SettingsPage'
import SubscribersPage from 'pages/SubscribersPage'
import SubscriptionsPage from 'pages/SubscriptionsPage'
import TrendingPage from 'pages/TrendingPage'
import TrendingPostPage from 'pages/TrendingPostPage'
import TrendingVideoPage from 'pages/TrendingVideoPage'
import VariationFinishPage from 'pages/VariationFinishPage'
import VideoArtFinishPage from 'pages/VideoArtFinishPage'
import WebviewPage from 'pages/WebviewPage'
import AlbumItem from 'components/AlbumItem'
import CreateAlbum from 'components/CreateAlbum'
import DeleteAccount from 'components/DeleteAccount'
import PrivacyPolicy from 'components/documents/PrivacyPolicy'
import TermsOfUse from 'components/documents/TermsOfUse'
import LanguageChange from 'components/LanguageChange'
import PluginList from 'components/PluginList'
import SettingsList from 'components/SettingsList'
import MainLayout from 'layouts/MainLayout'
import AboutCompany from './components/AboutCompany'
import ImagePage from './pages/ImagePage'

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<MainLayout />}>
          <Route index element={<TrendingPage />} />
          <Route
            path="enhancement"
            element={<EnhancementPage handler="George_Washington" />}
          >
            <Route path="finish/:assetId" element={<EnhancementFinishPage />} />
          </Route>
          <Route
            path="face-restoration"
            element={<EnhancementPage handler="Thomas_Jefferson" />}
          >
            <Route path="finish/:assetId" element={<EnhancementFinishPage />} />
          </Route>
          <Route
            path="background-remover"
            element={<EnhancementPage handler="James_Monroe" />}
          >
            <Route path="finish/:assetId" element={<EnhancementFinishPage />} />
          </Route>
          <Route
            path="neuro-art"
            element={<GenerationPage handler="George_Washington" />}
          >
            <Route
              path="finish/:requestId"
              element={<GenerationFinishPage />}
            />
          </Route>
          <Route
            path="ai-avatars"
            element={<GenerationPage handler="Andrew_Jackson" />}
          >
            <Route path="finish/:requestId" element={<MixerFinishPage />} />
          </Route>
          <Route
            path="ai-inpaint"
            element={<GenerationPage handler="Martin_Van_Buren" />}
          >
            <Route path="finish/:assetId" element={<EnhancementFinishPage />} />
          </Route>
          <Route
            path="dall-e-3"
            element={<GenerationPage handler="William_Henry_Harrison" />}
          >
            <Route path="finish/:requestId" element={<DallE3FinishPage />} />
          </Route>
          <Route
            path="magic-variations"
            element={<GenerationPage handler="Thomas_Jefferson" />}
          >
            <Route path="finish/:requestId" element={<VariationFinishPage />} />
          </Route>
          <Route
            path="magic-variations/:assetId"
            element={<GenerationPage handler="Thomas_Jefferson" />}
          />
          <Route
            path="video-art"
            element={<GenerationPage handler="James_Monroe" />}
          >
            <Route path="finish/:requestId" element={<VideoArtFinishPage />} />
          </Route>
          <Route path="feed" element={<FeedPage />} />
          <Route path="subscriptions" element={<SubscriptionsPage />} />
          <Route path="subscribers" element={<SubscribersPage />} />
          <Route path="trending" element={<TrendingPage />} />
          <Route path="trending-video-art" element={<TrendingVideoPage />} />
          <Route path="settings" element={<SettingsPage />}>
            <Route index element={<SettingsList />} />
            <Route path="info" element={<AboutCompany />} />
            <Route path="language" element={<LanguageChange />} />
            <Route path="delete" element={<DeleteAccount />} />
          </Route>
          <Route path="chats" element={<AIChatPage />} />
          <Route path="chats/:id" element={<AIChatPage />} />
          <Route path="post/:id" element={<PostPage />} />
          <Route path="trending-post/:id" element={<TrendingPostPage />} />
          <Route
            path="trending-post/:id/comments/:commentId"
            element={<GenerationPage handler="James_Madison" />}
          >
            <Route path="finish/:requestId" element={<CommentsFinishPage />} />
          </Route>
          <Route
            path="trending-post/:id/comments"
            element={<GenerationPage handler="James_Madison" />}
          >
            <Route path="finish/:requestId" element={<CommentsFinishPage />} />
          </Route>
          <Route path="archive/:id" element={<ArchivePostPage />} />
          <Route path="profile/:nickname/*" element={<ProfilePage />}>
            <Route path="liked" element={<ProfileLikedPage />} />
            <Route path="archive" element={<ProfileArchivePage />} />
            <Route path="albums/*" element={<ProfileAlbumsPage />}>
              <Route path="create" element={<CreateAlbum />} />
              <Route path=":albumId" element={<AlbumItem />} />
            </Route>
          </Route>
          <Route path="history/:requestId" element={<HistoryPage />} />
          <Route path="plugins/*" element={<PluginsPage />}>
            <Route path=":id" element={<PluginList />} />
          </Route>
          <Route path="images/:name" element={<ImagePage />} />
          <Route path="search" element={<SearchPage />} />
          {/* <Route path="filter" element={<ImageFilterPage />} /> */}
          <Route path="/*" element={<SettingsPage />}>
            {/*
              <Route
              path="terms-of-use"
              element={<DocumentPage document={<TermsOfUse />} />}
            />
            <Route
              path="privacy-policy"
              element={<DocumentPage document={<PrivacyPolicy />} />}
            />
            */}
          </Route>
          <Route path="success" element={<PaymentSuccessPage />} />
          <Route path="*" element={<NoMatchPage />} />
        </Route>

        <Route path="/webview" element={<WebviewPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
