import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from 'hooks/store'
import useFetchRequest from 'hooks/query/useFetchRequest'
import {
  changeCurrentAssetIndex,
  changeProcessedAssets,
  changeStage,
} from 'store/reducers/stageReducer'

import Container from 'components/common/Container'
import Link from 'components/common/Link'
import Loader from 'components/common/Loader'
import FinishStage from 'components/stages/enhancement/FinishStage'
import { Rating } from 'types/Rating'

import c from './HistoryPage.module.scss'

function HistoryPage() {
  const dispatch = useAppDispatch()

  const { stage } = useAppSelector((state) => state.stage)

  const navigate = useNavigate()

  const { t } = useTranslation()

  const { requestId } = useParams()

  const [rating, setRating] = useState<Rating | null>(null)

  const { data, isLoading, isSuccess, isError, error } = useFetchRequest({
    requestId,
  })

  const handleGoBack = useCallback(() => {
    dispatch(changeStage('idle'))
    navigate('/')
  }, [])

  useEffect(() => {
    // Сброс данных, если пользователь вернулся на главный экран
    if (stage !== 'idle') return
    dispatch(changeProcessedAssets([]))
    dispatch(changeCurrentAssetIndex(0))
    setRating(null)
    navigate('/')
  }, [stage])

  const imageUrls = useMemo(
    () =>
      (isSuccess && data.assets.map((asset) => asset.source).filter(Boolean)) ||
      [],
    [data?.assets],
  )
  const processedImageUrls = useMemo(
    () =>
      (isSuccess && data.assets.map((asset) => asset.url).filter(Boolean)) ||
      [],
    [data?.assets],
  )

  return (
    <main className={c.main}>
      <Container flex fluid>
        {isLoading && (
          <div className={c.loader}>
            <Loader />
          </div>
        )}
        {isSuccess && imageUrls && processedImageUrls && (
          <FinishStage
            assetId={requestId!}
            imageUrls={imageUrls}
            processedImageUrls={processedImageUrls}
            rating={rating}
            onRatingChange={setRating}
            onGoBack={handleGoBack}
          />
        )}
        {isError && (
          <>
            <p className={c.errorMessage}>{error?.message ?? ''}</p>
            <Link className={c.errorLink} to="/">
              {t('general.buttons.backToMainPage')}
            </Link>
          </>
        )}
      </Container>
    </main>
  )
}

export default HistoryPage
