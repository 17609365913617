import React from 'react'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import Authorizer from 'components/helpers/Authorizer'
import HeightSetter from 'components/helpers/HeightSetter'
import LangInitializer from 'components/helpers/LangInitializer'
import { WebSocketProvider } from './providers/WebSocket'
import Router from './Router'
import store from './store'

import './i18n/config'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Authorizer />
        <WebSocketProvider>
          <LangInitializer />
          <HeightSetter />
          <Router />
        </WebSocketProvider>

        {/* <ReactQueryDevtools /> */}
      </QueryClientProvider>
    </Provider>
  )
}

export default App
