import React from 'react'

// eslint-disable-next-line react/require-default-props
function UserIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99998 0.9375C7.03249 0.9375 5.4375 2.53249 5.4375 4.5C5.4375 6.46751 7.03249 8.0625 8.99998 8.0625C10.9675 8.0625 12.5625 6.46751 12.5625 4.5C12.5625 2.53249 10.9675 0.9375 8.99998 0.9375ZM6.5625 4.5C6.5625 3.1538 7.6538 2.0625 8.99998 2.0625C10.3462 2.0625 11.4375 3.1538 11.4375 4.5C11.4375 5.8462 10.3462 6.9375 8.99998 6.9375C7.6538 6.9375 6.5625 5.8462 6.5625 4.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00007 9.1875C7.26493 9.1875 5.66629 9.58192 4.48168 10.2482C3.31471 10.9047 2.4376 11.8996 2.4376 13.125L2.43755 13.2015C2.4367 14.0728 2.43564 15.1665 3.39491 15.9476C3.86701 16.3321 4.52746 16.6055 5.41975 16.7861C6.31455 16.9672 7.48077 17.0625 9.00007 17.0625C10.5194 17.0625 11.6857 16.9672 12.5804 16.7861C13.4728 16.6055 14.1332 16.3321 14.6053 15.9476C15.5645 15.1665 15.5635 14.0728 15.5626 13.2015L15.5626 13.125C15.5626 11.8996 14.6855 10.9047 13.5185 10.2482C12.3339 9.58192 10.7353 9.1875 9.00007 9.1875ZM3.5626 13.125C3.5626 12.4865 4.02863 11.7938 5.03323 11.2288C6.0202 10.6736 7.42156 10.3125 9.00007 10.3125C10.5787 10.3125 11.98 10.6736 12.967 11.2288C13.9716 11.7938 14.4376 12.4865 14.4376 13.125C14.4376 14.1059 14.4073 14.658 13.8949 15.0753C13.617 15.3017 13.1524 15.5225 12.3572 15.6835C11.5645 15.8439 10.4808 15.9375 9.00007 15.9375C7.51942 15.9375 6.43565 15.8439 5.64295 15.6835C4.84774 15.5225 4.38319 15.3017 4.10529 15.0753C3.59284 14.658 3.5626 14.1059 3.5626 13.125Z"
        fill="white"
      />
    </svg>
  )
}

export default UserIcon
