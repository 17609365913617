import React from 'react'

// eslint-disable-next-line react/require-default-props
function BotIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Bot">
        <g id="&#240;&#159;&#148;&#141;-Product-Icons">
          <g id="ic_fluent_bot_24_regular">
            <path
              id="&#240;&#159;&#142;&#168;-Color"
              d="M13.3146 10.4996C14.2466 10.4996 15.0021 11.2551 15.0021 12.1871V12.8659C15.0021 13.6861 14.644 14.4655 14.0216 14.9998C12.8475 16.0078 11.1673 16.5004 8.99978 16.5004C6.83266 16.5004 5.15355 16.008 3.9814 15.0002C3.35993 14.4659 3.00244 13.6871 3.00244 12.8676V12.1871C3.00244 11.2551 3.75796 10.4996 4.68994 10.4996H13.3146ZM13.3146 11.6246H4.68994C4.37928 11.6246 4.12744 11.8764 4.12744 12.1871V12.8676C4.12744 13.3593 4.34193 13.8266 4.71482 14.1472C5.65838 14.9584 7.08039 15.3754 8.99978 15.3754C10.9198 15.3754 12.3431 14.9581 13.2888 14.1463C13.6622 13.8257 13.8771 13.358 13.8771 12.8659V12.1871C13.8771 11.8764 13.6252 11.6246 13.3146 11.6246ZM8.92371 1.50513L9.00003 1.5C9.2848 1.5 9.52015 1.71162 9.5574 1.98617L9.56253 2.0625L9.56194 2.62425L12.1873 2.62463C13.1193 2.62463 13.8748 3.38015 13.8748 4.31213V7.69057C13.8748 8.62255 13.1193 9.37807 12.1873 9.37807H5.81229C4.8803 9.37807 4.12479 8.62255 4.12479 7.69057V4.31213C4.12479 3.38015 4.8803 2.62463 5.81229 2.62463L8.43694 2.62425L8.43753 2.0625C8.43753 1.77773 8.64915 1.54238 8.92371 1.50513ZM12.1873 3.74963H5.81229C5.50162 3.74963 5.24979 4.00147 5.24979 4.31213V7.69057C5.24979 8.00123 5.50162 8.25307 5.81229 8.25307H12.1873C12.4979 8.25307 12.7498 8.00123 12.7498 7.69057V4.31213C12.7498 4.00147 12.4979 3.74963 12.1873 3.74963ZM7.31175 4.87463C7.82922 4.87463 8.24872 5.29412 8.24872 5.8116C8.24872 6.32907 7.82922 6.74856 7.31175 6.74856C6.79428 6.74856 6.37478 6.32907 6.37478 5.8116C6.37478 5.29412 6.79428 4.87463 7.31175 4.87463ZM10.6813 4.87463C11.1988 4.87463 11.6183 5.29412 11.6183 5.8116C11.6183 6.32907 11.1988 6.74856 10.6813 6.74856C10.1638 6.74856 9.74434 6.32907 9.74434 5.8116C9.74434 5.29412 10.1638 4.87463 10.6813 4.87463Z"
              fill="url(#paint0_linear_5941_13806)"
            />
          </g>
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5941_13806"
          x1="0.795366"
          y1="9.00022"
          x2="17.5458"
          y2="15.5161"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9E5992" />
          <stop offset="0.505208" stopColor="#6A19AB" />
          <stop offset="1" stopColor="#3054BB" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default BotIcon
