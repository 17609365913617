import React from 'react'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import ArrowLeft from '../icons/ArrowLeft'
import c from './AboutCompany.module.scss'

export default function AboutCompany() {
  const { t } = useTranslation()

  const currentYear = new Date().getFullYear()
  return (
    <div className={c.about}>
      <div className={c.aboutItem}>
        <p className={c.listTitle}>{t('settings.documents.title')}</p>
        <ul className={c.list}>
          <li className={c.listItem}>
            <Link
              className={classNames({
                [c.button]: true,
                [c.borderButton]: true,
              })}
              to="/terms-of-use"
            >
              <span className={c.buttonText}>
                {t('settings.documents.items.terms')}
              </span>
              <ArrowLeft className={c.buttonEndIcon} />
            </Link>
          </li>
          <li className={c.listItem}>
            <Link
              className={classNames({
                [c.button]: true,
                [c.borderBottom]: true,
              })}
              to="/privacy-policy"
            >
              <span className={c.buttonText}>
                {t('settings.documents.items.policy')}
              </span>
              <ArrowLeft className={c.buttonEndIcon} />
            </Link>
          </li>
        </ul>
      </div>
      <p className={c.copyright}>&copy; 2020-{currentYear} RetoMagic</p>
    </div>
  )
}