import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import IconButton from 'components/common/IconButton'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import CloseIcon from '../../icons/CloseIcon'
import useModal from '../../../hooks/useModal'
import {
  setLogInModalOpen,
  setSignUpModalOpen,
} from '../../../store/reducers/modalReducer'
import { useAppSelector } from '../../../hooks/store'
import c from './Modal.module.scss'
import Button from '../Button/Button'
import MobileHeader from '../../MobileHeader'

const handleInnerClick = (e: React.MouseEvent<HTMLDivElement>) => {
  e.stopPropagation()
}

interface ModalProps {
  open: boolean
  children: React.ReactNode
  heading: string
  onClose?: () => void
  screen?: 'userModal' | 'none' | 'forgot-password' | 'updateUserModal'
}

function Modal({
  open,
  children,
  heading,
  screen = 'none',
  onClose = undefined,
}: ModalProps) {
  const { logInModalOpen, signUpModalOpen } = useAppSelector(
    (state) => state.modal,
  )

  const [isMobile, setIsMobile] = useState<boolean>(false)

  const className = useMemo(() => {
    let result = c.wrapper
    if (open) result += ` ${c.wrapperOpen}`
    return result
  }, [open])

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key !== 'Escape') return
      if (onClose) onClose()
    },
    [onClose],
  )

  const { t } = useTranslation()

  const { open: openLogInModal, close: closeLogInModal } = useModal(
    logInModalOpen,
    setLogInModalOpen,
  )

  const { open: openSignUpModal, close: closeSignUpModal } = useModal(
    signUpModalOpen,
    setSignUpModalOpen,
  )

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }
    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          role="button"
          tabIndex={0}
          className={className}
          onKeyDown={handleKeyPress}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className={c.mobileHeader}>
            <MobileHeader heading={heading} onClose={onClose} />
          </div>
          <button
            className={c.modalBackout}
            onClick={onClose}
            type="button"
            tabIndex={-1}
            aria-label="close"
          />
          <div className={c.modalContainer}>
            <div
              role="button"
              aria-hidden="true"
              className={c.modal}
              onClick={handleInnerClick}
            >
              <div className={c.content}>
                <div
                  className={classNames({
                    [c.head]: true,
                    [c.header]: (screen === 'userModal' || screen === 'updateUserModal')
                  })}
                >
                  {screen !== 'userModal' && screen !== 'updateUserModal' && (
                    <div className={c.heading}>
                      <button
                        type="button"
                        onClick={openSignUpModal}
                        className={classNames({
                          [c.button]: true,
                          [c.buttonUnActive]: !signUpModalOpen,
                          [c.buttonActive]: signUpModalOpen,
                        })}
                      >
                        {t('modals.signUp.heading')}
                      </button>
                      <button
                        type="button"
                        onClick={openLogInModal}
                        className={classNames({
                          [c.button]: true,
                          [c.buttonUnActive]: !logInModalOpen,
                          [c.buttonActive]: logInModalOpen,
                        })}
                      >
                        {t('modals.logIn.heading')}
                      </button>
                    </div>
                  )}
                  {!isMobile && screen === 'updateUserModal' && (
                    <span className={c.title}>{heading}</span>
                  )}
                  {!isMobile && screen !== 'userModal' && (
                    <button type="button" onClick={onClose}>
                      <CloseIcon />
                    </button>
                  )}
                </div>
                <div>{children}</div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Modal
