import React from 'react'

// eslint-disable-next-line react/require-default-props
function SocialShareIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="share-svgrepo-com 1">
        <path
          id="Vector"
          d="M6.75 9C6.75 10.0355 5.91053 10.875 4.875 10.875C3.83947 10.875 3 10.0355 3 9C3 7.96447 3.83947 7.125 4.875 7.125C5.91053 7.125 6.75 7.96447 6.75 9Z"
          stroke="white"
          strokeWidth="1.4"
        />
        <path
          id="Vector_2"
          d="M10.5 4.875L6.75 7.5"
          stroke="white"
          strokeWidth="1.4"
          strokeLinecap="round"
        />
        <path
          id="Vector_3"
          d="M10.5 13.125L6.75 10.5"
          stroke="white"
          strokeWidth="1.4"
          strokeLinecap="round"
        />
        <path
          id="Vector_4"
          d="M14.25 13.875C14.25 14.9105 13.4105 15.75 12.375 15.75C11.3395 15.75 10.5 14.9105 10.5 13.875C10.5 12.8395 11.3395 12 12.375 12C13.4105 12 14.25 12.8395 14.25 13.875Z"
          stroke="white"
          strokeWidth="1.4"
        />
        <path
          id="Vector_5"
          d="M14.25 4.125C14.25 5.16053 13.4105 6 12.375 6C11.3395 6 10.5 5.16053 10.5 4.125C10.5 3.08947 11.3395 2.25 12.375 2.25C13.4105 2.25 14.25 3.08947 14.25 4.125Z"
          stroke="white"
          strokeWidth="1.4"
        />
      </g>
    </svg>
  )
}

export default SocialShareIcon
