import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from 'components/common/Container'
import Link from 'components/common/Link'
import c from './NoMatchPage.module.scss'

function NoMatchPage() {
  const { t } = useTranslation()

  return (
    <main className={c.main}>
      <Container>
        <p className={c.text}>{t('general.notFound')}</p>
        <Link className={c.link} to="/">
          {t('general.buttons.backToMainPage')}
        </Link>
      </Container>
    </main>
  )
}

export default NoMatchPage
