import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { saveAs } from 'file-saver'
import { toast } from 'react-toastify'

const useSave = (
  imageUrl: string | undefined,
  format: 'jpg' | 'mp4' = 'jpg',
) => {
  const { t } = useTranslation()

  const [isSaved, setIsSaved] = useState(false)

  const handleSave = useCallback(() => {
    if (imageUrl == null) return

    const fileName = imageUrl.split('/')[imageUrl.split('/').length - 1]

    try {
      fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          saveAs(blob, fileName)
        })
        .catch(() => {
          toast.error(t('error.download'))
        })
      setIsSaved(true)
      setTimeout(() => setIsSaved(false), 3000)
    } catch (e) {
      toast.error(t('error.download'))
    }
  }, [t, imageUrl])

  return { isSaved, handleSave }
}

export default useSave
