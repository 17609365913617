import { useInfiniteQuery } from '@tanstack/react-query'
import { auth } from 'utils/firebase'
import { getSearchResult } from '../../api'
import { SearchResponse } from '../../types/Search'

interface Params {
  userText: string | undefined
  limit: number
  category?: string
}

const useInfiniteFetchSearchResult = ({
  userText,
  limit,
  category,
}: Params) => {
  const getOffset = (page: number) => (page - 1) * limit

  return useInfiniteQuery<SearchResponse, Error>(
    ['infinite-search-result', userText],
    async ({ pageParam = 1 }) => {
      const offset = getOffset(pageParam)

      const token = await auth.currentUser?.getIdToken()
      if (token && userText) {
        return getSearchResult(userText, limit, offset)
      }
      throw new Error('Invalid token')
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (
          lastPage?.posts &&
          lastPage.posts.length > 0 &&
          category === 'posts'
        ) {
          return allPages.length + 1
        }
        if (
          lastPage?.users &&
          lastPage.users.length > 0 &&
          category === 'users'
        ) {
          return allPages.length + 1
        }
        return undefined
      },
      enabled: !!userText,
    },
  )
}

export default useInfiniteFetchSearchResult
