import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Loader from 'components/common/Loader'
import ImagePreviewSpace from 'components/ImagePreview/ImagePreviewSpace'
import ImageComparisonSpace from 'components/ImageComparison/ImageComparisonSpace'
import c from './WebviewPage.module.scss'

type WebviewMode = 'preview' | 'comparison' | undefined

const SOURCE_IMAGE_URL_KEY = 'source_image_url'
const PROCESSED_IMAGE_URL_KEY = 'processed_image_url'
const LANGUAGE_KEY = 'lang'

function WebviewPage() {
  const [params] = useSearchParams()

  const { i18n, t } = useTranslation()

  const sourceImageUrl = useMemo(() => {
    const url = params.get(SOURCE_IMAGE_URL_KEY)

    const parts = url?.split('?')

    const endpoint = decodeURIComponent(parts?.[0] || '')

    return `${endpoint}?${parts?.[1]}`
  }, [params])
  const processedImageUrl = useMemo(() => {
    const url = params.get(PROCESSED_IMAGE_URL_KEY)

    const parts = url?.split('?')

    const endpoint = decodeURIComponent(parts?.[0] || '')

    return `${endpoint}?${parts?.[1]}`
  }, [params])

  const mode = useMemo<WebviewMode>(() => {
    if (sourceImageUrl || processedImageUrl) {
      if (sourceImageUrl && processedImageUrl) return 'comparison'
      return 'preview'
    }
    return undefined
  }, [sourceImageUrl, processedImageUrl])

  const [loadedImagesCount, setLoadedImagesCount] = useState<number>(0)
  const [isImagesLoadFail, setIsImagesLoadFail] = useState<boolean>(false)

  const isAllImagesLoaded = useMemo<boolean>(() => {
    if (mode === 'preview') return loadedImagesCount === 1
    if (mode === 'comparison') return loadedImagesCount === 2
    return true
  }, [mode, loadedImagesCount])

  const handleImagesLoad = useCallback(() => {
    setLoadedImagesCount((prevLoadedImagesCount) => prevLoadedImagesCount + 1)
  }, [])

  const handleImagesLoadError = useCallback(() => {
    setIsImagesLoadFail(true)
  }, [])

  useEffect(() => {
    const lang = params.get(LANGUAGE_KEY)
    if (lang === 'ru') i18n.changeLanguage('ru')
    if (lang === 'en') i18n.changeLanguage('en')
  }, [])

  if (mode) {
    if (isImagesLoadFail) {
      return (
        <main className={c.main}>
          <p className={c.error}>{t('error.webviewImageDownload')}</p>
        </main>
      )
    }

    return (
      <main className={c.main}>
        {!isAllImagesLoaded && (
          <div className={c.loader}>
            <div className={c.loaderWrapper}>
              <Loader />
            </div>
            <p className={c.loaderText}>{t('general.imagesLoading')}</p>
          </div>
        )}

        {mode === 'preview' && (
          <ImagePreviewSpace
            imageUrl={sourceImageUrl || processedImageUrl!}
            onLoad={handleImagesLoad}
            onLoadError={handleImagesLoadError}
          />
        )}

        {mode === 'comparison' && (
          <ImageComparisonSpace
            imageUrl={sourceImageUrl!}
            sourceUrl={processedImageUrl!}
            // processedImageUrl={processedImageUrl!}
            onLoad={handleImagesLoad}
            onLoadError={handleImagesLoadError}
          />
        )}
      </main>
    )
  }

  return (
    <main className={c.main}>
      <p className={c.error}>{t('error.webview')}</p>
    </main>
  )
}

export default WebviewPage
