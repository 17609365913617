import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InView } from 'react-intersection-observer'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { sendViewedArray } from 'api'
import { Mousewheel, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import Loader from 'components/common/Loader'
import SwitcherPages from 'components/SwitcherPages'
import TrendingVideoPost from 'components/TrendingVideoPost'
import {
  pushViewedPosts,
  synchronizeArrays,
} from 'store/reducers/viewedReducer'
import useInfiniteFetchPosts from 'hooks/query/useInfiniteFetchPosts'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import MobileHeader from '../../components/MobileHeader'

import c from './TrendingVideoPage.module.scss'

function TrendingVideoPage() {
  const [isInitialRender, setIsInitialRender] = useState(true)
  const { freezedExceptions } = useAppSelector((state) => state.viewed)

  const [volume, setVolume] = useState(1)

  const setMuted = () => {
    if (volume <= 0) {
      setVolume(1)
    } else {
      setVolume(0)
    }
  }

  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const [viewedPosts, setViewedPosts] = useState<number[]>([])
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [allIndexs, setAllIndexs] = useState<number>(0)

  const mutationView = useMutation<void, Error>(() => {
    return sendViewedArray(viewedPosts)
  })

  const exceptionsRestructured = useMemo(
    () => freezedExceptions.map((elem) => elem.id),
    [freezedExceptions],
  )

  const {
    data: infinitePostsData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteFetchPosts({
    take: 6,
    type: 'trending',
    conclusion: 'video_only',
    // exceptions: exceptionsRestructured,
    enabled: !isInitialRender,
  })

  const postsData = useMemo(() => {
    if (infinitePostsData === undefined) return []
    return infinitePostsData.pages.flatMap((p) => p)
  }, [infinitePostsData])

  const onScrollToEnd = () => {
    if (isLoading) return
    fetchNextPage()
  }

  const viewHandle = (inView: boolean, postId: number) => {
    if (inView) {
      setViewedPosts((prev) => [...prev, postId])
    }
  }

  useEffect(() => {
    if (viewedPosts.length === 3) {
      onScrollToEnd()
    }
    if (viewedPosts.length === 6) {
      mutationView.mutate()
      dispatch(pushViewedPosts(viewedPosts))
      setViewedPosts([])
    }
  }, [viewedPosts])

  useEffect(() => {
    setIsInitialRender(false)
    dispatch(synchronizeArrays())
  }, [])

  return (
    <div className={c.feedPage}>
      <MobileHeader heading={t('navigation.bestVideo')} isOnGoBack />
      <div className={c.content}>
        <div className={c.switch}>
          <SwitcherPages />
        </div>
        <div className={c.feed}>
          <div className={c.videoShortsWrapper}>
            <Swiper
              modules={[Mousewheel, Navigation]}
              mousewheel
              direction="vertical"
              spaceBetween={30}
              slidesPerView={1}
              style={{ width: '100%', height: 'calc(100vh - 180px)' }}
              onSlideChange={(s) => {
                const current = s.slides[s.activeIndex]
                if (current) {
                  setActiveIndex(s.activeIndex)
                }
              }}
            >
              {postsData.map((elem, index) => (
                <SwiperSlide className={c.wrapper} key={elem.id}>
                  <TrendingVideoPost
                    volume={volume}
                    setMuted={setMuted}
                    post={elem}
                    trending
                    slideIndex={index}
                    activeIndex={activeIndex}
                  />
                  <InView
                    as="div"
                    onChange={(inView) => viewHandle(inView, elem.id)}
                    triggerOnce
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrendingVideoPage
