import React, { useCallback, useEffect, useRef, useState } from 'react'
import { PostFeed } from 'types/Post'
import { UserResponse } from 'types/User'
import { ReactComponent as SettingsIcon } from '@material-design-icons/svg/round/more_horiz.svg'

import { useTranslation } from 'react-i18next'
import i18n from 'i18n/config'

import c from './SettingsComment.module.scss'
import {PostComment} from "../../types/Comments";

interface SettingsCommentProps {
    comData: PostComment
    userData: UserResponse | null | undefined
    delete: () => void
}

function SettingsComment({
                          userData,
                          comData,
                          delete: deletePost,
                      }: SettingsCommentProps) {
    const [isSettingsOpen, setIsSettingsOpen] = useState(false)

    const settingsRef = useRef<HTMLDivElement>(null)

    const { t } = useTranslation()

    const toggleSettings = () => {
        setIsSettingsOpen(!isSettingsOpen)
    }

    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (
            settingsRef.current &&
            !settingsRef.current.contains(event.target as Node)
        ) {
            setIsSettingsOpen(false)
        }
    }, [])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [handleClickOutside])

    return (
        <div
            className={c.container}
            role="button"
            onClick={toggleSettings}
            onKeyPress={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                    toggleSettings();
                }
            }}
            style={
                isSettingsOpen
                    ? { backgroundColor: '#282631', borderRadius: '200px' }
                    : {}
            }
            ref={settingsRef}
            tabIndex={0}
        >
            <div className={c.content}>
                <div className={c.iconWrapper}>
                    <SettingsIcon fill="#D1D1D6" className={c.settingsIcon} />
                </div>
                {isSettingsOpen && (
                    <div className={c.settingsWrapper}>
                        <div className={c.settingsWindow}>
                            <div className={c.actions}>
                                {comData.user.id === userData?.id && (
                                    <div className={c.admin}>
                                        <button
                                            type="button"
                                            className={c.deleteAction}
                                            onClick={deletePost}
                                        >
                                            {t('profile.delete')}
                                        </button>
                                    </div>
                                )}
                                {userData?.role === 'admin' && (
                                    <button
                                        type="button"
                                        className={c.deleteAction}
                                        onClick={deletePost}
                                    >
                                        <span>{`${i18n.t('general.post.deletePost')}`}</span>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default SettingsComment;
