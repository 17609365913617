import React from 'react'

import AdvancedSettings from 'components/AdvancedSettings'
import Container from 'components/common/Container'
import GenerationStyles from 'components/GenerationStyles'
import { Style, Suggestion } from 'types/Generation'
import { fixedResolutions } from 'constants/advancedSettings'
import RequestGenerationContainer from '../../../RequestGenerationContainer'

import c from './IdleStage.module.scss'

interface IdleStageProps {
  text: string
  suggestions?: Suggestion[]
  styles?: Style[]
  chosenStyle: string | undefined
  onTextChange: (newText: string) => void
  onProcessingStart: () => void
  onExampleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  onStyleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  resolution: number
  setResolution: (newIndex: any) => void
  guidanceScale: number
  setGuidanceScale: (newIndex: number) => void
  seed: string | undefined
  setSeed: (newSeed: string) => void
  steps: number
  setSteps: (newStep: number) => void
  negativePrompt: string
  setNegativePrompt: (newPrompt: string) => void
  isAdvancedSettingsOpen: boolean
  setIsAdvancedSettingsOpen: (prev: boolean) => void
}

export default function IdleStage({
  text,
  suggestions = [],
  styles = [],
  chosenStyle,
  onTextChange,
  onProcessingStart,
  onExampleClick,
  onStyleClick,
  resolution,
  setResolution,
  guidanceScale,
  setGuidanceScale,
  seed,
  setSeed,
  steps,
  setSteps,
  negativePrompt,
  setNegativePrompt,
  isAdvancedSettingsOpen,
  setIsAdvancedSettingsOpen,
}: IdleStageProps) {
  return (
    <Container flex>
      <div className={c.content}>
        <RequestGenerationContainer
          generationTextAreaVisible
          negativePromptVisible
          promptTipsVisible
          text={text}
          negativePrompt={negativePrompt}
          setNegativePrompt={setNegativePrompt}
          onTextChange={onTextChange}
          onProcessingStart={onProcessingStart}
          onExampleClick={onExampleClick}
          suggestions={suggestions}
        />

        <GenerationStyles
          chosenStyle={chosenStyle}
          styles={styles}
          onStyleClick={onStyleClick}
        />

        <AdvancedSettings
          changeableResolutionSelectionVisible
          guidanceScaleSelectionVisible
          stepsSelectionVisible
          seedSelectionVisible
          fixedResolutionSelectionVisible
          fixedResolutions={fixedResolutions}
          isAdvancedSettingsOpen={isAdvancedSettingsOpen}
          setIsAdvancedSettingsOpen={setIsAdvancedSettingsOpen}
          resolution={resolution}
          setResolution={setResolution}
          guidanceScale={guidanceScale}
          setGuidanceScale={setGuidanceScale}
          seed={seed}
          setSeed={setSeed}
          steps={steps}
          setSteps={setSteps}
        />
      </div>
    </Container>
  )
}
