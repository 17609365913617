/* eslint-disable import/prefer-default-export */
import store from 'store'
import i18n from 'i18n/config'
import { auth } from 'utils/firebase'

const endpoint = process.env.REACT_APP_API_ENDPOINT || ''
export const linkDeviceToUser = async (): Promise<boolean> => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  if (!token) return false

  const result = await fetch(`${endpoint}/login`, {
    method: 'POST',
    headers: {
      'x-language': language,
      'X-Device-Id': deviceId,
      Authorization: `Bearer ${token}`,
    },
  })

  if (!result.ok) throw new Error(`${i18n.t('error.api')}: ${result.status}`)

  return true
}

export const checkStatus = async () => {
  const {
    settings: { deviceId, language },
  } = store.getState()

  const result = await fetch(`${endpoint}/status`, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      'x-language': language,
    },
  })

  if (result.status === 521) return true

  if (!result.ok) throw new Error(`${i18n.t('error.api')}: ${result.status}`)

  return true
}
