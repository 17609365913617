import { useEffect } from 'react'

function CalcFuncEmpty() {
  useEffect(() => {
    function calcWidth() {
      const mainEl = document.getElementById('main')
      const asideEl = document.getElementById('aside')
      const contentEl = document.getElementById('content')

      if (mainEl && asideEl && contentEl) {
        const mainWidth = mainEl.getBoundingClientRect().width
        const asideWidth = asideEl.getBoundingClientRect().width
        const contentTop = contentEl.getBoundingClientRect().top
        const contentWidth =
          mainWidth - (asideWidth + (window.innerWidth <= 640 ? 0 : 16))

        document.documentElement.style.setProperty(
          '--aside-width',
          `${asideWidth}px`,
        )
        document.documentElement.style.setProperty(
          '--main-width',
          `${mainWidth}px`,
        )
        document.documentElement.style.setProperty(
          '--content-width',
          `${contentWidth}px`,
        )
        document.documentElement.style.setProperty(
          '--content-top',
          `${contentTop}px`,
        )
      }
    }

    calcWidth()

    window.addEventListener('resize', calcWidth)

    return () => {
      window.removeEventListener('resize', calcWidth)
    }
  }, [])

  return null
}

export default CalcFuncEmpty
