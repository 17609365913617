import React from 'react'

import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import AddIcon from '../icons/AddIcon'
import c from './ChatPanelHeader.module.scss'

interface ChatPanelHeaderProps {
  toggleSubscriptionVisible: () => void
  subscriptionsVisible: boolean
}

function ChatPanelHeader({
  toggleSubscriptionVisible,
  subscriptionsVisible,
}: ChatPanelHeaderProps) {
  const { t } = useTranslation()

  return (
    <div className={c.container}>
      <div className={c.content}>
        <span className={c.content__Title}>
          {subscriptionsVisible ? t('profile.following') : t('aiChat.chats')}
        </span>
        <button
          className={c.content__Icon}
          type="button"
          onClick={toggleSubscriptionVisible}
        >
          <AddIcon
            className={classNames({
              [c.iconSetting]: true,
              [c.iconSettingActive]: subscriptionsVisible,
            })}
          />
        </button>
      </div>
    </div>
  )
}

export default ChatPanelHeader
