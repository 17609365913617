import { UserResponse } from '../types/User'
import { MessageItem } from '../types/Chats'

const isMessageFromCurrentUser = (
  currentUser: UserResponse,
  chatData: MessageItem,
) => {
  if (currentUser?.id && chatData?.sender?.id) {
    return chatData.sender.id === currentUser.id
  }
  return false
}

const isInbox = (
  currentUser: UserResponse | null | undefined,
  chatData: MessageItem,
) => {
  if (currentUser && chatData) {
    const isMe = isMessageFromCurrentUser(currentUser, chatData)
    return !isMe
  }
  return false
}

export default isInbox
