/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { PostComment } from 'types/Comments'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteComment, likeComment, unlikeComment } from 'api'
import UnLikeIcon from 'components/icons/UnLikeIcon'
import LikeIcon from 'components/icons/LikeIcon'
import useInfiniteFetchCommentsOnComment from 'hooks/query/useInfiniteFetchCommentsOnComment'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
import useModal from 'hooks/useModal'
import { useAppSelector } from 'hooks/store'
import { setLogInModalOpen } from 'store/reducers/modalReducer'
import Loader from 'components/common/Loader'
import { useInView } from 'react-intersection-observer'
import IconButton from 'components/common/IconButton'
import CopyIcon from 'components/icons/CopyIcon'
import { ReactComponent as CheckIcon } from '@material-design-icons/svg/round/check.svg'
import useCopy from 'hooks/useCopy'
import { Img } from 'react-image'
import LightBox from 'components/LightBox'
import { PostFeed } from '../../types/Post'
import SettingsComment from '../SettingsComment'
import VerifiedIcon from '../VerifiedIcon'
import ReplyIcon from '../icons/ReplyIcon'

import FullScreenIcon from '../icons/FullScreenIcon'
import CommentIcon from '../icons/CommentIcon'
import c from './Comment.module.scss'

interface CommentProps {
  com: PostComment
  postData: PostFeed
  setParentComment: (id: PostComment | undefined) => void
  parentComment: PostComment | undefined
  isRecursive?: boolean
  parentCommentImage?: string
  parentCommentComment?: string
  isFirst?: boolean
}

function Comment({
  com,
  setParentComment,
  postData,
  isRecursive = false,
  parentComment,
  parentCommentImage = '',
  parentCommentComment = '',
  isFirst = false,
}: CommentProps) {
  const [isLiked, setIsLiked] = useState<boolean>(com.is_like)
  const [likesCount, setLikesCount] = useState<number>(com.likes_count)
  const { data: myProfileData } = useFetchRequestMyProfile()
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [seeMore, setSeeMore] = useState<boolean>(false)

  const {
    data: commentsOnCommentData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteFetchCommentsOnComment({
    postId: com?.post_id,
    commentId: com?.id!,
  })

  const queryClient = useQueryClient()

  const { data: user } = useFetchRequestMyProfile()

  const { id } = useParams()

  const { ref, inView } = useInView()

  const { t } = useTranslation()
  const { logInModalOpen } = useAppSelector((state) => state.modal)
  const { open: openLogInModal } = useModal(logInModalOpen, setLogInModalOpen)

  const mutationLike = useMutation<void, Error>(() => {
    return likeComment(com.id!)
  })
  const mutationUnlike = useMutation<void, Error>(() => {
    return unlikeComment(com.id!)
  })
  const mutationDelete = useMutation<void, Error>(() => {
    return deleteComment(com.id!)
  })
  const { isCopied, handleCopy } = useCopy(com.asset?.source)

  useEffect(() => {
    if (isLoading) return
    fetchNextPage()
  }, [inView])

  const commentsOnComment = useMemo(() => {
    if (commentsOnCommentData === undefined) return { items: [], meta: {} }

    const combinedItems = commentsOnCommentData.pages.flatMap((p) => p.items)
    const lastMeta =
      commentsOnCommentData.pages[commentsOnCommentData.pages.length - 1].meta

    return {
      items: combinedItems,
      meta: lastMeta,
    }
  }, [commentsOnCommentData])

  const likeHandler = () => {
    if (com.user.id !== myProfileData?.id) {
      if (isLiked) {
        setLikesCount((prev) => prev - 1)
        setIsLiked(false)
        mutationUnlike.mutate()
      } else {
        setLikesCount((prev) => prev + 1)
        setIsLiked(true)
        mutationLike.mutate()
      }
    } else {
      toast.error(t('modals.comment.likeError'))
    }
  }

  const handleFullScreenOpen = () => {
    setIsLightboxOpen(true)
  }

  const handleFullScreenClose = () => {
    setIsLightboxOpen(false)
  }

  const handleImageLoaded = useCallback(() => {
    setIsImageLoaded(true)
  }, [])

  const handlerDeleteComment = () => {
    const result = window.confirm('Are you sure want to delete this comment?')
    if (result) {
      mutationDelete.mutateAsync().then(() => {
        queryClient.invalidateQueries([`post`, id])
        queryClient.refetchQueries([`infinite-comments`]).then(() => {
          toast.success('Everything went well')
        })
      })
    }
  }

  const handleSeeMore = () => {
    setSeeMore(!seeMore)
  }

  const copyIcon = useMemo(() => {
    return isCopied ? (
      <CheckIcon className={c.icon} style={{ fill: 'currentColor' }} />
    ) : (
      <CopyIcon className={c.icon} style={{ fill: 'currentColor' }} />
    )
  }, [isCopied])

  useEffect(() => {
    const mainContent = document.getElementById('generationContainerMain')
    if (mainContent) {
      if (isLightboxOpen) {
        mainContent.style.zIndex = '100'
      } else {
        mainContent.style.zIndex = 'auto'
      }
    }
  }, [isLightboxOpen])

  return (
    com.asset && (
      <div className={`${c.post} ${isRecursive ? c.recursivePost : ''}`}>
        {isRecursive && <div id={c.recursiveLine} />}
        <div className={c.postTop}>
          <div className={c.postUser}>
            <div className={c.avatarWrapper}>
              {com.user.avatar && (
                <img className={c.avatar} src={com.user.avatar} alt="" />
              )}
            </div>
            <div className={c.nicknameWrapper}>
              <p className={c.nickname}>
                {com.user.nickname}{' '}
                {com.user.is_paid_subscription && <VerifiedIcon />}
              </p>
              <NavLink
                className={c.link}
                to={`/profile/${com.user.nickname}`}
                end
              />
            </div>
          </div>
          {isRecursive && (
            <div className={c.replyContainer}>
              <div className={c.replyIconWrapper}>
                <ReplyIcon className={c.iconReply} />
              </div>
              <div className={c.imgWrapper}>
                <img
                  src={parentCommentImage}
                  alt="replyImg"
                  className={c.replyCommentImg}
                />
              </div>
              <div className={c.replyText}>
                <p>{parentCommentComment}</p>
              </div>
            </div>
          )}
          {(user?.role === 'admin' || com.user.id === user?.id) && (
            <div className={c.settingsPost}>
              <SettingsComment
                userData={user}
                comData={com}
                delete={handlerDeleteComment}
              />
            </div>
          )}
        </div>
        {com.asset?.source_type !== 'video' && com.asset?.url && (
          <div className={c.imgWrapper}>
            <div className={c.postImgContainer}>
              <Img
                src={com.asset?.url}
                className={c.postImg}
                loader={<Loader />}
                onLoad={handleImageLoaded}
              />
              <button
                type="button"
                onClick={handleFullScreenOpen}
                className={c.wrapper}
              >
                {isImageLoaded && (
                  <div className={c.fullScreenContainer}>
                    <div className={c.iconSetting}>
                      <FullScreenIcon />
                    </div>
                  </div>
                )}
              </button>
            </div>
          </div>
        )}

        {isLightboxOpen && com.asset?.url && (
          <LightBox
            imageUrl={com.asset?.url}
            onCloseRequest={handleFullScreenClose}
            sourceUrl={com.asset.source_url}
          />
        )}
        <div className={c.postBottom}>
          {com.asset?.source_type !== 'image' && (
            <>
              <div className={c.text}>{com.asset?.source}</div>
              <IconButton
                className={c.postCopy}
                disabled={isCopied}
                onClick={handleCopy}
              >
                {copyIcon}
              </IconButton>
            </>
          )}
        </div>
        <div className={c.postTags}>
          {postData.handler === 'William_Henry_Harrison' && (
            <div className={c.textStyleContainer}>
              <span className={c.textStyleSub}>{t('feed.dalle3')}</span>
            </div>
          )}
          {postData.is_related && (
            <div className={c.textStyleContainer}>
              <span className={c.textStyleSub}>{t('feed.recommended')}</span>
            </div>
          )}
          {postData.assets?.[0]?.style &&
            postData.assets?.[0]?.style !== 'undefined' && (
              <div className={c.textStyleContainer}>
                <span className={c.textStyle}>
                  {postData.assets?.[0]?.style}
                </span>
              </div>
            )}
        </div>
        <div className={c.postFeedBack}>
          <div className={c.toolsFeedback}>
            <div className={c.leftContainerPart}>
              <div className={c.feedBackWrapper}>
                <div
                  className={c.postToolButton}
                  onClick={!user?.id ? openLogInModal : likeHandler}
                  onKeyDown={!user?.id ? openLogInModal : likeHandler}
                  role="button"
                  tabIndex={0}
                >
                  <div className={c.postToolButtonIcon}>
                    <UnLikeIcon className={c.iconSetting} />
                    <div
                      className={`${c.favoredIcon} ${isLiked ? c.favored : ''}`}
                    >
                      <LikeIcon className={c.iconSetting} />
                    </div>
                  </div>
                  <p className={c.postToolButtonCount}>{likesCount}</p>
                </div>
                <button type="button" className={c.postToolButton}>
                  <div className={c.postToolButtonIcon} onClick={handleSeeMore}>
                    <CommentIcon className={c.iconSetting} />
                  </div>
                  <p className={c.postToolButtonCount}>
                    {
                      commentsOnComment.items.filter(
                        (comment) => !comment.is_deleted,
                      ).length
                    }
                  </p>
                </button>
              </div>
              {commentsOnComment.items.length > 0 && (
                <button
                  className={c.more}
                  type="button"
                  onClick={handleSeeMore}
                >
                  {seeMore
                    ? t('modals.comment.hide')
                    : `${t('modals.comment.more')} (${
                        commentsOnComment.items.filter(
                          (comment) => !comment.is_deleted,
                        ).length
                      })`}
                </button>
              )}
            </div>
            <div className={c.rightContainerPart}>
              <div className={c.postReply}>
                <NavLink
                  to={`/trending-post/${id}/comments/${com.id}`}
                  end
                  className={c.postToolButton}
                >
                  <div className={c.postToolButtonIcon}>
                    <CommentIcon
                      className={c.iconSetting}
                      onClick={handleSeeMore}
                    />
                  </div>
                  <p className={c.postToolButtonCount}>
                    {t('modals.comment.reply')}
                  </p>
                </NavLink>
              </div>

              {commentsOnComment.items.length > 0 && (
                <button
                  className={c.more}
                  type="button"
                  onClick={handleSeeMore}
                >
                  {seeMore
                    ? t('modals.comment.hide')
                    : `${t('modals.comment.more')} (${
                        commentsOnComment.items.filter(
                          (comment) => !comment.is_deleted,
                        ).length
                      })`}
                </button>
              )}
            </div>
          </div>
        </div>
        <div className={isFirst ? c.commentsWrapper : ''}>
          {commentsOnComment.items.filter((comment) => !comment.is_deleted)
            .length > 0 && (
            <div
              className={`${c.comments}`}
              style={{
                display: seeMore ? 'flex' : 'none',
              }}
            >
              {commentsOnComment.items.filter((comment) => !comment.is_deleted)
                .length > 0 &&
                commentsOnComment.items.map(
                  (comment, _index) =>
                    com.is_deleted ||
                    (com.asset && (
                      <Comment
                        parentCommentComment={com.asset?.source}
                        parentCommentImage={com.asset?.url}
                        isRecursive
                        parentComment={parentComment}
                        postData={postData}
                        com={comment}
                        key={comment.id}
                        setParentComment={setParentComment}
                      />
                    )),
                )}
              {!isLoading && !isFetchingNextPage && hasNextPage && (
                <div ref={ref} />
              )}
              {isFetchingNextPage && (
                <div className={c.loaderWrapper}>
                  <div className={c.loader}>
                    <Loader />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  )
}

export default Comment
