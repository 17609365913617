import React, { useState, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import SearchIcon from 'components/icons/SearchIcon'
import c from './SearchForm.module.scss'

function SearchForm() {
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const searchValue = searchParams.get('text') || ''

  const { t } = useTranslation()

  const [text, setText] = useState<string>(searchValue || '')

  const handleClick = () => {
    if (!text) {
      setSearchParams()
      return
    }
    setSearchParams({ text })
  }

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value)

    const textarea = textareaRef.current
    if (!textarea) return

    textarea.style.height = 'auto'
    const scHeight = event.target.scrollHeight
    textarea.style.height = `${scHeight}px`
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleClick()
    }
  }

  return (
    <div className={c.container}>
      <textarea
        placeholder={t('general.search.searchTerm')}
        className={c.textarea}
        value={text}
        rows={1}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        ref={textareaRef}
      />
      <button type="button" className={c.button} onClick={handleClick}>
        <SearchIcon />
      </button>
    </div>
  )
}

export default SearchForm;
