import React, { useCallback, useEffect, useState } from 'react'
import useFetchRequest from 'hooks/query/useFetchRequest'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import IconButton from 'components/common/IconButton'
import { ReactComponent as ArrowBackIcon } from '@material-design-icons/svg/round/arrow_back_ios.svg'
import { ReactComponent as DownloadIcon } from '@material-design-icons/svg/outlined/file_download.svg'
import ArchivePost from 'components/ArchivePost'
import useSave from 'hooks/useSave'
import Processor, {
  GenerateStrategy,
  MixerStrategy,
  VariationsStrategy,
  VideoStrategy,
} from 'api/Retomagic'
import { changeProcessedAssets, changeStage } from 'store/reducers/stageReducer'
import {
  ProcessedAssetsChangedPayload,
  ProcessorEvents,
} from 'types/ProcessorEvents'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import GenerationProcessingStage from 'components/stages/generation/ProcessingStage'
import VideoProcessingStage from 'components/stages/video/ProcessingStage'
import MixerProcessingStage from 'components/stages/mixer/ProcessingStage'
import VariationsProcessingStage from 'components/stages/variations/ProcessingStage'
import Container from 'components/common/Container'
import useFetchStyles from 'hooks/query/useFetchStyles'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'

import s from './ArchivePostPage.module.scss'

function ArchivePostPage() {
  const { id } = useParams()

  const navigate = useNavigate()
  const { stage } = useAppSelector((state) => state.stage)
  const dispatch = useAppDispatch()
  const { data: stylesData } = useFetchStyles()
  const [seconds, setSeconds] = useState<number | undefined>(0)

  const { data: archiveData } = useFetchRequest({ requestId: id })
  const [currentSlide, setCurrentSlide] = useState<number>(0)
  const [handler, setHandler] = useState<string>(archiveData?.handler!)
  const { handleSave } = useSave(archiveData?.assets[currentSlide]?.url)

  const { data: you } = useFetchRequestMyProfile()

  const location = useLocation()
  const { postsData, index } = location.state || {
    postsData: undefined,
    index: undefined,
  }

  const onGoBack = () => {
    navigate(`/profile/${you?.nickname}`)
  }

  useEffect(() => {
    setHandler(archiveData?.handler!)
  }, [archiveData])

  const prevHandler = () => {
    if (currentSlide > 0) setCurrentSlide((prev) => prev - 1)
  }
  const nextHandler = () => {
    if (currentSlide + 1 < archiveData!.assets?.length)
      setCurrentSlide((prev) => prev + 1)
  }

  const [generationProcessor, setGenerationProcessor] =
    useState<Processor | null>(null)

  const handleProcessedAssetsChanged = useCallback(
    (payload: ProcessedAssetsChangedPayload) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { assets, isFirstSucceededResult, id } = payload

      dispatch(changeProcessedAssets(assets))
      dispatch(changeStage('idle'))

      if (isFirstSucceededResult) {
        if (handler === 'John_Adams') {
          navigate(`/generation/finish/${id}`)
        } else if (handler === 'James_Madison') {
          navigate(`/magic-variations/finish/${id}`)
        } else if (handler === 'Andrew_Jackson') {
          navigate(`/ai-avatars/finish/${id}`)
        } else if (handler === 'John_Quincy_Adams') {
          navigate(`/video-art/finish/${id}`)
        }
      }
    },
    [handler],
  )

  const handleProcessingStart = useCallback(async () => {
    dispatch(changeStage('processing'))
    const thisStyle = stylesData.find(
      (elem) => elem.title === archiveData?.assets?.[0]?.style,
    )

    let strategy:
      | VariationsStrategy
      | GenerateStrategy
      | MixerStrategy
      | VideoStrategy

    if (handler === 'John_Adams') {
      strategy = new GenerateStrategy(
        archiveData?.assets?.[0]?.source!,
        thisStyle?.title,
        thisStyle?.text,
        String(archiveData?.id),
      )
    } else if (handler === 'John_Quincy_Adams') {
      strategy = new VideoStrategy(archiveData?.assets?.[0]?.source!)
    } else if (handler === 'James_Madison') {
      strategy = new VariationsStrategy({
        image: '',
        request: archiveData?.assets?.[0]?.source!,
        strength: '',
        styleText: '',
        styleTitle: '',
        requestId: String(archiveData?.id),
      })
    } else if (handler === 'Andrew_Jackson') {
      strategy = new MixerStrategy({
        image: undefined,
        text: archiveData?.assets?.[0]?.source!,
        requestId: String(archiveData?.id),
      })
    }

    const processor = new Processor(strategy!)
    processor.on(
      ProcessorEvents.PROCESSED_ASSETS_CHANGED,
      handleProcessedAssetsChanged,
    )
    if (archiveData?.assets[0].source_type === 'video') {
      await processor.start()
      setSeconds(strategy!.getSeconds())
    } else {
      await processor.repeat()
      setSeconds(strategy!.getSeconds())
    }
    setGenerationProcessor(processor)
  }, [archiveData, handleProcessedAssetsChanged, handler])

  useEffect(() => {
    return () => {
      if (stage === 'processing') {
        dispatch(changeProcessedAssets([]))
        setGenerationProcessor(null)
        generationProcessor?.stop()
        dispatch(changeStage('idle'))
      }
    }
  }, [generationProcessor])

  return (
    <>
      {stage === 'idle' && (
        <div className={s.postPage}>
          <Container flex>
            <div className={s.navigationPanel}>
              <IconButton onClick={onGoBack} className={s.goBackButton}>
                <ArrowBackIcon style={{ fill: 'currentColor' }} />
              </IconButton>

              <IconButton onClick={handleSave} className={s.goBackButton}>
                <DownloadIcon style={{ fill: 'currentColor' }} />
              </IconButton>
            </div>
            {archiveData && (
              <div className={s.content}>
                <div className={s.postWrapper}>
                  <ArchivePost
                    post={archiveData}
                    imgClassName={s.postImage}
                    prevHandler={prevHandler}
                    nextHandler={nextHandler}
                    currentSlide={currentSlide}
                    setCurrentSlide={setCurrentSlide}
                    onGenerateMore={handleProcessingStart}
                    key={archiveData.id}
                    postsData={postsData}
                    currentPostId={index}
                  />
                </div>
              </div>
            )}
          </Container>
        </div>
      )}

      {stage === 'processing' && (
        <Container flex>
          {archiveData?.handler === 'John_Adams' && (
            <GenerationProcessingStage seconds={seconds!} />
          )}
          {archiveData?.assets[0].source_type === 'video' && (
            <VideoProcessingStage seconds={seconds!} />
          )}
          {archiveData?.handler === 'James_Madison' && (
            <VariationsProcessingStage seconds={seconds!} />
          )}
          {archiveData?.handler === 'Andrew_Jackson' && (
            <MixerProcessingStage seconds={seconds!} />
          )}
        </Container>
      )}
    </>
  )
}

export default ArchivePostPage
