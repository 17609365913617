import React from 'react'

import NeuroArtIcon from 'components/icons/NeuroArtIcon.svg'
import MagicVariationsIcon from 'components/icons/MagicVariationsIcon.svg'
import Dalle3Icon from 'components/icons/dalle3Icon.svg'
import AiChatIcon from 'components/icons/AiChatIcon.svg'
import AdvertisementIcon from 'components/icons/AdvertisementIcon.svg'
import VerifiedIcon from 'components/icons/VerifiedIcon.svg'

import { useTranslation } from 'react-i18next'

function getPaymentModalList() {
  const { t } = useTranslation()

  const paymentDesc = [
    {
      id: 1,
      desc: t('modals.payment.enhance'),
      icon: NeuroArtIcon,
    },
    {
      id: 2,
      desc: t('modals.payment.variations'),
      icon: MagicVariationsIcon,
    },
    {
      id: 3,
      desc: t('modals.payment.dalle3'),
      icon: Dalle3Icon,
    },
    {
      id: 4,
      desc: t('modals.payment.aiChat'),
      icon: AiChatIcon,
    },
    {
      id: 5,
      desc: t('modals.payment.ads'),
      icon: AdvertisementIcon,
    },
    {
      id: 6,
      desc: t('modals.payment.badge'),
      icon: VerifiedIcon,
    },
  ]

  const typesOfSubscription = [
    {
      id: 'price_1PGMNpLcmc4EIicsNdMuBp4j',
      period: t('modals.payment.periods.month.title'),
      price: `$9,99 / ${t('modals.payment.periods.month.interval')}`,
      tips: '',
      isPopular: false,
    },
    {
      id: 'price_1PGMOLLcmc4EIicshX1JxXBm',
      period: t('modals.payment.periods.3month.title'),
      price: `$24,99 / ${t('modals.payment.periods.3month.interval')}`,
      tips: `${t('modals.payment.discount')} 20%`,
      isPopular: false,
    },
    {
      id: 'price_1PGMOXLcmc4EIicslxxP8GSr',
      period: t('modals.payment.periods.year.title'),
      price: `$79,99 / ${t('modals.payment.periods.year.interval')}`,
      tips: `${t('modals.payment.discount')} 35.5%`,
      isPopular: true,
    },
  ]

  return {
    paymentDesc,
    typesOfSubscription,
  }
}

export default getPaymentModalList
