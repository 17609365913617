import React from 'react'
import c from './ImagePreviewSpace.module.scss'

interface ImagePreviewSpaceProps {
  imageUrl: string
  onLoad?: () => void
  onLoadError?: () => void
}

function ImagePreviewSpace({
  imageUrl,
  onLoad = undefined,
  onLoadError = undefined,
}: ImagePreviewSpaceProps) {
  return (
    <div className={c.wrapper}>
      <img
        className={c.image}
        src={imageUrl}
        alt=""
        onLoad={onLoad}
        onError={onLoadError}
      />
    </div>
  )
}

export default ImagePreviewSpace
