import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ReactComponent as SettingsIcon } from '@material-design-icons/svg/round/more_horiz.svg'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import i18n from 'i18n/config'
import c from './SettingsChat.module.scss'
import DeleteIIcon from '../icons/DeleteIIcon'
import { clearChat } from '../../api/chats'
import { ThemesResponse } from '../../types/Chats'

interface SettingsChatProps {
  theme?: ThemesResponse | undefined
}

function SettingsPost({ theme = undefined }: SettingsChatProps) {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const settingsRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()

  const { id } = useParams()

  const mutationClear = useMutation<void, Error>(() => {
    return clearChat(id, theme?.id)
  })

  const handleClearChat = () => {
    mutationClear.mutate()
  }

  const toggleSettings = () => {
    setIsSettingsOpen(!isSettingsOpen)
  }

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      settingsRef.current &&
      !settingsRef.current.contains(event.target as Node)
    ) {
      setIsSettingsOpen(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  return (
    <div
      className={c.container}
      role="button"
      onClick={toggleSettings}
      onKeyPress={(event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          toggleSettings()
        }
      }}
      style={
        isSettingsOpen
          ? { backgroundColor: '#282631', borderRadius: '200px' }
          : {}
      }
      ref={settingsRef}
      tabIndex={0}
    >
      <div className={c.content}>
        <div className={c.iconWrapper}>
          <SettingsIcon fill="#D1D1D6" className={c.settingsIcon} />
        </div>
        <div
          className={classNames({
            [c.settingsWrapper]: true,
            [c.open]: isSettingsOpen,
          })}
        >
          <div className={c.settingsWindow}>
            <div className={c.actions}>
              <button
                type="button"
                onClick={handleClearChat}
                className={c.deleteAction}
              >
                <DeleteIIcon className={c.iconSetting} />
                {`${i18n.t('aiChat.clearChat')}`}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingsPost;
