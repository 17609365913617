import React, { KeyboardEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import classNames from 'classnames'

import { clearChat, createThemes } from '../../api/chats'
import useFetchRequestMyProfile from '../../hooks/query/useFetchRequestMyProfile'
import { useWebSocketContext } from '../../providers/WebSocket'
import { ThemesResponse } from '../../types/Chats'
import ArrowDown from '../icons/ArrowDown'
import LockIcon from '../icons/LockIcon'

import c from './ChatInputMessage.module.scss'

interface ChatInputMessageProps {
  isWriting: boolean
  theme?: ThemesResponse
}

function ChatInputMessage({
  isWriting,
  theme = undefined,
}: ChatInputMessageProps) {
  const [textMessage, setTextMessage] = useState<string>('')
  const [textareaHeight, setTextareaHeight] = useState('52px') // New state for textarea height

  const { t } = useTranslation()

  const { socket } = useWebSocketContext()

  const { data: user } = useFetchRequestMyProfile()

  const { id } = useParams()

  const hasAssistantMessage = id === '23332' && !user?.is_paid_subscription

  const isDisabled = isWriting && id === '23332' && user?.is_paid_subscription

  const updateTextareaHeight = useCallback((text: string) => {
    const minHeight = 52
    const maxHeight = 202

    const tempTextarea = document.createElement('textarea')
    tempTextarea.style.visibility = 'hidden'
    tempTextarea.style.position = 'absolute'
    tempTextarea.style.height = 'auto'
    tempTextarea.value = text
    document.body.appendChild(tempTextarea)

    const newHeight = Math.min(
      Math.max(tempTextarea.scrollHeight, minHeight),
      maxHeight,
    )

    setTextareaHeight(`${newHeight}px`)
    document.body.removeChild(tempTextarea)
  }, [])

  const sendMessage = () => {
    if (textMessage.trim().length > 0 && socket) {
      if (!(id === '23332' && !user?.is_paid_subscription)) {
        socket.emit('chatMessage', {
          recipient: id,
          theme: theme?.id,
          text: textMessage.trim(),
        })
      }
      setTextMessage('')
      setTextareaHeight('52px')
    }
  }

  const onEnterPress = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.keyCode === 13 && !e.shiftKey && !isDisabled) {
        e.preventDefault()
        sendMessage()
      }
    },
    [textMessage, sendMessage],
  )

  return (
    <div className={c.container}>
      <form
        className={c.content}
        onSubmit={(e) => {
          e.preventDefault()
          sendMessage()
        }}
      >
        <div className={c.wrapperInput}>
          <textarea
            onKeyDown={onEnterPress}
            onChange={(e) => {
              setTextMessage(e.currentTarget.value)
              updateTextareaHeight(e.currentTarget.value)
            }}
            value={textMessage}
            style={{ height: textareaHeight }}
            className={c.input}
            placeholder={t('aiChat.messageInput')}
          />
        </div>
        <div className={c.buttonsContainer}>
          {user?.role !== 'admin' ? (
            <button type="button" className={c.wrapperButtonUnPaid}>
              <LockIcon />
            </button>
          ) : (
            <button
              type="submit"
              disabled={user?.role !== 'admin'}
              className={classNames({
                [c.wrapperButtonPaid]: true,
                [c.active]:
                  (id === '23332' && user?.is_paid_subscription) ||
                  !(id === '23332' && !user?.is_paid_subscription),
                [c.disabled]: user?.role !== 'admin',
              })}
            >
              <ArrowDown style={{ width: '24px', height: '24px' }} />
            </button>
          )}
        </div>
      </form>
    </div>
  )
}

export default ChatInputMessage
