import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import c from './ConfirmModal.module.scss'

interface ConfirmModalProps {
  confirmFunc: () => void
  cancelFunc: () => void
  isModalOpen: boolean
  heading: string
}

export default function ConfirmModal({
  confirmFunc,
  cancelFunc,
  heading,
  isModalOpen,
}: ConfirmModalProps) {
  const { t } = useTranslation()

  return (
    <div
      className={classNames({
        [c.containerModal]: true,
        [c.containerModalOpen]: isModalOpen,
        [c.containerModalClose]: !isModalOpen,
      })}
    >
      <div className={c.modalContent}>
        <span className={c.modalTitle}>{heading}</span>
        <div className={c.modalActions}>
          <button
            type="button"
            className={c.confirmButton}
            onClick={confirmFunc}
          >
            {t('stages.enhancement.idle.yes')}
          </button>
          <button type="button" className={c.cancelButton} onClick={cancelFunc}>
            {t('stages.enhancement.idle.no')}
          </button>
        </div>
      </div>
    </div>
  )
}
