import React, { useEffect, useMemo, useState } from 'react'
import useFetchRequestPost from 'hooks/query/useFetchRequestPost'
import { useNavigate, useParams, NavLink, useLocation } from 'react-router-dom'
import IconButton from 'components/common/IconButton'
import { ReactComponent as ArrowBackIcon } from '@material-design-icons/svg/round/arrow_back_ios.svg'
import { ReactComponent as AutoFixIcon } from '@material-design-icons/svg/round/auto_fix_high.svg'
import Container from 'components/common/Container'
import Post from 'components/Post'
import { EffectCoverflow, Pagination, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useMutation } from '@tanstack/react-query'
import { viewedRecommendations } from 'api'
import useFetchRecommendations from 'hooks/query/useFetchRecommendations'
import useInfiniteFetchComments from 'hooks/query/useInfiniteFetchComments'
import { useTranslation } from 'react-i18next'
import useModal from 'hooks/useModal'
import { setCommentModalOpen } from 'store/reducers/modalReducer'
import { useAppSelector } from 'hooks/store'
import Loader from 'components/common/Loader'
import { useInView } from 'react-intersection-observer'
import Comment from 'components/Comment'
import CommentModal from 'components/modals/CommentModal'
import { PostComment } from 'types/Comments'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'

import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'

import c from './PostPage.module.scss'

function PostPage() {
  const { id } = useParams()
  const { t } = useTranslation()
  const {
    data: commentsData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteFetchComments({ postId: Number(id!) })

  const { commentModalOpen } = useAppSelector((state) => state.modal)

  const { data: you } = useFetchRequestMyProfile()

  const location = useLocation()
  const { postsData, index } = location.state || {
    postsData: undefined,
    index: undefined,
  }

  const { open: openCommentModal, close: closeCommentModal } = useModal(
    commentModalOpen,
    setCommentModalOpen,
  )

  const { data: recommendations } = useFetchRecommendations({
    postId: Number(id)!,
  })
  const [parentComment, setParentComment] = useState<PostComment | undefined>(
    undefined,
  )

  const { ref, inView } = useInView()

  const comments = useMemo(() => {
    if (commentsData === undefined) return { items: [], meta: {} }

    const combinedItems = commentsData.pages.flatMap((p) => p.items)
    const lastMeta = commentsData.pages[commentsData.pages.length - 1].meta

    return {
      items: combinedItems,
      meta: lastMeta,
    }
  }, [commentsData])

  useEffect(() => {
    if (isLoading) return
    fetchNextPage()
  }, [inView])

  const navigate = useNavigate()

  const { data: postData } = useFetchRequestPost({ id })

  const onGoBack = () => {
    navigate(-1)
  }

  const mutationView = useMutation<void, Error, number>(
    (selected_post: number) => {
      const recommendationIds = recommendations?.map((r) => r.id)
      return viewedRecommendations(
        Number(id),
        recommendationIds!,
        selected_post,
      )
    },
  )
  const handleViewRecommendation = (selected_post: number) => {
    mutationView.mutate(selected_post)
  }

  return (
    <div className={c.postPage}>
      <Container flex>
        <div className={c.navigation}>
          <IconButton onClick={onGoBack} className={c.goBackButton}>
            <ArrowBackIcon style={{ fill: 'currentColor' }} />
          </IconButton>
        </div>
        {postData && (
          <div className={c.content}>
            <div className={c.postWrapper}>
              <Post
                post={postData}
                imgClassName={c.postImage}
                postsData={postsData}
                currentPostId={index}
              />
              <Swiper
                effect="coverflow"
                grabCursor
                centeredSlides
                slidesPerView="auto"
                initialSlide={1}
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true,
                }}
                speed={200}
                modules={[EffectCoverflow, Pagination, Mousewheel]}
                mousewheel
                className={`mySwiper ${c.recommendations__swiper}`}
              >
                {recommendations &&
                  recommendations?.length > 0 &&
                  recommendations.map((r) => (
                    <SwiperSlide
                      className={c.recommendations__swiperItem}
                      onClick={() => handleViewRecommendation(r.id)}
                      key={r.id}
                    >
                      <img src={r.assets[0].url} alt="" />
                      <NavLink
                        className={c.link}
                        to={`/trending-post/${r.id}`}
                        end
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
              {you && postData.assets[0].source_type !== 'video' && (
                <div className={c.leaveComment}>
                  <button
                    type="button"
                    className={c.comment}
                    onClick={openCommentModal}
                  >
                    <AutoFixIcon style={{ fill: 'currentColor' }} />
                    <div className={c.comment__text}>
                      {t('general.buttons.comment')}
                      qweqwe
                    </div>
                  </button>
                </div>
              )}
              <div className={c.comments}>
                {comments.items.map(
                  (com) =>
                    com.is_deleted || (
                      <Comment
                        parentComment={parentComment}
                        postData={postData}
                        com={com}
                        key={com.id}
                        setParentComment={setParentComment}
                      />
                    ),
                )}
                {!isLoading && !isFetchingNextPage && hasNextPage && (
                  <div ref={ref} />
                )}
                {isFetchingNextPage && (
                  <div className={c.loaderWrapper}>
                    <div className={c.loader}>
                      <Loader />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Container>
      <CommentModal
        open={commentModalOpen}
        onClose={closeCommentModal}
        setParentComment={setParentComment}
        parentComment={parentComment}
      />
    </div>
  )
}

export default PostPage
