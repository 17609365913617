import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'hooks/store'
import useFetchRequest from 'hooks/query/useFetchRequest'
import {
  changeCurrentAssetIndex,
  changeProcessedAssets,
  changeStage,
} from 'store/reducers/stageReducer'
import Processor, { MixerStrategy } from 'api/Retomagic'
import {
  ProcessedAssetsChangedPayload,
  ProcessorEvents,
} from 'types/ProcessorEvents'

import FinishStage from 'components/stages/mixer/FinishStage'
import ProcessingStage from 'components/stages/mixer/ProcessingStage'
import Container from 'components/common/Container'

import c from './MixerFinishPage.module.scss'

function MixerFinishPage() {
  const { stage, processedAssets, currentAssetIndex } = useAppSelector(
    (state) => state.stage,
  )
  const dispatch = useAppDispatch()
  const [seconds, setSeconds] = useState<number | undefined>(0)
  const [text, setText] = useState<string | undefined>('')
  const [sharedSlides, setSharedSlides] = useState<number[]>([])

  const navigate = useNavigate()

  const [generationProcessor, setGenerationProcessor] =
    useState<Processor | null>(null)

  const { requestId } = useParams()
  const { data, isLoading } = useFetchRequest({
    requestId,
  })
  // useEffect(() => {
  //   if (sharedSlides.length > 0) setSharedSlides([])
  // }, [requestId])

  const handleProcessedAssetsChanged = useCallback(
    (payload: ProcessedAssetsChangedPayload) => {
      const { assets, isFirstSucceededResult, id } = payload

      dispatch(changeProcessedAssets(assets))
      dispatch(changeStage('idle'))

      if (isFirstSucceededResult) {
        navigate(`/ai-avatars/finish/${id}`)
      }
    },
    [],
  )

  const handleProcessingStart = useCallback(async () => {
    if (!text) return
    dispatch(changeStage('processing'))

    const strategy = new MixerStrategy({
      image: undefined,
      text,
      requestId,
    })

    const processor = new Processor(strategy)

    processor.on(
      ProcessorEvents.PROCESSED_ASSETS_CHANGED,
      handleProcessedAssetsChanged,
    )

    await processor.repeat()
    setSeconds(strategy.getSeconds())
    setGenerationProcessor(processor)
  }, [text, handleProcessedAssetsChanged])

  const handleGoBack = useCallback(() => {
    dispatch(changeStage('idle'))
    dispatch(changeProcessedAssets([]))
    dispatch(changeCurrentAssetIndex(0))
    setText('')
    navigate('/ai-avatars')
  }, [])

  useEffect(() => {
    if (data) setText(data?.assets[0]?.source)
  }, [])

  // const currentAssetUrl = useMemo(() => {
  //   const currentAsset = processedAssets[currentAssetIndex]
  //   return currentAsset?.url
  // }, [processedAssets, currentAssetIndex])

  useEffect(() => {
    return () => {
      if (stage !== 'processing') {
        generationProcessor?.stop()
      }
    }
  }, [generationProcessor, stage])

  return (
    <div className={c.mixerFinishPage}>
      {stage === 'idle' && (
        <div className={c.container}>
          <FinishStage
            isLoading={isLoading}
            text={text}
            // currentAssetUrl={currentAssetUrl}
            currentAssetIndex={currentAssetIndex}
            assets={data?.assets}
            sharedSlides={sharedSlides}
            setSharedSlides={setSharedSlides}
            onTextChange={setText}
            onGenerateMore={handleProcessingStart}
            onGoBack={handleGoBack}
            generationStatus={data?.status || 'pending'}
          />
        </div>
      )}
    </div>
  )
}

export default MixerFinishPage
