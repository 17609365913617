import React from 'react'

// eslint-disable-next-line react/require-default-props
function ZoomMinus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.53 21.472L19.26 18.202C19.962 17.392 20.526 16.483 20.946 15.493C21.48 14.23 21.753 12.886 21.753 11.503C21.753 10.12 21.483 8.77595 20.946 7.51295C20.43 6.29195 19.692 5.19695 18.75 4.25495C17.808 3.31295 16.713 2.57495 15.492 2.05895C14.229 1.52495 12.885 1.25195 11.502 1.25195C10.119 1.25195 8.77498 1.52195 7.51198 2.05895C6.29098 2.57495 5.19598 3.31295 4.25398 4.25495C3.31198 5.19695 2.57398 6.29195 2.05798 7.51295C1.52398 8.77595 1.25098 10.12 1.25098 11.503C1.25098 12.886 1.52098 14.23 2.05798 15.493C2.57398 16.714 3.31198 17.809 4.25398 18.751C5.19598 19.693 6.29098 20.431 7.51198 20.947C8.77498 21.481 10.119 21.754 11.502 21.754C12.885 21.754 14.229 21.484 15.492 20.947C16.482 20.527 17.391 19.963 18.201 19.261L21.471 22.531C21.618 22.678 21.81 22.75 22.002 22.75C22.194 22.75 22.386 22.678 22.533 22.531C22.824 22.237 22.824 21.763 22.53 21.472ZM11.499 20.251C6.67498 20.251 2.75098 16.327 2.75098 11.5C2.75098 6.67595 6.67498 2.74895 11.502 2.74895C16.326 2.75195 20.25 6.67595 20.25 11.5C20.25 16.327 16.326 20.251 11.499 20.251Z"
        fill="white"
      />
      <path
        d="M14.001 10.75H9C8.586 10.75 8.25 11.086 8.25 11.5C8.25 11.914 8.586 12.25 9 12.25H14.001C14.415 12.25 14.751 11.914 14.751 11.5C14.751 11.086 14.415 10.75 14.001 10.75Z"
        fill="white"
      />
    </svg>
  )
}

export default ZoomMinus
