import React from 'react'

// eslint-disable-next-line react/require-default-props
function SortNameIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M20 7H3"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path
        d="M11 12H3"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path
        d="M10 17H3"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path
        d="M13.0442 16.264C12.9308 16.5269 13.0431 16.8357 13.2949 16.9539C13.5468 17.0722 13.8427 16.955 13.956 16.6923L13.0442 16.264ZM16.5 9.52173L16.9559 9.30763C16.8752 9.12041 16.6967 9 16.5 9C16.3033 9 16.1248 9.12041 16.0441 9.30763L16.5 9.52173ZM19.044 16.6923C19.1573 16.955 19.4532 17.0722 19.7051 16.9539C19.9569 16.8357 20.0692 16.5269 19.9558 16.264L19.044 16.6923ZM14.591 13.4268C14.3149 13.4268 14.091 13.6604 14.091 13.9485C14.091 14.2367 14.3149 14.4703 14.591 14.4703V13.4268ZM13.956 16.6923L16.9559 9.73583L16.0441 9.30763L13.0442 16.264L13.956 16.6923ZM19.9558 16.264L18.865 13.7345L17.9531 14.1626L19.044 16.6923L19.9558 16.264ZM18.865 13.7345L16.9559 9.30763L16.0441 9.73583L17.9531 14.1626L18.865 13.7345ZM18.409 13.4268H14.591V14.4703H18.409V13.4268Z"
        fill="white"
      />
    </svg>
  )
}

export default SortNameIcon
