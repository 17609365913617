import { useQuery } from '@tanstack/react-query'
import { getStyles } from 'api'
import { Style } from 'types/Generation'

const useFetchStyles = () => {
  return useQuery<Style[]>(
    ['styles'],
    () => {
      return getStyles()
    },
    { initialData: [] },
  )
}

export default useFetchStyles
