import React from 'react'
import { useTranslation } from 'react-i18next'
import InputRange from 'react-input-range'

import c from './StepsSelection.module.scss'

interface StepsSelectionProps {
  strength: number | undefined
  setStrength: (value: any) => void
}

function StepsSelection({ strength, setStrength }: StepsSelectionProps) {
  const { t } = useTranslation()

  return (
    <div className={c.powerSelection}>
      <p className={c.heading}>{t('stages.generation.idle.choseStepCount')}</p>
      <div className={c.rangeWrapper}>
        <div className={c.line} style={{ left: '0%' }} />
        <div className={c.line} style={{ left: '25%' }} />
        <div className={c.line} style={{ left: '50%' }} />
        <div className={c.line} style={{ left: '75%' }} />
        <div className={c.line} style={{ right: '0%' }} />
        <InputRange
          maxValue={40}
          minValue={10}
          value={strength || 20}
          step={5}
          onChange={(value) => setStrength(value)}
        />
      </div>
    </div>
  )
}

export default StepsSelection
