import React from 'react'

// eslint-disable-next-line react/require-default-props
function DeleteIIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_6006_16345)">
        <path
          d="M4.81624 2.69832C5.1211 2.80602 5.45546 2.64633 5.56317 2.34147C5.81066 1.64137 6.47634 1.17074 7.21953 1.17074C7.96271 1.17074 8.62839 1.64114 8.87588 2.34147C8.96088 2.58171 9.1866 2.73203 9.42777 2.73203C9.49239 2.73203 9.55842 2.72126 9.62282 2.69832C9.92768 2.59061 10.0874 2.25625 9.97966 1.95139C9.56709 0.78416 8.45793 0 7.21953 0C5.98112 0 4.87196 0.78416 4.45939 1.95139C4.35169 2.25625 4.51138 2.59061 4.81624 2.69832Z"
          fill="#EC4B5B"
        />
        <path
          d="M13.8538 3.12109H0.585369C0.262011 3.12109 0 3.3831 0 3.70646C0 4.02982 0.262011 4.29183 0.585369 4.29183H13.8538C14.1772 4.29183 14.4392 4.02982 14.4392 3.70646C14.4392 3.3831 14.1772 3.12109 13.8538 3.12109Z"
          fill="#EC4B5B"
        />
        <path
          d="M12.592 5.07359C12.2703 5.05228 11.9905 5.29603 11.9689 5.61868L11.61 11.0034C11.4821 12.9192 11.4182 13.881 10.9118 14.3547C10.4055 14.8284 9.44154 14.8284 7.52153 14.8284H6.9179C4.99765 14.8284 4.0339 14.8284 3.52744 14.3547C3.02122 13.881 2.95706 12.9194 2.82921 11.0034L2.47027 5.61868C2.44872 5.29603 2.16986 5.05205 1.8472 5.07359C1.52454 5.09513 1.28056 5.374 1.3021 5.69666L1.66105 11.0813C1.80459 13.2343 1.88373 14.4203 2.72759 15.2096C3.57146 15.9991 4.75999 15.9991 6.91766 15.9991H7.5213C9.67896 15.9991 10.8675 15.9991 11.7114 15.2096C12.5552 14.4201 12.6344 13.2341 12.7779 11.0813L13.1369 5.69666C13.1584 5.374 12.9144 5.09513 12.5918 5.07359H12.592Z"
          fill="#EC4B5B"
        />
        <path
          d="M5.65847 12.0968C5.67791 12.0968 5.69758 12.0959 5.71748 12.0938C6.0392 12.0617 6.27381 11.7749 6.24174 11.4532L5.85141 7.55063C5.81933 7.22891 5.5325 6.99429 5.21078 7.02637C4.88907 7.05845 4.65445 7.34528 4.68653 7.667L5.07685 11.5695C5.10706 11.8714 5.36157 12.0966 5.65871 12.0966L5.65847 12.0968Z"
          fill="#EC4B5B"
        />
        <path
          d="M8.78127 12.0986C9.07841 12.0986 9.33293 11.8733 9.36313 11.5715L9.75345 7.66899C9.78553 7.34727 9.55092 7.06044 9.2292 7.02836C8.90725 6.99605 8.62065 7.2309 8.58857 7.55262L8.19825 11.4552C8.16617 11.7769 8.40078 12.0637 8.7225 12.0958C8.7424 12.0977 8.76161 12.0986 8.78127 12.0986Z"
          fill="#EC4B5B"
        />
      </g>
      <defs>
        <clipPath id="clip0_6006_16345">
          <rect width="14.4392" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default DeleteIIcon
