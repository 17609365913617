 import React, { useMemo } from 'react'
import c from './Container.module.scss'

interface ContainerProps {
  children: React.ReactNode
  fluid?: boolean
  flex?: boolean
  fullHeight?: boolean
  noPaddingOnMobile?: boolean
}

function Container({
  children,
  fluid = false,
  flex = false,
  fullHeight = false,
  noPaddingOnMobile = false,
}: ContainerProps) {
  const classNames = useMemo(() => {
    let result = c.container
    if (fluid) result += ` ${c.containerFluid}`
    if (fullHeight) result += ` ${c.containerFullHeight}`
    if (noPaddingOnMobile) result += ` ${c.containerNoPaddingOnMobile}`
    return result
  }, [fluid, fullHeight])

  if (flex) {
    return (
      <div className={classNames}>
        <div className={c.innerContainer}>{children}</div>
      </div>
    )
  }

  return <div className={c.container}>{children}</div>
}

export default Container
