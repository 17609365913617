import React from 'react'
import ProcessingLoader from 'components/ProcessingLoader'

interface ProcessingStageProps {
  seconds: number
}

function ProcessingStage({ seconds }: ProcessingStageProps) {
  return <ProcessingLoader seconds={seconds} />
}

export default ProcessingStage
