import { useCallback } from 'react'
import { useAppDispatch } from './store'

const useModal = (modalOpen: boolean, setModalOpen: any) => {
  const dispatch = useAppDispatch()

  const open = useCallback(() => {
    dispatch(setModalOpen(true))
  }, [])

  const close = useCallback(() => {
    dispatch(setModalOpen(false))
  }, [])

  const toggle = useCallback(() => {
    if (modalOpen) {
      open()
    } else {
      close()
    }
  }, [])

  return { open, close, toggle }
}

export default useModal
