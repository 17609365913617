import React, { useCallback, useState } from 'react'

import Suggestions from 'components/Suggestions'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'

import { useTranslation } from 'react-i18next'
import useFetchSuggestionsForNegativePrompt from '../../hooks/query/useFetchSuggestionsForNegativePrompt'
import ShortArrow from '../icons/ShortArrow'
import c from './SuggestionsContainer.module.scss'
import { Suggestion } from '../../types/Generation'

interface SuggestionsContainerProps {
  onExampleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  suggestions?: Suggestion[]
}

function SuggestionsContainer({
  suggestions = [],
  onExampleClick,
}: SuggestionsContainerProps) {
  const [isPromptsOpen, setIsPromptsOpen] = useState<boolean>(false)

  const { data: suggestionsForNegativePrompt } =
    useFetchSuggestionsForNegativePrompt()

  const { t } = useTranslation()

  const togglePopup = () => {
    setIsPromptsOpen(!isPromptsOpen)
  }

  return (
    <div
      className={classNames({
        [c.promptsContainer]: true,
        [c.promptsContainerUnActive]: !isPromptsOpen,
      })}
    >
      <button
        type="button"
        onClick={togglePopup}
        className={c.wrapperAdvancedSettings}
      >
        <div className={c.leftPart}>
          <div className={c.stylesTitle}>
            {t('stages.generation.idle.suggestionsContainer')}
          </div>
        </div>
        <div className={c.rightPart}>
          <ShortArrow
            className={classNames(c.arrowIcon, {
              [c.arrowIconOpen]: !isPromptsOpen,
            })}
          />
        </div>
      </button>
      <CSSTransition
        in={isPromptsOpen}
        timeout={100}
        classNames={{
          enter: c.settingsEnter,
          enterActive: c.settingsEnterActive,
          exit: c.settingsExit,
          exitActive: c.settingsExitActive,
        }}
        mountOnEnter
        unmountOnExit
      >
        <div className={c.content}>
          <Suggestions
            suggestions={suggestions}
            onExampleClick={onExampleClick}
          />
        </div>
      </CSSTransition>
    </div>
  )
}

export default SuggestionsContainer
