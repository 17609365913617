import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { checkStatus } from 'api'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import { setTechWorkModalOpen } from 'store/reducers/modalReducer'

function CheckBackStatus() {
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const { techWorkModalOpen } = useAppSelector((state) => state.modal)

  const getStatus = async () => {
    try {
      await checkStatus()
    } catch (error) {
      console.log(error)

      dispatch(setTechWorkModalOpen(true))
    }
  }

  useEffect(() => {
    getStatus()
  }, [pathname])

  if (techWorkModalOpen) {
    return (
      <div className="techWorkModal">
        <div className="techWorkModal__content">
          <div>На сайте ведутся технические работы! </div>
          <div className="techWorkModal__subtext">Скоро мы всё починим!</div>
        </div>
      </div>
    )
  }

  return null
}

export default CheckBackStatus
