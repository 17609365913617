import { useQuery } from '@tanstack/react-query'

import { getPluginsById } from 'api/plugins'
import { PluginPostFeed } from 'types/Plugins'

const useFetchPluginById = (id: number) => {
  return useQuery<PluginPostFeed, Error>(['plugin', id], async () => {
    return getPluginsById(id)
  })
}

export default useFetchPluginById
