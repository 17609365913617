import React, { useState } from 'react'
import VolumeIcon from 'components/icons/VolumeIcon'
import Slider from 'rc-slider'
import MutedIcon from 'components/icons/MutedIcon'
import c from './VolumeSetting.module.scss'

interface VolumeSettingProps {
  setMuted: () => void
  volume: number
}

function VolumeSetting({ setMuted, volume }: VolumeSettingProps) {
  return (
    <button className={c.container} type="button" onClick={() => setMuted()}>
      <div className={c.content}>
        <div className={c.soundButton}>
          <div
            className={c.iconContainer}
          >
            {volume <= 0 ? (
              <MutedIcon className={c.iconSetting} />
            ) : (
              <VolumeIcon className={c.iconSetting} />
            )}
          </div>
        </div>
      </div>
    </button>
  )
}

export default VolumeSetting
