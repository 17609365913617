import { EventEmitter } from 'events'
import { getRequestById } from 'api'
import { BaseAsset } from 'types/Response'
import { toast } from 'react-toastify'
import {
  ProcessedAssetsChangedPayload,
  ProcessorEvents,
} from 'types/ProcessorEvents'
import delay from 'utils/delay'

const POLLING_INTERVAL = 3000

export default class AssetsCollector {
  private readonly requestId: number

  private readonly expectedAssetsLength: number

  private emitter?: EventEmitter

  private counter: number = 1

  private processedAssetsLength: number = 0

  private isFirstSucceededResult: boolean = true

  private stopFlag: boolean = false

  constructor(requestId: number, expectedAssetsLength: number) {
    this.requestId = requestId
    this.expectedAssetsLength = expectedAssetsLength
  }

  public setEmitter(emitter: EventEmitter) {
    this.emitter = emitter
    return this
  }

  public async start(): Promise<void> {
    while (
      this.processedAssetsLength < this.expectedAssetsLength &&
      !this.stopFlag
    ) {
      // eslint-disable-next-line no-await-in-loop
      await this.makeRequest()
      this.counter += 1
    }
  }

  public stop(): void {
    this.stopFlag = true
  }

  private async makeRequest(): Promise<void> {
    try {
      const { assets } = await getRequestById(this.requestId)
      this.extractProcessedAssets(assets)
    } catch (error) {
      if (error) {
        this.stop()

        if (error instanceof Error) {
          toast.error(error.message)
          this.emitter?.emit(ProcessorEvents.PROCESSED_ASSETS_CHANGED, {})
        } else {
          toast.error('Something went wrong, try again!')
        }
      }
    }
    await delay(POLLING_INTERVAL)
  }

  private extractProcessedAssets(assets: BaseAsset[]): void {
    const processedAssets = assets.filter((asset) => asset.status === 'success')

    this.emitProcessedAssetChangedIfNeeded(assets, processedAssets.length)
    this.toggleIsFirstSucceededResultIfNeeded(processedAssets.length)

    this.processedAssetsLength = processedAssets.length
  }

  private emitProcessedAssetChangedIfNeeded(
    assets: BaseAsset[],
    processedAssetsLength: number,
  ): void {
    if (processedAssetsLength !== this.processedAssetsLength) {
      const payload: ProcessedAssetsChangedPayload = {
        id: this.requestId,
        assets,
        finish: this.expectedAssetsLength === processedAssetsLength,
        isFirstSucceededResult: this.isFirstSucceededResult,
      }

      this.emitter?.emit(ProcessorEvents.PROCESSED_ASSETS_CHANGED, payload)
    }
  }

  private toggleIsFirstSucceededResultIfNeeded(
    processedAssetsLength: number,
  ): void {
    if (processedAssetsLength !== this.processedAssetsLength) {
      this.isFirstSucceededResult = false
    }
  }
}
