import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import Close from 'components/icons/close.svg'
import LikeIcon from 'components/icons/LikeIcon'
import UnLikeIcon from 'components/icons/UnLikeIcon'

import { likePlugin } from 'api/plugins'
import { PluginPostFeed } from 'types/Plugins'
import useModal from 'hooks/useModal'
import { useAppSelector } from 'hooks/store'
import { setLogInModalOpen } from 'store/reducers/modalReducer'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'

import c from './PluginPost.module.scss'

interface PluginPostProps {
  post: PluginPostFeed
  onePost?: boolean
}

function PluginPost({ post, onePost = false }: PluginPostProps) {
  const {
    id,
    title,
    description,
    image,
    link,
    category,
    is_liked: isLiked,
    likes_count: likesCount,
  } = post

  const navigate = useNavigate()
  const [liked, setLiked] = useState<boolean>(isLiked)
  const [count, setCount] = useState<number>(likesCount)

  const { logInModalOpen } = useAppSelector((state) => state.modal)
  const { open: openLogInModal } = useModal(logInModalOpen, setLogInModalOpen)

  const { data: user } = useFetchRequestMyProfile()

  const queryClient = useQueryClient()

  const mutationLike = useMutation<void, Error>(() => {
    return likePlugin(id!)
  })

  const handleClick = (
    event:
      | React.MouseEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (onePost) return
    if (!(event.target instanceof HTMLElement)) return
    const target = event.target as HTMLElement

    if (
      target.classList.contains(c.postLikes) ||
      target.classList.contains(c.postLink)
    )
      return

    navigate(`${id}`)
  }

  const handleLiked = async () => {
    if (liked) {
      setCount((prev) => prev - 1)
      setLiked(false)
    } else {
      setCount((prev) => prev + 1)
      setLiked(true)
    }
    mutationLike.mutateAsync().then(() => {
      queryClient.invalidateQueries(['plugin', id])
      queryClient.refetchQueries(['infinite-plugins'])
    })
  }

  useEffect(()=> {
    setLiked(post.is_liked)
    setCount(post.likes_count)
  },[post.is_liked , post.likes_count])

  const goBack = () => {
    navigate(-1)
  }

  return (
    <div
      className={c.postContainer}
      data-type-plugin={onePost}
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
    >
      <div className={c.postHeader}>
        <img className={c.avatar} src={image} alt="avatar" />
        <p className={c.postTitle}>{title}</p>
        {onePost && (
          <button className={c.postClose} onClick={goBack} type="button">
            <img src={Close} alt="close" />
          </button>
        )}
      </div>
      <p className={c.postText} data-type={onePost}>
        {description}
      </p>
      <div className={c.postCategory}>{category?.title}</div>
      <div className={c.postFoot}>
        <button
          type="button"
          onClick={!user?.id ? openLogInModal : handleLiked}
          className={c.postLikes}
        >
          {liked ? <LikeIcon /> : <UnLikeIcon />}
          {count}
        </button>
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className={c.postLink}
        >
          Open this plugin
        </a>
      </div>
    </div>
  )
}

export default PluginPost
