import React from 'react'

// eslint-disable-next-line react/require-default-props
function PremiumIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.9519 11.6444L20.9149 9.31639C20.8189 9.20539 20.7709 9.06139 20.7829 8.91439L21.0679 5.83639C21.0919 5.56939 20.9149 5.32339 20.6509 5.26339L17.6389 4.57639C17.4949 4.54339 17.3719 4.45339 17.2939 4.32439L15.7159 1.65439C15.5779 1.42039 15.2899 1.32739 15.0409 1.43539L12.2059 2.65339C12.0709 2.71039 11.9179 2.71039 11.7829 2.65339L8.94791 1.43539C8.69891 1.32739 8.41091 1.42339 8.27291 1.65439L6.69791 4.31539C6.62291 4.44139 6.49691 4.53439 6.35291 4.56439L3.34391 5.23939C3.07991 5.29939 2.90291 5.54539 2.92691 5.81239L3.21191 8.90239C3.22391 9.04939 3.17891 9.19339 3.08291 9.30439L1.04291 11.6444C0.865906 11.8484 0.865906 12.1484 1.04291 12.3524L3.07991 14.6834C3.17591 14.7944 3.22391 14.9384 3.21191 15.0854L2.92691 18.1754C2.90291 18.4424 3.07991 18.6884 3.34391 18.7484L6.35891 19.4294C6.50291 19.4624 6.62591 19.5524 6.70091 19.6784L8.27591 22.3394C8.41391 22.5734 8.70191 22.6664 8.95091 22.5584L11.7859 21.3344C11.9209 21.2744 12.0739 21.2744 12.2119 21.3344L15.0469 22.5524C15.2959 22.6604 15.5839 22.5644 15.7219 22.3334L17.2969 19.6724C17.3719 19.5464 17.4949 19.4564 17.6389 19.4234L20.6509 18.7394C20.9149 18.6794 21.0919 18.4334 21.0679 18.1664L20.7829 15.0884C20.7679 14.9414 20.8159 14.7974 20.9149 14.6864L22.9519 12.3584C23.1289 12.1484 23.1289 11.8484 22.9519 11.6444Z"
        fill="url(#paint0_linear_5279_3636)"
      />
      <path
        d="M9.54625 16.3328L6.64225 13.4228C6.36925 13.1498 6.36925 12.7088 6.64225 12.4358L7.17025 11.9078C7.44325 11.6348 7.88725 11.6348 8.16025 11.9078L9.54625 13.2998C9.81925 13.5728 10.2632 13.5728 10.5362 13.2998L15.5373 8.28678C15.8103 8.01378 16.2543 8.01378 16.5273 8.28678L17.0553 8.81478C17.3282 9.08778 17.3282 9.52878 17.0553 9.80178L10.5362 16.3328C10.2632 16.6088 9.81925 16.6088 9.54625 16.3328Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5279_3636"
          x1="-3.16837"
          y1="11.9969"
          x2="25.1515"
          y2="26.3926"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9E5992" />
          <stop offset="0.505208" stopColor="#6A19AB" />
          <stop offset="1" stopColor="#3054BB" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default PremiumIcon
