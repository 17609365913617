import { useInfiniteQuery } from '@tanstack/react-query'
import { getRequests } from 'api'
import Handlers from 'types/Handlers'
import { BaseResponse } from 'types/Response'
import { useAppSelector } from 'hooks/store'
import { auth } from '../../utils/firebase'

const FIRST_LIMIT = 6
const REST_LIMIT = 6

const getLimit = (page: number): number => {
  return page === 1 ? FIRST_LIMIT : REST_LIMIT
}

const getOffset = (page: number): number => {
  return page === 1 ? 0 : FIRST_LIMIT + (page - 2) * REST_LIMIT
}

interface Params {
  sourceType?: `${'image' | 'video' | 'text' | 'hybrid' | 'user_name'}`
  handler?: `${Handlers}`
}

const useInfiniteFetchRequests = ({ sourceType, handler }: Params) => {
  const deviceId = useAppSelector((state) => state.settings.deviceId)

  const deps = [deviceId, sourceType, handler]

  return useInfiniteQuery<BaseResponse[], Error>(
    ['infinite-requests', deps],
    async ({ pageParam: page = 1 }) => {
      const limit = getLimit(page)
      const offset = getOffset(page)

      const token = await auth.currentUser?.getIdToken()
      if (token) {
        return getRequests(limit, offset, sourceType, handler)
      }

      throw new Error('Invalid token')
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const expectedLength = getLimit(allPages.length)
        if (lastPage.length < expectedLength) return undefined
        return allPages.length + 1
      },
    },
  )
}

export default useInfiniteFetchRequests
