import React from 'react'

// eslint-disable-next-line react/require-default-props
function AppStoreIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <path
        d="M16.95 3.5H7.05C4.813 3.5 3 5.313 3 7.55V17.45C3 19.6865 4.813 21.5 7.05 21.5H16.95C19.187 21.5 21 19.6865 21 17.45V7.55C21 5.313 19.187 3.5 16.95 3.5Z"
        fill="url(#paint0_linear_5879_16588)"
      />
      <path
        opacity="0.05"
        d="M6.15039 15.9884C5.46139 15.9884 4.90039 15.4314 4.90039 14.7464C4.90039 14.0614 5.46139 13.5044 6.15039 13.5044H8.04939L10.4839 9.34991L9.77239 8.13541C9.60339 7.84641 9.55689 7.50991 9.64139 7.18691C9.72589 6.86391 9.93139 6.59291 10.2194 6.42491C10.4114 6.31291 10.6294 6.25391 10.8504 6.25391C11.2929 6.25391 11.7064 6.49091 11.9294 6.87241L11.9329 6.87841L11.9359 6.87291C12.1584 6.49141 12.5719 6.25441 13.0149 6.25441C13.2364 6.25441 13.4544 6.31391 13.6464 6.42591C14.2414 6.77491 14.4414 7.54241 14.0924 8.13691L10.9469 13.5049H12.6109L12.7574 13.6524C12.8684 13.7639 12.9699 13.8904 13.0689 14.0394L13.1674 14.2044C13.4119 14.6599 13.4664 15.1634 13.3269 15.6314L13.2214 15.9884H6.15039Z"
        fill="black"
      />
      <path
        opacity="0.07"
        d="M6.15039 15.7389C5.59889 15.7389 5.15039 15.2939 5.15039 14.7469C5.15039 14.1999 5.59889 13.7549 6.15039 13.7549H8.19239L10.7734 9.35041L9.98739 8.00941C9.85239 7.77891 9.81489 7.50941 9.88289 7.25041C9.95039 6.99191 10.1144 6.77541 10.3449 6.64091C10.4984 6.55141 10.6729 6.50391 10.8499 6.50391C11.2039 6.50391 11.5349 6.69341 11.7134 6.99841L11.9324 7.37291L12.1514 6.99891C12.3294 6.69391 12.6604 6.50441 13.0144 6.50441C13.1914 6.50441 13.3659 6.55191 13.5194 6.64141C13.9954 6.92091 14.1549 7.53491 13.8759 8.01041L10.5104 13.7549H12.5064L12.5794 13.8284C12.6784 13.9279 12.7699 14.0419 12.8594 14.1774L12.9519 14.3324C13.1609 14.7229 13.2074 15.1554 13.0869 15.5604L13.0339 15.7389H6.15039Z"
        fill="black"
      />
      <path
        d="M12.6514 14.3153C12.5774 14.2033 12.4954 14.0983 12.4024 14.0048H10.0744L13.6609 7.88376C13.8704 7.52626 13.7504 7.06676 13.3934 6.85726C13.0354 6.64776 12.5759 6.76776 12.3674 7.12526L11.9324 7.86726L11.4974 7.12476C11.2884 6.76726 10.8289 6.64776 10.4714 6.85676C10.1139 7.06576 9.99389 7.52526 10.2034 7.88276L11.0634 9.35026L8.33589 14.0048H6.15039C5.73589 14.0048 5.40039 14.3373 5.40039 14.7468C5.40039 15.1563 5.73589 15.4888 6.15039 15.4888H12.8474C12.9444 15.1623 12.9179 14.7978 12.7369 14.4598L12.6514 14.3153Z"
        fill="white"
      />
      <path
        opacity="0.05"
        d="M7.24982 18.5895C7.02832 18.5895 6.81032 18.53 6.61832 18.4175C6.33032 18.2485 6.12532 17.9775 6.04082 17.6545C5.95682 17.3315 6.00332 16.9945 6.17232 16.7065L6.52882 16.0975L6.74882 16.0535C6.92932 16.018 7.09782 16 7.26382 16L7.32282 16.0005C7.86332 16.0165 8.35282 16.2475 8.70582 16.6505L8.94382 16.9215L8.32932 17.9715C8.10532 18.353 7.69232 18.5895 7.24982 18.5895Z"
        fill="black"
      />
      <path
        opacity="0.07"
        d="M7.24991 18.3395C7.07291 18.3395 6.89791 18.292 6.74491 18.202C6.51491 18.067 6.35041 17.85 6.28291 17.5915C6.21541 17.333 6.25241 17.0635 6.38791 16.833L6.68791 16.321L6.79791 16.299C6.96241 16.266 7.11491 16.25 7.26391 16.25L7.31991 16.2505C7.78641 16.2645 8.21141 16.465 8.5179 16.815L8.63691 16.9505L8.1134 17.845C7.93491 18.15 7.60391 18.3395 7.24991 18.3395Z"
        fill="black"
      />
      <path
        d="M7.31257 16.5007C7.15407 16.4962 6.99857 16.5137 6.84657 16.5442L6.60307 16.9597C6.39357 17.3172 6.51357 17.7767 6.87107 17.9862C6.99007 18.0562 7.12107 18.0892 7.24957 18.0892C7.50707 18.0892 7.75807 17.9562 7.89707 17.7187L8.32957 16.9802C8.08607 16.7022 7.73457 16.5132 7.31457 16.5007H7.31257Z"
        fill="white"
      />
      <path
        opacity="0.05"
        d="M16.6147 18.5886C16.1722 18.5886 15.7587 18.3516 15.5357 17.9706L12.5222 12.8281L12.5137 12.8021C12.3052 12.1576 12.3462 11.4931 12.6207 10.9056L13.4552 9.47656L15.8152 13.5041H17.8502C18.5392 13.5041 19.1002 14.0611 19.1002 14.7461C19.1002 15.4311 18.5392 15.9881 17.8502 15.9881H17.2707L17.6917 16.7066C17.8607 16.9946 17.9072 17.3311 17.8232 17.6546C17.7392 17.9781 17.5337 18.2486 17.2457 18.4176C17.0547 18.5291 16.8367 18.5886 16.6147 18.5886Z"
        fill="black"
      />
      <path
        opacity="0.07"
        d="M16.6149 18.3402C16.2609 18.3402 15.9299 18.1512 15.7514 17.8462L12.7514 12.7272L12.7429 12.7012C12.5624 12.1422 12.5989 11.5427 12.8469 11.0132L13.4549 9.97266L15.6714 13.7552H17.8504C18.4019 13.7552 18.8504 14.2002 18.8504 14.7472C18.8504 15.2942 18.4019 15.7392 17.8504 15.7392H16.8349L17.4764 16.8342C17.6119 17.0647 17.6489 17.3342 17.5814 17.5927C17.5139 17.8512 17.3499 18.0682 17.1194 18.2032C16.9669 18.2927 16.7924 18.3402 16.6149 18.3402Z"
        fill="black"
      />
      <path
        d="M17.8504 14.0048H15.5289L13.4554 10.4668L13.0739 11.1183C12.8519 11.5933 12.8219 12.1303 12.9814 12.6238L15.9674 17.7193C16.1069 17.9573 16.3574 18.0898 16.6149 18.0898C16.7434 18.0898 16.8744 18.0568 16.9934 17.9868C17.3509 17.7773 17.4704 17.3178 17.2609 16.9603L16.3984 15.4888H17.8504C18.2649 15.4888 18.6004 15.1563 18.6004 14.7468C18.6004 14.3373 18.2649 14.0048 17.8504 14.0048Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5879_16588"
          x1="12"
          y1="2.8085"
          x2="12"
          y2="20.548"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#33BEF0" />
          <stop offset="1" stopColor="#0A85D9" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default AppStoreIcon