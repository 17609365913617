import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import HelpIcon from 'components/icons/HelpIcon'
import GenerateHelpModal from 'components/GenerateHelpModal'
import CloseIcon from 'components/icons/CloseIcon'
import { useAppSelector } from 'hooks/store'

import c from './GenerateHelp.module.scss'

function GenerateHelp() {
  const { t } = useTranslation()
  const language = useAppSelector((state) => state.settings.language)
  const { pathname } = useLocation()

  const [active, setActive] = useState(false)
  const [modalData, setModalData] = useState<{
    description: string
    title: string
    src?: string
  }>({
    description: '',
    title: '',
    src: '',
  })

  const handleModalDataText = () => {
    switch (pathname) {
      case '/enhancement': {
        setModalData({
          description: t('modals.generateHelper.enhancement.description'),
          title: t('modals.generateHelper.enhancement.heading'),
          src: '/enhanceVideo.mp4',
        })
        break
      }
      case '/face-restoration': {
        setModalData({
          description: t('modals.generateHelper.faceRestoration.description'),
          title: t('modals.generateHelper.faceRestoration.heading'),
          src: '/restorationVideo.mp4',
        })
        break
      }
      case '/background-remover': {
        setModalData({
          description: t('modals.generateHelper.removeBackground.description'),
          title: t('modals.generateHelper.removeBackground.heading'),
          src: '/removeVideo.mp4',
        })
        break
      }
      case '/magic-variations': {
        setModalData({
          description: t('modals.generateHelper.magicVariations.description'),
          title: t('modals.generateHelper.magicVariations.heading'),
        })
        break
      }
      case '/neuro-art': {
        setModalData({
          description: t('modals.generateHelper.neuroArt.description'),
          title: t('modals.generateHelper.neuroArt.heading'),
        })
        break
      }
      case '/dall-e-3': {
        setModalData({
          description: t('modals.generateHelper.dalle3.description'),
          title: t('modals.generateHelper.dalle3.heading'),
        })
        break
      }
      case '/ai-avatars': {
        setModalData({
          description: t('modals.generateHelper.aiAvatars.description'),
          title: t('modals.generateHelper.aiAvatars.heading'),
        })
        break
      }
      case '/video-art': {
        setModalData({
          description: t('modals.generateHelper.videoArt.description'),
          title: t('modals.generateHelper.videoArt.heading'),
        })
        break
      }
      default:
        setModalData({
          description: '',
          title: '',
        })
        break
    }
  }

  const handleClick = () => {
    setActive(!active)
  }

  useEffect(() => {
    handleModalDataText()
  }, [pathname, language])

  if (modalData.description === '' || modalData.title === '') return null

  return (
    <div className={c.container}>
      <GenerateHelpModal
        className={classNames({ [c.active]: active })}
        title={modalData.title}
        src={modalData.src}
        description={modalData.description}
        handleClick={handleClick}
      />
      <button type="button" className={c.iconContainer} onClick={handleClick}>
        {active ? <CloseIcon /> : <HelpIcon />}
      </button>
    </div>
  )
}

export default GenerateHelp
