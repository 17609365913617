import React from 'react'

// eslint-disable-next-line react/require-default-props
function LockIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.375 1.6875C11.0288 1.6875 9.9375 2.7788 9.9375 4.125C9.9375 4.27193 9.95048 4.41582 9.97545 4.55559L6.42743 7.03919C6.4094 7.0518 6.39234 7.06529 6.37626 7.07954C5.96245 6.7556 5.44127 6.5625 4.875 6.5625C3.5288 6.5625 2.4375 7.65382 2.4375 9C2.4375 10.3462 3.5288 11.4375 4.875 11.4375C5.44127 11.4375 5.96245 11.2444 6.37626 10.9205C6.39234 10.9347 6.4094 10.9482 6.42743 10.9608L9.97545 13.4444C9.95048 13.5841 9.9375 13.7281 9.9375 13.875C9.9375 15.2212 11.0288 16.3125 12.375 16.3125C13.7212 16.3125 14.8125 15.2212 14.8125 13.875C14.8125 12.5288 13.7212 11.4375 12.375 11.4375C11.5854 11.4375 10.8835 11.8129 10.4381 12.395L7.07854 10.0433C7.22857 9.72705 7.3125 9.37335 7.3125 9C7.3125 8.62665 7.22857 8.27295 7.07854 7.95668L10.4381 5.60497C10.8835 6.18707 11.5854 6.5625 12.375 6.5625C13.7212 6.5625 14.8125 5.4712 14.8125 4.125C14.8125 2.7788 13.7212 1.6875 12.375 1.6875ZM11.0625 4.125C11.0625 3.40013 11.6501 2.8125 12.375 2.8125C13.0999 2.8125 13.6875 3.40013 13.6875 4.125C13.6875 4.84987 13.0999 5.4375 12.375 5.4375C11.6501 5.4375 11.0625 4.84987 11.0625 4.125ZM4.875 7.6875C4.15013 7.6875 3.5625 8.27513 3.5625 9C3.5625 9.72488 4.15013 10.3125 4.875 10.3125C5.59987 10.3125 6.1875 9.72488 6.1875 9C6.1875 8.27513 5.59987 7.6875 4.875 7.6875ZM12.375 12.5625C11.6501 12.5625 11.0625 13.1501 11.0625 13.875C11.0625 14.5999 11.6501 15.1875 12.375 15.1875C13.0999 15.1875 13.6875 14.5999 13.6875 13.875C13.6875 13.1501 13.0999 12.5625 12.375 12.5625Z"
        fill="white"
      />
    </svg>
  )
}

export default LockIcon
