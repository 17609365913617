import React from 'react'

import Button from 'components/common/Button'

import { useTranslation } from 'react-i18next'

import c from './ConceptFixedButton.module.scss'

interface ConceptFixedButtonProps {
  handlePostConcept: () => void
  imageFiles: (File | undefined)[]
  name: string
  text?: string
  className?: string
}

function ConceptFixedButton({
  handlePostConcept,
  imageFiles,
  name = '',
  text = undefined,
  className = '',
}: ConceptFixedButtonProps) {
  const { t } = useTranslation()

  return (
    <Button
      className={`${c.button} ${className}`}
      disabled={
        imageFiles.filter((file) => file !== undefined).length <= 0 ||
        name.length <= 0
      }
      onClick={handlePostConcept}
    >
      {text || 'Create'}
    </Button>
  )
}

export default ConceptFixedButton
